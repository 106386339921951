import React, {Component} from "react";

class Banner extends Component {

    state = {
        title: ''
        
    };

    componentDidMount(){
        this.setState({
            title: this.props
        })
 
    };

    render() { 
        return (
            <section className="breadcrumb-area d-flex align-items-center" 
            style={{height:"200px",marginTop:'75px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content text-center">
                                <h2 className="m-0 normal-font text-dark" dangerouslySetInnerHTML={{__html: this.props.title}}></h2>
                            </div>
                        </div>
                    </div>
                </div>
  
            </section>
        );
    }
}
 
export default Banner;