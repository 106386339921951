import React, {Component, useState, useEffect} from "react";


//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
//new
import ModalLogin from "../../components/Modal/ModalLogin";
// import Banner from "../../components/Banner/Banner";
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import AuctionSoon from "../../components/Auctions/AuctionSoon";
import apiClient from "../../helpers/api";
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";
import ViewAuction from "../../components/Auctions/ViewAuctions/ViewAuction";

const ViewAuctionPage = () => {

    const [is_auth, setIsAuth] = useState(true);

    useEffect(() => {

        apiClient.get('/account/user')
        .then(res => {
          
            setIsAuth(true)
        }).catch(e => {
            setIsAuth(false)
            localStorage.clear()
        })

    })

    if (!is_auth) {
       return <PageNotFound/>
    }

    return (
        <div className="main">
            <PageLoading />
            <Header/>
            <BannerSale  title="Auction" />
            <ViewAuction />
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <ModalLogin/>
            <Scrollup />
        </div>
    );
  
}
 
export default ViewAuctionPage;