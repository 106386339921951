import React,{useState} from "react";
import CountdownTimer from "../CountdownTimer/Countdown";
import { NavLink } from "react-router-dom";

const AuctionItemGrid = (props) => {

    const partnerCode = typeof props.auction_code !== 'undefined' ? props.auction_code : ''
    const btn_outline_red = {
        border: '1px solid #c31b01',
        backgroundColor: '#fffff',
        color: '#c31b01'
    }

    const [isEnded,setisEnded] = useState([]);
    const getCollectionStatus = (v) => {
        if(!isEnded.includes(v)){
            setisEnded(isEnded => [...isEnded,v]);
        }
    }

    return (
        <div className="col-md-4 mb-2" key={props.key}>
        <div className="border p-2">
            <NavLink to={`/auction-details/${props.collection_}/${props.label}/${partnerCode}`}>
                <img src={props.art_details && props.art_details._mint_file} className="img-fluid" />
            </NavLink>
            <h3 className="text-dark mb-0 mt-2">{props.label}</h3>
            <p className="text-dark mb-2 mt-1 ">{props.artist && props.artist.artist_name}</p>
            

            {
                !props.highest_offer && props.base_price  ? 
                <p className=" mb-2 mt-1"><span className="font-weight-bold">Starting Price:</span> PHP {props.base_price && props.base_price.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}</p> : "" 
                

            } 

            {
                props.highest_offer ? 
                <p className="my-1 mb-3"><span className="font-weight-bold">Highest Bid:</span> PHP {props.highest_offer && props.highest_offer.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}</p>
                : ""
            }

            {
            !isEnded.includes(props.key) && (props.auctionstatus == 1 && props.isStarted) ?
               <CountdownTimer show={props.showTimer != 1 ?  'd-none' : ''} id={props.key} changeIsEnded={getCollectionStatus} targetDate={props.bid_clode_date} />
               : ""
            }
            
            { 
                props.free ? <p className="text-dark font-weight-bold text-uppercase  mb-2 mt-1" style={{cursor: "pointer"}} onClick={props.viewMerch}><u>{props.merch_title ? props.merch_title : "View Merch"}</u></p> : ""
            }
            
            {
          
             !isEnded.includes(props.key)  && (props.auctionstatus == 1 && props.isStarted)  ?
                <>
                    <NavLink to={`/auction-details/${props.collection_}/${props.label}/${partnerCode}`} className="btn btn-danger rounded-0 px-5 mb-3">BID</NavLink>
                </>
                : 
                    !props.isStarted ?
                    <p className="my-2"><span className=" text-danger text-uppercase font-weight-bold">Coming Soon</span></p>
                    : <p className="my-2"><span className=" text-danger text-uppercase font-weight-bold">Auction Ended</span></p>
                        
            }
            
        </div>
    </div>
    )


}
 
export default AuctionItemGrid;