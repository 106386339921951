import React, {Component} from "react";


//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Banner from "../../components/Banner/Banner";
import ModalLogin from "../../components/Modal/ModalLogin";
import Scrollup from "../../components/Scrollup/Scrollup";
import ModalMenu from '../../components/Modal/ModalMenu';
import ModalSearch from '../../components/Modal/ModalSearch';
import CollectionDetails from "../../components/CollectionDetails/CollectionDetails";
import BannerSale from "../../components/Banner/BannerSale";

class Details extends Component {


    render() { 
        return (
            <div className="main">
                <Header/>
                <BannerSale title="My Collection"/>
                <CollectionDetails/>
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <ModalLogin/>
                <Scrollup />
            </div>
        );
    }
}
 
export default Details;