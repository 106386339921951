import React, {Component} from "react";
import axios from "axios";


//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalLogin from "../../components/Modal/ModalLogin";
import Scrollup from "../../components/Scrollup/Scrollup";
// import Banner from "../../components/Banner/Banner";
import ItemDetails from "../../components/ItemDetails/ItemDetails";
import ModalMenu from '../../components/Modal/ModalMenu';
import ModalSearch from '../../components/Modal/ModalSearch';
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";

class ItemDetail extends Component {

    state = {
        data: [],
        content: ''
        
    };
    componentDidMount(){
        axios.get('http://localhost:3000/json-server/data.json')
        .then(res => {
            this.setState({
                data: res.data,
                content: res.data.item_details
               
            })
        })
    }


    render() { 
        return (
            <div className="main">
                <PageLoading />
                <Header/>
                <BannerSale  title={this.state.content.title} />
                <ItemDetails/>
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <ModalLogin/>
                <Scrollup />
            </div>
        );
    }
}
 
export default ItemDetail;