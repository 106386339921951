import React, {Component} from "react";

class Faq extends Component {
    render() { 
        return (
            <section className="faq-area pt-5">
            <div className="container">
               <div className="col-12">
                    <div className="faq-content">
                        <div className="accordion" id="netstorm-accordion">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10">
                                    {
                                        this.props.params.map((res, id)=> {
                                            return (
                                                <div className="single-accordion-item p-3 px-0">
                                                    <div className="bg-inherit border-0 p-0">
                                                            <button className="btn d-block border border-dark text-left w-100 py-4 text-dark" type="button" data-toggle="collapse" data-target={`#collapseFaq${id}`}>
                                                            <span className="font-weight-normal">{res.questions}</span>
                                                            </button>
                                                    </div>
                                                    <div id={`collapseFaq${id}`} className="collapse" data-parent="#netstorm-accordion">
                                                        <div className="card-body py-3" dangerouslySetInnerHTML={{__html: res.answers}}>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}
 
export default Faq;