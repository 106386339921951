import React, {useState, useEffect} from 'react';
import apiClient from '../../helpers/api';
const Artists = (props) => {
    const [artists, setArtists] = useState([])
    useEffect(() => {
        try{
            apiClient.get(`${process.env.REACT_APP_API}/vip/get/artists/${props.vipida}`)
            .then(res => {
                setArtists(res.data.results);
            }).catch( err => {
                console.log(err)
            })
        } catch(e){

        }
         
    }, []);

    return (
        <div key="1" className="tab-pane fade" id="artist" role="tabpanel" aria-labelledby="artist-tab">
            
            <div className='row justify-content-center '>
                {artists.map((artist_val, artist_id)=> { 
                    return (
                    <>
                    <div  className='col-md-3 pt-5'>
                        <img src={artist_val.photo} className='img-fluid'></img>
                    </div>
                    <div    className='col-md-7 pt-5'>
                        <h3 className='text-dark my-0'>{artist_val.artist_name}</h3>
                        <p dangerouslySetInnerHTML={{__html: artist_val.description}}>
                        </p>
                    </div>
                    </>
                    )
                })
                }
            </div>
        </div>
    );

};
export default Artists;
