import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ModalLogin from '../Modal/ModalLogin';
import ModalSearch from '../Modal/ModalSearch';
import ModalMenu from '../Modal/ModalMenu';
import Scrollup from '../Scrollup/Scrollup';
import apiClient from "../../helpers/api";

const PageNotFound = (props) => {
    const [redirectIt,setRedirectIt] = useState(true)

    useEffect(() => {

        if(typeof props.url !== 'undefined'){
            apiClient.post(`${process.env.REACT_APP_API}/tools/seo_redirect/`,{
                url:props.url
            }).then(res => {
                window.location.href = res.data.link;
                setRedirectIt(true);
            }).catch(function (error) {
                setRedirectIt(false);
            });
        }

    }, [])

    return (
        <>
        {
            !redirectIt ?
            <>
             <Header/>
                <section className="hero-section page-not-found" style={{padding:"0px", marginTop:"95px"}}>
                    {/* <img className="img-fluid" src={this.state.page_not_found.image} alt="Page Not Found"/> */}
                    <img className="img-fluid" src="/img/Page Not Found Page.jpg" alt="Page Not Found"/>
                </section>
            <ModalLogin />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
            <Footer/>
        </> :
         ''
        }
       
        </>
    );

}

export default PageNotFound;
