import React, {Component, useState, useEffect} from "react";
import apiClient from '../../helpers/api';

class RoadMap extends Component {
 
    render() {

        // if(this.props.params.length == null){
           
        // }

        // return null;

        return (

            <div className="row items justify-content-center xbtk mt-5 pt-5" id="roadmap"> 
                <div className="col-12 ">
                    <h2 className="mb-4 text-center normal-font text-dark" >
                    Road Map
                    </h2>
                </div>  
                <div className="card-body" >

                    <div className="container">
                        <div className="timeline">

                        {
                            this.props.params.map((data, idx) => {
                                return(

                                <div key={`att_${idx}`} className="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes pl-lg-5 pl-xl-0">
                                    <div className="col-10 col-md-8 col-xl-5 order-3 order-md-1 timeline-content mt-xl-3">
                                        <h4 className="text-dark mt-2 mb-0 normal-font">{data.title}</h4>
                                        <div className="pt-0 px-3 my-2 text-dark" dangerouslySetInnerHTML={{__html: data.details}}></div>

                                    </div>
                                    <div className="col-2 col-md-2 col-xl-2 px-lg-5 order-2 timeline-image text-md-center">
                                        <img src={window.photo_url+data.photo} className="rounded-circle" alt="img" />
                                        {/* <img src="/img/circle.png" className="rounded-circle" alt="img" /> */}
                                    </div>
                                    <div className="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                                        
                                    </div>
                                </div>

                                );
                            })
                        }
                            {/* <div className="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
                                <div className="col-10 col-md-5 order-3 order-md-1 timeline-content">
                                    <h3 className="mt-0 text-light">Timeline Heading 1</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, eaque amet deleniti hic quas qui cumque delectus aliquid, eius quia quod, quae, aliquam aspernatur facilis. Minima quod corporis dignissimos porro.</p>
                                </div>
                                <div className="col-2 col-sm-2 px-md-4 order-2 timeline-image text-md-center">
                                    <img src="/img/artwork/Bids Mock Artwork 2.jpg" className="rounded-circle" alt="img" />
                                </div>
                                <div className="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                                    
                                </div>
                            </div> */}



                        </div>
                    </div>
                    



                </div>
            </div>
        

        );
    }

}

export default RoadMap;