import { useEffect, useState } from "react";
import apiClient from "../../helpers/api";
import Modal from "../Modal/Modal";
import { Alert } from "react-bootstrap";


const AuctionWinner = (props) => {

    const [control_art, setControlArt] = useState({});
    const [control_bid, setControlBid] = useState({});
    const [user, setUser]   = useState({});
    const [isError, setIsError]        = useState('')

    const btn_outline_red = {
        border: '1px solid #c31b01',
        backgroundColor: '#fffff',
        color: '#c31b01'
    }

    const verifyLogin = async () => {
        try {
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);
            if (res.status == 200) {
                setUser(res.data)
                return true;
            } 
        } catch (error) {
            return false;
        }
    }

    const getControlNumberDetails = () => {
        try {
            apiClient.get(`${process.env.REACT_APP_API}/auctions/get/art_control_number/${props.auction_url}`, {
                params: {
                  control_number: props.control_number
                }
              }).then(res => {
                setControlArt(res.data.art)
                setControlBid(res.data.bid)
            }).catch(errors => {
                console.log(errors.response.data.message);
                setIsError(errors.response.data.message)
            })
        } catch (error) {
            
        }
    }

    useEffect(() => {

        const isLogged = verifyLogin()

        console.log(isLogged);

        getControlNumberDetails()
    }, [])

    return (
        <section className="item-details-area pt-5">
            <div className='container'>
                {
                    !isError ? (
                        <div className="row border my-5 p-5">
                            <div className="col-md-3">
                                <img src={control_art.art_details && control_art.art_details._mint_file}/>
                            </div>
                            <div className="col-md-9">
                                <p className="my-2 font-weight-bolder" style={{fontSize: '20px'}}>{control_art.label}</p>
                                <p className="my-2 font-weight-bold">Artist: {control_art.artist ? control_art.artist.artist_name : ''}</p>
                                <p className="my-2 ">
                                {control_art.art_details ? control_art.art_details.description : ""}
                                </p>
                                <button type="button" className="btn btn-danger rounded-0 px-5 mb-3"> Proceed payment </button>
                                <button type="button" className="btn ml-sm-2 rounded-0 px-5 mb-3"  data-toggle="modal" data-target="#auctionMerchImg" style={btn_outline_red}>View Merch</button>
                                <p className="my-0"><span className="font-weight-bold">Highest Bid:</span> PHP {control_bid.bid_amount ? control_bid.bid_amount.toLocaleString(undefined, {maximumFractionDigits:2}) : ""}</p>
                                {
                                    props.base_price ? <p className="my-0"><span className="font-weight-bold">Starting Price:</span> PHP {props.base_price && props.base_price.toLocaleString(undefined, {maximumFractionDigits:2})}</p> : ""
                                }
                            </div>
                            <Modal id={'auctionMerchImg'} title="View Merch" img={control_art.merch_image} size="lg" product={props.product_details} shipping={props.shipping_details}/>
                        </div>
                    ) : (
                        <Alert show={true} variant="success" className="d-flex justify-content-between">
                            <p className="mb-0">{isError}</p> 
                        </Alert>
                    )
                }
                
            </div>
        </section> 
    );
}
    

 
export default AuctionWinner;