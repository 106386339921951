import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Collection from '../../pages/CollectionV2/Collection';


class ProjectCarousel extends Component {
   
    state = {
        content: ''

    };

    componentDidMount(){
        this.setState({
            content: this.props
         
          
        })
    console.log(this.props.content)

    };

    render() {
        return (
            <>
                <Carousel 
                // prevLabel={<span aria-hidden="true" className="carousel-control-prev-icon" />} 
                // nextLabel={<span aria-hidden="true" className="carousel-control-next-icon" />} 
                // pause={false} 
                // nextIcon={false}  
                // prevIcon={false}
                indicators={0}
                prevLabel={false} 
                nextLabel={false} 
                pause={false} 
                nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}  
                prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
                style={{paddingTop: "65px"}} >
                {
                    this.props.content.map((item, idx) => {
                        return (
                            <Carousel.Item>
                                <div key={`auc_${idx}`}>
                                    {
                                        item.button_target ? (
                                            <a href={item.button_target ? item.button_target : "#"} target={item.button_target ? "_blank" : ''}>
                                                <img src={window.photo_url+item.photo} alt="nft_image" className="img-fluid d-none d-md-block"/>
                                                <img src={window.photo_url+item.img_photo} alt="nft_images" className="img-fluid d-md-none"/>
                                                {item.header ?
                                                    <Carousel.Caption>
                                                        <div className='carousel-caption d-md-block'>
                                                            <h1 className="section_title text-yellow" dangerouslySetInnerHTML={{ __html: item.header}}></h1>
                                                            {item.sub_header ?
                                                                <p className="text-uppercase subtitla" dangerouslySetInnerHTML={{ __html: item.sub_header}}></p>
                                                                :
                                                                ""
                                                            }
                                                            {item.buttons ?
                                                                <p>
                                                                    <div className="button-group">
                                                                        <a className="btn bg-white text-danger" href={item.button_target}>{item.buttons}</a>
                                                                    </div>
                                                                </p>
                                                                :
                                                                ""
                                                            }
                                                            
                                                        
                                                        </div>
                                                    </Carousel.Caption>
                                                    :
                                                    ""
                                                } 
                                            </a>
                                        ) : (
                                            <>
                                                <img src={window.photo_url+item.photo} alt="nft_image" className="img-fluid d-none d-md-block"/>
                                                <img src={window.photo_url+item.img_photo} alt="nft_images" className="img-fluid d-md-none"/>
                                                {item.header ?
                                                    <Carousel.Caption>
                                                        <div className='carousel-caption d-md-block'>
                                                            <h1 className="section_title text-yellow" dangerouslySetInnerHTML={{ __html: item.header}}></h1>
                                                            {item.sub_header ?
                                                                <p className="text-uppercase subtitla" dangerouslySetInnerHTML={{ __html: item.sub_header}}></p>
                                                                :
                                                                ""
                                                            }
                                                            {item.buttons ?
                                                                <p>
                                                                    <div className="button-group">
                                                                        <a className="btn bg-white text-danger" href={item.button_target}>{item.buttons}</a>
                                                                    </div>
                                                                </p>
                                                                :
                                                                ""
                                                            }
                                                            
                                                        
                                                        </div>
                                                    </Carousel.Caption>
                                                    :
                                                    ""
                                                } 
                                            </>
                                        )
                                    }
                                </div>
                            </Carousel.Item>

                        );
                    })
                }
 
                </Carousel>

           
            </>


                
        );
    }
}

export default ProjectCarousel;