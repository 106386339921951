import React, { Component } from 'react';

import Auth from '../../helpers/auth/Auth';
import apiClient from "../../helpers/api";
class Footer extends Component {

    state = {
        data: [],
        links: [],
        communities: [],
        community_links: [],
        icon_image : []
    }
    componentDidMount(){
        apiClient.get(`${process.env.REACT_APP_API}/footer`)
        .then(res => {
            this.setState({
                data: res.data.data,
                links: res.data.data.links,
                communities:  res.data.data.coms.labels,
                community_links: res.data.data.coms.links,
                icon_image: res.data.data.icon_image
            });
        })
        .catch(err => console.log(err))
    }
    render() {
        return (
            
               
                  
            <footer className="text-dark">
                {/* Footer Top */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="text-center">
                                {/* Logo */}
                                <a className="navbar-brand" href="/">
                                    <img src="/img/footer/ScarletboxLogo2.png" className="text-center" alt="Scarletbox" />
                                </a>
                                <ul className='list-inline'>
                                
                                    {this.state.communities.map((labels, idx) => {
                                        return (
                                            <li key={`wdo_${idx}`} className="list-inline-item ">
                                                <a href={this.state.community_links[idx]} target="_blank">
                                                {this.state.icon_image ? <img width={50} src={window.footer_url + this.state.icon_image[idx]} /> : ""}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className='my-2 text-dark' dangerouslySetInnerHTML={{__html: this.state.data.content}}></div>
                            </div>

                           
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom pb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area ">
                                    {/* Copyright Left */}
                                    <div className="copyright-left text-dark text-center">© ScarletBox 2023, All Rights Reserved.</div>
                                    {/* Copyright Right */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
          
        );
    }
}

export default Footer;