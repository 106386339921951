import {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import apiClient from "../../helpers/api";


const Gcash = (props) => {

    const [errormsg,setErrormsg] = useState()
    const [users, setUsers] = useState()
    const [tr_no,setTrno] = useState(props.tr_no)
    const [price,setPrice] = useState(props.price_fiat)
    const {register, handleSubmit, formState:{errors},formState,reset} = useForm({ mode: 'onBlur' })
    const [redirect, setRedirect] = useState(false)

    const onGcashPay = async (data) => {
        setErrormsg('')
        let details = {
            client_id: props.user,
            project_id: props.project_id,
            tr_no: props.tr_no,
            art_bundle_qty: props.art_bundle_qty,
            type:props.type,
            artist_id:props.artist,
            sales_type:props.sales_type
        }

        try {

            var verifyArt  = await apiClient.post(`${process.env.REACT_APP_API}/vip/process-now/verify_art`, {
                art_id: props.eth_data.art_id,
                vip_id: props.eth_data.vip_id,
                user_id:props.user
            }) 

            if(verifyArt.data.code === 300){
                setErrormsg(verifyArt.data.msg)
            } else {
                try{  // create gcash
                    var trx = await apiClient.post(`${process.env.REACT_APP_API}/checkout/paymongo/shop_create_gcash`, data)
                    if(trx.data.code == 200){
                        window.location = trx.data.url;
                    } else if(trx.data.code == 250){
                        window.location = trx.data.url;
                    } else {
                        setErrormsg(trx.data.msg)
                        setRedirect(true)
                        try{
                            await apiClient.post(`${process.env.REACT_APP_API}/vip/process-now/update_art`, {
                                art_id: props.eth_data.art_id,
                                vip_id: props.eth_data.vip_id,
                                user_id:props.user
                            })
                            setErrormsg('Transaction failed')
                           
                        } catch(e){
                            setErrormsg('Transaction failed')
                        }
                    }
                } catch(e){
                    try{
                        await apiClient.post(`${process.env.REACT_APP_API}/vip/process-now/update_art`, {
                            art_id: props.eth_data.art_id,
                            vip_id: props.eth_data.vip_id,
                            user_id:props.user
                        })
                        if(e.code === 4001){
                            setErrormsg(e.message)
                        } else {
                            setErrormsg('Transaction failed')
                        }
                    } catch(e){
                        setErrormsg('Transaction failed')
                    }
                }

            }

        } catch (error) {
            if(error.code === 4001){
                setErrormsg(error.message)
            } else {
                setErrormsg('Transaction failed')
            }
        }

    }

    const {isSubmitting} = formState;

    useEffect( () => {
      
        apiClient.get(`${process.env.REACT_APP_API}/account/user`)
            .then(res => {
                setUsers(res.data)
                reset({control_number:tr_no})
            })
            
    },[])

    return (
        <>
        <Modal
        show={props.show} 
        onHide={props.onHide} 
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='checkout-modal'
        backdrop="static">
            
            <Modal.Header >
                <Modal.Title className='text-dark'>Gcash Payment</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onGcashPay)}>
            <Modal.Body>
                {
                        errormsg ?  <div className='alert alert-danger'>{errormsg}</div> : ''
                }
                <input type="hidden" className='form-control' {...register("control_number", { required: true})} defaultValue={props.tr_no}/>
                <input type="hidden" {...register("total_")} value={props.price_fiat} />
                <input type="hidden" {...register("client_id")} defaultValue={props.user}/>
                <input type="hidden" {...register("sales_type")} defaultValue={props.sales_type}/>
               {/* bundle */}
                <input type="hidden" {...register("bundle_no")} defaultValue={props.bundle_no} />
                <p>Thank you for your interest in purchasing our One of a kind NFT.</p>
                <p> You are about to pay <span className='font-weight-bold'>PHP {new Intl.NumberFormat().format(props.price_fiat)}</span></p>
                <p>You will now be redirected to the GCash checkout.</p>

                {/* <a href='#' className=''>*Need more eth? Get it at GCrypto <img className='mx-1 w-25' src='/img/gcrypto.jpg'></img></a> */}
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-dark' onClick={props.onHide}>Close</Button>
                <Button className='btn btn-dark' type="submit">Proceed</Button>
            </Modal.Footer>
            </Form>
        </Modal>
        </>
    )

}

export default Gcash