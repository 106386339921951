import { useState, useEffect } from "react";
import apiClient from "../../helpers/api";
const BannerHead = () => {
    
    const [bgImage, setBgImage] = useState('');
    const [bgImageMobile, setBgImageMobile] = useState('');
    const [title, setTitle]     = useState("The Premier Blue Chip Art NFT Launchpad in Asia");
    const [content, setContent] = useState('');
    const [button, setButton]   = useState('');
    const [link, setLink]       = useState('');
    
    /**
     * Get Banner Data from API
     * @return void
     */
    const getBannerHead = () => {
        apiClient.get(`${process.env.REACT_APP_API}/banner_main`).then(res => {
            if (res.data.image || res.data.image_mobile) {
                setBgImage(window.photo_url+res.data.photo+res.data.image);
                setBgImageMobile(window.photo_url+res.data.photo+res.data.image_mobile);
            }
            setTitle(res.data.title);
            setContent(res.data.content);
            setButton(res.data.buttons);
            setLink(res.data.link);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(()=>{
        getBannerHead()
    },[]);

    if (link && !button) {
        return (
            <a href={link}>
                <section className="carousel-bg align-items-center banner-height banner-main" data-mobile={bgImageMobile} style={{backgroundImage: `url(${bgImage})`}} >
                    <img src={bgImageMobile} className="d-block d-sm-none w-100 h-auto img-fluid"/>
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-xl-10 col-12  hero-text" style={{top: title ? '55vh' : "45vh"}}>
                                <h1 className={`pb-4 text-center banner-title normal-font mx-auto  ${!title ? "d-none" : ""}`} style={{color: "#343a40"}}>{title}</h1>
                                {
                                    content ? (<div dangerouslySetInnerHTML={{__html: content}}></div>) : ""
                                }
                                {   
                                    button && link ? (<div>
                                        <a href={link} className="btn btn-dark">{button}</a>
                                    </div>) : ""
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </a>
        )
    }

    return (
  
    <section className="carousel-bg align-items-center banner-height banner-main" data-mobile={bgImageMobile} style={{backgroundImage: `url(${bgImage})`}} >
        <img src={bgImageMobile} className="d-block d-sm-none w-100 h-auto img-fluid"/>
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-10 col-12  hero-text" style={{top: title ? '55vh' : "45vh"}}>
                    <h1 className={`pb-4 text-center banner-title normal-font ${!title ? "d-none" : ""}`} style={{color: "#343a40",fontSize:'30px'}}>{title}</h1>
                    {
                        content ? (<div dangerouslySetInnerHTML={{__html: content}}></div>) : ""
                    }
                    {
                        button && link ? (<div>
                            <a href={link} className="btn btn-dark">{button}</a>
                        </div>) : ""
                    }
                </div>
            </div>
        </div>
    </section>
    );
}

export default BannerHead;