import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className='countdown'>
      <p className={`countDays font-weight-bold pr-2 ${isDanger ? 'text-danger' : 'text-danger'}`}>{value}<span className='countDays'>{type}</span></p></div>
  );

}

export default DateTimeDisplay;
