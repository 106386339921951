import React, {useEffect, useState} from 'react';
import axios from 'axios';
import apiClient from './helpers/api';
import Maintenance from './components/Maintenance/Maintenance';
import { Helmet } from 'react-helmet';

// import { HashLink, HashLink as Link} from 'react-router-hash-link';
// import { BrowserRouter, HashRouter } from 'react-router-dom';


// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'

function App() { 

  const [isMaintenance, setIsMaintenance] = useState(false);
  const [maintenanceText, setMaintenanceText] = useState("");


  

  const checkIP = async () => {
    try {
      await axios.get('https://api.ipify.org').then(res => {
        if (res.data) {
          apiClient.get(`${process.env.REACT_APP_API}/maintenance/${res.data}`).then(response => {
            if(response.data.status == 201){
              setIsMaintenance(true);
              setMaintenanceText(response.data.content)
            }
          })
        }
      }).error(e => {
        setIsMaintenance(false)
      })

    } catch (error) {
      setIsMaintenance(false)
    }
  }

  useEffect(() => {
    try {
      checkIP()
    } catch (error) {
      isMaintenance(false)
    }

    
  },[])

  if (isMaintenance && isMaintenance) {
   return <Maintenance content={maintenanceText && maintenanceText}/>
  } 

  return (
    <div>
      <MyRouts />
      
      {/* <HashLink to="/#collection"> </HashLink>  */}
    </div>
  );
} 

export default App;  