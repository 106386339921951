import {useState, useEffect} from 'react';

const Upsell = (props) => {

    return (
        <div className='row border-top pt-2 pb-2'>
            <div className="col-md-2">
                <img src="https://scarlet.test/storage/mint_file/th(9)-08102407495866b753e6e5d33.jpeg" class="img-fluid"></img>
            </div>
            <div className="col-md-7">
                <p>
                    <span className="font-weight-bold">You may also like</span><br></br>
                    <span className='text-danger'>Php1,0000</span><br></br>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    <br></br>
                </p>
            </div>
            <div className="col-md-3">
                <button className='btn  btn-sm btn-dark float-right'>+</button>
            </div>
         </div>
    )

};

export default Upsell