import React, {useState, useEffect} from 'react';

const VipBanner = (props) => {
    
    return (
        <section className="carousel-bg align-items-center" data-mobile={props.banner} style={{backgroundImage: `url('${props.banner}')`,height:'60vh',backgroundAttachment:"unset",marginTop:95}} >
        </section>

    );

};
export default VipBanner;
