import React, {useState, useEffect} from 'react';
import apiClient from '../../helpers/api';
import Web3 from 'web3';
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from "../../components/Modal/ModalSearch";
import ModalMenu from "../../components/Modal/ModalMenu";
import Scrollup from "../../components/Scrollup/Scrollup";
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";


const Sbx = (props) => {


    const AllowedChainId = process.env.REACT_APP_CHAINID;
    const [errorMessage,setErrorMessage] = useState(null);
    const [defaultAccount,setDefaultAccount] = useState(null);
    const [defaultChain,setDefaultChain] = useState(null);
    const [isConnected,setIsConnected] = useState(false);
    const [users, setUsers] = useState()
    const [role, setRole] = useState(null)
    const [redirect,setRedirect] = useState(false)
    
    const [projects, setProjects] = useState()
    const [projectId, setProjectId] = useState()
    const [withAction, setWithAction] = useState(false)
    const [projId, setProjId] = useState()
    const [artistID, setartistID] = useState()
    const [contractAddress, setContractAddress] = useState()
    
    const [cost, setCost] = useState()
    const [minitTime, setMinitTime] = useState()
    const [isBundle, setIsBundle] = useState()
    const [isAllowed, setIsAllowed] = useState()
    const [ABI, setABI] = useState()
    const [minStatus, setMinStatus] = useState('');
    const [newCost, setNewCost] = useState('');
    const [newUpdateWallet, setNewUpdateWallet] = useState('');
    const [oldUpdateWallet, setOldUpdateWallet] = useState('');
    const [newBaseURI, setNewBaseURI] = useState('');
    const [currentBaseURI, setCurrentBaseURI] = useState('');
    const [totalPhaseCode, setTotalPhaseCode] = useState('');
    var contract = null;

    const [isWithdrawprocessing,setIsWithdrawprocessing] = useState(false)
    const [isMintingprocess,setIsMintingprocess] = useState(false)
    const [isPhraseCodeProcess,setIsPhraseCodeProcess] = useState(false)
    
    const [isUpdatePhraseCodeProcess,setisUpdatePhraseCodeProcess] = useState(false)
    const [isCostProcess,setIsCostProcess] = useState(false)
    const [isNewUriProcessing,setIsNewUriProcessing] = useState(false)

    const [errorWithdrawMsg, setErrorWithdrawMsg] = useState()
    const [errorMintMsg, setErrorMintMsg] = useState()
    const [errorNewCostMsg, setErrorNewCostMsg] = useState()
    const [errorURIMsg, setErrorURIMsg] = useState()
    const [errorPhaseGeneration, setErrorPhaseGeneration] = useState()
    const [errorUpdatePhaseGeneration, seterrorUpdatePhaseGeneration] = useState()
    const [projectErrorMsg, setProjectErrorMsg] = useState()

    const [isAirDropProcess,setAirDropProcess] = useState(false)
    const [AirDropError, setAirDropError] = useState()
    const [airDropArt, setairDropArt] = useState('');
    const [airDropWallet, setairDropWallet] = useState('');
    const [DropFor, setDropFor] = useState('');
  
    useEffect(() => {

        apiClient.get(`${process.env.REACT_APP_API}/sbx/project-artist`)
        .then(res => {
           
            setProjects(res.data.data)
        })
        
        
        apiClient.get(`${process.env.REACT_APP_API}/account/user`)
            .then(res => {
                setUsers(res.data)
                setRole(res.data.role)
                if(res.data.role == 1){
                    setRedirect(true)
                }
        })

    },[])

    async function connectWallet () {
  
        window.ethereum.request({method:"eth_requestAccounts"}).
        then(result => {
            setDefaultAccount(result[0])
            window.ethereum.request({ method: 'eth_chainId' }).then(
                chain => {
                    if(chain == AllowedChainId){
                        setDefaultChain(chain)
                        setIsAllowed(true)
                    } else {
                        setIsAllowed(false)
                        setErrorMessage('Please use mainet only');
                    }
                }
            );
        })
    }

    async function airDrop(){
        setAirDropProcess(true)
        if(airDropArt != '' & airDropWallet != '' & DropFor != ''){
            try{
                var res = await apiClient.post(`${process.env.REACT_APP_API}/sbx/project/verify_art`,{
                    art_code:airDropArt,
                    artist_id:artistID,
                    project:projId
                })
                if(res.data.status == 200){
                    
                    var web3        = new Web3(window.ethereum);

           
                    contract        = new web3.eth.Contract(ABI,contractAddress);
                    var _mintAmount = 1;
                    var mintRate    = Number(await contract.methods.cost().call());
                    var totalAmount = mintRate * 0;
                    
                    try{
                        var trx = await contract.methods.mint(airDropWallet,[airDropArt]).send({from:defaultAccount,value:String(totalAmount)})
                        try{
                            var transact = await apiClient.post(`${process.env.REACT_APP_API}/sbx/project/create_order`,{
                                id:res.data.art_id,
                                tr_hash:trx.transactionHash,
                                wallet_address:defaultAccount,
                                contract_address:contractAddress,
                                drop_for: DropFor
                            });
                            setAirDropProcess(false)
                            setAirDropError('Successfully drop with transaction #'+trx.transactionHash)
                        } catch(error) {
                            setAirDropProcess(false)
                            setAirDropError('Opps! Cant connect to our server')
                        }
                    } catch(error) {
                        setAirDropProcess(false)
                        setAirDropError('Opps! Contract has a problem')
                    }
                } else {
                    setAirDropProcess(false)
                    setAirDropError('Art is not available')  
                }
            } catch(error) {
                setAirDropProcess(false)
                setAirDropError('Opps! Cant connect to our server')
            }
        } else {
            setAirDropProcess(false)
            setAirDropError("Art # and metamask wallet should not be empty");
        }
    }

    async function updateGeneratePhaseCode(){
        setisUpdatePhraseCodeProcess(true)

        if(newUpdateWallet != '' & oldUpdateWallet != ''){
           
            try{
                var y = await apiClient.post(`${process.env.REACT_APP_API}/mint/process/verify_wallet_for_update/`, {
                    old_wallet: oldUpdateWallet,
                    new_wallet : newUpdateWallet
                })
               
                if(y.data.code == 300){
                    seterrorUpdatePhaseGeneration(y.data.message)
                    setisUpdatePhraseCodeProcess(false)
                } else {
                    try{
                        var web3        = new Web3(window.ethereum);
                        contract        = new web3.eth.Contract(ABI,contractAddress);
                        try{
                            var t = await contract.methods.updatePhaseCode(oldUpdateWallet,newUpdateWallet).send({from:defaultAccount});

                            try{
                               
                                var x = await apiClient.post(`${process.env.REACT_APP_API}/mint/process/update_wallet_phasecode_owner/`, {
                                    new_wallet : newUpdateWallet,
                                    old_wallet : oldUpdateWallet,
                                    client_id : y.data.client_id
                                })
        
                                alert('PhaseCode has been update to smart contract')
                                window.location.reload()
                            } catch(err){
                                if(err.code == 4001){
                                    setErrorPhaseGeneration(err.message)
                                } else {
                                    setErrorPhaseGeneration("transaction failed updating api")
                                }
                                setIsPhraseCodeProcess(false)
                            }

                        } catch(err){
                            if(err.code == 4001){
                                seterrorUpdatePhaseGeneration(err.message)
                            } else {
                                seterrorUpdatePhaseGeneration("transaction failed")
                            }
                            setisUpdatePhraseCodeProcess(false)
                        }

                    }  catch(err){

                        setisUpdatePhraseCodeProcess(false)
                        seterrorUpdatePhaseGeneration("Cant connect to smart contract");
                        
                    }
                }
            }  catch(err){
                if(err.code == 4001){
                    seterrorUpdatePhaseGeneration(err.message)
                } else {
                    seterrorUpdatePhaseGeneration("transaction failed")
                }
                setisUpdatePhraseCodeProcess(false)
            }
        } else {
            setisUpdatePhraseCodeProcess(false)
            seterrorUpdatePhaseGeneration("Old wallet and new wallet should not be empty");
        }
        
    }

    

    async function generatePhaseCode(){
        setIsPhraseCodeProcess(true)
        try{
            var x = await apiClient.post(`${process.env.REACT_APP_API}/mint/get/unminited_untag/`, {
                project_id: projId,
                artist_id:artistID
            })
            try{
                var web3        = new Web3(window.ethereum);
                contract        = new web3.eth.Contract(ABI,contractAddress);
                try{
                    var t = await contract.methods.phaseCode(x.data.data).send({from:defaultAccount});
                   
                    try{
                        var y = await apiClient.post(`${process.env.REACT_APP_API}/mint/process/tag_pahse_code/`, {
                            id: x.data.bundle_id,
                            tr_no : t.transactionHash
                        })

                        alert('PhaseCode has been push to smart contract')
                        window.location.reload()
                    } catch(err){
                        if(err.code == 4001){
                            setErrorPhaseGeneration(err.message)
                        } else {
                            setErrorPhaseGeneration("transaction failed updating api")
                        }
                        setIsPhraseCodeProcess(false)
                    }

                } catch(err){
                    if(err.code == 4001){
                        setErrorPhaseGeneration(err.message)
                    } else {
                        setErrorPhaseGeneration("transaction failed")
                    }
                    setIsPhraseCodeProcess(false)
                }
            }catch(err){

                setIsPhraseCodeProcess(false)
                setErrorPhaseGeneration("Cant connect to smart contract");
            }
            console.log(x)
        } catch(err){

            setIsPhraseCodeProcess(false)
            setErrorPhaseGeneration("no data found");
        }
    }

    async function changeCost(){
        setIsCostProcess(true)
        setErrorNewCostMsg(null)
        var web3        = new Web3(window.ethereum);
        contract        = new web3.eth.Contract(ABI,contractAddress);

        try{
            var t = await contract.methods.setNewCOst(newCost).send({from:defaultAccount});
            alert('New Cost has been apply')
            window.location.reload()
        } catch(err){
            if(err.code == 4001){
                setErrorNewCostMsg(err.message)
            } else {
                setErrorNewCostMsg("transaction failed")
            }
            setIsCostProcess(false)
        }

    }


    async function changeMintingTime(){
        setErrorMintMsg(null)
        setIsMintingprocess(true)
        let mintedStatus = minStatus ? 2 : 1;
      
        var web3        = new Web3(window.ethereum);
        contract        = new web3.eth.Contract(ABI,contractAddress);

        try{
            var t = await contract.methods.setMinting(minStatus).send({from:defaultAccount});
            try{
                var res2 = await apiClient.post(`${process.env.REACT_APP_API}/sbx/project/update-minted`,{
                    id:projectId,
                    minited:mintedStatus
                })

                alert('Fiat Transaction can now mint nft with 0eth')
                window.location.reload()

            } catch(err){
                if(err.code == 4001){
                    setErrorMintMsg(err.message)
                } else {
                    setErrorMintMsg("transaction failed")
                }
                setIsMintingprocess(false)
            }
            
        } catch(err){
            if(err.code == 4001){
                setErrorMintMsg(err.message)
            } else {
                setErrorMintMsg("transaction failed")
            }
            setIsMintingprocess(false)
           
        }

    }

    async function changeBaseUrl(){
        setIsNewUriProcessing(true)
        setErrorURIMsg(null)
        var web3        = new Web3(window.ethereum);
        contract        = new web3.eth.Contract(ABI,contractAddress);
        try{
            var t = await contract.methods.setBaseURI(newBaseURI).send({from:defaultAccount});
            alert('Base Url has been updated')
            window.location.reload()
        } catch(err){
            if(err.code == 4001){
                setErrorURIMsg(err.message)
            } else {
                setErrorURIMsg("transaction failed")
            }
            setIsNewUriProcessing(false)
        }
    }

    async function withdraw_(){
        setIsWithdrawprocessing(true)
        setErrorWithdrawMsg(null)
        var web3        = new Web3(window.ethereum);
        contract        = new web3.eth.Contract(ABI,contractAddress);
        try{
            var t = await contract.methods.withdraw().send({from:defaultAccount});
            setIsWithdrawprocessing(false)
            alert('Successful!')
            
        } catch(err){
            if(err.code == 4001){
                setErrorWithdrawMsg(err.message)
            } else {
                setErrorWithdrawMsg("transaction failed")
            }
            setIsWithdrawprocessing(false)
        }
    }

    async function onChangeProjects(e){
      
        if(!window.ethereum){
            setErrorMessage('Please Install Metamask');
        } else {
            try{
                setIsBundle( e.target.selectedOptions[0].getAttribute('data-isbundle') )
                var res = await apiClient.post(`${process.env.REACT_APP_API}/sbx/project/get`,{
                    id:e.target.value
                })
                
                setContractAddress(res.data.data.contract_address)
                setABI(JSON.parse(res.data.data.abi))
                setWithAction(true)
                setProjId(res.data.data.project_id)
                setProjectId(res.data.data.id)
                setartistID(res.data.data.artist_id)

                var web3        = new Web3(window.ethereum);
                contract        = new web3.eth.Contract(JSON.parse(res.data.data.abi),res.data.data.contract_address);
                setCost(Number( await contract.methods.cost().call()))
                setCurrentBaseURI(await contract.methods.baseURI().call())
                // let mint_status = await contract.methods.mitingTime().call()
                setMinitTime(false)
                setMinStatus(false)

                try{
                   
                    var yx = await apiClient.post(`${process.env.REACT_APP_API}/mint/get/unminited_untag/`, {
                        project_id: res.data.data.project_id,
                        artist_id:res.data.data.artist_id
                    })
                  
                    setTotalPhaseCode(yx.data.total)
                    
                }catch(err){
                    setTotalPhaseCode(0)
                }
            } catch(error) {
                setIsAllowed(false)
                setProjectErrorMsg('Opps! Contract has problem please contact your administrator')
            }
           
        }
    }
     
    try{
        window.ethereum.on('chainChanged', (chainId) => {
            if(chainId != AllowedChainId){
                setErrorMessage('Please use mainnet');
                setIsAllowed(false)
            } else {
                setErrorMessage(null)
                setDefaultChain(chainId)
                setIsAllowed(true)
            }
        });
        
        window.ethereum.on('accountsChanged', (accounts) => {
            setDefaultAccount(accounts[0])
        });
    } catch(e){ }

    if(redirect){
        return <PageNotFound></PageNotFound>
    }

    return (
        <div className="main">
            <PageLoading />
            {/* <Header/> */}
            <Header/>
            <BannerSale  />
            <section className="item-details-area pt-5">
                <div className="container">
                   
                <div className="row  bg-white">
            <div className='col-md-8'>
            <h4 className="text-black">Welcome back admin</h4>
            </div>
            
            <div className='col-md-4 mt-4 '>
            {!defaultAccount ? <button className='float-right btn btn-dark' onClick={connectWallet}>Connect Wallet</button> : <p>{defaultAccount}</p>}
            </div>
            <div className="col-md-12">
            {contractAddress && <p className='alert alert-info'>Contract Address {contractAddress}</p>}
            {errorMessage ? <p className="alert alert-danger mb-3">{errorMessage}</p> : ''}
            </div>
            
            <div className="col-md-12">
          
            {
            defaultAccount && defaultChain  ?
            <>
                <div className="form-group">
                    <label>Select Contract</label>
                        <select className='form-control' onChange={onChangeProjects}>
                            <option value=""></option>
                            {
                                projects ? projects.map((val, id) => {
                                return (
                                    <option data-isbundle={val.allowed_art_bundle} key={id} value={val.p_artis_id}>{val.artist} ( {val.project} )</option>
                                )
                                }) : ""
                            }
                        </select>
                   </div>
                   {projectErrorMsg && <p className='alert alert-danger mb-2'>{projectErrorMsg}</p>}
             </> : ''}
            </div>
           
          
            {
            role >= 2 && withAction ? 
            <>
            <div className="col-md-12">
                <h4 className="text-black">Withdraw</h4>
                <p>Withdraw all eth</p>
                {errorWithdrawMsg ? <p className='text-danger'>{errorWithdrawMsg}</p> : ''}
                {defaultAccount && !errorMessage && isAllowed  ? <><button className="btn btn-dark " onClick={withdraw_}>{isWithdrawprocessing && <span className="spinner-border spinner-border-sm mr-1"></span> }  Withdraw</button></> : ''}
                <hr></hr>
            </div>
            </>
            : '' 
            }

            {
            role > 2 && withAction  ? 
            <>
            <div className="col-md-12">
                <h4 className="text-black">Change Base Url</h4>
                <small>Current BaseUri {currentBaseURI} </small><p></p>
                <input type="text" className='form-control mb-2' onChange={e => setNewBaseURI(e.target.value)}></input>
                {errorURIMsg ? <p className='text-danger'>{errorURIMsg}</p> : ''}
                {defaultAccount && !errorMessage && isAllowed  ? <> <button className="btn btn-dark " onClick={changeBaseUrl}>{isNewUriProcessing && <span className="spinner-border spinner-border-sm mr-1"></span> } Change</button></> : ''}
                <hr></hr>
            </div>
            

            <div className="col-md-12">
                <h4 className="text-black">Change Cost</h4>
                <input type="text" className='form-control mb-2' onChange={e => setNewCost(e.target.value)}></input>
                <small>Current Cost is {cost} Wei</small><p></p>
                {errorNewCostMsg ? <p className='text-danger'>{errorNewCostMsg}</p> : ''}
                {defaultAccount && !errorMessage && isAllowed  ? <> <button className="btn btn-dark " onClick={changeCost}>{isCostProcess && <span className="spinner-border spinner-border-sm mr-1"></span> }Change</button></> : ''}
                <hr></hr>
            </div>

            {/* <div className="col-md-12">
                <h4 className="text-black">Allowed Minting</h4>
                <p>All fiat can now mint with 0 eth status is {minitTime ? 'True' : 'False'}</p>
                {errorMintMsg ? <p className='text-danger'>{errorMintMsg}</p> : ''}
                {defaultAccount && !errorMessage ? <><button className="btn btn-dark " onClick={changeMintingTime}>{isMintingprocess && <span className="spinner-border spinner-border-sm mr-1"></span> }  {minitTime ? 'Disable' : 'Enable'}</button></> : ''}
                <hr></hr>
            </div> */}
            </>
            : ''}


{
            role >= 2 && isBundle == 2 ? 
            <>
            <div className="col-md-12">
                <h4 className="text-black">Generate Phrase Code</h4>
                <p>All fiat NFT will be allowed on free minting</p>
                {totalPhaseCode != 0 ? <p className='alert alert-info'>{totalPhaseCode} Phase code ready to generate</p> : <p className='alert alert-info'>No Phase code to generater</p>}
                {errorPhaseGeneration ? <p className='text-danger'>{errorPhaseGeneration}</p> : ''}
                {defaultAccount && !errorMessage && isAllowed  ? <><button className="btn btn-dark " onClick={generatePhaseCode}>{isPhraseCodeProcess && <span className="spinner-border spinner-border-sm mr-1"></span> }  Generate Now</button></> : ''}
                
                <hr></hr>
            </div>
            
            </>
            : ''
            }

            {
            role >= 2 && isBundle == 2 ? 
            <>
            <div className="col-md-12">
                <h4 className="text-black">AIR DROP</h4>
                <p>AIR DROP ART</p>
                <label className="pl-1">Art #</label>
                <input type="text" className='form-control mb-2' onChange={e => setairDropArt(e.target.value)}></input>
                <label className="pl-1">Wallet</label>
                <input type="text" className='form-control mb-2' onChange={e => setairDropWallet(e.target.value)}></input>
                <label className="pl-1">Air Drop For</label>
                <input type="text" className='form-control mb-2' onChange={e => setDropFor(e.target.value)}></input>
                {AirDropError ? <p className='text-danger'>{AirDropError}</p> : ''}
                {defaultAccount && !errorMessage && isAllowed  ? <><button className="btn btn-dark " onClick={airDrop}>{isAirDropProcess && <span className="spinner-border spinner-border-sm mr-1"></span> }  Drop Now</button></> : ''}
                
                <hr></hr>
            </div>
            
            </>
            : ''
            }

            {
            role >= 2 && isBundle == 2 ? 

            <>
            <div className="col-md-12">
                <h4 className="text-black">Update Phrase Code and client wallet</h4>
                <p>All fiat NFT will be allowed on free minting</p>
                <label className="pl-1">Old Wallet</label>
                <input type="text" className='form-control mb-2' onChange={e => setOldUpdateWallet(e.target.value)}></input>
                <label className="pl-1">New Wallet</label>
                <input type="text" className='form-control mb-2' onChange={e => setNewUpdateWallet(e.target.value)}></input>
                
                <p></p>

                {errorUpdatePhaseGeneration ? <p className='text-danger'>{errorUpdatePhaseGeneration}</p> : ''}
                {defaultAccount && !errorMessage && isAllowed  ? <><button className="btn btn-dark " onClick={updateGeneratePhaseCode}>{isUpdatePhraseCodeProcess && <span className="spinner-border spinner-border-sm mr-1"></span> }  Generate and Update</button></> : ''}
               

                <hr></hr>
            </div>
            </>

            : ''
            }
           
        </div> 
                       
                       

                </div>
            </section> 
            <Footer />
            <ModalSearch/>
            <ModalMenu />
            <Scrollup />
        </div>
    );

}

export default Sbx;