import { Modal } from "react-bootstrap";


export const loadData = (url, method = "GET") => {

    const state = {
        isLoading: true,
        data: [],
    }

    
    

    return (<div>
        <Modal show={true}>
        <Modal.Body>Loading..{method}</Modal.Body>
      </Modal>
    </div>);
}