import React, {Component} from "react";
import ModalArthouse from "../Modal/ModalArthouse";

class Arthouses extends Component {
    render() { 
        return (
            <>
              <div className="row items d-flex justify-content-center py-5"> 
                <div className="col-12 ">
                    <h1 className="mb-4 text-center section_title text-danger">
                        Arthouses
                    </h1>
                </div>  
                {

                
                    this.props.params.map((res, id)=> {
                                return (
                                    <>

                                    <div key={id} id={res.art_house_id} className="col-12 col-sm-6 col-lg-3 item">
                                    
                                        <div className="card">
                                            <div className="image-over">
                                                <img className="card-img-top h-100 img-fluid" src={window.photo_url+res.arthouse.photo} alt="" />

                                            </div>
                                            <div className="card-caption col-12 p-0">
                                                <div className="text-center">
                                                    <div className="breadcrumb-content">
                                                        <h4 className="text-red normal-font">{res.arthouse.house_name}</h4>
                                                    </div>
                                                    <button className="btn btn-smaller btn-danger loginBtn text-white font-weight-normal" data-toggle="modal" data-target={`#modal-arthouse-${res.art_house_id}`}>View</button>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <ModalArthouse content={ res } />

                                    </>
                                
                                    
                                );

                            
                            })

                        
                }
                {/* {
                    this.props.params.map(res => {
                        return (
                            <section className="artist mb-3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="breadcrumb-content ">
                                                <h2 className="m-0 section_title font-weight-normal text-red">{res.arthouse.house_name}</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <img src={window.photo_url+res.arthouse.photo} className="img-fluid"/>
                                        </div>
                                        <div className="col-12 col-sm-8 pt-2 text-black" dangerouslySetInnerHTML={{__html: res.arthouse.description}}></div>
                                    </div>
                                </div>
                            </section>
                        )
                    })
                } */}
                </div>
            </>
            
        );
    }
}
 
export default Arthouses;