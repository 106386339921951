import React, {Component} from "react";
import ModalArtist from "../Modal/ModalArtist";
import { HashLink, HashLink as Link} from 'react-router-hash-link';
class ArtistCollection extends Component {
    render() { 
        return (
            <>
            <section className="artist" >
                <div className="container-fluid py-5">
               
                    <div className="row d-flex justify-content-center xbtk">
                        <div className="container-fluid">
                            <div className="row d-flex justify-content-center">

                                {
                                    this.props.params.map((info, id) => {
                   
                                        return (
                                        <div  key={`${id}`} className="col-xl-6 px-md-1 p-2 my-3">
                                            <Link smooth to={`/collection-projects/view/${info.url}#the-artists`}>  
                                            <div className="p-md-1">
                                                <center className="">
                                                    <img
                                                    className="img-fluid"
                                                    src={window.photo_url+info.photo}
                                                    alt=""
                                                    
                                                />
                                                </center>
                                                <div className="text-center w-md-75 mx-auto">
                                                    <h4 className="text-uppercase mt-2 text-dark normal-font font-weight-normal mb-md-4 mb-0">{info.project_name}</h4>
                                                    <div className="text-center text-justify text-white" dangerouslySetInnerHTML={{__html: info.description}}></div>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                        );

                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        );
    }
}
 
export default ArtistCollection;