import React, {Component} from "react";

class ModalBid extends Component {
    state = {  } 
    render() { 
        return (
            <div id="modal-bid" className="modal fade p-0">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content bg-white">
                        <div className="modal-header pb-0" data-dismiss="modal">
                            Enter Bid<i className="far fa-times-circle icon-close" />
                        </div>
                        <div className="modal-body">
                        <form className="item-form no-hover px-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="number" className="form-control rounded-0" name="amount" placeholder="AMOUNT" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-12 text-right">
                                        <button className="btn mt-3 mr-2 mt-sm-4" type="submit">SUBMIT</button>
                                        <button className="btn mt-3 mt-sm-4" data-dismiss="modal">CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalBid;