import React, {Component} from "react";

import apiClient from "../../helpers/api";

import PageLoading from "../FullPageLoading/PageLoading";
import Account from "./MyAccount/MyAccount";
import Security from "./Security/Security";
import MyBids from "./MyBids/MyBids";
import MyCollection from "./MyCollection/MyCollection";
import { Redirect, Link } from "react-router-dom";

class MyAccount extends Component {

    state = {
        user:{},
        isloading: true,
    }


    componentDidMount(){
        apiClient.get(`/account/user`)
        .then(res => {
            this.setState({
                user:res.data,
                isloading: false,
            })

            console.log(res);
        }).catch( e => {
            console.log(e.response.data);
            // if (e.response.status === 401) {
            //     localStorage.clear()
            //     window.location.replace('/');
            // }
            
        })

    }
    
    render() { 
        
        return (
        <>{
            this.state.isloading ? <PageLoading/> : (
                <div className="container">
                    <div className="row xbtk">
                        <div className="col-12 col-lg-4 pt-lg-5 py-2 mb-5">
                            <div className="border border-dark p-3 rounded-lg">
                                
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a className="nav-link active font-weight-bold" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                            My Account
                                        </a>
                                        {this.state.user.register_type == 1 ? 
                                        <a className="nav-link font-weight-bold" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                            Security
                                        </a>
                                        : ''}
                                        <a className="nav-link font-weight-bold" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                            My BID
                                        </a>
                                        {/* <a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                            MY Collections
                                        </a> */}

                                        
                                      
                                        <Link to="/account/collections">
                                            <a className="nav-link font-weight-bold">
                                                My Collections
                                            </a>
                                        </Link>
                                       
                                        {this.state.user.role >= 2 ? 
                                        <Link to="/admin/sbx">
                                            <a className="nav-link font-weight-bold">
                                                Admin
                                            </a>
                                        </Link>
                                         : '' }
                                    </div>
                                
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 pt-lg-5 py-2 mb-5"> 
                            <div className="border border-dark rounded-lg">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <Account user={this.state.user}/>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                        <Security/>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                        <MyBids user={this.state.user}/>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                        <MyCollection/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
            
            </>
        );
    }
}
 
export default MyAccount;