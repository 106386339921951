import React, { Component } from 'react';


const NoCollection = () => {

    return (
        <div className="alert alert-info" role="alert">
            You dont have any collections
        </div>
    );
}

 
export default NoCollection;