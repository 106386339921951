
/**
 * 
 * @param string 
 * @returns 
 */
export const getShortCodeName = (string) => {
    let val = string.replace(/[\[\]']+/g,'')
    let shortcode = val.split("__");
    let shortcode_name = shortcode[0].split(" ")
    return shortcode_name[0]
}

export const getShortCodeParam = (string) => {
    let val = string.replace(/[\[\]']+/g,'')
    let shortcode = val.split("__");
    shortcode.shift()
   
    return shortcode
}


export const setShortCodeParam = (shortcode, param, content, additional_content=[]) => {

    return {
        shortcode: shortcode,
        param: param,
        content: content,
        additional_content: additional_content
    }
}