import React, {useState} from 'react';
import apiClient from '../../helpers/api';
import Alert from 'react-bootstrap/Alert';
import { useForm } from "react-hook-form";
import Auth from '../../helpers/auth/Auth';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;


const Login  = () =>  {
    const { handleSubmit, formState } = useForm();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [show, setShow] = useState(false);
    const { isSubmitting } = formState;

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
 
    function onSubmit(data) {
        // return promise that resolves after 2 seconds
        return new Promise(resolve => {

        apiClient.post(`/auth/login`, { 
            email: email,
            password: password
        }).then(response => {
            if (response.status == 200) { 
                // localStorage.setItem('isAuth', true)
                Auth.storeAuth()
                window.location.reload()
                resolve();
            }
        }).catch(function (error) {
            if(error.response.status==401){
                setShow(true)
                resolve();
            }
        })

        });
    }
   
    return (
        <section className="author-area p-0">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 px-0 mx-0">
                        {/* Item Form */}
                        <Alert show={show} variant="danger">
                            <p>
                                Invalid Email or Password
                            </p>
                        </Alert>
                        <form onSubmit={handleSubmit(onSubmit)} className="item-form no-hover px-0 py-2">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <input type="email" className="form-control" name="email"  onChange={e => setEmail(e.target.value)} placeholder="Enter your Email" required="required" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <input type={passwordShown ? "text" : "password"}  className="form-control" name="password" onChange={e => setPassword(e.target.value)} placeholder="Enter your Password" required="required" />
                                    </div>
                                   
                                </div>
                                <div className="col-12">
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="form-group form-check">
                                                <input type="checkbox" className="form-check-input"  onClick={togglePasswordVisiblity} />
                                                <label className="form-check-label ml-2" >Show Password</label>
                                            </div>
                                  
                                        </div>
                                        <div className='col-6'>
                                            <div className="text-right">
                                                <a href='/auth/forget_password'>Forgot Password</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                              
                              
                                {/* <div className="col-12 text-right">
                                    <a href='/auth/forget_password'>Forgot Password</a>
                                </div> */}
                                <div className="col-12">
                                    <button disabled={isSubmitting} className="btn btn-dark w-100 mt-2 mt-sm-3" type="submit">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}

                                    Sign In</button>
                                </div>
                                <div className="col-12 pt-3">
                                    <p className="text-center" style={{ fontSize: '15px' }}>**Please register if you do not have an account yet.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
    
}

export default Login;