import React, {useState, useEffect} from 'react';
import Auth from '../../helpers/auth/Auth';
import apiClient from '../../helpers/api';
import ModalRegister from '../Modal/ModalRegister';


const PublicSaleCollection = (props) => {

    const [is_auth, setIsAuth] = useState(true);
    const [private_status, setPrivateStatus] = useState('');
    const [private_message, setPrivateMessage] = useState('');
    const [metamask,setMetamask] = useState(true);
    useEffect(() => {

        apiClient.get(`/sale/public-sales/status/${props.params}`)
        .then(res => {
            
            setPrivateStatus(res.data.status);
            setPrivateMessage(res.data.title);
        }).catch( e => {
            console.log(e);
        });

        apiClient.get('/account/user')
        .then(res => {
            setIsAuth(true)
        }).catch(e => {
            setIsAuth(false)
            localStorage.clear()
        })

    },[])

    async function connectWalletx(url) {

      

        if(metamask){
            console.log(window.ethereum);
             window.ethereum.request({method:"eth_requestAccounts"}).
            then(result => {
            
            window.ethereum.request({ method: 'eth_chainId' }).then(
                chain => {
                    apiClient.post(`/auth/register-wallet`, { 
                        wallet: result[0]
                    }).then(response => {
                        if (response.status == 200) {
                            localStorage.setItem('wallet',result[0])
                            localStorage.setItem('chain',chain)
                            // localStorage.setItem('isAuth', true)
                            Auth.storeAuth()
                           window.location.href =  url; 
                        }
                    }).catch(function (error) {
                        localStorage.removeItem("wallet")
                        localStorage.removeItem("chain")
                    })

                }
            );
        })

        } else {
            window.open("https://metamask.io/download/")
        }
    }
    

    return (
        <section className="artist mb-4 mt-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                         <img
                            className="img-fluid"
                            src="/img/revamp_v3/PublicSale.png"
                            alt=""
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <h2 className="text-dark mt-0 normal-font mb-0">Public Sale</h2>
                        {
                            private_status != 200 ? 
                            <p className='font-weight-bold text-dark mt-1'>{private_message}</p>
                            : ''
                        }
                        <p className="text-justify text-dark mt-2">Our Public Sale is open for everyone to purchase as long as they are registered and signed-in in our website. </p>
                        {
                            private_status == 200 ? <a className="btn btn-dark btn-smaller px-5" href={'/'+props.params+'/sale/public-sale/'}  role="button">Mint Now | Buy Now</a> : ""
                        }                        
                        {/* {
                                private_status == 200 ? 
                                <>
                                {
                                    !is_auth
                                    ? <button className="btn btn-danger btn-smaller px-5 my-2 bkt_min_button" data-toggle="modal" data-target="#modal-login">Login to Purchase</button>
                                    : <a className="btn btn-danger btn-smaller px-5" href={'/'+props.params+'/sale/public-sale/'}  role="button">Mint Now | Buy Now</a>
                                }
                                </> : ''
                        }

                        {
                                private_status == 200 ? 
                                <>
                                {
                                    !is_auth
                                    ? <button className="btn btn-danger btn-smaller px-5 ml-lg-3 ml-sm-0 my-md-2 bkt_min_button" data-toggle="modal" data-target="#modal-register">Register to Purchase</button> : ""
                                }
                                </> : ''
                        }

                        {       
                        private_status == 200 ? 
                                <>
                                {
                                    !is_auth
                                    ? 
                                    <button onClick={() => connectWalletx('/'+props.params+'/sale/public-sale/')} className="btn btn-danger btn-smaller px-5 my-2 bkt_min_button" style={{background:"#fff", color:"red", borderRadius: "50px", cursor: "pointer"}} role="button">Connect Metamask Wallet to Purchase</button>
                                 : ""
                                }
                                </> : ''
                        } */}
                        <ModalRegister title="Register Now"/>
                    </div>
                </div>  
            </div>
        </section> 
    );

}

export default PublicSaleCollection;