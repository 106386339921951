import React, { useEffect, useState } from 'react';
import Auth from '../../helpers/auth/Auth';
import apiClient from '../../helpers/api';
import PageLoading from '../FullPageLoading/PageLoading';
import { HashLink, HashLink as Link} from 'react-router-hash-link';
// import { useHistory } from "react-router-dom";
// import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import Web3 from 'web3';

const Header = () => {

    // const [user, setUser] = useState({})

    // useEffect(() => {
    //     apiClient.get('/account/user')
    //     .then(res => {
    //         setUser(res.data)
    //     })
    // }, user)
    const [defaultChain,setDefaultChain] = useState(null);
    const AllowedChainId = process.env.REACT_APP_CHAINID;
    const [errorMessage,setErrorMessage] = useState(null);
    const [metamask,setMetamask] = useState(true);
    const [defaultAccount,setDefaultAccount] = useState(localStorage.getItem('wallet'));
    const [is_auth, setIsAuth] = useState(true);
    // const [isScroll, setIsScroll] = useState(false);
   
    async function connectWallet() {
        if(metamask){
            console.log(window.ethereum);
             window.ethereum.request({method:"eth_requestAccounts"}).
            then(result => {
            
            window.ethereum.request({ method: 'eth_chainId' }).then(
                chain => {
                    apiClient.post(`/auth/register-wallet`, { 
                        wallet: result[0]
                    }).then(response => {
                        if (response.status == 200) {
                            localStorage.setItem('wallet',result[0])
                            localStorage.setItem('chain',chain)
                            // localStorage.setItem('isAuth', true)
                            Auth.storeAuth()
                           window.location.reload()
                        }
                    }).catch(function (error) {
                        localStorage.removeItem("wallet")
                        localStorage.removeItem("chain")
                    })

                }
            );
        })

        } else {
            window.open("https://metamask.io/download/")
        }
    }

    try{
        if(localStorage.getItem('wallet')){
            window.ethereum.on('chainChanged', (chainId) => {
                localStorage.setItem('chain',chainId)
            });
    
            window.ethereum.on('accountsChanged', (accounts) => {
               
                if(accounts[0] != localStorage.getItem('wallet')){
                    apiClient.post('/account/logout')
                    .then( res => {
                        localStorage.removeItem("wallet")
                        localStorage.removeItem("chain")
                        localStorage.removeItem('isAuth')
                        apiClient.post(`/auth/register-wallet`, { 
                            wallet: accounts[0]
                        }).then(response => {
                            if (response.status == 200) {
                                localStorage.setItem('wallet',accounts[0])
                                // localStorage.setItem('isAuth', true)
                                Auth.storeAuth()
                               window.location.reload()
                            }
                        }).catch(function (error) {
                            localStorage.removeItem("wallet")
                            localStorage.removeItem("chain")
                        })

                    }).catch( e => {
                        console.log(e.response.data);
                    })
                    
                }
            });
        }
    } catch(e){ }

    useEffect(() => {

        if(!window.ethereum){
            setMetamask(false)
        } 

        apiClient.get('/account/user')
        .then(res => {
            setIsAuth(true)
            // setIsScroll(false)
            
        }).catch(e => {
            setIsAuth(false)
            localStorage.clear()
        })

    },[])

    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-sticky" src="/img/scarletbox-red.png" alt="sticky brand-logo" />

                    </a>
                    <div className="ml-auto" />
                    {/* Navbar */}
                    {
                        Auth.isAuthenticated() ? (
                            <ul className="navbar-nav items mx-auto">
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/artists">Artists</a>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="/#collection" className="nav-link font-weight-normal"> Collections </Link>
                                 
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/about-us">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/auctions">Auction</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/vip/scarletbox-virtual-gallery/scarletbox">Online Gallery</a>
                                </li>
                                <li className="nav-item">                                    
                                    <button type='button' className="btn btn-smaller btn-dark loginBtn btn-sm  text-white font-weight-normal mx-1 btn-block mt-lg-1 mt-2"  onClick={() => window.location = '/auth/my-account'}>My Account</button>
                                </li>
                                <li className="nav-item">
                                    <button className="btn btn-smaller btn-dark loginBtn btn-sm  text-white font-weight-normal mt-lg-1 mt-2 mx-1 d-none d-md-none d-lg-block" style={{cursor: "pointer"}} onClick={ () => {Auth.logout()}}>Logout</button>      
                                </li>
                                <li className="nav-item">
                                    <button className="btn btn-smaller btn-dark loginBtn text-white font-weight-normal mx-1 btn-block mt-lg-1 mt-2 d-lg-none d-md-block" style={{cursor: "pointer"}} onClick={ () => {Auth.logout()}}>Logout</button>
                                   
                                </li>
                            </ul>
                        ) : (
                            <ul className="navbar-nav items ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/artists">Artists</a>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="/#collection" className="nav-link font-weight-normal"> Collections </Link>
                                   
                                </li>
                               
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/about-us">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/auctions">Auction</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link font-weight-normal" href="/vip/scarletbox-virtual-gallery/scarletbox">Online Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <button className="btn btn-sm btn-dark loginBtn text-white font-weight-normal mx-1 btn-block mt-lg-1 mt-2" data-toggle="modal" data-target="#modal-login">Login</button>
                                </li>
                                <li className="nav-item">
                                    <button className="btn btn-sm btn-dark loginBtn text-white font-weight-normal mx-1 btn-block mt-lg-1 mt-2" data-toggle="modal" data-target="#modal-register">Register</button>
                                </li>
                                <li className="nav-item">
                                     <button className="btn btn-smaller loginBtn btn-sm btn-dark  text-white font-weight-normal mx-1 btn-block mt-lg-1 mt-2" onClick={connectWallet} >Connect Wallet</button>
                                </li>
                            </ul>
                        )
                    }
                   
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu-nav">
                                <i className="fas fa-bars toggle-icon m-0" />
                            </a>
                        </li>
                    </ul>
                   
                </div>
            </nav>
        </header>
    );
};

export default Header;