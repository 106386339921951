import React, {useState, useEffect} from "react";
import { useParams } from 'react-router';
import apiClient from "../../../helpers/api";
import CountdownTimer from "../../CountdownTimer/Countdown";
import OfferForm from "./OfferForm";

const ViewAuction = () => {

    let {id} = useParams()

    const [art_details, setArtDetails] = useState({});
    const [status, setStatus]          = useState({});
    const [is_ended, setIsEnded]       = useState(false);
    
    const getArtDetails = () => {
        apiClient.get(`/sale/auctions/item/${id}`)
        .then(res => {
            console.log(res.data);
            setArtDetails(res.data.data)
            setIsEnded(res.data.is_ended)
        })
    }

    useEffect(() => {
        getArtDetails();
    }, [])


    return (
        <section className="item-details-area mt-1">
            <div className="container">
                <div className="row xbtk">
                    <div className='col-12'>
                        <div className='card my-3'>
                            <div className='card-body p-0'>
                                <div className='row justify-content-md-center'>
                                    <div className="col-lg-5 col-12 pt-3 pb-4">
                                        <img src={art_details.art ? process.env.REACT_APP_ART_PHOTO_URL+art_details.art.photo : ""} className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-7 col-12 pt-3 pb-4">
                                        <div className="content">
                                            <h2 className="mt-md-0 mt-2 mb-3 normal-font text-danger">{art_details.art && art_details.art.art_name}</h2>
                                            {
                                                is_ended ? (<>
                                                    <h4 className="m-0 pt-2 text-center normal-font text-danger font-weight-bold">Auction Sale Ended</h4>
                                                </>):<CountdownTimer targetDate={art_details.status && art_details.status.date_end}/>
                                            }
                                            
                                            <br></br>
                                            <p className="mt-md-0 mb-2 normal-font pri-color"><span className="font-weight-bold">Artist : {art_details.art ? art_details.art.artist.artist_name : ""}</span> </p>
                                            <p className="mt-md-0 mb-2 normal-font pri-color"><span className="font-weight-bold">Type:</span>  Auction</p>
                                            <p className="mt-md-0 mb-2 normal-font pri-color"><span className="font-weight-bold">Minting Status:</span> Minted </p>
                                            <p className="mt-md-0 mb-2 normal-font pri-color"><span className="font-weight-bold">Rarity:</span> Rare</p>
                                            <p className="mt-md-0 mb-2 normal-font pri-color"><span className="font-weight-bold">Frame:</span>  Without Frame</p>
                                            <p className="mt-md-0 mb-2 normal-font pri-color"><span className="font-weight-bold">Description:</span>  {art_details.art && art_details.art.description}</p>
                                            <h4 className="mt-0 mb-0 normal-font pri-color">Current Price: ETH {art_details.art && art_details.art.current_price} </h4>
                                            <h6 className="mb-3 mt-0 normal-font pri-color">Base Price: ETH {art_details.art && art_details.art.price} </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-right">
                                <button className={is_ended ? "btn btn-outline-danger mx-2 text-white disabled" : "btn btn-outline-danger mx-2 text-white " } data-toggle="modal" data-target="#modal-make-offer" disabled={is_ended ? "disabled" : ''}>Make Offer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row xbtk">
                    <div className='col-12'>
                        <div className='card my-3'>
                            <div className="card-header">
                                <p className="font-weight-bold">Offers</p>
                            </div>
                            <div className='card-body p-0'>
                                {
                                    art_details.art_offers && art_details.art_offers.length > 0 ? (
                                        <table className="table table-striped border-0 text-left">
                                            <thead>
                                                <tr>
                                                <th className="border-0" scope="col">Price</th>
                                                <th className="border-0" scope="col">USD Price</th>
                                                <th className="border-0" scope="col">Expiration</th>
                                                <th className="border-0" scope="col">From</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    art_details.art_offers && art_details.art_offers.map(offer => {
                                                        return (
                                                            <tr>
                                                                <th className="border-0"scope="row">{offer.offer_price} ETH</th>
                                                                <td className="border-0"  >{offer.offer_price} ETH</td>
                                                                <td className="border-0"  >{offer.date_end}</td>
                                                                <td className="border-0"  >{offer.client.username}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="text-center my-5">
                                            <img src="/img/bid.png"/>
                                            <p className="font-weight-bold">Not Offers Yet</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OfferForm/>
        </section>
    )

}

export default ViewAuction;