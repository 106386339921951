import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Auth from "../helpers/auth/Auth";
import PrivateRoutes from "./private_routes";

// import pages

import Register from "../pages/Register/Register";
import WhatIsNft from "../pages/WhatIsNft/WhatIsNft";
import PageNotFound from "../components/PageNotFound/PageNotFoundPage";
import HomePage from "../page/Home";
import SinglePage from "../page/Single";
import Paymongo from "../pages/Payment/Paymongo";
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ForgotPassword/ResetPasswordPage";
import Verify from "../pages/Register/Verify";
import RoadMapPage from "../pages/RoadMap/RoadMapPage";
import LoginPage from "../components/Login/LoginPage";
import CollectionPage from "../pages/CollectionDetails/CollectionPage";
import PrivateSale from "../pages/Sale/PrivateSale";
import PublicSale from "../pages/Sale/PublicSale";

import Collection from "../pages/CollectionV2/Collection";
import Vip from "../pages/Vip/Vip";
import AuctionPage from "../pages/Auction/AuctionPage";
import ListAuctionPage from "../pages/Auction/ListAuctionPage";
import ArtDetails from "../pages/Auction/ArtDetails";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
            {
              Auth.isAuthenticated() ? (
                <Router>
                  <Switch>
                    <PrivateRoutes />
                  </Switch>
                </Router>
              ) : (
                <Router>
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/register" component={Register}/>
                    <Route exact path="/auth/forget_password" component={ForgotPasswordPage}/>
                    <Route exact path="/auth/reset_password/:forget_token" component={ResetPasswordPage}/>
                    <Route exact path="/what-is-nft" component={WhatIsNft} />
                    <Route exact path="/thank-you/:code" component={Paymongo} />
                    <Route exact path="/auth/verify/:code" component={Verify} />
                    <Route exact path="/account/collections" component={CollectionPage}/>
                    <Route exact path="/collection-projects/view/:url" component={Collection} />
                    <Route exact path="/collection-projects/road-map" component={RoadMapPage}/>
                    <Route exact path="/vip/:url/:code" component={Vip} />
                    <Route exact path="/auctions" component={ListAuctionPage}/>
                    <Route exact path="/auction-details/:collection/:label" component={ArtDetails}/>
                    <Route exact path="/auction-details/:collection/:label/:code" component={ArtDetails}/>
                    <Route exact path="/auctions/:url" component={AuctionPage}/>
                    <Route exact path="/auctions/:url/:code/" component={AuctionPage} />
                    <Route exact path="/:url/sale/private-sale" component ={PrivateSale}/>
                    <Route exact path="/:url/sale/public-sale" component={PublicSale}/>
                    <Route exact path="/:url" component={SinglePage} />
                    <Route component={PageNotFound} />

                  </Switch>
                </Router>
              )
            }

      </div>
    );
  }
}
export default MyRouts;