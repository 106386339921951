import Register from "../components/Register/Register";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import FeaturedArtist from "../components/FeaturedArtist/FeaturedArtist";

import Auth from "../helpers/auth/Auth";

//import shortcodes
import Sections from "../components/Shortcodes/Sections";
import Artist from "../components/Shortcodes/Artist";
import Arthouses from "../components/Shortcodes/Arthouses";
import Faq from "../components/Shortcodes/Faq";
import Video from "../components/Shortcodes/Video";
import Press from "../components/Shortcodes/Press";
import SneakPeek from "../components/Shortcodes/SneakPeek";
import Projects from "../components/Shortcodes/Projects";
import Resources from "../components/Shortcodes/Resources";
import ArtistCollection from "../components/Shortcodes/ArtistCollection";
import ArtistList from "../components/Shortcodes/ArtistList";



/**
 * Add custom shortcodes method name is equivalent to shortcode name
 */
class PageShortcodes {

    static section = (param, content) => {
        // console.log(content);
        return <Sections params={content}/>
    }

    static register = (param, content) => {
     
        if(localStorage.getItem('isAuth')){
            return ''
        }
        return <Register params={content}/>
    }

    static section_title = (params, content) => {
        
    }

    static sneak = (params, content) => {
    //     // [sneak __id=4]
    //     console.log(content)
        // return <SneakPeek content={content}/>
    }

    static video_resources = (param) => {
        return <Resources params={param}/>
    }

    static faq = (param) => {
        return <Faq params={param}/>
    }

    static featured_artists = (param) => {
        return <FeaturedArtist />
    }

    static artist = (param) => {
        // console.log(param);
        return <Artist params={param} />
    }

    static artist_collection = (param) => {
        // console.log(param);
        return <ArtistCollection params={param} />
    }

    static arthouses = (param) => {
        // console.log(param);
        // return <Arthouses params={param}/>
    }

    static video = (param, content) => {
        return <Video params={param} content={content}/>
    }

    static press = (param,content) => {
        return <Press params={param}/>
    }

    static artists_lists = (param) => {
        return <ArtistList params={param}/>
    }

    static projects = (param, content, additional_content) => {
        // console.log(additional_content);
        return <Projects additional_content={additional_content} />
    }

   

}

export default PageShortcodes;
