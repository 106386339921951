import React, {Component} from "react";

class Video extends Component {

    processWhiteList = () => {
        const content = this.props.params.slice(-1)
        const split = content[0].split('=')

        if(split[0] == 'whitelist'){
            return content[0].replace('whitelist=', '')
        }

        return null;
        
    }

    render() { 
        // console.log(this.props.content);
        return (
            <section className="artist mt-0" style={{backgroundImage: `url(${"../../img/new_banner/Common_Background.jpg"})`}}>
                <div className="container" >
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="breadcrumb-content ">
                                <h2 className="mb-5 text-center text-dark normal-font">
                                    {this.props.content.title}
                                </h2>
                            </div>
                        </div> 
                        <div className="col-md-8 col-12 mx-0 px-0 mb-5 ">
                            <div className="embed-responsive embed-responsive-21by9">
                                <iframe className="embed-responsive-item" src={this.props.content.link} allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-10 col-12 mb-5 ">
                        {/* <h3 className=" text-white"></h3> */}
                            <div className="text-white text-center" dangerouslySetInnerHTML={{__html: this.props.content.content}}></div>
                        
                          <div className="text-center">
                            {
                                this.processWhiteList() ? <a className="btn btn-primary mt-3 btn-smaller px-5" target="__blank" href={this.processWhiteList()}>Scarletbox Whitelist Form</a> : ""
                            }
                          </div>
                        </div>
                        
                    </div>
                </div>
            </section> 
        );
    }
}
 
export default Video;