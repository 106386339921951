import React, {Component, useState, useEffect} from "react";
import apiClient from '../../helpers/api';

import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import PageLoading from "../FullPageLoading/PageLoading";

import Auth from "../../helpers/auth/Auth";

// const Projects = (props) => {

//     const [data, setData] = useState([])

    

//     useEffect(() => {
//         apiClient.get('/collection-projects/list/top')
//         .then(res => {
//             // console.log(res);
//             setData(res.data)
            
//         })
//     }, [])

//     return (
//         <>

//         <div className="artist " >
//             <div className="container py-5">
//                 <div className="row xbtk">
//                     <div className="col-12">
//                         <h1 className="mb-5 text-center section_title text-danger">
//                              Our Collection
//                          </h1>
//                     </div>

                     
//                     <div className="row ">

//                     { 
//                          data.map((items, idx) => {

                                                            
//                             return (

//                                     <div key={`${idx}`}>
//                                     <div className="card-body col-eq bg-border-red rounded-0">

//                                         <div className="row p-3 d-flex align-items-center justify-content-center">
//                                             <div className="col-12 col-sm-6 py-1">
//                                                 <img
//                                                 className="img-fluid  px-1"
//                                                 src={window.photo_url+items.photo}
//                                                 alt=""
//                                             />
//                                             </div>
//                                             <div className="col-12 col-sm-6 py-1">
//                                                 <h3 className="text-danger  py-0 my-0">{items.project_name}</h3>
//                                                 <p className="text-justify">{items.description}</p>
//                                                 <a className="btn btn-danger btn-smaller px-5" href="/collection-projects/view/:url" role="button">View</a>

//                                             </div>
//                                         </div>  


//                                     </div>
//                                     </div>




//                                     );


//                             })
//                     }
                    

//                         {
                            


//                             data.map((items, idx) => {

//                                 return (

//                                     <div key={`${idx}`}>


//                                     <div>
//                                         <div className={data.length > 2 ? "col-12 col-md-4 col-eq " :  "col-12 col-md-6 col-eq"  }>

//                                             <div className="card-body bg-border-red">
//                                                <div className="p-3">
//                                                 <center>
//                                                         <img
//                                                         className="img-fluid"
//                                                         src={window.photo_url+items.photo}
//                                                         alt=""
//                                                     />
//                                                     </center>
//                                                     <div className="text-center">
//                                                         <h3 className="text-danger normal-font font-weight-normal py-2 ">{items.project_name}</h3>
//                                                         <p className="text-justify">{items.description}</p>
//                                                         <a className="btn btn-danger btn-smaller px-5" href="/collection-projects/view/:url" role="button">View</a>


//                                                     </div>
//                                                </div>
//                                             </div>
//                                         </div>
//                                     </div>


//                                     </div>
                                        

//                                     );
//                             })

//                         }
                                
                      
//                     </div>
//                 </div>
//             </div>
//         </div>

//         </>
        
//     );
// }
 
// export default Projects;


const Projects = (props) => {

    const [defaultChain,setDefaultChain] = useState(null);
    const AllowedChainId = process.env.REACT_APP_CHAINID;
    const [errorMessage,setErrorMessage] = useState(null);
    const [metamask,setMetamask] = useState(true);
    const [defaultAccount,setDefaultAccount] = useState(localStorage.getItem('wallet'));
    const [is_auth, setIsAuth] = useState(true);

    async function connectWalletx(url) {

      

        if(metamask){
            console.log(window.ethereum);
             window.ethereum.request({method:"eth_requestAccounts"}).
            then(result => {
            
            window.ethereum.request({ method: 'eth_chainId' }).then(
                chain => {
                    apiClient.post(`/auth/register-wallet`, { 
                        wallet: result[0]
                    }).then(response => {
                        if (response.status == 200) {
                            localStorage.setItem('wallet',result[0])
                            localStorage.setItem('chain',chain)
                            // localStorage.setItem('isAuth', true)
                            Auth.storeAuth()
                           window.location.href =  '/collection-projects/view/'+url; 
                        }
                    }).catch(function (error) {
                        localStorage.removeItem("wallet")
                        localStorage.removeItem("chain")
                    })

                }
            );
        })

        } else {
            window.open("https://metamask.io/download/")
        }
    }

    try{
        if(localStorage.getItem('wallet')){
            window.ethereum.on('chainChanged', (chainId) => {
                localStorage.setItem('chain',chainId)
            });
    
            window.ethereum.on('accountsChanged', (accounts) => {
               
                if(accounts[0] != localStorage.getItem('wallet')){
                    apiClient.post('/account/logout')
                    .then( res => {
                        localStorage.removeItem("wallet")
                        localStorage.removeItem("chain")
                        localStorage.removeItem('isAuth')
                        apiClient.post(`/auth/register-wallet`, { 
                            wallet: accounts[0]
                        }).then(response => {
                            if (response.status == 200) {
                                localStorage.setItem('wallet',accounts[0])
                                // localStorage.setItem('isAuth', true)
                                Auth.storeAuth()
                               window.location.reload()
                            }
                        }).catch(function (error) {
                            localStorage.removeItem("wallet")
                            localStorage.removeItem("chain")
                        })

                    }).catch( e => {
                        console.log(e.response.data);
                    })
                    
                }
            });
        }
    } catch(e){ }

    useEffect(() => {

        if(!window.ethereum){
            setMetamask(false)
        } 

        apiClient.get('/account/user')
        .then(res => {
            setIsAuth(true)
            // setIsScroll(false)
            
        }).catch(e => {
            setIsAuth(false)
            localStorage.clear()
        })

    },[])

   

    if(!props.additional_content[0]) {
        <PageLoading />
    }

    return(
        <>
                <section className="artist">
                    <div className="container-fluid py-5">
                   
                        <div className="row d-flex justify-content-center xbtk">
                            <div className="col-12" >
                                <h2 className="mb-5 normal-font text-center text-dark">
                                    Our Collections
                                </h2>
                            </div>
                            <div className="container-fluid" id="collection">
                                <div className="row d-flex justify-content-center">

                                    {
                                        props.additional_content[0].map((data, idx) => {

                                            return (

                                                <div  key={`${idx}`} className={ props.additional_content[0].length == 3 ? "col-xl-4 pt-3 py-md-2" : " col-xl-6 px-md-1 p-2 my-3"}  >
                                                    
                                                        <div className="p-md-1">
                                                      
                                                            <a target={data.temporary_url != null ? '_blank' : ''} href={data.temporary_url != null  ? data.temporary_url : `/collection-projects/view/${data.url}`}>
                                                                <center className="">
                                                                    <img
                                                                    className="img-fluid"
                                                                    src={window.photo_url+data.photo}
                                                                    alt=""
                                                                    
                                                                />
                                                                
                                                                </center>
                                                            
                                                                {/* <div className="text-center project-image-text" style={{bottom: "90px"}}> */}
                                                                <div className="text-center w-md-75 mx-auto">
                                                                    <h4 className="text-uppercase mt-2 text-dark normal-font font-weight-normal mb-md-4 mb-0">{data.project_name}</h4>
                                                                    <div className="text-center text-justify text-white" dangerouslySetInnerHTML={{__html: data.description}}></div>
                                                                </div>

                                                                {/* <div className="info-button text-center pb-4" style={{bottom: "95px"}}> */}
                                                                {/* <div className="info-button text-center pb-md-5 pb-3">
                                                                    <a className="btn btn-danger btn-smaller px-5" href={`/collection-projects/view/${data.url}`} role="button">Buy Now</a>
                                                                    
                                                                </div> */}
                                                            </a>

                                                            {/* <div className="info-button text-center pb-4 px-2" style={{bottom: "10px"}}>
                                                                <button onClick={() => connectWalletx(data.url)} style={{backgroundColor: "white", color:"red", padding: "5px 10px 5px 10px", borderRadius: "50px", cursor: "pointer"}} role="button">Connect Metamask Wallet to Purchase</button>
                                                            </div> */}

                                                        </div>
                                                
                                                    
                                                </div>

                                            );

                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>
    ) 
   
}




// class Projects extends Component {

 
    
//     render() {

//        if(!this.props.additional_content[0]) {
//             <PageLoading />
//        }

//         if(this.props.additional_content[0].length > 1){

//             return(

//                 <>
//                 <section className="artist"  style={{backgroundImage: `url(${"../../img/bg/BG5.jpg"})`}}>
//                     <div className="container py-5">
                   
//                         <div className="row d-flex justify-content-center xbtk">
//                             <div className="col-12" >
//                                 <h1 className="mb-5 text-center text-white">
//                                     Our Collections
//                                 </h1>
//                             </div>
//                             <div className="container" id="collection">
//                                 <div className="row d-flex justify-content-center">

//                                     {
//                                         this.props.additional_content[0].map((data, idx) => {

//                                             return (

//                                                 <div  key={`${idx}`} className={ this.props.additional_content[0].length == 2 ? "col-12 col-md-6 p-0" : "col-12 col-md-4 p-0"}  >
//                                                     <a href={`/collection-projects/view/${data.url}`}>
//                                                         <div className="p-3">
//                                                             <center>
//                                                                 <img
//                                                                 className="img-fluid"
//                                                                 src={window.photo_url+data.photo}
//                                                                 alt=""
                                                                
//                                                             />
//                                                             </center>
//                                                             <div className="text-center project-image-text pb-2">
//                                                                 <h3 className="text-uppercase text-white text-center normal-font font-weight-normal">{data.project_name}</h3>
//                                                                 <div className="text-center text-justify text-white" dangerouslySetInnerHTML={{__html: data.description}}></div>
                                                            
//                                                             </div>

//                                                             <div className="info-button text-center pb-5">
//                                                                 <a className="btn btn-danger btn-smaller px-5" href={`/collection-projects/view/${data.url}`} role="button">Buy Now</a>
//                                                             </div>
                                                            

//                                                         </div>
//                                                     </a>
//                                                     <div className="mx-auto text-center">
//                                                         <a  data-toggle="modal" data-target="#modal-login" style={{backgroundColor: "white", color:"red", padding: "10px 20px 10px 20px", borderRadius: "50px", cursor: "pointer"}} role="button">Connect wallet to pay via MetaMask</a>
//                                                     </div>
//                                                 </div>

//                                             );

//                                         })
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 </>

//             );

//         }
      
//         return (

//             <>
//             <section className="artist" style={{backgroundImage: `url(${"../../img/bg/BG5.jpg"})`}} >
//              <div className="container py-5">
                
//                     <div className="row xbtk">
//                         <div className="col-12"  id="collection">
//                             <h1 className="mb-5 text-center text-white">
//                                 Our Collection
//                             </h1>
//                         </div>
//                     <div className="col-12">

//                     {
//                         this.props.additional_content[0].map((data, idx) => {

//                             return (
                                
//                                 <div key={`${idx}`}>
//                                     {/* <div className="bg-border-red rounded-0">

//                                         <div className="row p-3 d-flex align-items-center justify-content-center">
//                                             <div className="col-12 col-sm-6 py-1">
//                                                 <img
//                                                 className="img-fluid  px-1"
//                                                 src={window.photo_url+data.photo}
//                                                 alt=""
//                                             />
//                                             </div>
//                                             <div className="col-12 col-sm-6 py-1 text-center">
//                                                 <h4 className="text-uppercase text-danger text-center normal-font font-weight-normal mt-0">{data.project_name}</h4>
//                                                 <p className="text-left">{data.description}</p>
//                                                 <a className="btn btn-danger btn-smaller px-5" href={`/collection-projects/view/${data.url}`} role="button">
//                                                     {data.sched_status > 0 ? "View" : "Buy / Mint Now"}
//                                                 </a>

//                                             </div>
//                                         </div>  


//                                     </div> */}

                                    
//                                     <div className="d-flex align-items-center justify-content-center">
//                                         <a href={`/collection-projects/view/${data.url}`}>
//                                             <div className="p-2">
//                                                 <center>
//                                                     <img
//                                                     className="img-fluid"
//                                                     src={window.photo_url+data.photo}
//                                                     alt=""
//                                                     />
//                                                 </center>
//                                                 <div className="text-center project-image-text ">
//                                                     <h3 className="text-uppercase text-white text-center normal-font font-weight-normal">{data.project_name}</h3>
//                                                     <div className="text-center text-justify text-white py-0" dangerouslySetInnerHTML={{__html: data.description}}></div>
                                                    
//                                                 </div>
//                                             </div>
//                                         </a>
//                                     </div>  
//                                 </div>
//                             );
                               
//                         })
                    
//                     }
                                
//                     </div>
//                  </div>
//              </div>
//          </section>


//             </>
//         );


//     }


// }

export default Projects;
