import {useEffect, useState} from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import apiClient from "../../../helpers/api";
import NoBids from "./NoBids";

const MyBids = (props) => {
    
    const [bids, setBids] = useState([]);

    const getBids = () =>{
        try {
            
            apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_user_bids/${props.user.id}`).then(res => {
                console.log(res.data.bids);
                setBids(res.data.bids)
            })

        } catch (error) {
            setBids([])
        }

    }

    useEffect(() => {
        getBids();
    },[]);

    return (
            
        <div className="px-2 px-md-5 pb-3">
            <h5 className="font-weight-bold text-dark font-family-normal">My Bids</h5>
            {
                bids.length > 0 ? (
                    <div className="row">
                        {bids.map(bid => {
                            return (
                                <div className="col-md-6 mb-3">
                                    <img src={bid.art._mint_file} width={150} alt={bid.art.art_name}/>
                                    <br/>
                                    <p className="m-0 pt-2 font-weight-bold">{bid.art.art_name}</p>
                                    <p className="m-0">{bid.auction.title}</p>
                                    <p className="m-0">My Bid: PHP {bid.bid_amount}</p>
                                    <a href={`/auctions/${bid.auction.url}/${bid.source}`}>View Bid</a>
                                </div>
                            )
                        })}
                    </div>
                ) : <NoBids />
            } 
        </div>
      
    );
}
 
export default MyBids;