import React, {useState, useEffect} from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MyAccount from "../../components/MyAccount/MyAccount";
import BannerSale from "../../components/Banner/BannerSale";
import ModalMenu from "../../components/Modal/ModalMenu";
import apiClient from "../../helpers/api";
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";
import { Redirect } from "react-router-dom";


const MyAccountPage = () => {

    // document.addEventListener('contextmenu', (e) => e.preventDefault());

    // document.onkeydown = (e) => {
    //     if (e.keyCode === 123) {
    //         e.preventDefault();
    //     }
    //     if (e.ctrlKey && e.shiftKey && e.key == 'I') {
    //         e.preventDefault();
    //     }
    //     if (e.ctrlKey && e.shiftKey && e.key == 'C') {
    //         e.preventDefault();
    //     }
    //     if (e.ctrlKey && e.shiftKey && e.key == 'J') {
    //         e.preventDefault();
    //     }
    //     if (e.ctrlKey && e.key == 'U') {
    //         e.preventDefault();
    //     }
    // };

    const [is_auth, setIsAuth] = useState(true);

    useEffect(() => {

        apiClient.get('/account/user')
        .then(res => {
            setIsAuth(true)
        }).catch(e => {
            setIsAuth(false)
            localStorage.clear()
        })

    })

    if (!is_auth) {
       return <PageNotFound/>
    }

    return (
        <div className="main">
            <Header/>
            <BannerSale title="My Account"/>
            <div className="container mt-5">
                <MyAccount/>
            </div>
            <ModalMenu />
            <Footer />
        </div>
    );
}

 
export default MyAccountPage;