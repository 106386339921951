import React, {Component} from "react";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import NoCollection from "./NoCollections";



class MyCollection extends Component {

    state = { 

        collection: [],
    
    } 

    componentDidMount(){
        axios.get("http://localhost:3000/json-server/collection.json")
        .then(res => {
            this.setState({
                collection: res.data.collection_list
            })
            
        }).catch( e => console.log(e))
    }


    render() { 
        return (
            <div className="center-col">
                <h5 className="font-weight-bold normal-font pri-color">MY COLLECTION</h5>

               {/* No Collection */}  
               {/* <NoCollection /> */}

                
                {/* With Collection */}

                <div className="row">
                    {this.state.collection.map(collection => {
                        return (
                            <div className="col-md-6 mb-3">
                                <Link to={`/collection-details/${collection.id}`}>
                                <img src={collection.image} className="img-fluid" alt={collection.title}/>
                                </Link>
                                <br/>
                                <h5 className="m-0 pt-2 text-red normal-font">{collection.title}</h5>
                                <p className="m-0 text-red">{collection.description}</p>
                                <p className="m-0"> <strong>Php: </strong>{collection.details.my_bid}</p>
                                <p className="m-0"><strong>Payment Status: </strong> {collection.payment_status}</p>
                                <p className="m-0"><strong>Shipment Status: </strong> {collection.shipment_status}</p>
                            </div>
                        )
                    })}
                </div>
                  
               
            </div>
        ); 
    }
}
 
export default MyCollection;