
import React, {Component, useEffect, useState} from "react";


class ModalArtist extends Component{


    state = {
        content: ''

    };

    componentDidMount(){
        this.setState({
            content: this.props
        })
    };


    render() {

            return (

                <div id={`modal-pomotion-${this.props.id}`} className="modal fade p-0">
                    <div className={`modal-dialog   modal-dialog-centered ${this.props.size}`}>
                        <div className="modal-content m-2">
                        <div className="modal-header text-dark" data-dismiss="modal">
                            &nbsp;<i className="far fa-times-circle icon-close" />
                            </div>
                            <div className="modal-body pt-0">
                                <div className="row p-3 d-flex  justify-content-center">
                                    <div className="col-12">
                                        <img
                                        className="img-fluid"
                                        src={this.props.img}
                                        alt=""
                                       
                                    />
                                    </div>
                            </div>  

                                
                            </div>
                        </div>
                    </div>
                </div>
                      
            );
            
        


     
    }
       
}
 
export default ModalArtist;

