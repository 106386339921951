import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";
import apiClient from "../../helpers/api";
const Newsletter = () => {

    const [isShow, setIsShow] = useState(false);
    const [cookies, setCookie] = useCookies(['hide_newsletter']);

    const [backgroundImage, setBackgroundImage] = useState('');
    const [content, setContent]                 = useState('');
    const [newsletterId, setNewsletterId]       = useState('');

    const { register, handleSubmit, reset, setError, formState} = useForm()

    const {isSubmitting, errors, isSubmitSuccessful} = formState;


    const closeModal = () => {
        setIsShow(false);
        var today = new Date();
        today.setHours(today.getHours() + 12);
        setCookie('hide_newsletter', true, {expires: today});
    }

    const getNewsletter = () => {
        apiClient.get(`${process.env.REACT_APP_API}/newsletter`).then(res => {
            console.log(res);
            if (res.data == "" || cookies.hide_newsletter) {
                setIsShow(false)
                closeModal()
            }else{
                setIsShow(true)
                setBackgroundImage(window.photo_url + "storage/newsletter/" + res.data.bg_image)
                setContent(res.data.content)
                setNewsletterId(res.data.id)
                reset({
                    newsletter_id: res.data.id,
                });
            }
        }).catch(error => {
            setIsShow(false)
            closeModal()
        })
    }

    const onSubmit = (data) => {
        return new Promise(resolve => {
            setContent('<center><span class="spinner-border spinner-border-xl mr-1"></span></center>')
            setTimeout(() => {
                apiClient.post(`${process.env.REACT_APP_API}/newsletter/subscribe`, data).then(res => {
                    if (res.status == 201) {
                        setError("email", { type: "focus", message: res.data.msg}, { shouldFocus: true });
                        setContent(`<div class="text-center">
                                        <p style="font-size:20px;">Get first dibs on updates and premium drops</p>
                                        <h2 class="normal-font text-dark">Subscribe to our Newsletter</h2>    
                                    </div>`);
                    }else{

                        setContent(`<h2 class="normal-font text-dark text-center mx-auto w-50">${res.data.msg}<h2>`);
                        setTimeout(() => {
                            closeModal()
                        }, 1800)
                    }
                    resolve();
                }).catch(error => {
                    resolve()
                })
            }, 2300)
            
    
        });
    }

    useEffect(() => {
        getNewsletter();
        
        if (cookies.hide_newsletter) {
            setIsShow(false);
            return false;
        }

        return ;
    }, [])

    return ( <>
    <Modal show={isShow}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="newsletter-modal"
        className="px-3"
    >
        <Modal.Header>
            <h5 className="modal-title"></h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                <span aria-hidden="true" className="text-white">X</span>
            </button>
        </Modal.Header>
        <Modal.Body style={{height: backgroundImage ? "" : "30rem", backgroundImage:  `url("${backgroundImage}")`}}>
            <div className="vertical-center">
                <div dangerouslySetInnerHTML={{__html: content}}>
                </div>
                <br/>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group mb-3 col-lg-9 col-xl-8 col-12  mx-auto">
                        
                        {errors.email && <p className="text-danger m-0 ml-3">{errors.email.message}</p>}
                        <div className={`row col-12 ${isSubmitSuccessful ? "d-none" : ""}`}>
                            <input type="hidden" name="newsletter_id" {...register("newsletter_id")} value={newsletterId}/>
                            
                            <div className="col-lg-8 p-0">
                                
                                <input type="text" className="form-control form-control-lg newsletter-input" required name="email" {...register("email")}  placeholder="Enter Email address here" aria-label="Enter Email address here" aria-describedby="basic-addon2"/>
                            </div>
                            <div className="col-lg-4 p-0">
                                <button disabled={isSubmitting} type="submit" className="btn btn-dark btn-block border-0 rounded-0">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    SIGN ME UP
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>
            </div>
        </Modal.Body>
      </Modal>
    </> );
}
 
export default Newsletter;