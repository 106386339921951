import React, { useEffect, useState} from 'react';
import AuctionItem from './AuctionItem';
import apiClient from '../../helpers/api';
import AuctionItemSkeleton from '../../Skeleton/Auctions/AuctionItemSkeleton';
import { Alert, Modal } from 'react-bootstrap';
import ModalAuctions from '../Modal/ModalAuctions';
import AuctionItemGrid from './AuctionItemGrid';
import ModalRegister from '../Modal/ModalRegister';
const Auction = (props) => {

    const [isLoaded, setIsLoaded]  = useState(false)
    const [auction_arts, setAuctionArts] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [modalType, setModalType] = useState('auction_bids');
    const [auction_id, setAuctionId] = useState(null);
    const [auction_art_id, setAuctionArtId] = useState(null);
    const [showMerch, setShowMerch] = useState(false);
    const [showOther, setShowOther] = useState(false);
    const [otherImage, setOtherImage] = useState('');
    const [otherType, setOtherType] = useState('');

    const [merchImage, setMerchImage] = useState('');
    const [searchKey, setSearchKey] = useState('')
    const [auctId, setauctId] = useState(null)
    const [artBasePrice, setArtBasePrice] = useState(0)
    const [searchOn, setSearchOn] = useState(false)
    const [biddingClose, setbiddingClose] = useState('')
    const [showbiddingCount, setshowbiddingCount] = useState('')

    const [currentModalImage,setCurrentModalImage] = useState('')
    const [mainImage,setMainImage] = useState('')
    
    const [imageOtherList,setImageOtherList] = useState([])
    const [lastKey,setLastKey] = useState(0)
  
    const btn_outline_red = {
        border: '1px solid #c31b01',
        backgroundColor: '#fffff',
        color: '#c31b01'
    }

    const soldOut = {
        background:"#f9f9f9"
    }

    const getAuctionArts = () => {
       
        try {
            apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_arts/${props.auction_url}`).then(res => {
               
                setAuctionArts(res.data.result)
                setauctId(res.data.auction_id)

                if(res.data.result.length > 1){
                    setSearchOn(true);
                }
                setIsLoaded(true)

            }).catch( err => {
                
                setAuctionArts([])
                setIsLoaded(true)
            })
        } catch (error) {
            setAuctionArts([])
            setIsLoaded(true)
        }
    }

    const searchAuctionArts = (key_) => {
        
        try {
            apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_arts_by_key/${auctId}?key=${key_}`).then(res => {
                setAuctionArts(res.data.result)
                setIsLoaded(true)
            }).catch( err => {
                setAuctionArts([])
                setIsLoaded(true)
            })
        } catch (error) {
            setAuctionArts([])
            setIsLoaded(true)
        }

    }

    const verifyLogin = async () => {
        
        try {
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);
            if (res.status == 200) {
                setModalType('auction_bids')
                return true;
            } 
        } catch (error) {
            setModalType("login")
            return false;
        }
    }

    const placeBids = (art) => {
        
        setbiddingClose(art.date_end);
         //verifyLogin()
        setAuctionId(art.auction_id)
        setAuctionArtId(art.id)
        setArtBasePrice(art.bid_start);
        setIsShow(true)
    }

    const closeModal = () => {
        setAuctionId(null)
        setAuctionArtId(null)
        setIsShow(false)
        setShowMerch(false)
        setShowOther(false)
    }

    const searchArts = (e) => {
        
        let key_ = e.target.value;

        setIsLoaded(false)

        if(key_ !== ''){
            searchAuctionArts(key_);
        } else {
            getAuctionArts();
        }
        
        setSearchKey(key_)
    }
    
    const clearSearch = () => {
        document.querySelector('#search_form').value = ""
        setSearchKey('');
        getAuctionArts();
    }

    const viewMerch = (art) => {
        setMerchImage(art.merch_image)
        setShowMerch(true)
    }

    const NextBtn = () => {
        var  key = currentModalImage == 'main' ? 0 : currentModalImage + 1,
             src_ = imageOtherList[key].image_,
             type = imageOtherList[key].extension;

        setCurrentModalImage(key);
        setOtherImage(src_);
        setOtherType(type)
        setShowOther(true)
    }

    const PrevBtn = () => {
        
        if(currentModalImage > lastKey ){
            var  key = lastKey - 1;
        } else {
            var  key = currentModalImage == 0 ? 'main' : currentModalImage - 1;
        }
        
        if(currentModalImage == 0){
            setCurrentModalImage('main');
            var src_ = mainImage,
            type = 'jpg';
        } else {
            setCurrentModalImage(key);
            var src_ = imageOtherList[key].image_,
            type = imageOtherList[key].extension;
        }

        setOtherImage(src_);
        setOtherType(type)
        setShowOther(true)
        
    }


    const viewOther = (type,src_,key,other_images,main) => {
        
        setMainImage(main)
        setLastKey((other_images.length) - 1);
        setCurrentModalImage(key);
        setImageOtherList(other_images);
        setOtherImage(src_);
        setOtherType(type)
        setShowOther(true)

    }
    
    useEffect(() => {
        getAuctionArts();
    }, []);

    return (
        <section className="item-details-area pt-5">
            <div className='container'>
                {
                    searchOn ? 
                    <div className="row justify-content-end">
                        <div className="col-md-4 align-self-end ">
                            <input type="text" id="search_form" className="form-control mb-2 mr-sm-2" onChange={e => searchArts(e)} placeholder="Search Art or Artist"></input>

                        </div>
                    </div> : ""
                }
                

                { 
                    searchKey != '' ?
                        <div className="col-12 col-md-12 p-2" style={soldOut}>
                            <p className='text-dark'>Result found for <b>"{searchKey}"</b> key. <a style={{cursor:'pointer'}} className='text-danger' onClick={() => clearSearch()}>Clear Search</a></p>
                        </div>
                    : ''
                }
                {   
                    isLoaded ? 
                        auction_arts.length > 0 ? 
                            props.layout == 1 ? 
                                auction_arts.map((art, i) =>
                                    <>
                                        <AuctionItem
                                        id_upload={props.id_upload}
                                        isStarted={props.isStarted}
                                        bid_clode_date={art.date_end}
                                        showTimer={props.showTimer} key={i} 
                                        auction_id = {art.auction_id}
                                        auction_art_id = {art.id}
                                        art_id = {art.art_id}
                                        label = {art.label}
                                        merch_image = {art.merch_image}
                                        merch_title = {art.merch_title}
                                        total_offers = {art.total_offers}
                                        status = {art.status}
                                        auctionstatus = {props.auction_status}
                                        auctionallowed = {props.auction_allowed}
                                        description ={art.description}
                                        art_code = {art.art_code}
                                        art_details={art.art_details}
                                        other_images={art.other_images}
                                        artist={art.artist}
                                        
                                        highest_offer={parseInt(art.highest_offer)}
                                        getAuctionArts={getAuctionArts}
                                        base_price={parseInt(art.bid_start)}
                                        base_price_eth={parseInt(art.base_price_eth)}
                                        product_details={props.product_details}
                                        shipping_details={props.shipping_details}
                                        auction_code={props.auction_code}
                                        free={props.free}
                                        placeBids={() => placeBids(art)}
                                        viewMerch={() => viewMerch(art)}
                                        viewOtherImages={viewOther}
                                    />
                                    </>
                                )
                             :  <div className="row">
                                {
                                    auction_arts.map((art, i) =>
                                    <AuctionItemGrid
                                    isStarted={props.isStarted}
                                    collection_={props.collection_}
                                    bid_clode_date={art.date_end}
                                    showTimer={props.showTimer} key={i} 
                                    auction_id = {art.auction_id}
                                    auction_art_id = {art.id}
                                    art_id = {art.art_id}
                                    label = {art.label}
                                    merch_image = {art.merch_image}
                                    merch_title = {art.merch_title}
                                    total_offers = {art.total_offers}
                                    status = {art.status}
                                    auctionstatus = {props.auction_status}
                                    auctionallowed = {props.auction_allowed}
                                    art_code = {art.art_code}
                                    art_details={art.art_details}
                                    other_images={art.other_images}
                                    artist={art.artist}
                                    highest_offer={parseInt(art.highest_offer)}
                                    getAuctionArts={getAuctionArts}
                                    base_price={parseInt(art.bid_start)}
                                    base_price_eth={parseInt(art.base_price_eth)}
                                    product_details={props.product_details}
                                    shipping_details={props.shipping_details}
                                    auction_code={props.auction_code}
                                    free={props.free}
                                    placeBids={() => placeBids(art)}
                                    viewMerch={() => viewMerch(art)}
                                />
                                    )
                                }
                                </div>
                        : <Alert show={true} variant="light" className="mt-2 d-flex justify-content-between">
                                <p className="mb-0 ">Sorry, No Auction Arts Available</p> 
                            </Alert>
                        
                   : (<AuctionItemSkeleton arts={3}/>)
                }
            </div>
 
            {
                auction_art_id && auction_id && modalType ? (
                    <ModalAuctions 
                        bid_clode_date={biddingClose}
                            showTimer={props.showTimer}
                        isShow={isShow}
                        closeModal={closeModal}
                        modalType={modalType} 
                        auction_art_id={auction_art_id} 
                        auction_id = {auction_id}
                        setModalType={setModalType}
                        getAuctionArts={getAuctionArts}
                        base_price={parseInt(artBasePrice)}
                        base_price_eth={parseInt(props.base_price_eth)}
                        auction_code={props.auction_code}
                        auctionstatus = {props.auction_status}
                        id_upload= {props.id_upload}
                    />
                ) : null
            }
        
        <Modal size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered show={showMerch}>
            <Modal.Header className="pb-0">
                <p className="font-weight-bold pt-3"></p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
            </Modal.Header>
            <Modal.Body className="pt-0">
                <img alt="img" className='img-fluid' src={merchImage}></img>
            </Modal.Body>
        </Modal>


        <Modal size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered show={showOther}>
            <Modal.Header className="pb-0">
                <p className="font-weight-bold pt-3"></p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
            </Modal.Header>
            <Modal.Body className="pt-0">
            <div className='row'>
                    <div className='col-md-12'>
                    {
                    otherType ==  'mp4' ? 
                    <video preload="auto" muted= {true} autoPlay={false} controls= {true}  className="embed-responsive-item " 
                                            src={otherImage} style={{width: "100%",height:"100%"}} ></video> :
                    <img alt="img" className='img-fluid' src={otherImage}></img  >
                    
                    }
                    </div>
                    <div className='col-md-12 py-2'></div>
                    <div className='col-md-12 text-right'>
                        {
                            currentModalImage < lastKey  || currentModalImage != 'main' ? 
                            <button  onClick={() =>  PrevBtn() }   className='btn btn-dark py-2 px-3 mr-2'>
                            Prev
                            </button> : ''
                        }
                       
                        {
                            currentModalImage < lastKey || currentModalImage == 'main' ? 
                            <button  onClick={() =>  NextBtn() }  className='btn btn-dark py-2 px-3 '>Next</button>
                            : ''
                        }
                        
                    </div>
                </div>


            </Modal.Body>
        </Modal>
       <ModalRegister/>
        </section>
    )

}

export default Auction;