import React, {Component, useState, useEffect} from "react";
import apiClient from '../../helpers/api';

import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import PageLoading from "../FullPageLoading/PageLoading";


const Press = (props) => {

    const [currentId, setCurrentId] = useState(0);

    const active = {
        color: "white",
        backgroundColor: '#333',
        padding: "10px",
        cursor:'pointer',
        border:"none",
        marginBottom:"2px",
        height:"75px"
    };

    const inactive = {
        color: "white",
        cursor:'pointer',
        padding: "10px",
        backgroundColor: '#aaa',
        border:"none",
        marginBottom:"2px",
        height:"75px"
    };
    const pClassBkt = {
        width: '100%'
    }
  
    

    const selectProject = (e) => {
        let id = e.currentTarget.getAttribute('data-id');
        setCurrentId(id);
    }

    useEffect( () => {
        let loop = 0;
        props.params.filters.map((res, id)=> {
            if(loop == 0){
                setCurrentId(res.id);
            }
            loop++;
        })
    },[])

    return(
        <>
            <div className="container py-5 px-3 text-justify text-black">
                <div className="row  mt-3 mb-3  ">
                    <div className="col-md-3  mb-4 mb-md-0">
                        <ul className="list-group list-group-flush">
                            {
                                props.params.filters.map((res, id)=> {
                                    return (
                                        <li data-id={res.id} className="list-group-item  d-flex  align-items-center " onClick={selectProject} style={currentId == res.id ? active : inactive} >
                                            <p className=" text-center" style={pClassBkt}  dangerouslySetInnerHTML={{__html: res.project_name}}></p>
                                        </li>
                                        )
                                })
                            }
                        </ul>
                    </div>

                    <div className="col-md-9 text-center mb-4 mb-md-0">
                        <div className="row mb-3">
                        {
                                props.params.press.map((res, id)=> {
                                return (
                                    <>
                                    {
                                        currentId == 0 || currentId == res.project_id ?
                                            <div className={`col-md-3 text-center mb-4 mb-md-0 press press${res.project_id}`}>
                                                <a href={res.link} target="_blank" rel="noopener">
                                                <img className="img-fluid" src={res.url} alt=""/> 
                                                </a>
                                                <p className="text-dark mt-2">{res.name}</p> 
                                            </div>
                                        : ''
                                    }
                                    </>
                                    
                                )
                                })
                        }
                        </div>
                    </div>
                    
                </div>
            </div>
            </>
    ) 
   
}

export default Press;
