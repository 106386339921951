import React, {useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import apiClient from "../../helpers/api";
import { useForm } from "react-hook-form";
import Select from 'react-select';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;



const RegisterPopup = (props) => {

    const { params } = props;
    const { handleSubmit, formState } = useForm();
    const [show, setShow] = useState(false)
    const [country, setCountry] = useState()
    const [data, setData] = useState({})
    const [error, setError] = useState()

    //input fields
    const [username, setUsername] = useState('')
    const [name, setName] = useState('')
    const [last_name, setLastname] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
    const [twitter, setTwitter] = useState('')
    const [discord, setDiscord] = useState('')
    const [country_selected, setCountrySel] = useState()
    const [referal_code, setReferalCode]  = useState()
    const [isScroll, setIsScroll] = useState(false)
    const [terms, setTerms] = useState('')
    const [mobile_code, setMobileCOde] = useState(63)
    
    const { isSubmitting} = formState;

    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");
    const [verifyEmail,setVerifyEmail] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isResending,setIsResending] = useState(false)
    const [isVerifying,setiIVerifying] = useState(false)

    const [verificationCode, setVerificationCode] = useState('')
    const [errorVerification,setErrorVerification] = useState('')

    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }

    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password")
        {
            setConfirmPasswordType("text")
         return;
        }
            setConfirmPasswordType("password")
      }


  
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmpasswordShown ? false : true);
      };

      
    const onVerifyCode = (e) => {
        setErrorVerification('')
        setiIVerifying(true)
        try {
            apiClient.post(`${process.env.REACT_APP_API}/auth/vefication-email`, {
                verificationCode: verificationCode,
                email:email
            }).then(res => {
                window.location.reload();
            }).catch(error => {
                setErrorVerification('invalid code')
                setiIVerifying(false)
            })

        } catch(error) {

        }

    }

    const onresendEmail = (e) => {
        setIsResending(true)
        let email_ = e.target.getAttribute('data-email');
        try {
            apiClient.post(`${process.env.REACT_APP_API}/auth/resend-email`, {
                email: email_,
            }).then(res => {
                setIsResending(false)
                setCounter(60)
                countersec()
            }).catch(error => {
                setIsResending(false)
            })
        } catch(error) {
            setIsResending(false)
        }

        e.preventDefault();
        
    }
    
    const onRegister = (e) => {
    
        return new Promise(resolve => {
            apiClient.post(`${process.env.REACT_APP_API}/auth/register`, {
                username: username,
                name: name,
                mobile_code:mobile_code,
                last_name: last_name,
                mobile: mobile,
                email: email,
                password: password,
                confirm_password: confirm_password,
                twitter: twitter,
                discord: discord,
                country_code: country_selected,
                referal_code: referal_code
            }).then(res => {
                setIsScroll(false)
                setData(res.data)
                if(res.data.code !== 300){
                    setVerifyEmail(true)
                }
                resolve(); 
            }).catch(error => {
                if(error.response.status >= 401){

                    setError(error.response.data)
                    var url = window.location.href
                    var scroll = url.includes("/#")
                
                    if(scroll){
                        setIsScroll(true)
                    }
                    document.querySelector('#register-form').parentElement.scrollIntoView({behavior: "smooth"})

        
                    resolve();
                }  
            })
        })

    };
    

    const countersec = () => {
       counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }

    useEffect( () => {
        apiClient.get(`${process.env.REACT_APP_API}/geo-location/country`).then(res => {
           setCountry(res.data.data)
        })
        countersec()
        
    },[counter])

    let options = country && country.map(val => {
        return  { value: val.id, label: `${val.name} (${val.phonecode})` };
    })


    return (
    <section className="author-area py-0 px-lg-0">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 p-0 m-0">

                   

                {/* Intro */}
                { !verifyEmail ? 
                <div className="intro text-center" id="regform">
                    <h2 className="text-dark">{props ? props.title : ""}</h2>
                    {/* <div className="text-dark" dangerouslySetInnerHTML={{__html: params ? params.sub_title : ""}}></div> */}
                </div>
                : ''}
                {
                    !verifyEmail ? 
                    <form id="register-form" onSubmit={handleSubmit(onRegister)} className="">
                
                    <div className="row justify-content-center">
                    {
                        !isSubmitting && data.code == 300 ? 
                        <div className="col-lg-10 col-sm-12">
                            <div className="alert alert-danger">{data.msg}</div>
                            </div>
                        : ''
                    }
                        
                    <div className="col-lg-10 col-sm-12">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12 pl-0">
                                    <div className="form-group mt-2">
                                        <label className="text-dark">First Name</label>
                                        <input type="text" className={`form-control ${ error && error.errors.name ? "is-invalid" : ""}`} name="name" onChange={ e =>setName(e.target.value) }  />
                                        {error ? ( <div class="invalid-feedback"> {error.errors.name} </div>) : ""}
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 pl-0">
                                    <div className="form-group mt-2">
                                        <label className="text-dark">Last Name </label>
                                        <input type="text" className={`form-control ${ error && error.errors.last_name ? "is-invalid" : ""}`} name="last_name" onChange={ e =>setLastname(e.target.value) }  />
                                        {error ? ( <div class="invalid-feedback"> {error.errors.last_name} </div>) : ""}
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-10 col-sm-12">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12 pl-0 d-none">
                                    <div className="form-group mt-2">
                                        <label className="text-dark">Username &#40; Optional &#41;</label>
                                        {/* <input type="text" className={`form-control ${ error && error.errors.username ? "is-invalid" : ""}`} name="username" onChange={ e =>setUsername(e.target.value) }  />
                                        {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""} */}
                                        <input type="text" className="form-control" name="username" onChange={ e =>setUsername(e.target.value) }  />
                                    </div>
                                </div> 
                                <div className="col-md-12 col-12 pl-0">
                                    <div className="form-group mt-2">
                                        <label className="text-dark">Email</label>
                                        <input type="email" className={`form-control ${ error && error.errors.email ? "is-invalid" : ""}`} name="email" onChange={ e =>setEmail(e.target.value) } />
                                        {error ? (  <div class="invalid-feedback"> {error.errors.email} </div>) : ""}
                                    </div>
                                </div> 
                                
                                <div className="col-md-12 col-12 pl-0">
                                    <label className="text-dark">Mobile Number</label>
                                    <div className="row">
                                        <div className="col-2 pl-0">
                                        <select  onChange={ e =>setMobileCOde(e.target.value) }  className={`form-control ${ error && error.errors.mobile_code ? "is-invalid" : ""}`}>
                                            {/* <option value="">Code</option> */}
                                                {
                                                    country && country.map((count_cde,id) => {
                                                        return(<option selected={count_cde.id == 174 ? true : false} value={`+${count_cde.phonecode}`}>{`${count_cde.name} (${count_cde.phonecode})`}</option>)
                                                    })
                                                } 
                                            </select>
                                        </div>
                                        <div className="col-10  pl-1 pr-0">
                                        <input type="text" className={`form-control ${ error && error.errors.mobile ? "is-invalid" : ""}`} name="mobile" onChange={ e =>setMobile(e.target.value) } />
                                        {error ? (  <div class="invalid-feedback"> {error.errors.mobile} </div>) : ""}
                                        </div>
                                    </div>
                                
                                </div> 
                                
                                <div className="col-md-9 col-9 pl-0">
                                    <div className="form-group mt-2">
                                        
                                        
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-10 col-sm-12">
                            
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6 px-0">
                                    <div className="row align-items-center">
                                        <div className="col-10 pl-0 pr-2">                                           
                                            <div className="form-group mt-2">
                                                <label className="text-dark">Password</label>
                                                <input type={passwordType} className={`form-control ${ error && error.errors.password ? "is-invalid" : ""}`} name="password" onChange={ e =>setPassword(e.target.value) }   />
                                                {error ? ( <div class="invalid-feedback">{error.errors.password} </div>) : ""}
                                            </div>
                                        </div> 
                                        <div className="col-md-1 col-2 pl-0 ml-0  mt-3 ">
                                            <div className="pt-1">
                                                <button type="button" className="btn btn-dark btn-smaller" style={{ padding: "11px 14px" }} onClick={togglePassword}>
                                                    { passwordType==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                </button>

                                            </div>
                                        </div>
                                        </div>
                                </div>
                                <div className="col-12 col-lg-6 px-0">
                                    <div className="row align-items-center">
                                        <div className="col-10 pl-0 pr-2">
                                            <div className="form-group mt-2">
                                                <label className="text-dark">Confirm Password</label>
                                                <input type={confirmpasswordType} className={`form-control ${ error && error.errors.confirm_password ? "is-invalid" : ""}`} name="confirm_password" onChange={ e =>setConfirmPassword(e.target.value) }  />
                                                {error ? ( <div class="invalid-feedback"> {error.errors.confirm_password} </div>) : ""}
                                            </div>
                                        </div> 
                                        
                                        <div className="col-md-1 col-2 pl-0 ml-0 mt-3 ">
                                            <div className="pt-1">
                                                <button type="button" className="btn btn-dark btn-smaller" style={{ padding: "11px 14px" }}  onClick={toggleConfirmPassword}>
                                                { confirmpasswordType ==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                </button>
                                            </div>
                                        </div>
                                    </div>   
                                </div> 
                            </div>
                        </div> 
                       
                        <div className="col-10">
                            <div className="form-group mt-1 ml-3">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input " type="checkbox" name="inlineRadioOptions" id="inlineRadio1" onChange={ e =>setTerms(e.target.value) }/>
                                    <label className="form-check-label pt-2" htmlFor="inlineRadio1">I have read and agreed to the <a target="blank" href="https://scarletbox.io/privacy">Terms and Conditions</a></label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <button className="btn btn-dark mt-3 mt-sm-4 text-center px-5" type="submit">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            LET'S DO THIS!</button>
                        </div>
                        {/* <div className="col-12">
                            <p className="d-block text-center mt-4 text-dark">Already have an account? 
                                <a className="text-red px-1" href="" data-toggle="modal" data-target="#modal-login">Login</a>
                            </p>
                        </div> */}
                
                    </div>
                </form>

                :
                <div className="text-center">
                <h3  className="text-dark">Verify your email address</h3>
                <p>We sent a verification code to {email}. <br></br>Check your email and enter the code below</p>
                <p></p>
                <div className="row justify-content-md-center">
                    <div className="col-12 col-md-6">
                    
                    <label>Verification Code</label>
                    <input type="text" className="form-control mb-3" onChange={ e => setVerificationCode(e.target.value) }  />
                    {errorVerification && <p className="text-red m-0 mb-2">{errorVerification}</p>}
                    <button  data-email={email} className="btn btn-danger mb-3 btn-block" onClick={e => onVerifyCode(e)}>
                    {isVerifying && <span className="spinner-border spinner-border-sm mr-1"></span>} Verify</button>
                    
                    <small>Didn't receive an email? 
                        { counter == 0 ? 
                            <a href="#"  data-email={email} onClick={e => onresendEmail(e)}  disabled={isResending ? true : false} className="ml-1">{isResending && <span className="spinner-border spinner-border-sm mr-1"></span>}Resend</a> 
                            : ''
                        } <small>{counter > 0 && counter}</small></small>
                    </div>
                    
                </div>

                </div>
               
                }
               
                
            </div>
            </div>
        </div>
        {/* <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Body>
                            
                                { data.code == 300 ? (
                                        <div className="bg-lwhite">
                                                <p className="text-center text-dark">{data.msg}</p>
                                                    <br/>
                                            <button className="btn btn-primary btn-block" onClick={() => { setShow(false)}}>CLOSE</button>
                                        </div>
                                    ): (
                                        <div className="bg-lwhite">
                                                <h5 className="text-center text-dark normal-font">ALMOST THERE!</h5>
                                                <p className="text-center  text-dark">
                                                    Registration successful.
                                                    <br/>
                                                    We sent you an email verification please verify your emal
                                                </p>
                                            <br/>
                                            <button className="btn btn-primary btn-block" onClick={() => { window.location.reload()}}>CLOSE</button>
                                        </div>
                                    ) 
                                }
                               
                            </Modal.Body>
                        </Modal> */}
    </section>
                    
    );

}

export default RegisterPopup;