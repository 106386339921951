import React, { Component } from 'react';
import PageLoading from "../../components/FullPageLoading/PageLoading";
class PublicSaleSoon extends Component {

    state = {
        subtitle: '',
        text: ''
        
    };
 
    componentDidMount(){
        this.setState({
            subtitle: this.props,
            text: this.props
        })
 
    };

    render() { 
        return (
            <div className='container'>            
                <div className='container'>
                    <div className='row'>
                        <section className="faq-area pt-5">
                            <div className="container">
                                <div className="row justify-content-md-center">
                                    <div className="col-md-8 ">
                                        <h4 className="text-center normal-font text-dark ">
                                                {this.props.subtitle}
                                            </h4>
                                        {this.props.next_scheedule ? <p className="text-center text-dark">Our next public sales will be on {this.props.next_scheedule}</p> : ''}
                                        <div className="row justify-content-md-center">
                                        <div className="col-md-5 ">
                                        {
                                        !this.props.subtitle ? 
                                        <PageLoading />
                                        : <img src={window.photo_url+'private_.jpg'} className="img-fluid" />
                                        }
                                        </div>
                                        </div>
                                        <p className="text-center normal-font text-dark"  dangerouslySetInnerHTML={{ __html: this.props.text }}>
                                        </p>
                                       
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>  
                </div>
               
            </div>
        );
    }
}
 
export default PublicSaleSoon;