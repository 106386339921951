import React, {useState, useEffect} from "react";
//import Modal from "../Modal/Modal";
import { Modal } from "react-bootstrap";
import apiClient from "../../helpers/api";
import { useForm } from "react-hook-form";
import Select from 'react-select';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;



const Register = (props) => {
    const { params } = props;
    const { handleSubmit, formState } = useForm();
    const [show, setShow] = useState(false)
    const [country, setCountry] = useState()
    const [data, setData] = useState({})
    const [error, setError] = useState()

    //input fields
    const [username, setUsername] = useState('')
    const [name, setName] = useState('')
    const [last_name, setLastname] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
    const [twitter, setTwitter] = useState('')
    const [discord, setDiscord] = useState('')
    const [country_selected, setCountrySel] = useState()
    const [referal_code, setReferalCode]  = useState()
    const [isScroll, setIsScroll] = useState(false)
    
    const { isSubmitting} = formState;

    // const [passwordShown, setPasswordShown] = useState(false);
    // const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);

    // const togglePasswordVisiblity = () => {
    //   setPasswordShown(passwordShown ? false : true);
    // };


    // const toggleConfirmPasswordVisiblity = () => {
    //     setConfirmPasswordShown(confirmpasswordShown ? false : true);
    //   };

    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");

  
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }

    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password")
        {
            setConfirmPasswordType("text")
         return;
        }
            setConfirmPasswordType("password")
      }


  
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmpasswordShown ? false : true);
      };

  
    
    const onRegister = (e) => {

        return new Promise(resolve => {
            apiClient.post(`${process.env.REACT_APP_API}/auth/register`, {
                username: username,
                name: name,
                last_name: last_name,
                mobile: mobile,
                email: email,
                password: password,
                confirm_password: confirm_password,
                twitter: twitter,
                discord: discord,
                country_code: country_selected,
                referal_code: referal_code
            }).then(res => {
                setIsScroll(false)
                setData(res.data)
                setShow({
                    showModal: true
                })
                resolve();
            }).catch(error => {
                if(error.response.status >= 401){

                    setError(error.response.data)
                    console.log(error);

                    var url = window.location.href
                    var scroll = url.includes("/#")
                
                    if(scroll){
                        setIsScroll(true)
                    }
                    document.querySelector('#register-form').parentElement.scrollIntoView({behavior: "smooth"})

        
                    resolve();
                }  
            })
        })

    };



    useEffect( () => {
        apiClient.get(`${process.env.REACT_APP_API}/geo-location/country`).then(res => {
           setCountry(res.data.data)
        })
        
    },[])

    let options = country && country.map(val => {
        return  { value: val.id, label: `${val.name} (${val.phonecode})` };
    })

    return (
        
        <section className="author-area" style={{backgroundImage: `url(${"../../img/bg/BG2.jpg"})`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 p-0 m-0">
                        {/* Intro */}
                        <div className="intro text-center" id="regform">
                            <h1 className="text-white">{params ? params.title : ""}</h1>
                            {/* <div className="text-white" dangerouslySetInnerHTML={{__html: params ? params.sub_title : ""}}></div> */}
                        </div>
                        {/* Item Form */}
                        <form id="register-form" onSubmit={handleSubmit(onRegister)} className="">
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 col-12 pl-0">
                                            <div className="form-group mt-2">
                                                <label className="text-white">First Name</label>
                                                <input type="text" className={`form-control ${ error && error.errors.name ? "is-invalid" : ""}`} name="name" onChange={ e =>setName(e.target.value) }  />
                                                {error ? ( <p className="text-red m-0"> {error.errors.name} </p>) : ""}
                                            </div>
                                        </div> 
                                        <div className="col-md-6 col-12 pl-0">
                                            <div className="form-group mt-2">
                                                <label className="text-white">Last Name </label>
                                                <input type="text" className={`form-control ${ error && error.errors.last_name ? "is-invalid" : ""}`} name="last_name" onChange={ e =>setLastname(e.target.value) }  />
                                                {error ? ( <p className="text-red m-0"> {error.errors.last_name} </p>) : ""}
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 col-12 pl-0 d-none">
                                            <div className="form-group mt-2">
                                                <label className="text-white">Username &#40; Optional &#41;</label>
                                                {/* <input type="text" className={`form-control ${ error && error.errors.username ? "is-invalid" : ""}`} name="username" onChange={ e =>setUsername(e.target.value) }  />
                                                {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""} */}
                                                <input type="text" className="form-control" name="username" onChange={ e =>setUsername(e.target.value) }  />
                                            </div>
                                        </div> 
                                        <div className="col-md-12 col-12 pl-0">
                                            <div className="form-group mt-2">
                                                <label className="text-white">Email</label>
                                                <input type="email" className={`form-control ${ error && error.errors.email ? "is-invalid" : ""}`} name="email"onChange={ e =>setEmail(e.target.value) } />
                                                {error ? ( <p className="text-red m-0"> {error.errors.email} </p>) : ""}
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="row align-items-center">
                                        <div className="col-md-5 col-10 pl-0 pr-2">                                           
                                            <div className="form-group mt-2">
                                                <label className="text-white">Password</label>
                                                <input type={passwordType} className={`form-control ${ error && error.errors.password ? "is-invalid" : ""}`} name="password" onChange={ e =>setPassword(e.target.value) }   />
                                                {error ? ( <p className="text-red m-0"> {error.errors.password} </p>) : ""}
                                            </div>
                                        </div> 
                                        <div className="col-md-1 col-2 pl-0 ml-0  mt-3 ">
                                            <div className="pt-1">
                                                <button type="button" className="btn btn-danger btn-smaller" onClick={togglePassword}>
                                                    { passwordType==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }

                                                </button>

                                            </div>
                                        </div>
                                    
                                        <div className="col-md-5 col-10 pl-0 pr-2">
                                            <div className="form-group mt-2">
                                                <label className="text-white">Confirm Password</label>
                                                <input type={confirmpasswordType} className={`form-control ${ error && error.errors.confirm_password ? "is-invalid" : ""}`} name="confirm_password" onChange={ e =>setConfirmPassword(e.target.value) }  />
                                                {error ? ( <p className="text-red m-0"> {error.errors.confirm_password} </p>) : ""}
                                            </div>
                                        </div> 
                                        
                                        <div className="col-md-1 col-2 pl-0 ml-0 mt-3 ">
                                            <div className="pt-1">
                                                <button type="button" className="btn btn-danger btn-smaller"  onClick={toggleConfirmPassword}>
                                                { confirmpasswordType ==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                </button>
                                            </div>
                                        </div>    
                                    </div>
                                </div> 
                                <div className="col-10 d-none">
                                    <div className="row align-items-center">
                                        {/* <div className="col-md-4 pl-0">
                                        <div className="form-group mt-3"> */}
                                        <div className="col-md-6 col-12 pl-0">
                                            <div className="form-group mt-2">
                                                {/* <select className={`form-control p-0 select2 ${ error && error.errors.country_code ? "is-invalid" : ""}`} onChange={ e => setCountrySel(e.target.value)} id="exampleFormControlSelect1">
                                                    <option>Country Code</option>
                                                    {
                                                        country ? country.map((val, id) => {
                                                            return (
                                                                <option key={id} value={val.id}>{`${val.name} (${val.phonecode})`}</option>
                                                            )
                                                        }) : ""
                                                    }
                                                </select> */}
                                                <label className="text-white">Country Code  &#40; Optional &#41;</label>
                                                <Select options={options} className="react-select" classNamePrefix="react-select" onChange={ e => setCountrySel(e.value)} />
                                                {error ? ( <p className="text-red m-0"> {error.errors.country_code} </p>) : ""}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 pl-0">
                                            <div className="form-group mt-2">
                                                <label className="text-white">10 Digit Mobile Number  &#40; Optional &#41;</label>
                                                <input type="number" className={`form-control ${ error && error.errors.mobile ? "is-invalid" : ""}`} name="mobile" onChange={ e =>setMobile(e.target.value) }  />
                                        {/* <div className="col-md-8 p-0">
                                            <div className="form-group mt-3">
                                                <input type="number" className={`form-control ${ error && error.errors.mobile ? "is-invalid" : ""}`} name="mobile" onChange={ e =>setMobile(e.target.value) } placeholder="10 DIGIT NUMBER"  />

                                                {error ? ( <p className="text-red m-0"> {error.errors.mobile} </p>) : ""} */}
                                            </div>
                                        </div>
                                    </div>                                   
                                </div>   
                                <div className="col-10 d-none">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 col-12 pl-0">                                           
                                            <div className="form-group mt-2">
                                                <label className="text-white">Twitter Username &#40; Optional &#41;</label>
                                                <input type="text" className="form-control" name="twitter" onChange={ e =>setTwitter(e.target.value) }  />
                                            </div>
                                        </div> 
                                        <div className="col-md-6 col-12 pl-0">
                                            <div className="form-group mt-2">
                                            <label className="text-white">Discord Username &#40; Optional &#41;</label>
                                                <input type="text" className="form-control" name="discord" onChange={ e =>setDiscord(e.target.value) }  />
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="col-10 d-none">
                                    <div className="row align-items-center">
                                        <div className="col-12 pl-0"> 
                                            <div className="form-group mt-2">
                                                <label className="text-white">Referral Code &#40; Optional &#41;</label>
                                                <input type="text" className="form-control" name="referal_code" onChange={ e =>setReferalCode(e.target.value) }  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className="form-group mt-3 ml-3">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input " type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
                                            <label className="form-check-label pl-3" htmlFor="inlineRadio1">By signing up you agree to receive occasional emails, invitations to future events, offers and newsletters from ScarletBox.io</label>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 text-center">
                                    <button className="btn mt-3 mt-sm-4 text-center px-5" type="submit">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    LET'S DO THIS!</button>
                                </div>
                                {/* <div className="col-12">
                                    <p className="d-block text-center mt-4 text-white">Already have an account? 
                                        <a className="text-red px-1" href="" data-toggle="modal" data-target="#modal-login">Login</a>
                                    </p>
                                </div> */}
                           
                            </div>
                        </form>
                        
                        {/* <Modal id="modal-register" title="Register" content="Successfully registered"/> */}
                        <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Body>
                            
                                { data.code == 300 ? (
                                        <div className="bg-lwhite">
                                                <p className="text-center text-white">{data.msg}</p>
                                                    <br/>
                                            <button className="btn btn-primary btn-block" onClick={() => { setShow(false)}}>CLOSE</button>
                                        </div>
                                    ): (
                                        <div className="bg-lwhite">
                                                <h5 className="text-center text-white normal-font">ALMOST THERE!</h5>
                                                <p className="text-center  text-white">
                                                    Registration successful.
                                                    <br/>
                                                    Please log in using your email address and password to purchase your Larry Alcala digital collectible.
                                                    <br/>
                                                    1. Click Collections
                                                    <br />
                                                    2. Click Larry Alcala
                                                </p>
                                            <br/>
                                            <button className="btn btn-primary btn-block" onClick={() => { window.location.reload()}}>CLOSE</button>
                                        </div>
                                    ) 
                                }
                               
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Register;