import React, {useEffect, useState} from "react";
import apiClient from '../../helpers/api';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
//new
import ModalLogin from "../../components/Modal/ModalLogin";
import ModalRegister from "../../components/Modal/ModalRegister";
// import Banner from "../../components/Banner/Banner";
import BannerSale from "../../components/Banner/BannerSale";
import PrivateSaleSoon from "../../components/Sale/PrivateSaleSoon";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import PrivatePreSalesForm from "../../components/Sale/PrivatePreSalesForm";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from "../../components/Modal/ModalSearch";
import ModalMenu from "../../components/Modal/ModalMenu";
import Scrollup from "../../components/Scrollup/Scrollup";
import {Helmet} from "react-helmet";

const PrivateSale = (props) => {
    const [isLive, setIsLive] = useState(0);
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [next_scheedule, setNextSchedule] = useState('');
    const [changeClient, setChangeClient] = useState(false);
    const [isSeoEnable,setIsSeoEnable] = useState(false)
    const onChangeClient = (value) => {
        //console.log(value);
        setChangeClient(value)
    }

    useEffect(()=>{
        changeHeader()
    },[changeClient])

    useEffect(()=>{
        apiClient.get(`/sale/private-sales/${props.match.params.url}`)
            .then(res => {
                setIsSeoEnable(res.data.data.info.disable_seo);
                setIsLive(res.data.status)
                setText(res.data.message)
                setTitle(res.data.project_title)
                setSubtitle(res.data.project_title)
                setNextSchedule(res.data.next_schedule)
            }).catch( e => {
            });
    },[])


    const changeHeader = () =>{
        return <Header/>
    }

    if(redirect){
        return <Redirect to=""/>
    }

    return (
        <>
         {
            isSeoEnable == 1 ? 
                <Helmet>
                    <title>Scarletbox Private Collection - {title}</title>
                    <meta name="robots" content="noindex,nofollow" data-react-helmet="true" />
                </Helmet> 
            : 
            <Helmet>
                <title>{title}</title>
                <meta name="robots" content="all" data-react-helmet="true" />
            </Helmet>
        }
        <div className="main">
            <PageLoading />
            {/* <Header/> */}
            {changeHeader()}
            <BannerSale  title={title}  />
            {
                isLive && isLive != 200  ? <PrivateSaleSoon subtitle={subtitle} text={text}/> : <PrivatePreSalesForm onChangeClient={onChangeClient}  purl={props.match.params.url}/>    
            }
            <Footer />
            <ModalSearch/>
            <ModalMenu />
            <ModalRegister/>
            <ModalLogin/>
            <Scrollup />
        </div>
        </>
    )
}


// class PrivateSale extends Component {

//     state = {
//         isLive: 1, 
//         text:'',
//         title:'Private Sale',
//         subtitle:"",
//         redirect:false,
//         routeParam:''
//     }

//     componentDidMount(){
//             apiClient.get(`/sale/private-sales/${this.props.match.params.url}`)
//             .then(res => {
//                 this.setState({
//                     isLive: res.data.status,
//                     text: res.data.message,
//                     subtitle: res.data.title,
//                     bannersub : res.data.project_title
//                 })
//             }).catch( e => {
//                 this.setState({
//                     redirect:true
//                 });
//             });
//     }
   
//     render() {  

//         if(this.state.redirect){
//             return <Redirect to=""/>
//         }
        
//         return (
//             <div className="main">
//                 <PageLoading />
//                 {/* <Header/> */}
//                 <Header/>
//                 <BannerSale  title={this.state.title} sub={this.state.bannersub} />
//                 {
//                  this.state.isLive != 200  ? <PrivateSaleSoon  subtitle={this.state.subtitle} text={this.state.text}/> : <PrivatePreSalesForm purl={this.props.match.params.url} />    
//                 }
//                 <Footer />
//                 <ModalSearch/>
//                 <ModalMenu />
//                 <ModalLogin/>
//                 <Scrollup />
//             </div>
//         );
//     }
// }
 
export default PrivateSale;