import React, {Component} from "react";

class BannerSale extends Component {

    state = {
        title: '',
        sub: '',
    };

    componentDidMount(){ 
        this.setState({
            title: this.props,
            sub: this.props,
        })
 
    };

    render() { 
        return (
            <section className='breadcrumb-area d-flex align-items-center p-5 ' style={{height: "auto"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content text-center pt-5 ">
                                {
                                    this.props.sub ? <p className=" font-weight-normal text-yellow" style={{fontSize: "25px"}}>{this.props.sub}</p> : ''
                                }
                                <h2 className="m-0 section_title font-weight-normal normal-font" dangerouslySetInnerHTML={{ __html: this.props.title }}></h2>
                            </div>
                        </div>
                    </div>
                </div>
  
            </section>

        );
    }
}
 
export default BannerSale;