import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Images = (props) => {
    const playIcon = <FontAwesomeIcon  icon="fa-solid fa-play" size='lg' />;
    const playBtn = {
        position: "absolute",
        background: "rgb(0 0 0 / 30%)",
        left:"45%",
        top: '20%',
        border:"0px",
        color:"#fff"
    };

    return (
        <div className="col-12 col-lg-6">
                <div className="item-info">
                    <div className="item-thumb text-center">
                        <div className="image-over">
                            <img src={props.art && props.art.art_details._mint_file} className="card-img-top wp-post-image" alt=""  />					
                        </div>
                    </div>
                </div>
                <div className="row ">
                     {
                        props.art.other_images ?
                        props.art.other_images.map((images, i)=> {
                            return(
                            <div key={i} className="col-4 col-md-3 mt-2">
                                {
                                    images.extension != 'mp4' ?
                                    <img onClick={() => props.showOtherImagesBtn(images.extension,images.image_)} className="img-fluid" src={images.image_}/>
                                    :   <div className="image-wrapper" style={{background:"#000"}}>
                                         <a style={playBtn}>{playIcon}</a>
                                    <video onClick={() => props.showOtherImagesBtn(images.extension,images.image_)} preload="auto" muted= {true} autoPlay={false} controls= {false}  className="embed-responsive-item " 
                                    src={images.image_} style={{width: "100%",height:"70px"}} ></video>
                                </div>
                                }
                               
                            </div>
                            )
                        } )
                        : ''
                    } 
                </div>
                <div className="row">
                    
                </div>

        </div>
    )
}

export default Images