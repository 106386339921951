import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form} from "react-bootstrap";
import apiClient from '../../helpers/api';
import { useParams } from 'react-router';
import PageNotFound from '../PageNotFound/PageNotFoundPage'
import Web3 from 'web3';

const CollectionDetails = () => {

    let {id, art_id} = useParams()
    const [details, setDetails] = useState({})
    const AllowedChainId = process.env.REACT_APP_CHAINID;
    const [errorMessage,setErrorMessage] = useState(null);
    const [errorEthMessage,setErrorEthMessage] = useState(null);
    const [defaultAccount,setDefaultAccount] = useState(null);
    const [defaultChain,setDefaultChain] = useState(null);
    const [isPaying,setIsPaying] = useState(false);
    const [isDownloading,setIsDownloading] = useState(false);
    
    const [abi,setAbi] = useState(null);
    const [contractAddress,setContractAddress] = useState(null);
    const [artCode,setArtCode] = useState(null);
    const URL_DE = 'https://scarlet.mypinata.cloud/ipfs/QmXK6rKENsJaimduAwim2E8dWLYWqsmiAySLUbjDP1gzsV/93.mp4';
    var contract = null;

    async function downloadNFT(url) {

        setIsDownloading(true);
        const imageBlob = await fetch(url)
        .then((response) => response.arrayBuffer())
        .then((buffer) => new Blob([buffer]));
        const fileName = url.split('/').pop();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(imageBlob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsDownloading(false);
       
    }

    async function connectWallet () {
        
        window.ethereum.request({method:"eth_requestAccounts"}).
        then(result => {
            setDefaultAccount(result[0])
            window.ethereum.request({ method: 'eth_chainId' }).then(
                chain => {
                    if(chain === AllowedChainId){
                        setDefaultChain(chain)
                    } else {
                        setErrorMessage('Please use mainet only');
                    }
                }
            );
        })
    }
    
    try{

        window.ethereum.on('chainChanged', (chainId) => {
        if(chainId === AllowedChainId){
                setDefaultChain(chainId)
                setErrorMessage(null);
            } else {
                setErrorMessage('Please use mainet only');
            }
        });
        
        window.ethereum.on('accountsChanged', (accounts) => {
        setDefaultAccount(accounts[0])
        });
    } catch(e){ }

    useEffect(() => {

        if(!window.ethereum){
            setErrorMessage('Please install metamask to mint this NFT');
        } 

        apiClient.get(`/account/collections/details/${id}/${art_id}`)
        .then(res => {
           
            setDetails(res.data)
            if(res.data.project_mint == 2){
                setArtCode(res.data.art_code)
                setAbi(JSON.parse(res.data.abi))
                setContractAddress(res.data.contract_address)
            }
        })

    }, details)

    async function mint_(){
        setIsPaying(true);
        setErrorEthMessage('Please dont refresh or leave the site while the transaction is on progress')
        var web3        = new Web3(window.ethereum);
        contract        = new web3.eth.Contract(abi,contractAddress);
        var _mintAmount = 1;
        var mintRate    = 0;

        var totalAmount = mintRate;
        try{

        var trx = await contract.methods.mint(defaultAccount,_mintAmount,artCode).send({from:defaultAccount,value:String(totalAmount)})
            try{
                var res = await apiClient.post(`${process.env.REACT_APP_API}/account/collections/process`,{
                    id:id,
                    tr_hash:trx.transactionHash,
                    wallet_address:defaultAccount,
                    contract_address:contractAddress,
                    art_id:details.art_id
                })

                alert("Minted Successfully")
                window.location.reload()


            } catch(e){
                if(e.code === 4001){
                    setErrorEthMessage(e.message)
                } else {
                    setErrorEthMessage('Transaction failed')
                }
               
            }

            setIsPaying(false);
             
        } catch(e){
            if(e.code === 4001){
                setErrorEthMessage(e.message)
            } else {
                setErrorEthMessage('Transaction failed')
            }
            setIsPaying(false);
        }
    }

    return (
        <section className="item-details-area mt-1">
            <div className="container">
                <div className="row xbtk">
                    <div className='col-12 rounded-lg border border-dark p-2'>
                        <div className='my-3'>
                            <div className='card-body p-0'>
                                <div className='row justify-content-md-center'>
                                    <div className="col-lg-5 col-12 pt-3 pb-4">
                                    
                                    {
                                        details.sale_type_id != 4 && details.project_mint == 2 && details.tr_hash != null && details.art_uri != null ?
                                        <>
                                          {
                                            details.extension == 'mp4' ? 
                                            <video className="embed-responsive-item img-fluid" autoPlay="true" loop="true"  src={details.art_uri}></video>
                                                 : 
                                            <img src={details.art_uri} className="img-fluid mx-auto d-block" alt={details.project}/>
                                          }
                                          
                                        </>
                                        : (
                                            details.sale_type_id == 4 ? 
                                            <>
                                            {
                                                details.art_special_img_type == 'mp4' ? 
                                                <video className="embed-responsive-item img-fluid" autoPlay="true" loop="true"  src={details.art_special_img}></video>
                                                    : 
                                                <img src={details.art_special_img} className="img-fluid mx-auto d-block" alt={details.project}/>
                                            }
                                            
                                            </>
                                            
                                            : (
                                                details.art_uri != null ?
                                                <>
                                                {
                                                    details.extension == 'mp4' ? 
                                                    <video className="embed-responsive-item img-fluid" autoPlay="true" loop="true"  src={details.art_uri}></video>
                                                         : 
                                                    <img src={details.art_uri} className="img-fluid mx-auto d-block" alt={details.project}/>
                                                }
                                               
                                                </>
                                                : <img src={details.art_foto} className="img-fluid mx-auto d-block kenchee" alt={details.project}/>
                                            )

                                        )
                                    }
                                    
                                    </div>
                                    <div className="col-lg-7 col-12 pt-3 pb-4">
                                        <div className="content">
                                            <h4 className="mt-md-0 mt-2 mb-3 font-family-normal text-dark">{details.art_name} </h4>
                                            <h5 className="mt-md-0 mt-2 mb-3 font-family-normal text-dark">{details.project} </h5>
                                            <p className="mt-md-0 mt-2 mb-3  text-dark">Artist : <strong>{details.artist}</strong> </p>
                                            <p className="mt-md-0 mt-2 mb-3  text-dark">Type: {details.sales_type} </p>
                                            <p className="mt-md-0 mt-2 mb-3  text-dark">Minting Status: {details.mint_status} </p>
                                            <p className="mt-md-0 mt-2 mb-3  text-dark">Rarity: {details.rearity} </p>
                                           
                                            <> 
                                            
                                            {
                                            
                                                details.sale_type_id == 4 ? 
                                                <a href="#" className='text-danger' onClick={() => {
                                                    downloadNFT(details.art_uri)
                                                }} style={{cursor: "ponter"}} >{isDownloading && <span className="spinner-border spinner-border-sm mr-1"></span>} Download</a>
                                                : details.art_uri != '' ?
                                                <a href="#" className='text-danger' style={{cursor: "ponter"}} onClick={() => {
                                                    downloadNFT(details.art_uri)
                                                }}>{isDownloading && <span className="spinner-border spinner-border-sm mr-1"></span>} Download</a>
                                                : ''
                                            
                                            } <br></br>
                                           </>
                                            {/* <p className="mt-md-0 mt-2 mb-3  text-dark">Frame: {details.with_frame} </p> */}
                                            {/* <p className="mt-md-0 mt-2 mb-3  pri-color">Description: Founders NFT - the first and only time this shall be released, this will give access to Whitelists of all future drops of ScarletBox (refers to the Premium Collection of Imao x Imao and Joya x Baldovino, excluding Genesis Collection).</p> */}

                                            {/* <h5 className="mt-md-0 mt-2 mb-0  pri-color"><i className="fab fa-ethereum" /> 1.4005 ETH </h5> */}
                                            {/* <h5 className="mb-3 mt-0  pri-color">Php {details.price}  </h5> */}
                                            {
                                                details.is_minted == 1 ?
                                                details.sale_type_id == 4 ? 
                                                <>
                                                <a href={details.special_url} target="_blank"  className='text-danger'>View on Opensea</a><br></br>
                                                </>
                                                :
                                                <>
                                                {
                                                    details.ether_scan ? 
                                                    <> <a href={details.ether_scan} target="_blank"  className='text-danger'>View on Etherscan</a><br></br>  </>
                                                    : ''
                                                }
                                                
                                                <a href={details.opensea} target="_blank"  className='text-danger'>View on Opensea</a>
                                                </>
                                                : ''
                                            }

                                            {
                                                details.is_bundle != 1 ?
                                                details.project_mint == 2 && details.tr_hash == null && details.allowe_own_reveal == 1 ?
                                                <>
                                                {errorEthMessage ?  <p className='text-danger'> {errorEthMessage }</p> : '' }
                                                {
                                                    errorMessage  ? <div className="alert alert-danger">{errorMessage}</div> : 
                                                    <>
                                                     {
                                                        defaultAccount ? 
                                                        details.is_bundle == 0 ?
                                                        <Button onClick={mint_}  className='btn btn bg-dark'>{isPaying && <span className="spinner-border spinner-border-sm mr-1"></span>} Mint Now</Button>  
                                                        :
                                                        <Button onClick={mint_}  className='btn btn bg-dark'>{isPaying && <span className="spinner-border spinner-border-sm mr-1"></span>} Mint Now</Button>  
                                                        : 
                                                        <Button variant="primary" onClick={connectWallet} >Connect Wallet</Button>
                                                     }
                                                    </>
                                                }
                                                </>
                                                : ''
                                                : ''
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                           
                                <a className="btn btn-dark float-right" href='/account/collections'>Back</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='row'>
                    <div className='col-md-4'>
                        <div className='card'>
                            <div className='card-head'>
                                Details
                            </div>
                            <div className='card-body'>
                                <p className='my-0'><span className='font-weight-bold'>Contract Address:</span> 0x495f947276749ce646f68ac8c248420045cb7b5e</p>
                                <p className='my-0'><span className='font-weight-bold'>Token ID:</span> 67890372873873864686347</p>
                                <p className='my-0'><span className='font-weight-bold'>Token Standard:</span> ERC-1155</p>
                                <p className='my-0'><span className='font-weight-bold'>Blockchain:</span>: Etherium</p>
                                <p className='my-0'><span className='font-weight-bold'>Metadata:</span> Editable</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='card'>
                            <div className='card-head'>
                            Listing
                            </div>
                            <div className='card-body'>
                                <div className="card">
                                   No Listings yet 
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    );

}

export default CollectionDetails;