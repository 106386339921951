import React, { Component } from 'react';

import PageLoading from "../../components/FullPageLoading/PageLoading";

class PrivateSaleSoon extends Component {

    render() { 
        return (
            <div className='container'>            
                <div className='container'>
                    <div className='row'>
                        <section className="faq-area pt-5">
                            <div className="container">
                                <div className="row justify-content-md-center">
                                    <div className="col-md-8 ">
                                        <h3 className="text-center text-dark ">
                                                {this.props.subtitle}
                                            </h3>
                                        <div className="row justify-content-md-center">
                                        <div className="col-md-5 ">
                                        {
                                        !this.props.subtitle ? 
                                        <PageLoading />
                                        : <img src={window.photo_url+'private_.jpg'} className="img-fluid" />
                                        }
                                        </div>
                                        
                                        </div>
                                        <p className="text-center text-dark"  dangerouslySetInnerHTML={{ __html: this.props.text }}>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>  
                </div>
               
            </div>
        );
    }
}
 
export default PrivateSaleSoon;