import React, {Component, useEffect, useState} from "react";

import {Helmet} from "react-helmet";
import apiClient from "../helpers/api";
import PageShortcodes from "../services/PageShortcodes";
import { getShortCodeName, getShortCodeParam,setShortCodeParam } from "../helpers/shortcode/Shortcode";
import Auth from "../helpers/auth/Auth";
//Page components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Banner_Carousel from "../components/Banner/Carousel";
import FeaturedArtist from "../components/FeaturedArtist/FeaturedArtist";
import { Modal } from "react-bootstrap";
import ModalLogin from "../components/Modal/ModalLogin";
import ModalCampaign from "../components/Modal/ModalCampaign";
import PageLoading from "../components/FullPageLoading/PageLoading";
import shortcodes from "remark-shortcodes";
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import SneakPeek from "../components/Shortcodes/SneakPeek";
import Projects from "../components/Shortcodes/Projects";
import BannerHead from "../components/Banner/BannerHead";
import ModalRegister from "../components/Modal/ModalRegister";
import Newsletter from "../components/Newsletter/Newsletter";

var ip = require('ip');

// const HomePage = () =>{
//     const [data, setData] = useState([]);
//     const [content, setContent] = useState({});
//     const [page_content, setPageContent] = useState([]);

//     const handlePage = () => {
//         return new Promise(resolve => {
//             axios.get(`${process.env.REACT_APP_API}/page`)
//             .then(res => {
//                 setData(res.data.data)
//                 setContent(res.data.data.content.content)

//                 let regex = content.match(/\[.*?\]/g);

//             if (regex.length > 0) {
//                 regex.map( (val, key) => {
//                     let shortcodename = getShortCodeName(val)
//                     let shortcodeParam = getShortCodeParam(val)
//                     let shortcode

//                     if (Array.isArray(data[shortcodename])) {
                        
//                         let cont_id = shortcodeParam.map(res => {
//                             const id = res.split("=")
//                             return id[1]

//                         })
                        
//                         let code = data[shortcodename].filter((res) => {
//                             return res.id == cont_id[0]
//                         })
                    
//                         shortcode = setShortCodeParam(shortcodename, shortcodeParam, code)
                        

//                     }else{
//                        shortcode = setShortCodeParam(shortcodename, shortcodeParam, data[shortcodename])
//                     }


                    
//                     setPageContent(prevState => ({
//                         page_content: [...prevState.page_content, {
//                             id: key,
//                             shortcode: shortcode.shortcode,
//                             param: shortcode.param,
//                             content: shortcode.content
//                         }]
//                     }))

//                 })


//             }
//                 resolve()
//             }).catch( e => {

//                 resolve()
//             })
//         })
//     }


//     useEffect(() => {
//         handlePage()
        
        
        
//     }, [])

//     return (
//         <div>
//             <PageLoading />
//             <Header/>
//             <Banner_Carousel/>

//             {/* {
//                 page_content.map((comp, key) => {
//                     return PageShortcodes[comp.shortcode](comp.param, comp.content)
//                 })
//             } */}
//             <ModalLogin/>
//             <Footer/>
//             <ModalSearch />
//             <ModalMenu />
//             <Scrollup />
//         </div>
//     )


// }


class HomePage extends Component {

    state = {  
        data: [],
        content: [],
        page_content: [],
        content_data: [],
        isScroll: false,
        campagin:false,
        cam_code:'',
     } 

     

    componentDidMount(){
        var url = window.location.href
        var scroll = url.includes("/#")
        var query  = new URLSearchParams(this.props.location.search);

        if(query.get('partner')){
          
            apiClient.get(`${process.env.REACT_APP_API}/campaign/${query.get('partner')}`)
            .then(res => {
                if(res.data.status == 200){
                    
                    this.setState({
                         campagin: true,
                         cam_code: res.data.data.campaign_code
                    })
                }
               
            }).catch( e => {
                console.log(e);
            });
          
        }
      
        if(scroll){
            this.setState({
                isScroll: true
            })
        }

        apiClient.get(`${process.env.REACT_APP_API}/page`)
        .then(res => {
            this.setState({
                data: res.data.data,
                content: res.data.data.content.content,
                content_data: res.data.data.content
            })
            let regex = this.state.content.match(/\[.*?\]/g);

            if (regex.length > 0) {
                regex.map( (val, key) => {
                    let shortcodename = getShortCodeName(val)
                    let shortcodeParam = getShortCodeParam(val)
                    let shortcode

                    if (Array.isArray(this.state.data[shortcodename])) {
                        
                        let cont_id = shortcodeParam.map(res => {
                            const id = res.split("=")
                            return id[1]

                        })
                      
                        let code = this.state.data[shortcodename].filter((res) => {
                            return res.id == cont_id[0]
                        })
                        
                        // console.log(this.state.data);

                        shortcode = setShortCodeParam(shortcodename, shortcodeParam, code, this.state.data[shortcodename])
                        // console.log(shortcode);

                    }else{
                       shortcode = setShortCodeParam(shortcodename, shortcodeParam, this.state.data[shortcodename])
                    }

                    this.setState(prevState => ({
                        page_content: [...prevState.page_content, {
                            id: key,
                            shortcode: shortcode.shortcode,
                            param: shortcode.param,
                            content: shortcode.content,
                            additional_content: shortcode.additional_content
                        }]
                    }))

                })

            }



        }).catch( e => {
            console.log(e);
           
        }) 

    }

    scroll = () => {

        const section = document.querySelector( '#collection' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        
        
    }; 
   

    render() { 

       
      
        // if(this.state.isScroll){

        //     // window.location.reload();
        //     // window.onunload = function(){ window.scrollTo(0,0); }

        //     document.querySelector('#collection').parentElement.scrollIntoView({behavior: "smooth"});
       
        // }
        
        return (

            <div>

                <Helmet>
                    <title>{this.state.content_data && this.state.content_data.seo_title }</title>
                    <meta name="robots" content="all" data-react-helmet="true" />
                    <meta name="keywords" content={this.state.content_data && this.state.content_data.keywords}></meta>
                    <meta name="title" content={this.state.content_data && this.state.content_data.seo_title }></meta>
                    <meta name="description" content="Enable Artists to connect humanity across time. Increase the Value of the Artist's works as it becomes recognizable in more Hearts and Homes."></meta>
                    <meta property="og:type" content="website"></meta>
                    <meta property="og:url" content="https://scarletbox.io/"></meta>
                    <meta property="og:title" content="ScarletBox"></meta>
                    <meta property="og:description" content="Enable Artists to connect humanity across time. Increase the Value of the Artist's works as it becomes recognizable in more Hearts and Homes."></meta>
                    <meta property="og:image" content="https://scarletbox.io/img/scarlet_box.jpg"></meta>

                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content="https://scarletbox.io/"></meta>
                    <meta property="twitter:title" content="ScarletBox.io"></meta>
                    <meta property="twitter:description" content=""></meta>
                    <meta property="twitter:image" content="https://scarletbox.io/img/scarlet_box.jpg"></meta>
                {this.state.content_data.meta}
                </Helmet>
                {
                    !this.state.isScroll ? 

                    <PageLoading /> :

                    this.scroll
                }
               
                <Header/>
                <BannerHead />
                <Banner_Carousel/>
                
                <Newsletter/>
                {/* <FeaturedArtist/> */}
                {
                    this.state.page_content.map((comp, key) => {
                        {/* let shortcode = new PageShortcodes(comp.param) */}
                        {/* console.log(comp); */}
                        return PageShortcodes[comp.shortcode](comp.param, comp.content, comp.additional_content)
                    
                    })
                }
                {/* {console.log(this.state.page_content)} */}
                {/* <SneakPeek/> */}
                {/* <Projects /> */}
                <ModalLogin/>  
                <ModalRegister/>
                {
                this.state.campagin ?
                    <ModalCampaign code={this.state.cam_code}/> : ''
                }
                          
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
                <Footer />
            </div>
        );
    }
}
 
export default HomePage;