import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useForm } from "react-hook-form";
import apiClient from '../../../helpers/api';
import swal from 'sweetalert';
import axios from 'axios';

const OfferForm = () => {

    let {id} = useParams();
    const [offer_price, setOfferPrice] = useState(0);
    const [date_end, setDateEnd] = useState('');
    const [client_id, setClient] = useState('');
    const [errors, setErrors]    = useState() 
    const [usd_price, setUSD]    = useState(0);

    const {handleSubmit, formState} = useForm();
    const { isSubmitting } = formState;

    //handle exchange to usd
    const handleCurrency = async (offer_amount) => {
            
            await axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum")
            .then(res => {
                let exchangeRate = parseInt(res.data[0].current_price);
                let amount = parseFloat(offer_amount * exchangeRate);
                setOfferPrice(offer_amount);
                setUSD(amount)
            });
        

    }

    const onSubmit = (data) => {
        return new Promise(resolve => {

            apiClient.post(`/sale/auctions/offer`, { 
                offer_price: offer_price,
                date_end: date_end,
                art_id: id,
                client_id: client_id,
            }).then(response => {
                console.log(response);
                if (response.data.code == 200) {
                    swal({
                    title: "Success",
                    text: response.data.msg,
                    icon: "success",
                  }).then((value) => {
                    window.location.reload()
                  });
                }else{
                    swal({
                        title: "Error",
                        text: response.data.msg,
                        icon: "error",
                      })
                }
                
                resolve();
            }).catch(function (error) {
                console.log(error.response.data);
                setErrors(error.response.data)
                resolve();
            
            })
    
            });

    }

    // useEffect(() => {
        
    //     handleCurrency()

    // }, offer_price)

    useEffect( () => {
        apiClient.get('/account/user')
        .then(res => {
            if (res.status == 200) {
                console.log(res.data);
                setClient(res.data.id);
            }
        })
    }, [])

   

    return (
        <div id="modal-make-offer" className="modal fade p-0">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content bg-white text-dark">
                        <div className="modal-header pb-0" data-dismiss="modal">
                           Make Offer <i className="far fa-times-circle icon-close" />
                        </div>
                        <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)} className="item-form no-hover px-0">
                            {
                                errors && errors.code == 300 ? (
                                    <div className="alert alert-danger" role="alert">
                                        {errors.msg}
                                    </div>
                                ) : ''
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                        <div className="input-group-text"> ETH</div>
                                        </div>
                                        <input type="text" onChange={e => handleCurrency(e.target.value)} className={`form-control ${ errors && errors.errors.offer_price ? "is-invalid" : ""}`} id="inlineFormInputGroupUsername2" placeholder="Amount"/>
                                        <div className="input-group-append">
                                        <div className="input-group-text">$ {usd_price}</div>
                                        </div>
                                        {errors ? ( <p className="text-red m-0"> {errors.errors.offer_price} </p>) : ""}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                        <div className="input-group-text">USD</div>
                                        </div>
                                        <input type="text" onChange={e => handleCurrency(e.target.value)} className={`form-control ${ errors && errors.errors.offer_price ? "is-invalid" : ""}`} id="inlineFormInputGroupUsername2" placeholder="Amount"/>
                                        <div className="input-group-append">
                                        <div className="input-group-text">$ {usd_price}</div>
                                        </div>
                                        {errors ? ( <p className="text-red m-0"> {errors.errors.offer_price} </p>) : ""}
                                    </div>
                                </div>
                                <div className="col-md-12 m-0">
                                    <div className="form-group row mt-3">
                                        <label for="exampleInputEmail1">Expiry</label>
                                        <input type="datetime-local" onChange={e => setDateEnd(e.target.value)} className={`form-control ${ errors && errors.errors.date_end ? "is-invalid" : ""}`} />
                                        {errors ? ( <p className="text-red m-0"> {errors.errors.date_end} </p>) : ""}
                                    </div>
                                </div>

                                    <div className="col-12 text-right">
                                        <button className="btn mt-3 mr-2 mt-sm-4" type="submit">SUBMIT</button>
                                        <button className="btn mt-3 mt-sm-4" data-dismiss="modal">CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    )

}

export default OfferForm;