import React, {useEffect, useState} from "react";
import { useParams } from 'react-router';

import apiClient from "../../helpers/api";
import Alert from 'react-bootstrap/Alert';
import { useForm } from "react-hook-form";
import swal from 'sweetalert';

const ResetPassword = () => {

    let {forget_token}    = useParams()
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;

    const [data, setData]                        = useState({})
    const [errors, setErrors]                    = useState()
    const [new_password, setNewPassword]         = useState("");
    const [confirm_password, setConfirmPassword] = useState("");

    const onSubmit = () => {
        
        return new Promise(resolve => {

            apiClient.post(`${process.env.REACT_APP_API}/auth/reset_password/${forget_token}`,{
                new_password: new_password,
                confirm_password: confirm_password
            }).then(res => {
              
                setData(res.data)
                swal({
                    title: "Success",
                    text: res.data.msg,
                    icon: "success",
                  }).then((value) => {
                    document.location.href="/";
                  });
                resolve();
            }).catch(function (error) {
               
                setErrors(error.response.data)
                resolve();
            })

        });
    }

    //verify if expired link
    useEffect(() => {
        apiClient.get(`${process.env.REACT_APP_API}/auth/forgot_password/verify/${forget_token}`)
        .then(res => {
           
            setData(res.data)
        }).catch(error => {
            
            setData(error.response.data)
        })
    }, [])

    if (data && data.code == 300) {
        return (
            <section className="item-details-area mt-1">
                <div className="container">
                    <div className="row xbtk">
                        <div className='col-7 mx-auto'>
                            <div className='red-border bg-dark my-3'>
                                <div className='card-body py-5 px-4'>
                                    <h2 className="text-center my-0 text-white">ScarletBox.io</h2>
                                    <hr className="w-50 mx-auto my-2"></hr>
                                    <p className="text-center my-0 text-white">Reset Password</p>
                                    <Alert variant="danger">
                                        <p>{data.msg}</p>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }


    return (
        <section className="item-details-area mt-1">
            <div className="container">
                <div className="row xbtk">
                    <div className='col-md-7 mx-auto'>
                        <div className='red-border bg-dark my-3'>
                            <div className='card-body py-5 px-4'>
                                <h2 className="text-center my-0 text-white">ScarletBox.io</h2>
                                <hr className="w-50 mx-auto my-2"></hr>
                                <p className="text-center my-0 text-white">Reset Password</p>
                                <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                                    <div className="form-group">
                                        <input type="password" onChange={e => {setNewPassword(e.target.value)}} className={`form-control ${ errors && errors.errors.new_password ? "is-invalid" : ""}`} placeholder="New Password"/>
                                        {errors ? ( <p className="text-red m-0"> {errors.errors.new_password} </p>) : ""}
                                        <br/>
                                        <input type="password" onChange={e => {setConfirmPassword(e.target.value)}} className={`form-control ${ errors && errors.errors.confirm_password ? "is-invalid" : ""}`} placeholder="Confirm New Password"/>
                                        {errors ? ( <p className="text-red m-0"> {errors.errors.confirm_password} </p>) : ""}

                                        <button disabled={isSubmitting}  className="btn btn-danger mt-3 btn-lg btn-block rounded-0" type="submit">
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}


export default ResetPassword;