import React, {Component, useState, useEffect} from "react";
import apiClient from '../../helpers/api';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import PageLoading from "../FullPageLoading/PageLoading";
import { HashLink, HashLink as Link} from 'react-router-hash-link';
import { Route, Switch, useLocation } from 'react-router-dom';
import PageNotFound from "../PageNotFound/PageNotFoundPage";


const PageRoadMap = (props) => {

    const [data, setData] = useState([])
    const [isError, setIsError] = useState(false)


    useEffect(() => {
        apiClient.get(`${process.env.REACT_APP_API}/page`)
        .then(res => {
            // console.log(res.data.data.projects[0]);
            setData(res.data.data.projects[0])
            setIsError(false)

            
        }).catch( err => {

            console.log(err);
            setIsError(true)
        })


    }, [])

    if (isError) {
        return <PageNotFound />
    }


    if(data.length > 1){

        return(

            <>
            <section className="artist" >
                <div className="container-fluid py-5">
               
                    <div className="row d-flex justify-content-center xbtk">
                        <div className="container-fluid">
                            <div className="row d-flex justify-content-center">

                                {
                                    data.map((info, idx) => {
                   
                                        return (

                                            <div  key={`${idx}`} className={ data.length == 3 ? "col-xl-4 pt-3 py-md-2" : " col-xl-6 px-md-1 p-2 my-3"}  >
                                            <Link smooth to={`/collection-projects/view/${info.url}#roadmap`}>  

                                            
                                                <div className="p-md-1">
                                                    <center className="">
                                                        <img
                                                        className="img-fluid"
                                                        src={window.photo_url+info.photo}
                                                        alt=""
                                                        
                                                    />
                                                    </center>
                                                    <div className="text-center w-md-75 mx-auto">
                                                        <h4 className="text-uppercase mt-2 text-dark normal-font font-weight-normal mb-md-4 mb-0">{info.project_name}</h4>
                                                        <div className="text-center text-justify text-white" dangerouslySetInnerHTML={{__html: info.description}}></div>
                                                    </div>

                                                   
                                                </div>
                                            </Link>
                                            </div>

                                        );

                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>

        );

    }
  
    return (

        <>
        <div className="artist" >
         <div className="container py-5">
            
            <div className="row xbtk">
                
                <div className="col-12">

                {
                    data.map((info, idx) => {

                        return (
                            
                            <div key={`${idx}`}>

                                    <div className="d-flex align-items-center justify-content-center">
                                        <Link smooth to={`/collection-projects/view/${info.url}#roadmap`}>  
                                            <div className="p-2">
                                                <center>
                                                    <img
                                                    className="img-fluid  px-1"
                                                    src={window.photo_url+info.photo}
                                                    alt=""
                                                    />
                                                </center>
                                                <div className="text-center project-image-text ">
                                                    <h3 className="text-uppercase text-white text-center normal-font font-weight-normal">{info.project_name}</h3>
                                                    <div className="text-center text-justify text-white py-0" dangerouslySetInnerHTML={{__html: info.description}}></div>
                                                    
                                                </div>
                                            </div>
                                        </Link>
                                    </div>  


                               
                            </div>
                        );
                           
                    })
                
                }
                            
                </div>
             </div>
         </div>
     </div>


        </>
    );

}
 
export default PageRoadMap;

