import React, {Component} from "react";

class SneakPeek extends Component {

    render() { 
        return (
            <>
           { this.props.content.length == null ?
           " "
           :
           
                this.props.content.map((res, idx) => {
                    return (
                        <section key={`${idx}`} className="live-auctions-area sneak-peek bg-gray pt-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 ">
                                        <h1 className="mb-5 text-center section_title text-danger">
                                            Sneak Peek
                                        </h1>
                                    </div>  
                                </div>
                                <div className="auctions-slides">
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-7">
                                            <div className="row">
                                                {
                                                    res.arts.map(sneak => {
                                                        return (
                                                            <div key={`${idx}`} className="col-md-6 mb-2">
                                                                <div className="card bg-white">
                                                                    <div className="image-over">
                                                                    <div className="bkt embed-responsive embed-responsive-16by9 ">
                                                                        <video className="embed-responsive-item" autoPlay muted loop src={window.sneak_url+sneak.url} />
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="card-caption col-12 p-0">
                                                                    <div className="card-body pt-2">
                                                                        <h5 className="mb-0" dangerouslySetInnerHTML={{__html: sneak.title}}></h5>
                                                                        
                                                                    </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            
                                                        );
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                <div className="col-12">
                                    <div className="normal-font text-black sneak-peek-content" dangerouslySetInnerHTML={{__html: res.content}}></div>
                                </div>
                            </div>
                            </div>
                        </section>
                    )
                })
            
           }
           
            </>
            
            
        );
    }
}
 
export default SneakPeek;