import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import apiClient from "../../helpers/api";
import swal from 'sweetalert';

const ModalCampaign = (props) => {

    const [errormsg,setErrormsg] = useState()
    const {register, handleSubmit, formState:{errors},formState,reset} = useForm({ mode: 'onBlur' })
   
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isProcessing,setIsProcessing] = useState(false);

    const [email, setEmail] = useState('');
    const [bg, setBg] = useState('');
    const [content, setContent] = useState('');
    const [logo, setLogo] = useState('');
    const [camapignId, setCamapignId] = useState('');
    const [projectId, setProjectId] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const closeNow = () => {
        window.location = '/';
    }
    async function reserveNow(){
        setIsProcessing(true)
        setErrormsg('')
        try{
            if(email){
            var x = await apiClient.post(`${process.env.REACT_APP_API}/campaign-process`, {
                email: email,
                project_id:projectId,
                camapign_id:camapignId
            })

            setSuccessMsg('Your email has been whitelisted!')
            setIsProcessing(false);
            } else {
                setErrormsg('Your email is required')
                setIsProcessing(false);
            }
        }catch(e){
            setIsProcessing(false);
            console.log(e)
        }
        

    }

    const style = {
        backgroundImage: `url(${process.env.REACT_APP_PHOTO_URL+bg})`,
        backgroundSize:'cover',
        backgroundPosition:'left'
      };
    
    const {isSubmitting} = formState;

    useEffect(() => {
    
        apiClient.get(`${process.env.REACT_APP_API}/campaign/${props.code}`)
        .then(res => {
            setBg(res.data.data.campaign_image)
            setLogo(res.data.data.partner_logo)
            setProjectId(res.data.data.project_id)
            setContent(res.data.data.content)
            setCamapignId(res.data.data.id)
        }).catch( e => {
            console.log(e);
        });
    },[])

   
    
    

    return (
        <>

            <Modal 
                    show={show} 
                    onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='campaign-modal'
                    backdropClassName="Campaing-modal-backdrop"
                    size="xl"
                    backdrop="false"
                    
                   >
                    {/* <Modal.Header >
                    <Modal.Title className='text-white' >{props.cam_name}
                    
                    </Modal.Title>
                    <Button type="button" onClick={handleClose} variant="primary" className="text-white p-2" aria-label="Close">
                        <b>X</b>
                    </Button>
                </Modal.Header> */}
                
                <Modal.Body style={style}>
                    
                    <div className='row justify-content-center mb-5 mt-5'>
                        <div className='col-md-8'>
                            <div className='row justify-content-center'>
                                <div className='col-md-6'>
                                    <img src={process.env.REACT_APP_PHOTO_URL+logo} className='img-fluid'></img>
                                </div>
                            </div>
                            <>
                            <div className="text-white text-center mb-4" dangerouslySetInnerHTML={{__html: content}}></div>
                            </>
                            {successMsg ?  
                            <>
                            <div className='row justify-content-center'>
                                <div className='col-md-8'>
                                    
                                    <center>
                                        <p className='text-white'>{successMsg}</p>
                                    <button className='btn btn-primary' onClick={closeNow}>Close</button>
                                    </center>
                                </div>
                           </div>
                            </>
                            :
                            <>
                            
                            <div className='row justify-content-center'>
                                <div className='col-md-8'>
                                    <div className="form-group text-center">
                                        <label className='text-white'>Email Address</label>
                                        <input type="text" className="form-control rounded-0" name="email" onChange={e => setEmail(e.target.value)}  />
                                    </div>
                                    <center>
                                        <p className='text-warning'>{errormsg ? errormsg : ''}</p>
                                    <button className='btn btn-primary' onClick={reserveNow}>{isProcessing && <span className="spinner-border spinner-border-sm mr-1"></span>} Reserve a slot for me!</button>
                                    </center>
                                </div>
                           </div>
                           </>
                            }
                        </div>
                        
                    
                    </div>
                </Modal.Body>
                
            </Modal>
        </>
    )

}

export default ModalCampaign;