import { useEffect, useState } from "react";
import { useParams } from "react-router";


import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalLogin from "../../components/Modal/ModalLogin";
import Scrollup from "../../components/Scrollup/Scrollup";
import ModalMenu from '../../components/Modal/ModalMenu';
import ModalSearch from '../../components/Modal/ModalSearch';
import { Link, Redirect } from "react-router-dom";
import apiClient from '../../helpers/api';

const Verify = () => {

    let { code } = useParams();

    const [isVerified, setIsVerified] = useState(true);

    useEffect(() => {
       
        apiClient.get(`${process.env.REACT_APP_API}/auth/verify/${code}`)
        .then(res => {
            if (res.code == 200) {
                setIsVerified(true)
            }else{
                setIsVerified(false)
            }
        }).catch(e => {
            console.log(e);
        })
        
    }, [code]);

    return (
        <div className="main">
            <Header />
            <section className="author-area mt-5" style={{backgroundColor: "#000"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="card bg-dark red-border">
                                <div className="card-body">
                                    <div className="col-md-6 col-12 mx-auto">
                                        <h3 className="text-white normal-font text-uppercase">Great!</h3>
                                        <img style={{width: 250}} src="/img/scarlet_box.jpg"/>
                                        <p className="text-white">Your email has been verified successfully!</p>
                                        <a href="/" className="btn btn-primary pl-5 pr-5 text-white">Back to Home</a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <ModalSearch />
            <ModalMenu />
            <ModalLogin/>
            <Scrollup />
        </div>
    );

}

export default Verify;