import React, {Component} from "react";

class Sections extends Component {
    render() { 
    // console.log(this.props.params);

        return (
            <>
            {
                this.props.params.map((res, id) => {
                    return (
                        <section key={id} className={`concept ${res.bg_class}`}  style={{backgroundImage: `url(${window.bgsectors_url + res.bg_image}) `}} >
                            <div className="container" id="about-scarlet">
                                <div className="row d-flex justify-content-center">
                                    {
                                        res.header ? (
                                            <div className="col-12 ">
                                                <h1 className="mb-5 text-center text-white">
                                                    {res.header}
                                                </h1>
                                            </div>
                                        ) : ("")
                                    }
                                    {
                                        res.contents.map( data => {
                                            return (
                                                <div className={`col-12  ${res.class}`}>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            {/* <center>
                                                                <img src={window.photo_url+data.photo}/>
                                                            </center> */}
                                                            <h3 className="text-uppercase text-dark text-center normal-font font-weight-normal">
                                                            {data.header}
                                                            </h3>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <div className="pt-3 px-4 text-dark" dangerouslySetInnerHTML={{__html: data.content}}></div>
                                                            {
                                                                data.label ? (
                                                                    <div className="info-button pb-3">
                                                                        <a href={data.target} className=" btn bg-white text-uppercase text-danger btn-block">{data.label}</a>
                                                                    </div>
                                                                ): ("")
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                
                                    
                                </div>
                            </div>
                        </section>
                    )
                })
            }
            </>
            
            
        );
    }
}
 
export default Sections;