import React, {useState, useEffect} from 'react';
import apiClient from '../../helpers/api';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//new
import ModalLogin from "../../components/Modal/ModalLogin";
// import Banner from "../../components/Banner/Banner";
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from "../../components/Modal/ModalSearch";
import ModalMenu from "../../components/Modal/ModalMenu";
import Scrollup from "../../components/Scrollup/Scrollup";
import swal from 'sweetalert';
import CheckoutLoading from '../../components/FullPageLoading/CheckoutLoading';


const Paymongo = (props) => {

    const {register, handleSubmit, formState:{errors},formState,reset, getValues,unregister, setValue} = useForm({ mode: 'onBlur' })

    const [isRedirect,setIsRedirect] = useState(false);
    const [selfRedirect,setSelfRedirect] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    const [isError,setIsError] = useState(false);
    const [msg,setMsg] = useState();
    const [fire_,setFire_] = useState(false);
    const [comp_l,setComp_l] = useState(false);
    const loadCounter = localStorage.getItem("load_counter");

    const {isSubmitting} = formState;
 
    const [country, setCountry] = useState()
    const [users, setUsers] = useState()
    const [region, setRegion] = useState()
    const [city, setCity] = useState()
    const [shipping_fee, setShipping_fee] = useState(0)
    const [eth_shipping_fee, setEth_shipping_fee] = useState(0)
    const [total, setTotal] = useState(props.price)
    const [withFree, setWithFree] = useState(false)
    const [withAdon, setWithAdon] = useState(false)
    const [addOn, setAddOn] = useState('')
    const [price, setPrice] = useState()
    const [tr_no, setTrno] = useState()
    const [withDefaultSF, setWithDefaultSF] = useState(0);
    
    const onChangeCountry = (e) => { 
        setRegion('')
        setCity('')
        const idx = e.target.selectedIndex;
        const option = e.target.querySelectorAll('option')[idx];
        const eth = option.getAttribute('ethshipping');
        const ph_sf = option.getAttribute('phshipping');
        setEth_shipping_fee(eth);


        console.log(withDefaultSF);
        apiClient.get(`${process.env.REACT_APP_API}/geo-location/state/${e.target.value}`)
        .then(res => {
            //let price_sf = 0
            let price_sf = ph_sf

            if (addOn == ''){
                setShipping_fee(price_sf)
                setTotal(parseInt(price) + parseInt(price_sf))

                reset({
                    total: parseInt(price) + parseInt(price_sf),
                    sf: price_sf,
                });
                
                setRegion(res.data.data)
            
            
            }else{
                // console.log('default2');
                setRegion(res.data.data)

                if (withDefaultSF > 0 ) {
                    setShipping_fee(withDefaultSF)
                    reset({
                        sf: withDefaultSF,
                    });
                }
            }

            
        })

     };

     const onChangeRagion = (e) => { 
        setCity('')
        apiClient.get(`${process.env.REACT_APP_API}/geo-location/city/${e.target.value}`)
        .then(res => {
            setCity(res.data.data)
        })

     };

    useEffect(() => {
        if(!fire_){
            setTimeout( () => {
            setFire_(true)
        
            apiClient.get(`${process.env.REACT_APP_API}/paymongo-meta/success/${props.match.params.code}`).then(res => {
                console.log(res);
                
                if(res.data.sta){
                    setIsError(false)
                    setIsLoading(false)
                    setMsg(res.data.message)
                    setComp_l(true)
                    return false;
                }
                setPrice(res.data.order.price)
                
                //success random art
                if (res.data.is_bundle == 2) {
                    apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/success/`, {
                        tr_no: res.data.tr_no,
                        tr_no_log: props.match.params.code
                    }).then(res => {
                        if (res.data.code == 200) {
                            setSelfRedirect(false)
                            setIsError(false)
                            setIsLoading(false)
                            localStorage.removeItem("load_counter")
                            localStorage.removeItem("artx_bundle_tr_no_");
                            localStorage.removeItem("artx_bundle_");
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }

                if (res.data.free_type || res.data.order.with_frame == true || res.data.order.free_type >=1 || res.data.order.chaddontitle != '' ) {

                    
                    apiClient.get(`${process.env.REACT_APP_API}/geo-location/country`)
                    .then(res => {
                        setCountry(res.data.data)
                    })

                    apiClient.get(`${process.env.REACT_APP_API}/account/user`)
                    .then( async res => {
                        // console.log(res);
                        setUsers(res.data)
                        
                        if (res.data.client_address) {
                            const prov = await apiClient.get(`${process.env.REACT_APP_API}/geo-location/state/${res.data.client_address.country_id}`)
                            const city = await apiClient.get(`${process.env.REACT_APP_API}/geo-location/city/${res.data.client_address.province_id}`)
                                
                            await setRegion(prov.data.data)
                            await setCity(city.data.data)
                            
                            if (res.data.client_address.shipping_fee > 0) {
                                setShipping_fee(res.data.client_address.shipping_fee)
                                // setTotal(parseInt(price) + parseInt(res.data.client_address.shipping_fee))
                            }

                            await reset({
                                'country_id': res.data.client_address.country_id,
                                'state_id': res.data.client_address.province_id,
                                'city_id': res.data.client_address.city_id,
                                'shipping_address': res.data.client_address.address,
                                'post_code': res.data.client_address.postal,
                                'sf' : res.data.client_address.shipping_fee,
                                // 'total': parseInt(price) + parseInt(res.data.client_address.shipping_fee),
                            })

                            
                        }

                        reset();
                    })
                    setWithFree(true)
                    setTrno(res.data.order.tr_no)
                    //setPrice(res.data.order.price)
                    setTotal(parseInt(res.data.order.total))
                }else{
                    setWithFree(false)
                }

                console.log(typeof(res.data.order.sf));

                if (parseInt(res.data.order.sf) > 0) {
                    setWithDefaultSF(parseInt(res.data.order.sf))
                }

                if (res.data.order.free_type == 8 && !res.data.order.chaddontitle || !res.data.order.with_frame || res.data.order.sf == 0 ||res.data.order.with_frame == false) {
                    setWithFree(false)
                }

                if(res.data.free_type){
                    setWithFree(true)
                }

                if(res.data.order.chaddontitle != ''){
                    setWithAdon(true)
                    setAddOn(res.data.order.chaddontitle )
                    reset({
                        'sf' : res.data.order.chaddonShippingFee                        ,
                        // 'total': parseInt(price) + parseInt(res.data.client_address.shipping_fee),
                    })

                } else {
                    setWithAdon(false)
                    setAddOn('')
                }
                
                setSelfRedirect(false)
                setIsError(false)
                setIsLoading(false)
                setMsg(res.data.message)
                localStorage.removeItem("load_counter")
                if(res.data.sta){
                    setComp_l(true)
                }
            }).catch(error => {
                setIsError(true)
                console.log(error);
                if(error.response.data.message == 'Payment'){
                    setSelfRedirect(true)
                } else {
                    setIsLoading(false)
                    localStorage.removeItem("load_counter")
                    apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/cancel`, {
                        tr_no: localStorage.getItem("artx_bundle_tr_no_"),
                        bundle_no: localStorage.getItem("artx_bundle_"),
                    }).then(res => {
                        console.log(res);
                        localStorage.removeItem("artx_bundle_tr_no_");
                        localStorage.removeItem("artx_bundle_");
                    })
                    // apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/cancel`, {tr_no: props.match.params.code}).then(res => {
                    //     console.log(res);
                    // }).catch(e => {
                    //     console.log(e);
                    // })
                    swal({
                        title: "Error",
                        text: error.response.data.message,
                        icon: "warning",
                        closeOnClickOutside: false
                    }).then((value) => {
                        setIsRedirect(true)
                    });
                }
                setComp_l(true)
            });
        },  1000)
        }
        
    });

    const onSubmitShipping = (data) => {
        apiClient.post(`${process.env.REACT_APP_API}/checkout/paymongo/shipping_address`, data).then(res => {
            swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                closeOnClickOutside: false
            }).then((value) => {
                window.location.href = "/account/collections"
            });

        }).catch(error =>{
            swal({
                title: "Error",
                text: error.response.data.message,
                icon: "warning",
                closeOnClickOutside: false
            }).then((value) => {
                setIsRedirect(true)
            });
        })
    }


    if(selfRedirect){
       
        setTimeout( () => {
            localStorage.setItem('load_counter', 1)
            window.location.reload()
        }, 60000)
    }
    
    if(isRedirect){
        window.location.href = "/"
    }

    return (
        <div className="main"> 
            {/* <PageLoading/> */}
             {isLoading ? <CheckoutLoading title="Processing your payment please dont close window" /> : <>
            
            <Header/>
            <BannerSale  title=""/>
            <section className="item-details-area pt-5">
                <div className="container">
                    {/* with shipping */}
                    {
                        withFree ? (
                            <div className="row justify-content-md-center rounded-lg border border-dark pt-5 pb-5">
                                <div className='col-12 px-5'>
                                {!comp_l ?
                                <>
                                    <h3 className="text-dark text-center">Thank you! Purchase is complete.</h3>
                                    <p className='text-dark'>
                                    Congratulations! You are now the proud owner of a historic moment in Philippine Arts and Culture.<br></br>
                                    {msg}<br/>                          
                                    </p>

                                    <p>Only You will own this collectible NFT that is preserved on the blockchain forever.</p>
                                    <p className='my-0'>To view your Digital Collectible in your Scarletbox account:</p>
                                    <p className='my-0'>1. Click "My Account"</p>
                                    <p className='my-0'>2. Click "My Collections"</p>
                                    
                                    <p>**Voila! You will see it and you may download a hi-res image for your personal use.</p>
                                </>
                                : <p className='text-dark'>Transaction already completed please go to your collections</p>
                                }
                                
                                </div>

                                {/* shipping details */}
                                {
                                    withFree ? (
                                        <div className="col-12 px-5 text-dark">
                                            <br/>
                                            <p className='mb-0 text-danger' style={{fontSize: "26px"}}>Wait! <span style={{fontSize: "26px"}}>&#9995;</span></p>
                                            <p className='mt-2'>
                                            {
                                                withAdon ? 
                                                <>To receive your <span className='font-weight-bold'>{addOn ? addOn : "FREE merchandise"}</span>, we need an address to send to. <br/>Please fill out below. </>
                                                :
                                                <>To receive your FREE merchandise, we need an address to send to. Please fill out below. </>
                                            }
                     
                                            <br></br>
                                            <p className='mb-0'>(For addresses outside Philippines, we will contact you for a possible shipping fee.)</p>
                                            <br></br>
                                            {
                                                !withAdon ? 
                                                <span className='text-danger'>Note: If you do not give an address, we will not be able to send your gift.</span>
                                            :   ''
                                            }
                                            <br></br>
                                            Questions? Reach out to <a target="_blank" href="mailto:concierge@scarletbox.io"> concierge@scarletbox.io</a>
                                            </p>
                                            <hr style={{backgroundColor: "white", margin: "auto !important", height: "1px", border: "none" }}/> 

                                            <div className='alert chkout'>
                                                <form onSubmit={handleSubmit(onSubmitShipping)}  className="item-form no-hover text-dark">
                                                    <input type="hidden" {...register("tr_no")} value={tr_no} />
                                                    <input type="hidden" {...register("total")} value={total} />
                                                    <div className="row">
                                                        <div className="col-md-12 pb-2">
                                                            <strong >Shipping Details</strong>
                                                        </div>
                                                        
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>First Name</label>
                                                                <input type="text" className={`form-control ${ errors.shipping_first_name ? "is-invalid" : ""}`} {...register("shipping_first_name", { required: true})} defaultValue={users ? users.name : ''} />
                                                                {errors.shipping_first_name && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Last Name</label>
                                                                <input type="text" className={`form-control ${ errors.shipping_last_name ? "is-invalid" : ""}`} {...register("shipping_last_name", { required: true})} defaultValue={users ? users.last_name : ''}/>
                                                                {errors.shipping_last_name && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Email</label>
                                                                <input type="email" className={`form-control ${ errors.email ? "is-invalid" : ""}`} {...register("email", { required: true})} defaultValue={users ? users.email : ''}/>
                                                                {errors.email && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Country</label>
                                                                <select className={`country form-control ${ errors.country_id ? "is-invalid" : ""}`}  {...register("country_id", { required: true})} onChange={onChangeCountry}>
                                                                    <option value=""></option>
                                                                    {
                                                                    country ? country.map((val, id) => {
                                                                        return (
                                                                            <option ethshipping={val.eth_shipping} phshipping={val.ph_shipping} key={id} value={val.id}>{val.name}</option>
                                                                        )
                                                                    }) : ""
                                                                    }
                                                                </select>
                                                                {errors.country_id && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Provice/Region</label>
                                                                <select className={`form-control selectCountry ${ errors.state_id ? "is-invalid" : ""}`} id="province"  {...register("state_id", { required: true})} onChange={onChangeRagion}>
                                                                    <option value=""></option>
                                                                    {
                                                                    region ? region.map((val, id) => {
                                                                        return (
                                                                            <option key={id} value={val.id}>{val.name}</option>
                                                                        )
                                                                    }) : ""
                                                                    }
                                                                </select>
                                                                {errors.state_id && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>City</label>
                                                                <select className={`form-control  ${ errors.city_id ? "is-invalid" : ""}`}   id="city" {...register("city_id")}>
                                                                    <option value=""></option>
                                                                    {
                                                                    city ? city.map((val, id) => {
                                                                        return (
                                                                            <option key={id} value={val.id}>{val.name}</option>
                                                                        )
                                                                    }) : ""
                                                                    }
                                                                </select>
                                                                
                                                                {errors.city_id && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                        
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Address</label>
                                                                <small style={{fontSize: "11px"}}>If the city is not avaible please include the city on your address</small>
                                                                <input type="text" className={`form-control  ${ errors.shipping_address ? "is-invalid" : ""}`} {...register("shipping_address", { required: true})}  />
                                                                {errors.shipping_address && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Postal Code</label>
                                                                <input type="text" className={`form-control  ${ errors.post_code ? "is-invalid" : ""}`} {...register("post_code", { required: true})} />
                                                                {errors.post_code && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Mobile Number</label>
                                                                <input type="text" className={`form-control  ${ errors.mobile ? "is-invalid" : ""}`} {...register("mobile", { required: true})} defaultValue={users ? users.mobile : ''}/>
                                                                {errors.mobile && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group form-check">
                                                                <input type="checkbox" id="defaultAddress" defaultChecked={true} className={`form-check-input  ${ errors.default_address ? "is-invalid" : ""}`} {...register("default_address")}/>
                                                                <label className='form-check-label mt-1' for="defaultAddress">Set as Default Address</label>
                                                                {errors.default_address && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <button className="btn btn-block btn-dark mb-2 mx-2" >
                                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Submit
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            
                                        </div>
                                    ) : ""
                                }
                                
                            </div>
                        ) : (
                            <div className="row justify-content-md-center border border-dark rounded-lg pt-5 pb-5">
                                <div className='col-md-10 px-5'>
                                {!comp_l ?
                                <>
                                <h3 className="text-dark text-center">Thank you! Purchase is complete.</h3>
                                <p className='text-dark'>
                                Congratulations! You are now the proud owner of a historic moment in Philippine Arts and Culture.<br></br>
                                {msg}<br/><br/>
                                As an owner of this NFT, you are contributing to the value of the artist’s work as it connects humanity across time.                           
                                </p>
                                <p>Only You will own this collectible NFT that is preserved on the blockchain forever.</p>
                                    <p className='my-0'>To view your Digital Collectible in your Scarletbox account:</p>
                                    <p className='my-0'>1. Click "My Account"</p>
                                    <p className='my-0'>2. Click "My Collections"</p>
                                    
                                    <p>**Voila! You will see it and you may download a hi-res image for your personal use.</p>
                                </>
                                : <p className='text-dark text-center'>Transaction already completed please go to your collections</p>
                                }
                                <center> 
                                <a href="/account/collections" className="btn btn-dark mt-2">View My Collections</a>
                                </center>
                                </div>
                            </div>
                        )
                    }

                    
                </div>
            </section>
            <Footer />
            <ModalSearch/>
            <ModalMenu />
            <ModalLogin/>
            <Scrollup />
            </>}
        </div>
    )

}

 
export default Paymongo;