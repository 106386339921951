import React, {Component} from "react";

class HowItWorks extends Component {
  
    render() { 
        return (
            <>
        
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="section_title text-center">How it works</h1>
                            <br></br>
                            <br></br>
                            <div className="row">
                                {/* {
                                    this.props.content.map(data => {
                                        return (
                                            <div key={data.id} className="col-md-4">
                                                <div className="card how-it-works bg-white rounded-0 text-center">
                                                    <img className="mx-auto" width={100} src={data.img} alt={`Image_${data.id}`}></img>
                                                    <p className="font-weight-bold">{data.heading}</p>
                                                    <p className="pt-0">{data.content}</p>
                                                </div>  
                                            </div>   
                                            
                                        )
                                    })
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default HowItWorks;