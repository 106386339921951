import React, { useEffect, useState } from 'react';
import Auth from '../../helpers/auth/Auth';
import apiClient from '../../helpers/api';
import { HashLink, HashLink as Link} from 'react-router-hash-link';


const ModalMenu = () => {

    const logout = () => {
        console.log("logout");
        apiClient.post('/account/logout')
        .then( res => {
            
            localStorage.removeItem('isAuth')
            window.location.href = "/";
        }).catch( e => {
            console.log(e.response.data);
        })
        
    }

    const [is_auth, setIsAuth] = useState(true);
    const [isScroll, setIsScroll] = useState(false);

    
    useEffect(() => {

        apiClient.get('/account/user')
        .then(res => {
            setIsAuth(true)
            setIsScroll(false)

        }).catch(e => {
            setIsAuth(false)
            localStorage.clear()
        })

    },[])

    const hideActiveModal = () => {
        const modal = document.getElementsByClassName('modal show')[0];
        const fade = document.getElementsByClassName('modal-backdrop show')[0];
        modal.className = modal.className.replace('show', '');
        fade.className = fade.className.replace('show', '');


        // const body = document.getElementsByTagName("body")[0]; body.classList.remove("modal-open"); 
        // setTimeout(()=>{
        //     window.location.reload();
        // }, 1500);


      };
      


    if (!is_auth) {
       return (
        <div id="menu-nav" className="modal fade p-0">
        <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content text-dark">
                <div className="modal-header text-dark" data-dismiss="modal">
                    Menu <i className="far fa-times-circle icon-close" />
                </div>
                <div className="modal-body">
                    <div className="row w-100">
                    <div className='col-12'>
                        {/* <div className="items p-0 col-12 text-center" /> */}
                      
                        <ul className="navbar-nav items ml-auto">
                            <li className="nav-item">
                                <a className="nav-link text-dark font-weight-normal" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-dark font-weight-normal" href="/artists">Artists</a>
                            </li>
                            <li className="nav-item">
                                {/* <a className="nav-link text-dark font-weight-normal" href="/#collection">Collection</a> */}
                                <Link smooth to="/#collection" className="nav-link text-dark font-weight-normal" onClick={hideActiveModal}> Collections </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-dark font-weight-normal" href="/about-us">About Us</a>
                            </li>
                            <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/auctions">Auction</a>
                                </li>
                            <li className="nav-item">
                                <a className="nav-link text-dark font-weight-normal" href="/vip/scarletbox-virtual-gallery/scarletbox">Online Gallery</a>
                            </li>

                            <li className="nav-item">
                                <button className="btn btn-smaller btn-dark loginBtn font-weight-normal mx-1 btn-block mt-lg-1 mt-2" data-toggle="modal" data-target="#modal-login" data-dismiss="modal">Login</button>
                            </li>
                            <li className="nav-item">
                            <button className="btn btn-smaller btn-dark loginBtn font-weight-normal mx-1 btn-block mt-lg-1 mt-2" data-toggle="modal" data-target="#modal-register" data-dismiss="modal">Register</button>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
       )
    }


    return (
        <div id="menu-nav" className="modal fade p-0">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content h-100 text-dark">
                    <div className="modal-header text-dark" data-dismiss="modal">
                        Menu <i className="far fa-times-circle icon-close" />
                    </div>
                    <div className="modal-body">
                        <div className="row w-100">
                        <div className='col-12'>
                            {/* <div className="items p-0 col-12 text-center" /> */}
                            {
                        Auth.isAuthenticated() ? (
                            <ul className="navbar-nav items mx-auto">
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/artists">Artists</a>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="/#collection" className="nav-link text-dark font-weight-normal" onClick={hideActiveModal}> Collections </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/silent-auction-coming-soon">Silent Auction</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/vip/scarletbox-virtual-gallery/scarletbox">Online Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/about-us">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <button type='button' className="btn btn-smaller btn-dark loginBtn font-weight-normal mx-1 btn-block mt-lg-1 mt-2" onClick={() => window.location = '/auth/my-account'}>My Account</button>
                                </li>
                                <li className="nav-item">
                                    <button type='button' className="btn btn-smaller btn-dark loginBtn font-weight-normal mx-1 btn-block mt-lg-1 mt-2 d-xl-none d-md-block" style={{cursor: "pointer"}} onClick={ () => {Auth.logout()}}>Logout</button>
                                </li>
                            </ul>
                        ) : (
                            <ul className="navbar-nav items ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/artists">Artists</a>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="/#collection" className="nav-link text-dark font-weight-normal" onClick={hideActiveModal}> Collections </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/silent-auction-coming-soon">Silent Auction</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/vip/scarletbox-virtual-gallery/scarletbox">Online Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark font-weight-normal" href="/about-us">About Us</a>
                                </li>
                
                                <li className="nav-item">
                                    <button className="btn btn-smaller btn-dark loginBtn font-weight-normal mx-1 btn-block mt-lg-1 mt-2" data-toggle="modal" data-target="#modal-login" data-dismiss="modal">Login</button>
                                </li>
                                <li className="nav-item">
                                <button className="btn btn-smaller btn-dark loginBtn font-weight-normal mx-1 btn-block mt-lg-1 mt-2" data-toggle="modal" data-target="#modal-register" data-dismiss="modal">Register</button>
                                    {/* <a className="btn btn-smaller btn-danger loginBtn text-dark font-weight-normal mx-1 btn-block mt-lg-1 mt-2" href="/#regform" data-dismiss="modal">Register</a> */}
                                    {/* <a smooth href="/#regform" className="btn btn-smaller btn-danger loginBtn text-dark font-weight-normal mx-1 btn-block mt-lg-1 mt-2" onClick={hideActiveModal}> Register </a> */}
                                </li>
                            </ul>
                        )
                    }
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMenu;