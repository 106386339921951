import React from "react";

const Bidder = (props) => {
    return(
      <div class="tab-pane fade" id="bidder" role="tabpanel" aria-labelledby="bidder-tab">
        <div class="row mt-2 table-responsive">
            <table class="table table-sm table-bordered rounded border border-dark">
                <thead>
                    <tr>
                      <th scope="col">BID Amount</th>
                      <th scope="col">BIDDER</th>
                      {
                        props.show_bid_log_time === 1 ? <th scope="col">Date</th> : ''
                      } 
                    </tr>
                </thead>
                <tbody>
                  {
                    props.bids.map((bid,id) => {
                      return(
                  <tr>
                    <th scope="row">PHP {parseInt(bid.bid_amount).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2,})}</th>
                    <td>{bid.bidder_name}</td>
                    {
                        props.show_bid_log_time === 1 ? <td scope="col">{bid.diff}</td>: ''
                      }
                    
                  </tr>
                      )
                    })
                  }
                  
                </tbody>
              </table>
            </div>
      </div>
    )
}

export default Bidder