const CheckoutLoading = () => {
    return (
        <section className="checkout-loading">
            <div className="container">
                <div className="row">
                    <img src="/img/WEB ICONS/BoxAsset.png"></img>
                    <div className="checkout-loading-heading text-center ml-xl-5 mt-3">
                        <h2 className="normal-font text-dark m-0">Processing your payment.</h2>
                        <h2 className="normal-font text-dark m-0">Please do not close window this window</h2>
                        <p>It will take 2 to 3 minutes</p>
                    </div>
                </div>
                <div className="text-center">
                    <p className="m-0">After 5 minutes, your NFT will be dropped into your account if payment goes through</p>
                    <p className="m-0">You will get an email immediately to confirm your purchase.</p>
                    <br></br>
                    <p className="m-0">To view your art, <span className="font-weight-bold font-italic">Log in</span> &gt; go to your <span className="font-weight-bold font-italic">My Account</span> &gt; click <span className="font-weight-bold font-italic">My Collections</span></p>
                    <br></br>
                    <p className="m-0">You should be able to see your NFT as a spinning box (if not yet revealedd) or your revealed Art.</p>
                    <p className="m-0">Congratulations on owning a piece of history</p>
                    <br></br>
                    <p className="m-0">Email us for questions: <a href="#" className="font-weight-bold text-dark">concierge@scarletbox.io</a></p>
                </div>
                
                
            </div>
        </section>
    );
}
 
export default CheckoutLoading;