import axios from "axios";

const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Credentials': true,
        'api-key':"c2NAcjczdGIweDE5ODchYmt0MjAyMyFAIQ=="
    }
})

export default apiClient;
