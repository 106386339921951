
import React, {Component, useEffect, useState} from "react";


class ModalArthouse extends Component{


    state = {
        content: ''

    };

    componentDidMount(){
        this.setState({
            content: this.props
         
          
        })
     console.log(this.props.content)

    };


    render() {

        // console.log(this.props.content.id)

            return (

                <div id={`modal-arthouse-${this.props.content.art_house_id}`} className="modal fade p-0">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content bg-white">
                            <div className="modal-header" data-dismiss="modal">
                                <i className="far fa-times-circle icon-close" />
                            </div>
                            <div className="modal-body pt-0">
                           
                                <div className="card-body text-center pt-0">
                                    <img className="avatar-lg" src={window.photo_url+this.props.content.arthouse.photo} alt="" />
                                    <h4 className="text-red">{this.props.content.arthouse.house_name}</h4>
                                    <div className="text-justify" dangerouslySetInnerHTML={{__html: this.props.content.arthouse.description}}></div>

                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
                      
            );
            
        


     
    }
       
}
 
export default ModalArthouse;

