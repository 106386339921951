import React, {Component} from "react";

//import components
import RegisterComp from "../../components/Register/Register";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalLogin from "../../components/Modal/ModalLogin";
import Scrollup from "../../components/Scrollup/Scrollup";
import ModalMenu from '../../components/Modal/ModalMenu';
import ModalSearch from '../../components/Modal/ModalSearch';

import { loadData } from "../../helpers/loadData";

const registerData = {
    heading: 'Create an Account',
    content: "Can't Wait for our NFT's top drop? Be the first to know more about our featured items! JOIN US and receive special access to Public and Private Auctions"
}

class Register extends Component {

    render() { 
        return (
            <div className="main">
                <Header />
                <RegisterComp registerData={registerData}/>
                {loadData}
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <ModalLogin/>
                <Scrollup />
            </div>
        );
    }
}
 
export default Register;