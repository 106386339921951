import React, {Component, useState, useEffect} from "react";
import apiClient from '../../helpers/api';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalLogin from "../../components/Modal/ModalLogin";
import Scrollup from "../../components/Scrollup/Scrollup";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import BannerSale from "../../components/Banner/BannerSale";
import PublicSaleSoon from "../../components/Sale/PublicSaleSoon";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import ModalRegister from "../../components/Modal/ModalRegister";
import PublicSalesForm from "../../components/Sale/PublicSalesForm";
import {Helmet} from "react-helmet";

const PublicSale = (props) => {

    const [isLive, setIsLive] = useState(0);
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [next_scheedule, setNextSchedule] = useState('');
    const [changeClient, setChangeClient] = useState(false);

    const onChangeClient = (value) => {
        //console.log(value);
        setChangeClient(value)
    }

    useEffect(()=>{
        changeHeader()
    },[changeClient])

    useEffect(()=>{
        apiClient.get(`/sale/public-sales/${props.match.params.url}`)
            .then(res => {
                setIsLive(res.data.status)
                setText(res.data.message)
                setTitle(res.data.project_title)
                setSubtitle(res.data.project_title)
                setNextSchedule(res.data.next_schedule)
            }).catch( e => {
                console.log(e)
                // this.setState({
                //     redirect:true
                // });
            });
    },[])


    const changeHeader = () =>{
        return <Header/>
    }

    if(redirect){
        return <Redirect to=""/>
    }

    return (
        <>
        <Helmet>
        <meta name="robots" content="noindex,nofollow" data-react-helmet="true" />
        </Helmet>
        <div className="main">
            <PageLoading />
            {/* <Header/> */}
            {changeHeader()}
            <BannerSale  title={title} />
            {
                isLive && isLive != 200  ? <PublicSaleSoon  next_scheedule={next_scheedule} subtitle={subtitle} text={text}/> : <PublicSalesForm onChangeClient={onChangeClient}  purl={props.match.params.url}/>    
            }
            <Footer />
            <ModalSearch/>
            <ModalMenu />
            <ModalRegister/>
            <ModalLogin/>
            <Scrollup />
        </div></>
    )


}


// class PublicSale extends Component {

//     constructor(props) {
//         super(props)
    
//         this.onChangeClient = this.onChangeClient.bind(this)
//       }
//     state = { 
//         isLive: 0, 
//         text:'',
//         title:'Public Sale',
//         subtitle:"",
//         redirect:false,
//         next_scheedule:"",
//         changeClient: ""
//     }
//     componentDidMount(){
//             apiClient.get(`/sale/public-sales/${this.props.match.params.url}`)
//             .then(res => {
//                 console.log(res);
//                 this.setState({
//                     isLive: res.data.status,
//                     text: res.data.message,
//                     subtitle: res.data.title,
//                     bannersub : res.data.project_title,
//                     next_scheedule:res.data.next_schedule
//                 })
//             }).catch( e => {
//                 console.log(e)
//                 // this.setState({
//                 //     redirect:true
//                 // });
//             });
//     }

//     renderHeader(){
//         return <Header/>
//     }

//     onChangeClient(value){
//         console.log(value);
//         this.setState({
//             changeClient: value
//         })
//     }


//     render() { 

//         if(this.state.redirect){
//             return <Redirect to=""/>
//         }
//         return (
//             <div className="main">
//                 <PageLoading />
//                 {/* <Header/> */}
//                 <Header/>
//                 <BannerSale  title={this.state.title} sub={this.state.bannersub}  />
//                 {
//                  this.state.isLive != 200  ? <PublicSaleSoon start_date={this.state.start_date} next_scheedule={this.state.next_scheedule} subtitle={this.state.subtitle} text={this.state.text}/> : <PublicSalesForm onChangeClient={this.onChangeClient}  purl={this.props.match.params.url}/>    
//                 }
//                 <Footer />
//                 <ModalSearch/>
//                 <ModalMenu />
//                 <ModalLogin/>
//                 <Scrollup />
//             </div>
//         );
//     }
// }
 
export default PublicSale;