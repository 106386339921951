import React, { Component } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import { isMobile } from "react-device-detect";
// import 'bootstrap/dist/css/bootstrap.min.css';
import apiClient from '../../helpers/api';
// import background from "../../img/BG1.jpg";

class Banner_Carousel extends Component {
    state = {
        data: [],
        banner: [],
    }
    componentDidMount(){
        apiClient.get(`${process.env.REACT_APP_API}/banners`)
        .then(res => {
            this.setState({
                banner: res.data, 
            })
        })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className='pt-3'>
                <div className="col-12">
                    <h2 className="text-center text-dark normal-font">What&#39;s New</h2>
                </div>
                <div className="carousel-bg"  style={{backgroundImage: `url(${"../../img/new_banner/Common_Background.jpg"})`}} >
                <Carousel 
                // prevLabel={<span aria-hidden="true" className="carousel-control-prev-icon" />} 
                // nextLabel={<span aria-hidden="true" className="carousel-control-next-icon" />} 
                // pause={false} 
                // nextIcon={false}  
                // prevIcon={false}
                prevLabel={false} 
                nextLabel={false} 
                pause={false} 
                nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}  
                prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}

                >
                {
                    this.state.banner.map((item, idx) => {
                        return (
                            <Carousel.Item>

                                <div key={`auc_${idx}`}>
                                    
                                {item.buttons == null && item.button_target != ''  ? 
                                    <a target="_blank" href={item.button_target}>
                                        {
                                            isMobile ?
                                            <img src={window.photo_url+item.img_photo} alt="mobile" className="img-fluid "/>
                                            :
                                            <img src={window.photo_url+item.photo} alt="desktop" className="img-fluid"/>
                                        }
                                    </a> : 
                                    <>
                                       {
                                            isMobile ?
                                            <img src={window.photo_url+item.img_photo} alt="mobile" className="img-fluid"/>
                                            :
                                            <img src={window.photo_url+item.photo} alt="desktop" className="img-fluid"/>
                                        }
                                     
                                   
                                     </>
                                    } 

                                    {item.header ?
                                        <Carousel.Caption>
                                            <div className='carousel-caption d-md-block'>
                                                <h1 className="section_title text-yellow" dangerouslySetInnerHTML={{ __html: item.header}}></h1>
                                                {item.sub_header ?
                                                    <p className="text-uppercase subtitla" dangerouslySetInnerHTML={{ __html: item.sub_header}}></p>
                                                    :
                                                    ""
                                                }
                                                {item.buttons ?
                                                    <p>
                                                        <div className="button-group">
                                                            <a className="btn bg-white text-danger" href={item.button_target}>{item.buttons}</a>
                                                        </div>
                                                    </p>
                                                    :
                                                    ""
                                                }
                                                
                                            
                                            </div>
                                        </Carousel.Caption>
                                        
                                        :
                                        ""
                                    } 
                                </div>
                            </Carousel.Item>

                        );
                    })
                }
 
                </Carousel>
                </div>
               

       

            </section>


                
        );
    }
}

export default Banner_Carousel;