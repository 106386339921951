import {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import apiClient from "../../helpers/api";
import swal from 'sweetalert';


const BillEase = (props) => {

    const [errormsg,setErrormsg] = useState()
    const [users, setUsers] = useState()
    const [tr_no,setTrno] = useState(props.tr_no)
    const [price,setPrice] = useState(props.price_fiat)
    const {register, handleSubmit, formState:{errors},formState,reset} = useForm({ mode: 'onBlur' })
    const [redirect, setRedirect] = useState(false)

    const onBilleasePay = async (data) => {
        setErrormsg('')

        
        let details = {
                client_id: users ? users.id : '',
                project_id: props.project_id,
                tr_no: props.tr_no,
                art_bundle_qty: props.art_bundle_qty,
                type: props.type,
                artist_id:props.artist
            }
        if (props.art_bundle_qty) {

            try {
                
                var bundle = await apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/generate_arts`, details)

                if (bundle.data.code === 301) {
                    setErrormsg(bundle.data.msg)
                }else{
                    localStorage.setItem('artx_bundle_tr_no_', props.tr_no)
                    localStorage.setItem('artx_bundle_', bundle.data.result.bundle_no)

                    //process payyment 
                    try {
                        var trx = await apiClient.post(`${process.env.REACT_APP_API}/checkout/paymongo/create_billease`, data)

                        if(trx.data.code == 200){
                            window.location = trx.data.url;
                        } else if(trx.data.code == 250){
                            window.location = trx.data.url;
                        } else {
                            setErrormsg(trx.data.msg)
                            setRedirect(true)
                            //revert reserved if transaction fail 
                            if (props.art_bundle_qty) {
                                apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/cancel`, {
                                    tr_no: localStorage.getItem("artx_bundle_tr_no_"),
                                    bundle_no: localStorage.getItem("artx_bundle_"),
                                }).then(res => {
                                    console.log(res);
                                    localStorage.removeItem("artx_bundle_tr_no_");
                                    localStorage.removeItem("artx_bundle_");
                                })
                            }
                        }
                    } catch (error) {
                        setErrormsg(error.data)
                    }

                }

            } catch (error) {
                if(error.code === 4001){
                    setErrormsg(error.message)
                } else {
                    setErrormsg('Transaction failed')
                }
            }

        //     return new Promise(resolve => {
            
        //         apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/generate_arts`, details).then(res => {
    
        //             // process payment if success in generate art
        //             if(res.data.code == 200){
        //                 return new Promise(resolve => {
        //                     apiClient.post(`${process.env.REACT_APP_API}/checkout/paymongo/create_billease`, data).then(res => {
        //                         console.log(res);
        //                         if(res.data.code == 200){
        //                             window.location = res.data.url;
        //                         } else if(res.data.code == 250){
        //                             window.location = res.data.url;
        //                         } else {
        //                             setErrormsg(res.data.msg)
        //                             setRedirect(true)
        //                             //revert reserved if transaction fail 
        //                             if (props.art_bundle_qty) {
        //                                 apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/cancel`, details).then(res => {
        //                                     console.log(res);
        //                                 }).catch(e => {
        //                                     console.log(e);
        //                                 })
        //                             }
        //                         }
        //                         resolve()
        //                     }).catch(error => { 
        //                             if(error.response.status == 401){
        //                                 console.log(error.response)
        //                                 setErrormsg(error.response.data.message)
        //                             }
        //                         resolve()
        //                     });
                            
        //                 })
    
        //             } else if(res.data.code == 250){
        //                 window.location = res.data.url;
        //             } else {
        //                 swal({
        //                     title: "Error",
        //                     text: res.data.msg,
        //                     icon: "warning",
        //                   })
        //                 setErrormsg(res.data.msg)
        //             }
        //             resolve()
        //        }).catch(error => { 
        //             if(error.response.code == 301){
        //                 console.log(error.response)
        //                 swal({
        //                     title: "Error",
        //                     text: error.response.data.message,
        //                     icon: "warning",
        //                   })
        //                 setErrormsg(error.response.data.message)
        //             }
        //            resolve()
        //        });
              
        //    })
        }else{
            return new Promise(resolve => {
                apiClient.post(`${process.env.REACT_APP_API}/checkout/paymongo/create_billease`, data).then(res => {
                        if(res.data.code == 200){
                        window.location = res.data.url;
                        } else if(res.data.code == 250){
                            window.location = res.data.url;
                        } else {
                            setErrormsg(res.data.msg)
                        }
                        resolve()
                    }).catch(error => { 
                            if(error.response.status == 401){
                                console.log(error.response)
                                setErrormsg(error.response.data.message)
                            }
                        resolve()
                    });
                
            })
        }
    
    }

    const {isSubmitting} = formState;

    useEffect( () => {
        console.log(props);
        apiClient.get(`${process.env.REACT_APP_API}/account/user`)
            .then(res => {
                setUsers(res.data)
                reset({control_number:tr_no})
            })
    },[])

    return (
        <>
        <Modal
        show={props.show} 
        onHide={props.onHide} 
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='checkout-modal'
        backdrop="static">
            
            <Modal.Header >
                <Modal.Title className='text-white'>BillEase Payment</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onBilleasePay)}>
            <Modal.Body>
                {
                        errormsg ?  <div className='alert alert-danger'>{errormsg}</div> : ''
                }
                <input type="hidden" className='form-control' {...register("control_number", { required: true})} defaultValue={props.tr_no}/>
                <input type="hidden" {...register("total_")} value={props.price_fiat} />
                <input type="hidden" {...register("client_id")} defaultValue={users ? users.id : ''}/>
                
                {/* bundle */}
                <input type="hidden" {...register("bundle_no")} defaultValue={props.bundle_no} />
                <p>Thank you for your interest in purchasing our One of a kind NFT.</p>
                <p> You are about to pay <span className='font-weight-bold'>PHP {new Intl.NumberFormat().format(props.price_fiat)}</span></p>
                <p>You will now be redirected to Billease client</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                <Button variant="primary" type="submit">Proceed</Button>
            </Modal.Footer>
            </Form>
        </Modal>
        </>
    )

}

export default BillEase