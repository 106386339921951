import apiClient from "../api";
import { Redirect } from "react-router-dom";

class Auth {

    static isAuthenticated = () => { 

        // apiClient.get('/account/user')
        //     .then(res => {
        //         if (res.status == 200) {
        //             let isAuth = localStorage.getItem('isAuth')
        //             return isAuth
        //         }
                
        //     }).catch(error => {
        //         if (error.response.status === 401) {
        //             localStorage.clear();
        //             let isAuth = localStorage.getItem('isAuth')
        //             return isAuth
        //         }
                
        //     })
        
        if (this.getWithExpiry()) {
            let isAuth = localStorage.getItem('isAuth')
            
            return isAuth
        }
        
        
    }


    static getUser = async () => {
        const user  = apiClient.get('/account/user')
            .then(res => {
                if (res.status == 200) {
                    return res.data
                }
            })
        
        return await user
    }
    



    /**
     * @param {*} key 
     * @param {*} value 
     * @param {*} expire
     * @return void
     */
    static storeAuth = (key = "isAuth" ,value = true,expire = (1000 * 60 * 60 * 12)) => {
        const now = new Date()

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + expire,
        }
        
        localStorage.setItem(key, JSON.stringify(item))

    }


    static getWithExpiry = (key = "isAuth") => {
        
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            localStorage.removeItem('jwt')
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            localStorage.removeItem('jwt')
            return null
        }
        return item.value
    }


    /**
     * @param {*} name 
     */
    static deleteCookie = (name) => {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };



    static logout = () => {

        apiClient.post('/account/logout')
        .then( res => {
            localStorage.removeItem("wallet")
            localStorage.removeItem('isAuth')
            localStorage.removeItem('jwt')
            window.location.href = "/";
        }).catch( e => {
            console.log(e.response.data);
        })
        
    }

}

export default Auth

