import React, {Component} from "react";

class Modal extends Component {
    state = { 
        id: "",
        title: 'Modal Title',
        content: "Loading content",
        img: "",
        size: "md",
        html: ""
    }
    
    componentDidMount(){
        this.setState({
            id: this.props.id,
            title: this.props.title,
            content: this.props.content,
            img: this.props.img,
            size: this.props.size,
            html: this.props.html
        })
    }

    render() { 
        return (
            <div id={this.state.id} data-show="true" className="modal fade p-0">
                <div className={`modal-dialog modal-${this.state.size} modal-dialog-centered`}>
                    <div className="modal-content bg-white">
                        <div className="modal-header pb-0" data-dismiss="modal">
                        {this.state.title}<i className="far fa-times-circle icon-close" />
                        </div>
                        <div className="modal-body text-dark px-5">
                            {this.props.img != "" ? <img alt="img" src={this.props.img}></img> : ""}
                            {this.props.product != "" ?<div className="mt-3" dangerouslySetInnerHTML={{__html: this.props.product}}></div> : ""}
                            {this.props.shipping != "" ?<div className="mt-3" dangerouslySetInnerHTML={{__html: this.props.shipping}}></div> : ""}
                            {this.state.content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Modal;