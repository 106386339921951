import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const AuctionDetailsSkeleton = () => {
    return  (
        <div className="container">
                <div className="row justify-content-between item-details-area">
                    <div className="col-12 col-lg-6 "> <Skeleton height={200}/></div>
                    <div className="col-12 col-lg-6 "> <Skeleton height={200}/></div>
                    <div className="col-12 col-lg-12  mt-2"> <Skeleton height={100}/></div>
                </div>
            </div>
    )

}

export default AuctionDetailsSkeleton