import React, {useState, useEffect} from 'react';

import apiClient from '../../helpers/api';
import { useParams } from 'react-router';


//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import ModalLogin from "../../components/Modal/ModalLogin";
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import SaleCollection from '../../components/Collections/SaleCollection';
import Artist from '../../components/Shortcodes/Artist';
import ProjectCarousel from '../../components/Banner/ProjectCarousel';
import ProjectSneakPeek from '../../components/Shortcodes/ProjectSneakPeek';
// import Banner_Carousel from '../../components/Banner/Carousel';
import RoadMap from '../../components/RoadMap/RoadMap';
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";
import ModalRegister from '../../components/Modal/ModalRegister';
import {Helmet} from "react-helmet";


const Collection = (props) => {

    const [banners, setBanners]  = useState([])
    const [artists, setArtists]  = useState([])
    const [sneakpeek, setSneakPeek]  = useState({})
    const [sneak, setSneak]  = useState([])
    const [roadmap, setRoadMap]  = useState([])
    const [isError, setIsError] = useState(false)
    const [private_status, setPrivateStatus] = useState()
    const [public_status, setPublicStatus] = useState() 
    const [isScroll, setIsScroll] = useState(false)
    const [isSeoEnable,setIsSeoEnable] = useState(false)
    const [projectName,setProjectName] = useState('')
    const [artistSectionTitle,setArtistSectionTitle] = useState('Artist')

    useEffect(() => {
    
        // apiClient.get('/collection-projects/view/legends')
        apiClient.get(`${process.env.REACT_APP_API}/collection-projects/view/${props.match.params.url}`)
        .then(res => {
            setIsError(false)
            setPrivateStatus(res.data.private_schedule)
            setPublicStatus(res.data.public_schedule)
            setIsScroll(false)
            setBanners(res.data.banners)
            setArtists(res.data.p_artists)
            setSneakPeek(res.data.sneak_peak)
            setIsSeoEnable(res.data.disable_seo)
            setArtistSectionTitle(res.data.artist_title)
            if (res.data.sneak_peak != null) {
                setSneak(res.data.sneak_peak.arts)
            }
            setIsSeoEnable(res.data.disable_seo);
            setRoadMap(res.data.road_maps)
            setProjectName(res.data.project_name);
            const url = window.location.href

            const scroll = url.includes("#")

            if(scroll){
                setIsScroll(true)
            }

            
        }).catch( err => {

            
            setIsError(true)
        })
    }, [])

     let scroll = () => {
       
        const section = document.querySelector( '#roadmap' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        
        
    };


    if (isError) {
        return <PageNotFound url={props.location.pathname}/>
    }

    return ( 
        <>
        {
            isSeoEnable == 1 ? 
                <Helmet>
                    <title>Scarletbox Collection - {projectName}</title>
                    <meta name="robots" content="noindex,nofollow" data-react-helmet="true" />
                </Helmet> 
            : 
            <Helmet>
                <title>{projectName}</title>
                <meta name="robots" content="all" data-react-helmet="true" />
            </Helmet>
        }
          
        <div className="main">
            {/* <PageLoading /> */}
            {
                !isScroll ?

                <PageLoading /> :

                scroll

            }
            <Header/>
            {/* <BannerSale title="Collection Page" /> */}
            <ProjectCarousel content={banners} />
            {/* <SaleCollection /> */}
            <SaleCollection params={props.match.params.url} private={private_status && private_status} public={public_status && public_status} />
            
                
            {/* <RoadMap params={roadmap} /> */}
            {
                roadmap.length == 0 || null ? 
                null
                :
                <RoadMap params={roadmap} />

            }
            <Artist title={artistSectionTitle} params={artists} />
            {
                sneakpeek ? <ProjectSneakPeek params={sneakpeek && sneakpeek} sneak={sneak && sneak}  /> : ""
            }
            
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <ModalLogin/>
            <ModalRegister/>
            <Scrollup />
        </div>
        </>
    );

}


 
export default Collection;