import React, {useState, useReducer, useEffect} from 'react';
import { useForm, } from "react-hook-form";
import apiClient from "../../helpers/api";
import swal from 'sweetalert';
import Fiat from "./Fiat";
import CryptoPayment from "./CryptoPayment";
import Gcash from './Gcash';
import BillEase from '../../components/Checkout/BillEase';
import CheckoutAuth from '../../components/Checkout/CheckoutAuth';
import { Alert } from 'react-bootstrap';
import Auth from '../../helpers/auth/Auth';
import ModalPromo from "../Modal/ModalPromo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Upsell from './Upsell';
const eye = <FontAwesomeIcon icon={faEye} />;
const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;

const Checkout = (props) => {  
    
    const payment_methods = props.vipdata.with_meta == 1 ? ['Credit/Debit Card','', '', '', 'GCash'] : ['Credit/Debit Card','Meta Mask', '', '', 'GCash']
    const upsell = props.vipdata.upsell;
    const [paymentType, setPaymentType] = useState([]);
    const {register, handleSubmit, formState:{errors},formState,reset, getValues,unregister, setValue} = useForm({ mode: 'onBlur' })
    const {isSubmitting} = formState;
    const [terms, setTerms] = useState('')
    const [users, setUsers] = useState()
    const [payment_method, setPayment_method] = useState(5)
    
    const [isopen, setIsopen] = useState(false)
    const [trno,setTrno] = useState()
    const [pricefiat,setPricefiat] = useState()
    const [cyrtop, setcyrtop] = useState(false)
   
    const [isGcash, setIsGcash] = useState(false);
    const [isBillease, setIsBillease] = useState(false);

    const [eth_shipping_fee, setEth_shipping_fee] = useState(0)
    const [ethData, setEthData] = useState([])
    const [upsellOptions, setUpsellOptions] = useState([])
    const [upsellDesciprtion,setUpsellDescription] = useState('')
    const [upsellPrice,setUpsellPrice] = useState(0)
    const [upsellOptionId,setUpsellOptionId] = useState(0)
    const [withUpsell,setWithUpsell] = useState(1)
    const [upsellName,setUpsellName] = useState('')

    //bundle_no
    
    const [newVal,setNewVal] = useState(0)

    const [isLogin, setIsLogin] = useState();
    const [showLogin, setShowLogin] = useState();
    

    const [loginData, setLoginData] = useState();


    //login

    const [with_account, setWithAccount] = useState(false);
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState('');

    const [passwordShown, setPasswordShown] = useState(false);

    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");
    const [metamask,setMetamask] = useState(true); 

    const [username, setUsername] = useState('')
    const [name, setName] = useState('')
    const [last_name, setLastname] = useState('')
    const [mobile, setMobile] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
  
    const [errormsg, setErrorMsg] = useState('')



    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    //register form toggle password
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    }

    const onChangeAccount = (e) => {
        setWithAccount(e.target.checked)
    }

    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password")
        {
            setConfirmPasswordType("text")
         return;
        }
        setConfirmPasswordType("password")
    }

    const onChangeClient = (e) =>{
        setValue('client_id',e)
        props.onChangeClient(true)
    }

    const onPaymentMethod = (e) => { 
        setPayment_method(e.target.value)
        
        reset({
            payment_method: e.target.value
        });
     }

    const closeModal = () => {
        
        setShowLogin(false);
        setIsopen(false);
        setcyrtop(false);
        setIsGcash(false);
        setIsBillease(false);
    }

    const back_url = () => {
        props.stateChanger('')
    }

    const getUpsellOptions = async () => {
        //
        try{
            const res = await apiClient.get(`${process.env.REACT_APP_API}/vip/get/vip_art_id/${props.vipdata.vip_art_id}`);
            if (res.status == 200) {
                setUpsellOptions(res.data.results)
            } 
        } catch (error) {
            return false;
        }
    }

    const verifyLogin = async () => {
        try {
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);
            if (res.status == 200) {
                setUsers(res.data.id)
                setValue('client_id',res.data.id)
                return true;
            } 
        } catch (error) {
            return false;
        }
    }

    const onCheckout = async (data) => {

       

        setLoginData(data);
        const loggin = await verifyLogin();
        if (!loggin) {
            if (with_account) { // with acount
                return new Promise(resolve => {
                    apiClient.post(`/auth/login`, { 
                        email: email,
                        password: password
                    }).then(response => {
                       
                        if (response.status == 200){ 
                            Auth.storeAuth()
                            onChangeClient(response.data.client_id)
                            setUsers(response.data.id)
                            setIsLogin(true)
                            
                            data["client_id"] = response.data.client_id
                            // Transaction added
                            generateTransaction(data)
                            resolve()
                        } 
                    }).catch(function (error) {

                        if(error.response.status==401){
                            setShow(true)
                            resolve();
                        }
                    });
                });
            } else { // register
                return new Promise(resolve => {
                    apiClient.post(`${process.env.REACT_APP_API}/auth/login`, {
                        username: username,
                        name: name,
                        last_name: last_name,
                        mobile: mobile,
                        email: email,
                        password: password,
                        confirm_password: confirm_password,
                        isRegistered: false,
                    }).then(response => {
                        if (response.status == 200) { 
                            Auth.storeAuth()
                            onChangeClient(response.data.client_id)
                            data["client_id"] = response.data.client_id
                            setIsLogin(true)
                            setUsers(response.data.id)
                            // Transaction added
                            generateTransaction(data)
                        } 
                        resolve()
                    }).catch(error => {
                        resolve()
                    });
                })
            }
        } else { // logged
            generateTransaction(data)
        }
       
    }

    const generateTransaction = async (data) => {
        setUsers(  data["client_id"])

       
        
        if(data['total'] == 0){

            data['total'] = parseFloat(props.vipdata.price) + parseFloat(props.vipdata.sf) + parseFloat(upsellPrice)
        }

        if(withUpsell == 2){
            data['upsell'] = withUpsell;
            data['upsell_desciprtion'] = upsellDesciprtion;
            data['upsell_price'] = upsellPrice;
            data['total'] = newVal;
            data['upsell_name'] = upsellName;
        }


        
        return new Promise(resolve => {
        apiClient.post(`${process.env.REACT_APP_API}/checkout/process/vip_generate_transaction`,data)
        .then(res => {
            if(res.data.status == 201){
                swal({
                    title: "Error",
                    text: res.data.message,
                    icon: "warning",
                }).then((value) => {
                    window.location.reload()
                });
            } else {
                setTrno(res.data.result.tr_no)
               
                setPricefiat(parseFloat(res.data.result.total_crypto))
                setEthData({
                    chainCode:res.data.result.chain_code,
                    chainName:res.data.result.chain_name,
                    allowChainId:res.data.result.chaid_id,
                    eth:props.vipdata.eth_price,
                    sf: 0,
                    art_code:props.vipdata.art_code,
                    vip_id:props.vipdata.vip_id,
                    art_id:props.vipdata.art_id,
                })
               
                if(payment_method == 1){
                    setIsopen(true)
                }else if(payment_method == 5){
                    setIsGcash(true)
                }else if(payment_method == 6){
                    setIsBillease(true)
                } else {
                    setcyrtop(true)
                
                }
                resolve()
            }
            
        });
    });
    }

    const onClickWallet = async () => {
        if(metamask){
            window.ethereum.request({method:"eth_requestAccounts"}).
             then(result => {
                window.ethereum.request({ method: 'eth_chainId' }).then(
                    chain => {
                        apiClient.post(`/auth/register-wallet`, { 
                            wallet: result[0],
                            email: email,
                            password: password
                        }).then(response => {
                            if (response.status == 200) {
                                localStorage.setItem('wallet',result[0])
                                localStorage.setItem('chain',chain)
                                if (response.data.client.email == null && response.data.client.name == null && response.data.client.last_name== null) {
                                    setPayment_method(2)
                                    setPaymentType(['','Meta Mask','', '','', ''])
                                    alert("Only ETH payment is allowed")
                                }
                                Auth.storeAuth()
                                onChangeClient(response.data.client.id)
                                setIsLogin(true)
                                setUsers(response.data.client.id)
                                // Transaction added
                                setPayment_method(2)
                            }

                        }).catch(function (error) {
                            localStorage.removeItem("wallet")
                            localStorage.removeItem("chain")
                        })
                    }
                 );
             });
        }  else {
            window.open("https://metamask.io/download/")
        }
    } 
    const addUpsell = (e) => {
        if(upsellOptionId == e.target.getAttribute('data-option_id')){
            setWithUpsell(1);
            setUpsellOptionId(0)
            setUpsellPrice(0);
            setUpsellDescription('');
            setUpsellName('');
            setNewVal(parseFloat(props.vipdata.price) + parseFloat(props.vipdata.sf));
        } else {
            setWithUpsell(2);
            setUpsellName(e.target.getAttribute('data-name'));
            setUpsellOptionId(e.target.getAttribute('data-option_id'))
            setUpsellPrice( e.target.getAttribute('data-price'));
            setUpsellDescription( e.target.getAttribute('data-description'));
            setNewVal(parseFloat(props.vipdata.price) + parseFloat(props.vipdata.sf) + parseFloat(e.target.getAttribute('data-price')));
        }

        e.preventDefault();
    }

    useEffect(() => {
        if(props.vipdata.upsell == 2){
            getUpsellOptions();
        }
       
        //refresh page when pressed back button
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.reload());
        };
         apiClient.get(`${process.env.REACT_APP_API}/account/user`)
        .then( async res => {
            if(!localStorage.getItem('isAuth')){
                Auth.storeAuth()
            }
            setUsers(res.data.id)
            setIsLogin(true);
            reset();
        }).catch(e => {
            localStorage.removeItem("wallet")
            localStorage.removeItem('isAuth')
            Auth.deleteCookie('jwt')
            setIsLogin(false)
        });
       
        setNewVal(parseFloat(props.vipdata.price) + parseFloat(props.vipdata.sf) + parseFloat(upsellPrice));
       
    },[])
 
    return (
       
        <section className="item-details-area pt-5">
            <div className="container-fluid">
            <form onSubmit={handleSubmit(onCheckout)}  className="item-form no-hover text-dark">
                    <div className="row justify-content-md-center pt-3 pt-lg-5 pb-5">
                        <div className="col-md-12 p-0 m-0">
                            <div className="row justify-content-md-center">
                                <div className="col-lg-5 my-lg-0 my-2 mx-auto mx-lg-2 col-12 pt-3 rounded-lg border border-dark">
                                    {/* random art */}
                                    
                                    <input type="hidden" {...register("source")} value={props.vipdata.source} />
                                    <input type="hidden" {...register("vip")} value={props.vipdata.vip_id} />
                                    <input type="hidden" {...register("client_id")} defaultValue={users} />
                                    <input type="hidden" {...register("artist")} value={props.vipdata.artist_id} />
                                    <input type="hidden" {...register("art_id")} value={props.vipdata.art_id} />
                                    <input type="hidden" {...register("free")} value={props.vipdata.free} />
                                    <input type="hidden" {...register("project_id")} value={props.vipdata.project_id} />
                                    <input type="hidden" {...register("total")} value={newVal} />
                                    <input type="hidden" {...register("sf")} defaultValue={props.vipdata.sf} />
                                    <input type="hidden" {...register("payment_method")} value={payment_method} />
                                    <input type="hidden" {...register("price")} defaultValue={props.vipdata.price} />
                                    <input type="hidden" {...register("eth")} defaultValue={props.vipdata.eth_price}/> 
                                    <input type="hidden" {...register("sales_type")} defaultValue={props.vipdata.sales_type}/> 
                                    <input type="hidden" {...register("upsell")} value={withUpsell}/>
                                    <input type="hidden" {...register("upsell_desciprtion")} value={upsellDesciprtion}/>
                                    <input type="hidden" {...register("upsell_price")} value={upsellPrice}/>
                                    
                                    <strong >Details</strong>
                                    <div className="form-group mt-3"> 
                                        <div className="row">
                                            <div className="col-12 col-lg-5">
                                                {
                                                    props.vipdata.art_img_type == 'mp4' ?
                                                    <div className="image-wrapper" style={{background:"#000"}}>
                                                        <video preload="auto" muted= {true} autoPlay={false} controls= {false}  className="embed-responsive-item " 
                                                        src={props.vipdata.art_img} style={{width: "100%",height:"180px"}} ></video>
                                                    </div>
                                                    :  <img src={props.vipdata.art_img} className='img-fluid'></img>
                                                }
                                            </div>
                                            <div className="col-12 col-lg-7">
                                                <span className="font-weight-bold text-uppercase" dangerouslySetInnerHTML={{__html: props.vipdata.label}}></span><br></br>
                                                <span className="font-weight-boldx">Artist</span> : <span>{props.vipdata.artist}</span><br></br>
                                                <span className="font-weight-boldx">Price</span> : <span>Php {Math.trunc(props.vipdata.price).toLocaleString("en-US")} or {props.vipdata.eth_price}eth</span><br></br>
                                                {  
                                                props.vipdata.free != '' && props.vipdata.free != null ?
                                                    <>
                                                        <span className="font-weight-boldx">Free</span> : <span>{props.vipdata.free}</span>
                                                        <br></br>
                                                    </>
                                                    : ''
                                                }
                                                {
                                                    props.vipdata.merch != '' && props.vipdata.merch != null ?
                                                    <>
                                                        <a href="#" className="text-danger font-weight-bold" data-toggle="modal" data-target={`#modal-pomotion-${1}`} style={{color: "red"}}>
                                                            {props.vipdata.merch_label}
                                                        </a>
                                                        <p className='mt-0'> {props.vipdata.merch_details} </p>
                                                        <ModalPromo img={ props.vipdata.merch } id="1" /> 
                                                    </>
                                                    : ''
                                                }
                                            </div>
                                            { 
                                            props.vipdata.upsell == 2 ?
                                            <div className="col-12 pl-0">
                                                <hr></hr>
                                                <h5 className='text-danger'>{props.vipdata.upsel_label != '' && props.vipdata.upsel_label != null  ? props.vipdata.upsel_label : 'Optional Addition'}</h5>
                                                {
                                                    upsellOptions.map((val, id)=> { 
                                                        
                                                        return (
                                                            <div className='row border-top pt-2 pb-2'>
                                                                {
                                                                    val.image != '' &&  val.image != null ? 
                                                                    <div className="col-md-5">
                                                                        <img src={val.image_} class="img-fluid"></img>
                                                                    </div>
                                                                    :''
                                                                }
                                                               
                                                                <div className={'col-md-7'}>
                                                                    <p>
                                                                        <span className="font-weight-bold">{val.name}</span><br></br>
                                                                        <span className='text-danger'>Php{Math.trunc(val.price).toLocaleString("en-US")}</span><br></br>
                                                                        {val.description}
                                                                        <br></br>
                                                                        <button  onClick={e => addUpsell(e)} data-name={val.name} data-option_id={val.id} data-upsellOptionId={upsellOptionId} data-description={val.description} data-price={val.price} 
                                                                    className={`btn  btn-sm ${upsellOptionId == val.id ?  'btn-danger' : 'btn-dark'} mt-2 py-2`}>
                                                                        {upsellOptionId == val.id ?  'Remove' : 'Add'}
                                                                    </button>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                     )
                                                    })
                                                }
                                                
                                                
                                                
                                            </div>
                                            : ''  }
                                            
                                            <div className="col-12">
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 my-lg-0 my-2 col-12 mx-auto mx-lg-2 pt-3 rounded-lg border border-dark">
                                    
                                    <p><span className="font-weight-bold">Sub Total</span> : <span className="float-right">Php {Math.trunc(props.vipdata.price).toLocaleString("en-US")}</span></p>
                                    {
                                    withUpsell == 2 ?
                                    <p><span className="font-weight-bold">Additional Item<br></br>{upsellName}</span> : 
                                    <span className="float-right"><small>Php {Math.trunc(upsellPrice).toLocaleString("en-US")}</small></span> </p>
                                    : ''
                                    }
                                    <hr  style={{backgroundColor:"#fff"}}></hr>

                                    {       
                                                    props.vipdata.sf != 0 && payment_method != 2 ? <>
                                                    <p><span className="font-weight-bold">Shipping Fee</span> : <span className="float-right">Php {Math.trunc(props.vipdata.sf).toLocaleString("en-US")}</span></p>
                                                    <p className='text-danger'>Note: This shipping fee only includes shipping within the Philippines. For overseas shipping, we will contact the collector for the quote after their purchase</p>
                                                    </> : ''
                                    }

                                    <hr  style={{backgroundColor:"#fff"}}></hr>
                                    <p><span className="font-weight-bold">Total</span> : <span className="float-right">Php { payment_method == 2 ? props.vipdata.price.toLocaleString("en-US") : newVal.toLocaleString("en-US")}</span></p>
                                    <hr  style={{backgroundColor:"#fff"}}></hr>

                                    <strong>Payment Method</strong>
                                    <div className='payment_container d-flex flex-column-reverse'>
                                    {
                                    !props.art_bundle_qty ? 
                                        payment_methods.map((level, index) => {
                                            return (
                                                level ? (
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" onChange={ onPaymentMethod } name="payment_methodx" id={`rd_${index}`} value={index + 1} checked={payment_method == index + 1} />
                                                        <label className="form-check-label" for={`rd_${index}`}>
                                                        <img style={{width: "30px"}} className="mr-2" src={`/img/payment_method_dark_${index}.png`}/>{level} 
                                                        </label>
                                                    </div>
                                                ) : ""
                                            )
                                            }) 
                                            : paymentType.map((level, index) => {
                                                return level !='' ? 
                                                (
                                                    <div className={"form-check form-check-"+index}>
                                                        <input className="form-check-input" type="radio" onChange={ onPaymentMethod } name="payment_methodx" id={`rd_${index}`} value={index + 1} checked={payment_method == index +1} />
                                                        <label className="form-check-label" for={`rd_${index}`}>
                                                        <img style={{width: "28px"}} className="mr-2" src={`/img/payment_method_dark_${index}.png`}/>{level} 
                                                        </label>
                                                    </div>)
                                                    : ""
                                            })
                                    }
                                    </div>
                                    <hr  style={{backgroundColor:"#fff"}}></hr> 
                                    <div className='alert chkout'>
                                        Note: Bank Transfer or Deposit accepted. Pls email 
                                        <a target="_blank" href="mailto:concierge@scarletbox.io"> concierge@scarletbox.io</a> for bank details. 
                                        {
                                            payment_method == 2 && withUpsell == 2 ?  
                                            <p className='text-danger'>
                                                We will contact you on how to pay your additional item
                                            </p> : ''
                                        }
                                        
                                    </div>
                                    {
                                        !isLogin || !users ? (
                                            <>
                                                <div className="form-group form-check">
                                                    <input type="checkbox" className="form-check-input" id='withAccount' defaultChecked={with_account} onClick={e => onChangeAccount(e)} />
                                                    <label className="form-check-label ml-1 mt-1 text-danger" for="withAccount" >I already have an account</label>
                                                </div>
                                                
                                                {
                                                    with_account ? (
                                                        <div className='alert chkout'>
                                                            <Alert show={show} variant="danger">
                                                                <p>
                                                                    Invalid Email or Password
                                                                </p>
                                                            </Alert>
                                                            <p className='font-weight-bold'>Log in or Create Account to Checkout</p>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group mt-0">
                                                                        <label className="text-dark font-weight-bold">Email</label>

                                                                    <input type="email" className="form-control" name="email"  defaultValue={email}  onChange={e => setEmail(e.target.value)} placeholder="Enter your Email" required="required" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mt-3">
                                                                        <label className="text-dark font-weight-bold">Password</label>

                                                                        <input type={passwordShown ? "text" : "password"}  className="form-control" name="password" onChange={e => setPassword(e.target.value)} placeholder="Enter your Password" required="required" />
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className="form-group form-check">
                                                                                <input type="checkbox" className="form-check-input checkboxx" id='showPassword' onClick={togglePasswordVisiblity} />
                                                                                <label className="form-check-label ml-1 mt-1" for="showPassword">Show Password</label>
                                                                            </div>
                                                                    
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className="text-right">
                                                                                <a href='/auth/forget_password' target='blank'>Forgot Password</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                            
                                                                </div>
                                                        
                                                                <div className='col-12'>
                                                                    <button className="btn w-100 btn-dark" >
                                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Login and checkout
                                                                    </button>
                                                                </div>
                                                                {
                                                                   !props.addon ? 
                                                                    <>
                                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                                    <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                                                                    <span className='px-4'>OR</span> 
                                                                    <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                                                                </div>
                                                                
                                                                <div className="col-12">
                                                                    <button className="btn btn-dark w-100" type="button" onClick={onClickWallet} >Connect to Wallet</button>
                                                                </div>
                                                                    </>
                                                                    : ''
                                                                }
                                                                
                                                                <div className="col-12 pt-3">
                                                                    {/* <p className="text-center text-dark" style={{ fontSize: '15px' }}>*Please register if you do not have an account <a className='text-danger' onClick={onClickRegisterLink}>here</a>.</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='alert chkout'>
                                                            <p className='font-weight-bold'>Register to proceed Checkout</p>
                                                            <Alert show={show} variant="danger">
                                                                <p>
                                                                    {errormsg}
                                                                </p>
                                                            </Alert>
                                                            <div className="row justify-content-center">
                                                            
                                                                <div className="col-12">
                                                                    <div className="form-group mt-2">
                                                                        <label className="text-dark">First Name</label>
                                                                        <input type="text" className={`form-control`} name="name" onChange={ e =>setName(e.target.value) }  />
                                                                        
                                                                    </div>
                                                                </div> 
                                                                <div className="col-12">
                                                                    <div className="form-group mt-2">
                                                                        <label className="text-dark">Last Name </label>
                                                                        <input type="text" className={`form-control`} name="last_name" onChange={ e =>setLastname(e.target.value) }  />
                                                                    </div>
                                                                </div> 
                                                            
                                                                <div className="col-md-6 col-12 pl-0 d-none">
                                                                    <div className="form-group mt-2">
                                                                        <label className="text-dark">Username &#40; Optional &#41;</label>
                                                                        {/* <input type="text" className={`form-control ${ error && error.errors.username ? "is-invalid" : ""}`} name="username" onChange={ e =>setUsername(e.target.value) }  />
                                                                        {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""} */}
                                                                        <input type="text" className="form-control" name="username" onChange={ e =>setUsername(e.target.value) }  />
                                                                    </div>
                                                                </div> 
                                                                <div className="col-md-12">
                                                                    <div className="form-group mt-2">
                                                                        <label className="text-dark">Email</label>
                                                                        <input type="email" className={`form-control`} name="email"onChange={ e =>setEmail(e.target.value) } required="required"/>
                                                                    </div>
                                                                </div> 
                                                            
                                                                <div className="col-12 row">
                                                                    <div className="form-group col-10 px-0">
                                                                        <label className="text-dark">Password</label>
                                                                        <input type={passwordType} className={`form-control`} name="password" onChange={ e =>setPassword(e.target.value) }  required="required"/>
                                                                    </div>
                                                                    <div className="form-group col-2 mt-4">
                                                                    <button type="button" className="btn btn-dark btn-smaller" onClick={togglePassword}>
                                                                        { passwordType==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                                    </button>
                                                                    </div>
                                                                </div> 
                                                            
                                                                <div className="col-12 row">
                                                                    <div className="form-group col-10 px-0">
                                                                        <label className="text-dark">Confirm Password</label>
                                                                        <input type={confirmpasswordType} className={`form-control`} name="confirm_password" onChange={ e =>setConfirmPassword(e.target.value) }  required="required"/>
                                                                    </div>
                                                                    <div className="form-group col-2 mt-4">
                                                                        <button type="button" className="btn btn-dark btn-smaller"  onClick={toggleConfirmPassword}>
                                                                            { confirmpasswordType ==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                                        </button>
                                                                    </div>
                                                                </div> 
                                                                <div className="col-12">
                                                                    <div className="form-group mt-1 ml-3">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input " type="checkbox" name="inlineRadioOptions" id="inlineRadio1" onChange={ e =>setTerms(e.target.value) }/>
                                                                            <label className="form-check-label ml-1 mt-1" htmlFor="inlineRadio1">I have read and agreed to the <a target="blank" href="https://scarletbox.io/privacy"> Terms and Conditions</a></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12'>
                                                                    <button className="btn w-100 btn-dark" >
                                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Register and checkout
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                         : ""
                                    }

                                    {
                                        !isLogin ? (
                                            <div className='col-12' style={{padding: "1rem 2.5rem"}}>
                                                <a href="#" onClick={back_url} className="btn btn-block btn-dark">
                                                    Cancel
                                                </a>
                                            </div>
                                        ): (
                                            <div className="row justify-content-center">
                                                <div className='col-6 p-1'>
                                                    <button className="btn btn-block btn-dark" >
                                                    {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}Checkout
                                                    </button>
                                                </div>
                                                <div className='col-6 p-1'>
                                                <a href="#" onClick={back_url} className="btn btn-block btn-dark">
                                                    Cancel
                                                </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                    

                                    
                                    {/* <div className='py-2'>
                                    <p className="text-justify px-2" style={{ fontSize: '13px' }}><i>Disclaimer: All sales will occur on the Scarletbox Platform. GCash makes no representations or warranties, express or implied, regarding the Scarletbox Platform and/or any material contained therein. All transactions shall be at your own, exclusive risk.</i></p>
                                    </div> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {
                !isLogin && showLogin ? <CheckoutAuth showmodal={showLogin} 
                                            onHide={closeModal} 
                                            mail={getValues("email")} 
                                            firstname={getValues("shipping_first_name")} 
                                            lastname={getValues("shipping_last_name")} 
                                            mobileno={getValues("mobile")} 
                                            changeClient={onChangeClient}
                                            setPaymentType={setPaymentType}
                                            setPayment_method={setPayment_method}
                                            showFiat={setIsopen}
                                            showCrypto={setcyrtop}
                                            showGcash={setIsGcash}
                                            showBillease={setIsBillease}
                                            paymentMethod={payment_method}
                                            loginData={loginData}
                                            setTrno={setTrno}
                                            setPricefiat={pricefiat}
                                         /> 
                : ""
            }

            {isBillease  
                ? <BillEase user={users} artist={props.artist} type={props.type} show={isBillease} onHide={closeModal} tr_no={trno} price_fiat={pricefiat} project_id={props.project_id} art_bundle_qty={props.art_bundle_qty}/> 
                : ''
            } 
            {isGcash  
                ? <Gcash upsell_price={upsellPrice} sales_type={props.vipdata.sales_type} eth_data={ethData}  deliveryfee={props.vipdata.sf} isbundle={props.vipdata.bundle}  user={users} artist={props.artist} type={props.type} show={isGcash} onHide={closeModal} tr_no={trno} price_fiat={pricefiat} project_id={props.project_id} art_bundle_qty={props.art_bundle_qty}/> 
                : ''
            }
            {isopen  
                ? <Fiat sales_type={props.vipdata.sales_type} eth_data={ethData}  deliveryfee={props.vipdata.sf} user={users} artist={props.artist} type={props.type} show={isopen} onHide={closeModal} tr_no={trno} price_fiat={pricefiat} project_id={props.project_id} art_bundle_qty={props.art_bundle_qty}/> 
                : ''
            }
            <CryptoPayment user={users} isbundle={props.vipdata.bundle} tr_no={trno} price_fiat={pricefiat} eth_data={ethData} show={cyrtop} onHide={closeModal} />
            
        </section>
         
       
       
    );

}
 
export default Checkout;