import React from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Banner from "../../components/Banner/Banner";
import ModalMenu from "../../components/Modal/ModalMenu";
import BannerSale from "../../components/Banner/BannerSale";
import MyCollections from "../../components/MyCollections/MyCollections";
import LoginPage from "../../components/Login/LoginPage";

import Auth from "../../helpers/auth/Auth";

const CollectionPage = () => {

    if (!Auth.isAuthenticated() ) {
        return(<>
            <LoginPage/>
        </>)
    }

    return (
        <>
            <Header/>
            <BannerSale title="My Collections"/>
            <MyCollections/>
            <ModalMenu />
            <Footer/>
        </>
    )


}

export default CollectionPage;