import React, {useState, useEffect} from "react";

import apiClient from "../../helpers/api";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import ModalLogin from "../../components/Modal/ModalLogin";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";

import Auction from "../../components/Auctions/Auction";
import BannerAuction from "../../components/Banner/BannerAuctions";
import {Helmet} from "react-helmet";
import { isMobile } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import { HeaderContext } from "../../helpers/headerContext";
import AuctionWinner from "../../components/Auctions/AuctionWinner";

const AuctionPage = (props) => {
    
    const [isLoaded, setIsLoaded]  = useState(false)
    const [isError, setIsError]    = useState(false)
    const [banners, setBanners]  = useState('')
    const [auction_id, setAuctionId] = useState();
    const [changeClient, setChangeClient] = useState(false);
    const [base_price, setBasePrice] = useState(0);
    const [base_price_eth, setBasePriceEth] = useState(0);
    const [product_details, setProductDetails] = useState('');
    const [shipping_details, setShippingDetails] = useState('');
    const [control_number, setControlNumber] = useState('');
    const [free, setFree] = useState('');
    const [auction_status, setAuctionStatus] = useState(0);
    const [auction_allowed, setAuctionAllowed] = useState(false);
    const [layout,setLayout] = useState(1)
    const [showTimer,setShowTimer] = useState('')
    const [isStarted,SetIsStarted] = useState(false)
    const [is_ended, setIsEnded]       = useState(false);
    const [id_upload,setIdUpload] = useState(false);
    
    const changeHeader = () =>{ 
        return <Header/>
    }

    // get auction details, banner, merch, etc
    const getAuction = async () => {
        try {
            apiClient.get(`${process.env.REACT_APP_API}/auctions/${props.match.params.url}/${props.match.params.code}`).then(res => {
                console.log(res.data.result);
                setBanners(isMobile ? res.data.result.banner_mobile : res.data.result.banner)
                setAuctionId(res.data.result.id)
                setBasePrice(res.data.result.base_price)
                setBasePriceEth(res.data.result.base_price_eth)
                setProductDetails(res.data.result.product_details)
                setShippingDetails(res.data.result.shipping_details)
                setFree(res.data.result.free)
                setIsLoaded(true)
                setAuctionStatus(res.data.result.status)
                setAuctionAllowed(res.data.result.allowed)
                setLayout(res.data.result.layout)
                setShowTimer(res.data.result.show_timer)
                SetIsStarted(res.data.result.is_started)
                setIsEnded(res.data.result.is_ended);
                setIdUpload(res.data.result.id_upload)
            }).catch( err => {
                console.log(err);
                setIsError(true)
            })
        } catch (error) {
            setIsError(true)
        }
    }

    useEffect(() => {
        changeHeader()
    }, [changeClient]);

    useEffect(() => {
        var query  = new URLSearchParams(props.location.search);

        if (query.get("control_number")) {
            setControlNumber(query.get("control_number"))
        }

        getAuction()
    }, []);

    if (isError) {
        return <PageNotFound url={props.location.pathname}/>
    }

  

    return (
        <div className="main">
            <PageLoading />
            {changeHeader()}
            {isLoaded ? <BannerAuction banner={banners}/> : <section className="carousel-bg align-items-center pb-0"><Skeleton height={200}/></section>}
            <HeaderContext.Provider value={{ changeClient, setChangeClient}}>
                {
                    control_number ? (
                        <AuctionWinner
                            auction_url={props.match.params.url}
                            auction_code={props.match.params.code} 
                            base_price={base_price} 
                            base_price_eth={base_price_eth}
                            product_details={product_details}
                            shipping_details={shipping_details} 
                            control_number={control_number}
                        />
                        ) : (
                           
                                !is_ended ? <Auction
                            isStarted={isStarted}
                            id_upload={id_upload}
                            collection_={props.match.params.url}
                            showTimer={showTimer} 
                            layout={layout} 
                            auction_url={props.match.params.url}
                            auction_code={props.match.params.code} 
                            base_price={base_price} 
                            base_price_eth={base_price_eth}
                            product_details={product_details}
                            shipping_details={shipping_details}
                            auction_status={auction_status} 
                            auction_allowed={auction_allowed}
                            free={free}
                        /> : <section className="item-details-area pt-5">
                                <div className='container'>
                                    <div class="alert alert-danger">Aution Ended</div>
                                </div></section>
                    
                     )
                }
            </HeaderContext.Provider>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <ModalLogin />
            <Scrollup />
        </div>
    );
  
}
 
export default AuctionPage;