import React, { useEffect, useState } from "react";
import Images from "./Details/Images";
import Form from "./Details/Form";
import { Modal } from "react-bootstrap";
import Bidder from "./Details/Tab/Bidder";
import apiClient from "../../helpers/api";
import AuctionDetailsSkeleton from "../../Skeleton/Auctions/AuctionDetailsSkeleton";

const AuctionDetails = (props) => {
    const partnerCode = props.auction_code
    const [showMerch,setShowMerch] = useState(false)
    const [isLoaded,setIsLoaded] = useState(false)
    const [merchImage,setMerchImage] = useState('')
    const [showOther, setShowOther] = useState(false);
    const [otherImage, setOtherImage] = useState('');
    const [otherType, setOtherType] = useState('');
    const [art,setArt] = useState({})
    const [bids,setBidders] = useState([])
    const [showBidLogTime,setShowBidLogTime] = useState(0)

    const [bidAmount,setBidAmount] = useState({})
    const [highest_bid,sethighest_bid] = useState('');
    const [collection,setCollection] = useState({})
    const [increamentLabel, setIncreamentLabel] = useState('');
    const refireData = () => {
      getDetail();
    }

    const getDetail = () => {
        try {
            apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_art_details/${props.art_id}`).then(res => {
                setIncreamentLabel(res.data.increament_label)
                setArt(res.data.art) 
                setBidders(res.data.bids);
                setIsLoaded(true)
                sethighest_bid(res.data.highest_bid)
                setBidAmount(res.data.amounts)
                setShowBidLogTime(res.data.collection.show_bid_log_time)
                setCollection(res.data.collection)
            }).catch( err => {
             
            })
        } catch(error){
            
        }

    }
    

    const closeModal = () => {
        setShowMerch(false)
        setShowOther(false)
    }

    const showMerchBtn = (merch) => {
        setMerchImage(merch);
        setShowMerch(true)
    }

    const showOtherImagesBtn = (type,source) => {
        setOtherImage(source)
        setOtherType(type)
        setShowOther(true)
    }

    useEffect(()=> {
        getDetail()
    },[])

    return (
        <section id="post-735" className="post-735 auction type-auction status-publish has-post-thumbnail hentry collections-doodles owner-themeland creator-sara">
        {
            isLoaded ? 
            <div className="container">
                <div className="row justify-content-between item-details-area">
                    <Images showOtherImagesBtn={showOtherImagesBtn} art={art} />
                    <Form increamentLabel={increamentLabel} refireData={refireData} partnerCode={partnerCode} code={partnerCode}  bidAmount={bidAmount}  showMerchBtn={showMerchBtn} art={art}  base_price={parseInt(art.bid_start)} highest_offer={highest_bid} collection={collection} />
                    <div className="col-12 mt-5">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="description-tab" data-toggle="tab" data-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Description</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="bidder-tab" data-toggle="tab" data-target="#bidder" type="button" role="tab" aria-controls="bidder" aria-selected="false">Bid History</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">.
                        <p className="my-2 " dangerouslySetInnerHTML={{ __html: art.description}}></p>
                        </div>
                        <Bidder bids={bids} show_bid_log_time={showBidLogTime} /> 
                        </div>
                    </div>
                </div>
            </div> 
            : <AuctionDetailsSkeleton />
        }

        <Modal size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered show={showMerch}>
            <Modal.Header className="pb-0">
                <p className="font-weight-bold pt-3"></p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
            </Modal.Header>
            <Modal.Body className="pt-0">
                <img alt="img" className='img-fluid' src={merchImage}></img>
            </Modal.Body>
        </Modal>

        <Modal size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered show={showOther}>
            <Modal.Header className="pb-0">
                <p className="font-weight-bold pt-3"></p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
            </Modal.Header>
            <Modal.Body className="pt-0">
                {
                    otherType ==  'mp4' ? 
                    <video preload="auto" muted= {true} autoPlay={false} controls= {true}  className="embed-responsive-item " 
                                            src={otherImage} style={{width: "100%",height:"100%"}} ></video> :
                    <img alt="img" className='img-fluid' src={otherImage}></img  >
                }
            </Modal.Body>
        </Modal>
        </section>
    )
}

export default AuctionDetails