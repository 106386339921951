import React, { useEffect, useState } from "react";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import Footer from "../../components/Footer/Footer";
import Scrollup from "../../components/Scrollup/Scrollup";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import ModalLogin from "../../components/Modal/ModalLogin";
import Header from "../../components/Header/Header";
import Skeleton from "react-loading-skeleton";
import BannerAuction from "../../components/Banner/BannerAuctions";
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";
import AuctionList from "../../components/Auctions/AuctionList";
import apiClient from '../../helpers/api';
import { isMobile } from "react-device-detect";

const ListAuctionPage = (props) => {
    const [isLoaded,setIsLoaded] = useState(false);
    const [banners, setBanners]  = useState('')
    const [isError,setIsError] = useState(false);
    const getAuctionSetting = () => {
        try{
            apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_settings/1`)
            .then( res => {
                if(res.data.result.status == 3){
                    setBanners(res.data.result.banner)
                } else {
                    setBanners(isMobile ? res.data.result.mobile_banner : res.data.result.banner)
                }
                setIsLoaded(true)
            }).catch( err => {
                setIsError(true)
            })
        } catch(error){
            setIsError(true)
        }


    }

    
    useEffect(() => {
        getAuctionSetting();
    }, [])

    if (isError) {
        return <PageNotFound url={props.location.pathname}/>
    }

    return(
        <div className="main">
            <PageLoading />
            <Header/>
            {isLoaded ? <BannerAuction banner={banners}/> : <section className="carousel-bg align-items-center pb-0"><Skeleton height={200}/></section>}
            <AuctionList/>
            <Footer/>
            <ModalSearch />
            <ModalMenu />
            <ModalLogin />
            <Scrollup /> 
        </div>
    )
}

export default ListAuctionPage;