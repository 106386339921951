import React, {useState} from "react";
import { useForm } from "react-hook-form";
import apiClient from "../../../helpers/api";
import { Modal } from "react-bootstrap";

const Security = () => {
    const { handleSubmit, formState } = useForm();
    const [show, setShow] = useState(false)

    const [current_pass, setCurrentPass] = useState('');
    const [new_pass, setNewPass]         = useState('');
    const [confirm_pass, setConfirmPass] = useState(''); 

    const [error, setError] = useState(); 

    const { isSubmitting } = formState;

    const onSubmit = (data) => {

        return new Promise(resolve => {
            apiClient.post('/account/update-password', {
                old_password: current_pass,
                new_password: new_pass,
                confirm_password: confirm_pass,
            })
            .then(res => {
              
                setShow(true)
                
            }).catch(e => {
                console.log(e);
                setError(e.response.data)
                console.log(error);
                resolve();
                  
            })

        })


    }

    return (
        <div className="px-2 px-md-5 pb-3">
            <h5 className="font-weight-bold text-dark font-family-normal">Security</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label>CURRENT PASSWORD</label>
                            <input type="password" className="form-control rounded-0" name="old_pass" onChange={e => setCurrentPass(e.target.value)} />
                            {error ? ( <p className="text-red"> {error.errors.old_password} </p>) : ""}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group mt-2">
                            <label>NEW PASSWORD</label>
                            <input type="password" className="form-control rounded-0" name="new_pass" onChange={e => setNewPass(e.target.value)} />
                            {error ? ( <p className="text-red"> {error.errors.new_password} </p>) : ""}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group mt-2">
                            <label>CONFIRM NEW PASSWORD</label>
                            <input type="password" className="form-control rounded-0" name="confirm_pass" onChange={e => setConfirmPass(e.target.value)} />
                            {error ? ( <p className="text-red"> {error.errors.confirm_password} </p>) : ""}
                        </div>
                    </div>
                    <div className="col-12 text-right">
                        <button className="btn btn-dark mt-3 mt-sm-4" type="submit">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            UPDATE
                        </button>
                    </div>
                </div>
            </form>
            <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="">
                        <h5 className="text-center text-normal">Successfully Changed Password</h5>
                        <p>You will need to log in again using your new password. Thank you</p>
                    <br/>
                    <button className="btn btn-smaller btn-primary btn-block" onClick={() => { localStorage.removeItem('isAuth')
            window.location.href = "/";}}>CLOSE</button>
                </div>
            </Modal.Body>
        </Modal>
        </div>
    );
}
 
export default Security;