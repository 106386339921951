import React, { Component, useState, useEffect } from 'react';

import { Modal, Button, Form} from "react-bootstrap";
import apiClient from '../../helpers/api';
import { useParams } from 'react-router';
import PageNotFound from '../PageNotFound/PageNotFoundPage'
import Web3 from 'web3';

const BulkReveal = () => {
   
    const [details, setDetails] = useState({})
    const [AllowedChainId, setAllowedChainId] = useState(process.env.REACT_APP_CHAINID)
    const [AllowedChainName, setAllowedChainName] = useState('Mainet')
    //const AllowedChainId = process.env.REACT_APP_CHAINID;
    const [errorMessage,setErrorMessage] = useState(null);
    const [errorEthMessage,setErrorEthMessage] = useState(null);
    const [defaultAccount,setDefaultAccount] = useState(null);
    const [clientAddress,setClientAddress] = useState(null);
    const [defaultChain,setDefaultChain] = useState(null);
    const [isPaying,setIsPaying] = useState(false);
    const [isallowed,setIsAllowed] = useState(false);
    const [ABI,setABI] = useState(null);
    const [contractAddress,setContractAddress] = useState(null);
    
    const [projectId,setProjectId] = useState('');
    const [artist,setArtist] = useState('');
    const [artistId,setArtistId] = useState('');
    const [clientId,setClientId] = useState('');
    const [totalNFT,setTotalNFT] = useState('');

    const [projects, setProjects] = useState();
    const [projectImg, setProjectImg] = useState('https://app.scarletbox.io/storage/baners/HomeMainBanner-07282301223664c3509c00b11.jpg');
    

    var contract = null;
   
    async function connectWallet () {
       
        window.ethereum.request({method:"eth_requestAccounts"}).
        then(result => {
            setDefaultAccount(result[0])
            window.ethereum.request({ method: 'eth_chainId' }).then(
                chain => {
                    if(chain === AllowedChainId){
                        setDefaultChain(chain)
                        setIsAllowed(true)
                    } else {
                        setErrorMessage('Please use '+AllowedChainName+' only');
                        setIsAllowed(false)
                    }
                }
            );
        })
    }
   
    try{

        window.ethereum.on('chainChanged', (chainId) => {
        if(chainId === AllowedChainId){
                setDefaultChain(chainId)
                setErrorMessage(null);
                setIsAllowed(true)
            } else {
                setErrorMessage('Please use '+AllowedChainName+'  only');
                setIsAllowed(false)
            }
        }); 
        
        window.ethereum.on('accountsChanged', (accounts) => {
        setDefaultAccount(accounts[0])
        });
    } catch(e){ }

    const getAllProjects = () => {
        apiClient.get('/account/user')
        .then(res => {
            setClientId(res.data.id)
            setClientAddress(res.data.wallet)
            apiClient.get(`/account/collections/bundle-reveal/${res.data.id}`)
            .then(res => {
                setProjects(res.data)
            })
        }).catch(e => {
            console.log(e);
        })
    }

    async function onChangeProject(e){

        if(!window.ethereum){
            setErrorMessage('Please Install Metamask');
        } else {

        }

        if(e.target.selectedOptions[0].getAttribute('data-chain') != ''){
            setAllowedChainId(e.target.selectedOptions[0].getAttribute('data-chain') )
            setAllowedChainName(e.target.selectedOptions[0].getAttribute('data-chainname') )
        }
        setProjectImg(e.target.selectedOptions[0].getAttribute('data-photo') );
        setProjectId(e.target.value)
        setArtist( e.target.selectedOptions[0].getAttribute('data-artist') )
        setTotalNFT( e.target.selectedOptions[0].getAttribute('data-total') )
        setArtistId( e.target.selectedOptions[0].getAttribute('data-artistid') )
        
        var res = await apiClient.post(`${process.env.REACT_APP_API}/sbx/project/get`,{
            id: e.target.selectedOptions[0].getAttribute('data-projectartistid')
        })
       
        setContractAddress(res.data.data.contract_address)
        setABI(JSON.parse(res.data.data.abi))
     };

    useEffect(() => {

        if(!window.ethereum){
            setErrorMessage('Please install metamask to mint this NFT');
        } 
        
        getAllProjects()

    }, details)

    async function mint(){
        setIsPaying(true)
        setErrorMessage("Please dont refresh or leave the site while the transaction is on progress")
        try{
            var x = await apiClient.post(`${process.env.REACT_APP_API}/account/collections/bundle-process/get_arts`, {
                project_id: projectId,
                artist_id: artistId,
                client_id: clientId,
            })
            try{
                var web3        = new Web3(window.ethereum);
                contract        = new web3.eth.Contract(ABI,contractAddress);
                
                var t = await contract.methods.fiatBulkmint(defaultAccount).send({from:defaultAccount});

                try{
                    var y = await apiClient.post(`${process.env.REACT_APP_API}/account/collections/bundle-process/update_arts`, {
                        id: x.data.bundle_id,
                        tr_no : t.transactionHash,
                        wallet_address:defaultAccount,
                        contract_address:contractAddress
                    })

                    alert('Bulk minted succesfully')
                    window.location.reload()
                } catch(err){
                    if(err.code == 4001){
                        setErrorMessage(err.message)
                    } else {
                        setErrorMessage("transaction failed updating api")
                    }
                    setIsPaying(false)
                }
            }catch(err){

                setIsPaying(false)
                setErrorMessage(err.message);
            }
           

        }catch(err){

            setIsPaying(false)
            setErrorMessage("no data found");
        }
    }

    return (
        <section className="item-details-area mt-1">
            <div className="container">
                <div className="row xbtk">
                    <div className='col-12 rounded-lg border border-dark p-2'>
                        <div className='my-3'>
                            <div className='card-body p-0'>
                                <div className='row justify-content-md-center'>
                                    <div className="col-lg-5 col-12 pt-3 pb-4">
                                    <img src={projectImg} alt="art" className="img-fluid"></img>
                                    </div>
                                    <div className="col-lg-7 col-12 pt-3 pb-4">
                                        <div className="content">
                                            <h3 className="mt-md-0 mt-2 mb-3 font-family-normal text-dark">Bulk Reveal</h3>
                                            
                                            <div className="form-group">
                                               <label className="text-dark">Select Project</label>
                                                <select className="form-control"  id="city" onChange={onChangeProject}>
                                                <option value=""></option>
                                                        {
                                                           projects ? projects.map((val, id) => {
                                                            return (
                                                                <option data-photo={val.photo} data-chainname={val.chain_name} data-chain={val.chain_id} data-projectartistid={val.project_artist_id} data-artistid={val.artist_id} data-total={val.total}   data-artist={val.artist} value={val.id}>{val.project_name}({val.artist})</option>
                                                            )
                                                            }) : ""
                                                        }
                                                </select>
                                            </div>
                                            {artist ?
                                            <>
                                            <p className="mt-md-0 mt-2 mb-3 text-dark">Artist    : <strong>{artist}</strong> </p>
                                            <p className="mt-md-0 mt-2 mb-3 text-dark">Total NFT : <strong>{totalNFT}</strong> </p>
                                            <p className="mt-md-0 mt-2 mb-3 text-dark">Registered Wallet : <strong>{clientAddress}</strong> </p>
                                            <hr className='border border-dark'></hr>
                                            </>
                                            : ''}
                                            {errorMessage ? <p className=" text-danger">{errorMessage}</p> : ''}
                                            {projectId ?
                                                !defaultAccount ?
                                                    <Button variant="dark"  onClick={connectWallet}>Connect Wallet</Button>
                                                    :
                                                    
                                                        isallowed ? 
                                                        <Button variant="dark"  onClick={mint}>
                                                        {isPaying && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                        Mint</Button>
                                                        : ''
                                                    
                                                    
                                            : ''}
                                            <a className="btn btn-dark ml-2" href='/account/collections'>Back</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );

}

export default BulkReveal;