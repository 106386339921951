import React, { useEffect } from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './UseCountdown';



  const ShowCounter = ({ days, hours, minutes, seconds,show }) => {
    return (
      <>
     <div className={`countdown-times countdown-pri  mt-1 mb-2 ${show}`}>
        <div className="countdown counter-time d-flex ">
            <DateTimeDisplay value={days} type={'d'} isDanger={days <= 3} />
            <DateTimeDisplay value={hours} type={'h'} isDanger={days <= 3}  />  
            <DateTimeDisplay value={minutes} type={'m'} isDanger={days <= 3}  /> 
            <DateTimeDisplay value={seconds} type={'s'} isDanger={days <= 3}   /> 
            
        </div>
    </div>
    </>
    );
  };

 

const CountdownTimer = ({show, targetDate,id,changeIsEnded }) => {
  const [days, hours, minutes, seconds,total] = useCountdown(targetDate);
 
  useEffect(() => {
    if(total <= 0){
      changeIsEnded(id)
    }
  });

  if (days + hours + minutes + seconds <= 0) {
   return <div ></div> 
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        show={show}
      />
    );

    

  }


 

};

export default CountdownTimer;