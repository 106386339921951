import React, {useState, useEffect} from 'react';
import apiClient from '../../helpers/api';
import Web3 from 'web3';
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from "../../components/Modal/ModalSearch";
import ModalMenu from "../../components/Modal/ModalMenu";
import Scrollup from "../../components/Scrollup/Scrollup";
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, Button, Form} from "react-bootstrap";
const MintTransfer = (props) => {

    let {id} =props.match.params.id;
    const [details, setDetails] = useState({})
    const [users, setUsers] = useState({})
    const [redirect,setRedirect] = useState(Boolean);

    const AllowedChainId = process.env.REACT_APP_CHAINID;
    const [errorMessage,setErrorMessage] = useState(null);
    const [errorEthMessage,setErrorEthMessage] = useState(null);
    const [defaultAccount,setDefaultAccount] = useState(null);
    const [defaultChain,setDefaultChain] = useState(null);
    const [isPaying,setIsPaying] = useState(false);

    const [metaAddress,setMetaAddress] = useState(null);
    

    const [abi,setAbi] = useState(null);
    const [contractAddress,setContractAddress] = useState(null);
    const [artCode,setArtCode] = useState(null);
   
    var contract = null;
    try{

        window.ethereum.on('chainChanged', (chainId) => {
        if(chainId === AllowedChainId){
                setDefaultChain(chainId)
                setErrorMessage(null);
            } else {
                setErrorMessage('Please use mainet only');
            }
        });
        
        window.ethereum.on('accountsChanged', (accounts) => {
        setDefaultAccount(accounts[0])
        });
    } catch(e){ }
  
    
    useEffect(() => {

        

        get_details()
        
        if(!window.ethereum){
            setErrorMessage('Please install metamask to mint this NFT');
        } 
     
    },[])

    var contract = null;
    async function connectWallet () {
       
        window.ethereum.request({method:"eth_requestAccounts"}).
        then(result => {
            setDefaultAccount(result[0])
            window.ethereum.request({ method: 'eth_chainId' }).then(
                chain => {
                    if(chain === AllowedChainId){
                        setDefaultChain(chain)
                    } else {
                        setErrorMessage('Please use mainet only');
                    }
                }
            );
        }) 
    }

    async  function get_details(){
        try{
            var get_user = await apiClient.get('/account/user');
            if(get_user.data.role ==3){

                try{
                    var $oder_details = await apiClient.get(`/sbx/mint/${props.match.params.id}/${get_user.data.id}`)
                    .then(res => {
                       
                        setDetails(res.data)
                        setArtCode(res.data.art_code)
                        setAbi(JSON.parse(res.data.abi))
                        setContractAddress(res.data.contract_address)

                    });
                } catch(e) {
                    setRedirect(true)
                }
                
            }
            
        } catch(e) {
            
            setRedirect(true)
        }
    }


    async function mint_(){
      
        if(metaAddress == null || metaAddress == ''){
            setErrorEthMessage('Client Meta Address is required')
        } else {
            setIsPaying(true);
            setErrorEthMessage('Please dont refresh or leave the site while the transaction is on progress')
            var web3        = new Web3(window.ethereum);

           
            contract        = new web3.eth.Contract(abi,contractAddress);
            var _mintAmount = 1;
            var mintRate    = 0;
    
            var totalAmount = mintRate;

           
            try{
                var trx = await contract.methods.mint(defaultAccount,_mintAmount,artCode).send({from:defaultAccount,value:String(totalAmount)})
                try{
                    var res = await apiClient.post(`${process.env.REACT_APP_API}/account/collections/process`,{
                        id:props.match.params.id,
                        tr_hash:trx.transactionHash,
                        wallet_address:defaultAccount,
                        contract_address:contractAddress,
                        art_id:details.art_id
                    });

                    try{
                       var transfer = await contract.methods.transferFrom(defaultAccount,metaAddress,artCode).send({from:defaultAccount})

                       alert("Minted and transfer Successfully")
                       window.location.reload()
                    } catch(e){
                        if(e.code === 4001){
                            setErrorEthMessage(e.message)
                        } else {
                           setErrorEthMessage('Transaction failed')
                        }
                       setIsPaying(false);
                    }
                } catch (e){
                    if(e.code === 4001){
                        setErrorEthMessage(e.message)
                    } else {
                        setErrorEthMessage('Transaction failed')
                    }
                    setIsPaying(false);
                }
                

               

            } catch(e){
                if(e.code === 4001){
                    setErrorEthMessage(e.message)
                } else {
                   setErrorEthMessage('Transaction failed')
                }
               setIsPaying(false);
            }
        }
        
    }

    if(redirect){
        return <Redirect to=""/>
    }
    
    return (
            <div className="main">
                <PageLoading />
                <Header/>
                <BannerSale  />
                <section className="item-details-area pt-5">
                    <div className="container">
                        <div className="row  bg-white">
                            <div className='col-md-12'>
                                <h4 className="text-black">Mint and Transfer</h4>
                            </div>
                        </div> 

                        <div className="row  bg-white">
                            <div className='col-md-4 mb-5  mt-0'>
                                <h4 className="text-black mt-0">
                                {
                                    details.project_mint == 2  && details.art_uri != null ?
                                        <>
                                            {
                                                details.extension == 'mp4' ? 
                                                <video className="embed-responsive-item img-fluid" autoPlay="true" loop="true"  src={details.art_uri}></video>
                                                             : 
                                                <img src={details.art_uri} className="img-fluid mx-auto d-block" alt={details.project}/>
                                            }
                                        </>
                                        : <img src={details.art_foto} className="img-fluid mx-auto d-block" alt={details.project}/>
                                    }
                                </h4>
                            </div>
                            <div className='col-md-7 mt-4 mb-5'>
                                Project : {details.project}<br></br>
                                Client : {details.client}<br></br>
                                Order ID : {details.artist}<br></br>
                                Artist : {details.artist}<br></br>
                                Art No : {details.art_code}<br></br>
                                <hr></hr>
                                {errorEthMessage ?  <p className='text-danger'> {errorEthMessage }</p> : '' }
                                {
                                    details.tr_hash != null ? 
                                    <>
                                    <a href={details.opensea} className="btn btn-primary">View On Opensea</a>
                                    <a href={details.ether_scan}   className="btn btn-primary ml-2">View On Etherscan</a>
                                    </>
                                    :  
                                    <>
                                    {
                                        errorMessage  ? <div className="alert alert-danger">{errorMessage}</div> : 
                                        <>
                                         {
                                            defaultAccount ? 
                                            <>
                                            <label>Enter Client Contract Address</label>
                                            <input type="text" className='form-control mt-2 mb-3' onChange={e => setMetaAddress(e.target.value)}></input>
                                            <Button onClick={mint_} disabled={isPaying}  className='btn'>{isPaying && <span className="spinner-border spinner-border-sm mr-1"></span>} Mint Now</Button> 
                                            </> 
                                            : 
                                            <Button variant="primary" onClick={connectWallet} >Connect Wallet</Button>
                                        }

                                        </>
                                    }
                                    
                                    </>
                                }
                              
                            </div>
                        </div> 

                    
                       
                </div>
            </section> 
            <Footer />
            <ModalSearch/>
            <ModalMenu />
            <Scrollup />
        </div>
    );

}

export default MintTransfer;