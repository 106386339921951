import React, {useState, useEffect} from 'react';
import Auth from '../../helpers/auth/Auth';
import apiClient from '../../helpers/api';
import ModalRegister from '../Modal/ModalRegister';



const PrivateSaleCollection = (props) => {

    const [is_auth, setIsAuth] = useState(true);
    const [private_status, setPrivateStatus] = useState('');
    const [private_message, setPrivateMessage] = useState('');
    const [whitelisted, setWhitelisted]  = useState(false)

    const [isShow, setIsShow] = useState(false);
    
    useEffect(() => {
       
        apiClient.get(`/sale/private-sales/status/${props.params}`)
        .then(res => {
            console.log(res);
            setPrivateStatus(res.data.status);
            setPrivateMessage(res.data.title);
            if(res.data.status == 200){
                apiClient.get(`${process.env.REACT_APP_API}/sale/private-sales/white-list/${res.data.result.info.id}`,{withCredentials:true}).
                then(res => {
                    if(res.data){
                        setWhitelisted(true);
                    }
                });
            }
           
        }).catch( e => {
            console.log(e);
        });

        apiClient.get('/account/user')
        .then(res => {
            setIsAuth(true)
        }).catch(e => {
            setIsAuth(false)
            localStorage.clear()
        })

    },[])
    


    return (
        <section className="artist mb-4 mt-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                         <img
                            className="img-fluid"
                            src="/img/revamp_v3/PrivateSale.png"
                            alt=""
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <h2 className="text-dark normal-font mt-0 mb-0">Private Sale </h2>
                        {
                            private_status != 200 ? 
                            <p className='text-dark font-weight-bold text-white mt-1'>{private_message}</p>
                            : ''
                        }
                        <p className="text-justify text-dark mt-2">
                        Our Private Sale is exclusive for our Priority collectors. 
                        <br/> This status will give you the opportunity to have first dibs to purchase this special collection for a limited period. 
                        <br/>You can email us at concierge@scarletbox.io for queries on how to join the Private Sale.
                        </p>
                        {
                            private_status == 200 ? <a className="btn btn-dark btn-smaller px-5" href={'/'+props.params+'/sale/private-sale/'}  role="button">Mint Now | Buy Now</a> : ""
                        }
                        
                        {/* {
                                private_status == 200 ? 
                                <>
                                {
                                     !is_auth ?
                                       <button className="btn btn-danger btn-smaller px-5 my-2" data-toggle="modal" data-target="#modal-login">Log In to Purchase</button> :
                                       whitelisted ? <> <a className="btn btn-danger btn-smaller px-5" href={'/'+props.params+'/sale/private-sale/'}  role="button">Mint Now | Buy Now</a> <p className='text-white mt-2 ml-1 font-weight-light text-yellow' style={{fontSize: '13px'}}></p> </> : ''
                                }
                                </> : ''
                              
                        }
                        {
                                private_status == 200 ? 
                                <>
                                {
                                     !is_auth ?
                                       <button className="btn btn-danger btn-smaller px-5 ml-lg-3 ml-sm-0 my-md-2"  data-toggle="modal" data-target="#modal-register">Register for New Collectors</button> : ""                                }
                                </> : ''
                              
                        } */}

                        <ModalRegister/>
                    </div>
                </div>  
            </div>
        </section> 
    );

}

export default PrivateSaleCollection;