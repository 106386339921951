import React, {useState, useEffect} from 'react';
import Auth from '../../helpers/auth/Auth';
import apiClient from '../../helpers/api';


const AuctionSaleCollection = () => {

    const [is_auth, setIsAuth] = useState(true);

    useEffect(() => {

        apiClient.get('/account/user')
        .then(res => {
            setIsAuth(true)
        }).catch(e => {
            setIsAuth(false)
            localStorage.clear()
        })

    },[])


    return (
        <section className="artist mb-4 mt-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-6">
                         <img
                            className="img-fluid"
                            src="/img/revamp_v2/Auction.jpg"
                            alt=""
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <h3 className="text-danger mt-2 mb-0">Auction</h3>
                        <p className="text-justify mt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>

                        {
                            !is_auth ? 
                            <a className="btn btn-danger btn-smaller px-5 d-none" href="/sale/auctions" role="button">View</a>

                            :
                            <a className="btn btn-danger btn-smaller px-5" href="/sale/auctions" role="button">View</a>
                        }
            
                    </div>
                </div>  
            </div>
        </section> 
    );

}

export default AuctionSaleCollection;