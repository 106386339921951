import React, {Component} from "react";
import Login from "../Login/Login";

const ModalLogin  = (props) => {

        return (
            <div id="modal-login" className="modal fade p-0">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header" data-dismiss="modal">
                            Login <i className="far fa-times-circle icon-close" />
                        </div>
                        <div className="modal-body">
                            <Login/>
                        </div>
                    </div>
                </div>
            </div>
        );
    
}
 
export default ModalLogin;