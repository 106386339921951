import React from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import ResetPassword from "../../components/ForgotPassword/ResetPassword";

const ResetPasswordPage = () => {

    return (
        <div className="main">
            <PageLoading />
            <Header/>
            <ResetPassword />
            <Footer />
        </div>
    )

}

export default ResetPasswordPage;