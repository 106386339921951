import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import apiClient from "../../helpers/api";
import Auth from "../../helpers/auth/Auth";
import swal from "sweetalert";
import { Alert } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;


const CheckoutLogin = ({
    mail, 
    firstname, 
    lastname,
    onHide, 
    showmodal, 
    mobileno, 
    changeClient, 
    setPayment_method,
    setPaymentType,
    showFiat,
    showGcash,
    showCrypto,
    showBillease,
    paymentMethod,
    loginData,
    setTrno,
    setPricefiat
}) => {

    const { handleSubmit, formState } = useForm();
    const [email, setEmail] = useState(mail);
    const [password, setPassword] = useState('');
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(showmodal); 
    const { isSubmitting } = formState;

    const [isRegistered, setIsRegistered] = useState(true);

    //register
    const [showRegister, setShowRegister] = useState(false);

    const [country, setCountry] = useState()
    const [data, setData] = useState({})
    const [error, setError] = useState()

    //input fields
    const [username, setUsername] = useState('')
    const [name, setName] = useState('')
    const [last_name, setLastname] = useState('')
    const [mobile, setMobile] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
    const [twitter, setTwitter] = useState('')
    const [discord, setDiscord] = useState('')
    const [country_selected, setCountrySel] = useState()
    const [referal_code, setReferalCode]  = useState()
    const [isScroll, setIsScroll] = useState(false)
    const [terms, setTerms] = useState('')

    const [passwordShown, setPasswordShown] = useState(false);

    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");

    const [metamask,setMetamask] = useState(true);

       //register form toggle password
       const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }

    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password")
        {
            setConfirmPasswordType("text")
         return;
        }
            setConfirmPasswordType("password")
      }

    //login form toggle password 
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
 
    //submit login user
    function onSubmit(data) {
        // return promise that resolves after 2 seconds
        return new Promise(resolve => {

        apiClient.post(`/auth/login`, { 
            email: email,
            password: password,
            isRegistered: isRegistered,
            name: firstname,
            last_name: lastname,
            mobile: mobileno

        }).then(response => {
            if (response.status == 200) { 
                // localStorage.setItem('isAuth', true)
                Auth.storeAuth()
                changeClient(response.data.client_id)
                setShowModal(false)
                loginData["client_id"] = response.data.client_id
                apiClient.post(`${process.env.REACT_APP_API}/checkout/process/generate_transaction`,loginData).then(res => {
                    if(res.data.status == 200){
                        setTrno(res.data.result.tr_no)
                        setPricefiat(res.data.result.total_crypto)
    
                        if(paymentMethod == 1){
                            showFiat(true)
                        }else if(paymentMethod == 5){
                            showGcash(true)
                        }else if(paymentMethod == 6){
                            showBillease(true)
                        } else {
                            showCrypto(true)
                        }
    
                        resolve()
                    } else {
                        swal({
                            title: "Error",
                            text: res.data.message,
                            icon: "warning",
                        }).then((value) => {
                            window.location.reload()
                        });
                        resolve()
                    }
                }).catch(error => {
                    resolve()
                });
                
                // window.location.reload(false)
                resolve();
            }
        }).catch(function (error) {
            if(error.response.status==401){
                setShow(true)
                resolve();
            }
        })

        });
    }

    //submit register new user
    const onRegister = (e) => {

        return new Promise(resolve => {
            apiClient.post(`${process.env.REACT_APP_API}/auth/login`, {
                username: username,
                name: name,
                last_name: last_name,
                mobile: mobile,
                email: email,
                password: password,
                confirm_password: confirm_password,
                twitter: twitter,
                discord: discord,
                country_code: country_selected,
                referal_code: referal_code,
                isRegistered: false,
            }).then(response => {
                console.log(response);
                if (response.status == 200) { 
                    // localStorage.setItem('isAuth', true)
                    Auth.storeAuth()
                    changeClient(response.data.client_id)
                    setIsRegistered(true)
                    setShowRegister(false)
                    setShowModal(false)
                    // window.location.reload(false)
                    resolve();
                }
            }).catch(function (error) {
                if(error.response.status==401){
                    setShowRegister(true)
                    setIsRegistered(false)
                    resolve();
                }
            })
        })

    };

    //open register form popup
    const onClickRegisterLink = () => {

        setShowModal(false);
        setShowRegister(true);

    } 

    const onClickWallet = async () => {

        if(metamask){
             window.ethereum.request({method:"eth_requestAccounts"}).
            then(result => {
                window.ethereum.request({ method: 'eth_chainId' }).then(
                    chain => {
                        apiClient.post(`/auth/register-wallet`, { 
                            wallet: result[0],
                            email: email,
                            password: password,
                            isRegistered: isRegistered,
                            name: firstname,
                            last_name: lastname,
                            mobile: mobileno
                        }).then(response => {

                            console.log(response);
                            if (response.status == 200) {
                                localStorage.setItem('wallet',result[0])
                                localStorage.setItem('chain',chain)
                                // localStorage.setItem('isAuth', true)
                                if (response.data.client.email == null && response.data.client.name == null && response.data.client.last_name== null) {
                                    setPayment_method(2)
                                    setPaymentType(['','Meta Mask','', '','', ''])
                                    alert("Only ETH payment is allowed")
                                }
                                Auth.storeAuth()
                                changeClient(response.data.client.id)
                                setShowModal(false)
                            //window.location.reload()
                            }
                        }).catch(function (error) {
                            localStorage.removeItem("wallet")
                            localStorage.removeItem("chain")
                        })

                    }
                );
            })

        } else {
            window.open("https://metamask.io/download/")
        }

    }


    useEffect(() => {
        if (mail) {

            const data = {
                email: email,
                firstname: firstname,
                lastname: lastname,
                
            }
            
            apiClient.get("/auth/verify_email", {
                params: data
            }).then(res => {
                console.log(res);
                if (mail && res.data.code == 300) {
                    setIsRegistered(false)
                }else{
                    setIsRegistered(true)
                }
            })
        }
    },[])


    return (
        <div className='alert chkout'>
            
           <Alert show={show} variant="danger">
                <p>
                    Invalid Email or Password
                </p>
            </Alert>
            <form onSubmit={handleSubmit(onSubmit)} className="item-form no-hover px-0 py-2">
                <p>Login to proceed to Checkout</p>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group mt-0">
                            {
                                mail ? (
                                    <p>Email: {mail}</p>
                                ) : 
                                <input type="email" className="form-control" name="email" readOnly={mail ? true : false} defaultValue={email}  onChange={e => setEmail(e.target.value)} placeholder="Enter your Email" required="required" />
                            }
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group mt-3">
                            <input type={passwordShown ? "text" : "password"}  className="form-control" name="password" onChange={e => setPassword(e.target.value)} placeholder="Enter your Password" required="required" />
                        </div>
                        
                    </div>
                    <div className="col-12">
                        <div className='row'>
                            <div className='col-6'>
                                <div className="form-group form-check">
                                    <input type="checkbox" className="form-check-input checkboxx"  onClick={togglePasswordVisiblity} />
                                    <label className="form-check-label ml-2" >Show Password</label>
                                </div>
                        
                            </div>
                            <div className='col-6'>
                                <div className="text-right">
                                    <a href='/auth/forget_password'>Forgot Password</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    
            
                    {/* <div className="col-12">
                        <button disabled={isSubmitting} className="btn w-100 mt-2 mt-sm-3" type="submit">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            {isRegistered ? "Sign In and proceed to Checkout" : "Register and proceed to Checkout"}
                        </button>
                    </div> */}
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <hr style={{backgroundColor: "red", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                        <span className='px-4'>OR</span> 
                        <hr style={{backgroundColor: "red", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                    </div>
                    
                    <div className="col-12">
                        <button className="btn w-100" type="button" onClick={onClickWallet} >Connect to Wallet</button>
                    </div>
                    <div className="col-12 pt-3">
                        <p className="text-center text-white" style={{ fontSize: '15px' }}>*Please register if you do not have an account <a className='text-danger' onClick={onClickRegisterLink}>here</a>.</p>
                    </div>
                </div>
            </form>

        </div>
    )


}

export default CheckoutLogin;