import React, {useEffect, useState} from "react";
// import background from "../../img/Maintenance.png";
// import background_mobile from "../../img/MaintenanceMobile.png";
import backgroundMaintenance from "../../img/MainBanner.jpg";


const Maintenance = (props) => {

    return (
      <section className="p-0" style={{height: "100vh", backgroundImage: `url(${backgroundMaintenance})`}}>
        <img src={window.photo_url+"maintenance/"+props.content.image} className="d-md-block d-none img-fluid" style={{height: "100vh"}}/>
        <img src={window.photo_url+"maintenance/"+props.content.image_mobile} className="d-block d-md-none img-fluid" style={{height: "100vh"}}/>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 text-center hero-text">
                    {
                      <div className="normal-font" dangerouslySetInnerHTML={{__html: props.content.content}}></div>
                    }
                    
                </div>
            </div>
        </div>
      </section>
    );
}

export default Maintenance;