import React, { useEffect, useState,useContext } from "react";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CountdownTimer from "../../CountdownTimer/Countdown";
import apiClient from '../../../helpers/api';
import { useForm } from "react-hook-form";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { HeaderContext } from "../../../helpers/headerContext";
import Auth from "../../../helpers/auth/Auth";
const Form = (props) => {
    const { changeClient, setChangeClient } = useContext(HeaderContext);
    const [user, setUser]   = useState({});
    const [isEnded,setisEnded] = useState([]);
    const [isLogged,seIslogged] = useState(false)
    const [isLoading,setIsLoading] = useState(true)
    const [isShow,setIsShow] = useState(false)
    const [isLogin, setIsLogin] = useState(true)
    const [showAlert, setShowAlert] = useState({show: false, msg: ""});
    const {register, handleSubmit, setError, formState:{errors}, formState, reset} = useForm({ mode: 'onBlur' })
    const {isSubmitting} = formState;
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");
    const [errorLog,setErrorLog] = useState(false)
    const eye = <FontAwesomeIcon icon={faEye} />; 
    const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;
    const source = '';
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const togglePassword = () => {
        if(passwordType==="password"){
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password"){
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }
    const onSubmitAuth = (data) => {

        if (isLogin) {
            return new Promise(resolve => {
                apiClient.post(`${process.env.REACT_APP_API}/auth/login`, data).then(res => {
                    if (res.status == 200) {
                        Auth.storeAuth()
                        setChangeClient(true)
                        seIslogged(true)
                        setIsShow(false)
                        setErrorLog(false)
                    }
                    resolve();
                }).catch(error => {
                    setShowAlert(prevState => ({ ...prevState, show: true,msg: error.response.data.error}))
                    resolve();
                })

            })
        } else {
            return new Promise(resolve => {
                apiClient.post(`${process.env.REACT_APP_API}/auth/register`, data).then(res => {
                    setIsLoading(true)
                    if (res.status == 200) {
                        Auth.storeAuth()
                        setChangeClient(true)
                        seIslogged(true)
                        setIsShow(false)
                        setErrorLog(false)
                        
                    }
                    resolve();
                }).catch(error => {
                    if (error.response.data) {
                        const errors = error.response.data.errors
                        if (errors.name) {
                            setError("name", { type: "focus", message: errors.name[0]}, { shouldFocus: true })
                        }
                        if (errors.last_name) {
                            setError("last_name", { type: "focus", message: errors.last_name[0]}, { shouldFocus: true })
                        }
                        if (errors.email) {
                            setError("email", { type: "focus", message: errors.email[0]}, { shouldFocus: true })
                        }
                        if (errors.password) {
                            setError("password", { type: "focus", message: errors.password[0]}, { shouldFocus: true })
                        }
                        if (errors.confirm_password) {
                            setError("confirm_password", { type: "focus", message: errors.confirm_password[0]}, { shouldFocus: true })
                        }
                    }
                    resolve();
                })
            })
        }

    }

    const getCollectionStatus = (v) => {
        if(!isEnded.includes(v)){
            setisEnded(isEnded => [...isEnded,v]);
        }
    }

    const closeModal = () => {
        setIsShow(false)
    }

     // toggle login view
     const onClickRegister = (e) => {
        setIsLogin(false)
        setIsShow(true)
        setIsLoading(false)
        e.preventDefault();
    }
    const onClickLogin = (e) => {

        setIsLogin(true)
        setIsShow(true);
        setIsLoading(false)
        e.preventDefault();
    }
    
    

    const verifyLogin = async () => {
        try {
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);
            if (res.status == 200) {
                setUser(res.data)
                seIslogged(true);
            } 
        } catch (error) {
            seIslogged(false);
        }
    }

    const onSubmitBid = (data) => {
       
        try { 
           if(isLogged){
             apiClient.post(`${process.env.REACT_APP_API}/auctions/process/offer/`, data).then(res => {
                if (res) {
                    setShowAlert(prevState => ({ ...prevState,
                        show: true,
                        msg: res.data.msg
                    }))
                    props.refireData()
                   // getArtDetail()
                   // props.getAuctionArts()
                    reset({
                        bid_amount: "",
                    })

                    setTimeout(()=>{
                        setShowAlert(prevState => ({ ...prevState,
                            show: false,
                            msg: ""
                        }))
                    }, 5000)
                }
            }).catch(error => {
                if (error.response.data) {
                    const errors = error.response.data.errors
                    if (errors.bidder_name) {
                     
                        setError("bidder_name", { type: "focus", message: errors.bidder_name[0]}, { shouldFocus: true })
                    }
                    if (errors.bid_amount) {
                        props.refireData()
                        setError("bid_amount", { type: "focus", message: errors.bid_amount[0]}, { shouldFocus: true })
                    }
                }
            })
                
            } else {
              
              setErrorLog(true)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        verifyLogin()
    },[])

    return (
        <>
        <div className="col-12 col-lg-6 ">
        <div className="content mt-5 mt-lg-0 ">
			    <h3 className="m-0 text-dark">{props.art.label}</h3>
                <div className="item-info-list mt-4">
                    <ul className="list-unstyled">
                        <li className="price">
                            <span><strong>Artist</strong> {props.art.artist && props.art.artist.artist_name}</span>
                        </li>
                        <li className="price">
                            <span><strong>Collection</strong> <NavLink to={`/auctions/${props.collection.url}/${props.partnerCode}`}>{props.collection && props.collection.title}</NavLink></span>
                        </li>
                    </ul>
                </div>
                <p></p>
                <p className="my-2 " dangerouslySetInnerHTML={{ __html: props.art.short_description}}></p>
               

                  
                    {
                    !isEnded.includes(props.art.id)  && props.collection.status == 1 && props.collection.allowed == 1 ?
                    <CountdownTimer show={props.collection.show_timer != 1 ?  'd-none' : ''} id={props.art.id}  changeIsEnded={getCollectionStatus} targetDate={props.art.date_end} /> : ''
                    }

                    {
                        props.collection.free ? 
                        <p onClick={() => props.showMerchBtn(props.art.merch_image)} className="text-dark font-weight-bold text-uppercase  mt-2 mb-2" style={{cursor: "pointer"}}><u>{props.art.merch_title ? props.art.merch_title : "View Merch"}</u></p>

                        : ''
                    }

                     <p></p>
                <div className="item-info-list mt-4">
                    <ul className="list-unstyled">

                        <li className="price">
                            <span><strong>Staring Bid</strong> PHP {props.base_price && props.base_price.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}</span>
                        </li>
                       
                        {
                        props.highest_offer ?
                            <li className="price">
                                <span><strong>Highest Bid</strong> PHP {props.highest_offer.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}</span>
                            </li>
                        :  ""
                        }

<li className="price">
                            <span><strong>Bid Increment</strong> {props.increamentLabel}</span>
                        </li>

                        
                    </ul>
                </div>

                    {
                         props.collection.allowed == 0 ?  <p className="my-2"><span className=" text-danger text-uppercase font-weight-bold">Coming Soon</span></p> : 
                         !isEnded.includes(props.art.id)  && props.collection.status == 1  ? 
                         <div className="card no-hover mt-4" style={{border:'1px solid #e1e1e1'}} >

<Alert show={showAlert.show} variant="success" className="d-flex justify-content-between">
                                            <p className="mb-0">{showAlert.msg}</p> <a type="button" className="" onClick={() => setShowAlert(prevState => ({ ...prevState,show: false,msg: ''}))}>x</a> 
                                        </Alert>

                            <form onSubmit={handleSubmit(onSubmitBid)} >
                            {
                                user.id ? <input type="hidden"  className="text-dark" {...register("user_id")} value={user.id}/> : ''
                            }

                            <input type="hidden" name="auction_id" {...register("auction_id")} value={props.collection.id}/>
                            <input type="hidden" name="auction_art_id" {...register("auction_art_id")} value={props.art.id}/>
                            <input type="hidden" name="source" {...register("source")} value={source}/>

                             <h4 className="mt-0 mb-2">Place Bid</h4>
                            {
                                isLogged ? 
                                    user.id ?
                                    <>
                                    <label>Bidder Name</label>
                                    <input className="form-control"  {...register("bidder_name")} defaultValue={user.name + " " + user.last_name}></input>
                                    {errors.bidder_name && <p className="mt-0 text-danger">{errors.bidder_name.message}</p>}
                                    </>
                                    : ''
                                : ''
                            }
                         
                         <label className="mt-2">Select Bid Amount</label>
                         <select className="form-control"  {...register("bid_amount")}>
                             <option value=""></option>
                             {
                                 props.bidAmount.map((amount) => {
                                     return(
                                         <option value={amount}>{`Php ${amount.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}`}</option>
                                     )
                                 })
                             }
                             
                         </select>
                         <small style={{ fontSize:'10px' }} className="mb-2">Next bid options will appear after highest bid from this set is selected.</small>
                         {errors.bid_amount && <p className="mt-0 text-danger">{errors.bid_amount.message}</p>}
                         <button className="d-block btn btn-danger mt-4 text-white" >BID</button>
                         </form>
                        
                         {
                            errorLog ?
                            <p>To BID, please <a className="text-danger" href="#" onClick={(e) => onClickLogin(e)}>LOGIN</a> or <a className="text-danger" href="#" onClick={(e) => onClickRegister(e)}>REGISTER</a> to your Scarletbox account.</p>
                            : ''
                         }
                     </div>
                     :<><span className=" text-danger text-uppercase font-weight-bold">Auction Ended</span><br></br></>
                    }
                   
                    
			</div>
            
       </div>


            <Modal size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered 
            show={isShow}>
                 <Modal.Header className="pb-0">
                    <p className="font-weight-bold pt-3">{isLogin ? 'Login' : 'Register'}</p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
                </Modal.Header>
                {
                    !isLoading ? (
                        <Modal.Body className="pt-0">
                            <form onSubmit={handleSubmit(onSubmitAuth)}>
                                {
                                    isLogin ? (
                                        <div>
                                             <Alert show={showAlert.show} variant="danger" className="d-flex justify-content-between">
                                                <p className="mb-0">{showAlert.msg}</p> <a type="button" className="" onClick={() => setShowAlert(prevState => ({ ...prevState,show: false,msg: ''}))}>x</a> 
                                            </Alert>
                                            <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mt-0">
                                                    <label className="text-dark font-weight-bold">Email</label>
                                                    <input type="email" className="form-control" {...register("email")} name="email" placeholder="Enter your Email" required="required" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label className="text-dark font-weight-bold">Password</label>
                                                    <input type={passwordShown ? "text" : "password"}  className="form-control" {...register("password")} name="password" placeholder="Enter your Password" required="required" />
                                                </div>
                                                
                                            </div>
                                            <div className="col-12">
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className="form-group form-check">
                                                            <input type="checkbox" className="form-check-input checkboxx" id='showPassword' onClick={togglePasswordVisiblity} />
                                                            <label className="form-check-label ml-1 mt-1" for="showPassword">Show Password</label>
                                                        </div>
                                                
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className="text-right">
                                                            <a href='/auth/forget_password' target='blank'>Forgot Password</a>
                                                        </div>
                                                    </div>
                                                </div>
                        
                                            </div>
                                    
                                            <div className='col-12'>
                                                <button className="btn w-100 btn-dark" > 
                                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Login
                                                </button>
                                            </div>
                                        
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                                                <span className='px-4'>OR</span> 
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                                            </div>
                                        
                                            <div className="col-12">
                                                <button className="btn btn-dark w-100" type="button" onClick={onClickRegister}>Register</button>
                                            </div>
                                            
                                            
                                            <div className="col-12 pt-3">
                                                <p className="text-center text-dark" style={{ fontSize: '15px' }}>*Please register if you do not have an account <a className='text-danger' onClick={onClickRegister}>here</a>.</p>
                                            </div>
                                        </div>
                                        </div>
                                    ) : (
                                        <div>
                                        {/* <Alert show={show} variant="danger">
                                            <p>
                                                {errormsg}
                                            </p>
                                        </Alert> */}
                                        <div className="row justify-content-center">
                                        
                                            <div className="col-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">First Name</label>
                                                    <input type="text" {...register("name")} className={`form-control`} name="name"  />
                                                    {errors.name && <p className="mt-0 text-danger">{errors.name.message}</p>}
                                                </div>
                                            </div> 
                                            <div className="col-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Last Name </label>
                                                    <input type="text" {...register("last_name")} className={`form-control`} name="last_name"   />
                                                    {errors.last_name && <p className="mt-0 text-danger">{errors.last_name.message}</p>}
                                                </div>
                                            </div> 
                                        
                                            <div className="col-md-6 col-12 pl-0 d-none">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Username &#40; Optional &#41;</label>
                                                    {/* <input type="text" className={`form-control ${ error && error.errors.username ? "is-invalid" : ""}`} name="username" onChange={ e =>setUsername(e.target.value) }  />
                                                    {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""} */}
                                                    <input type="text" {...register("username")} className="form-control" name="username"  />
                                                    {errors.username && <p className="mt-0 text-danger">{errors.username.message}</p>}
                                                </div>
                                            </div> 
                                            <div className="col-md-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Email</label>
                                                    <input type="email" {...register("email")} className={`form-control`} name="email" required="required"/>
                                                    {errors.email && <p className="mt-0 text-danger">{errors.email.message}</p>}
                                                </div>
                                            </div> 
                                            {/* <div className="col-md-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Mobile</label>
                                                    <input type="text" {...register("mobile")} className={`form-control`} name="mobile"/>
                                                </div>
                                            </div>  */}
                                            <input type="hidden" name="isRegistered" {...register("isRegistered")} value={false}/>
                                        
                                            <div className="col-12 row">
                                                <div className="form-group col-10 px-0">
                                                    <label className="text-dark">Password</label>
                                                    <input type={passwordType} {...register("password")} className={`form-control`} name="password"  required="required"/>
                                                    {errors.password && <p className="mt-0 text-danger">{errors.password.message}</p>}
                                                </div>
                                                <div className="form-group col-2 mt-4">
                                                <button type="button" className="btn btn-dark btn-smaller" onClick={togglePassword}>
                                                    { passwordType==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                </button>
                                                </div>
                                            </div> 
                                        
                                            <div className="col-12 row">
                                                <div className="form-group col-10 px-0">
                                                    <label className="text-dark">Confirm Password</label>
                                                    <input type={confirmpasswordType} {...register("confirm_password")} className={`form-control`} name="confirm_password"  required="required"/>
                                                    {errors.confirm_password && <p className="mt-0 text-danger">{errors.confirm_password.message}</p>}
                                                </div>
                                                <div className="form-group col-2 mt-4">
                                                    <button type="button" className="btn btn-dark btn-smaller"  onClick={toggleConfirmPassword}>
                                                        { confirmpasswordType ==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                    </button>
                                                </div>
                                            </div> 
                                            <div className="col-12">
                                                <div className="form-group mt-1 ml-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input " type="checkbox" name="inlineRadioOptions" id="inlineRadio1" required="required"/>
                                                        <label className="form-check-label ml-1 mt-1" htmlFor="inlineRadio1">I have read and agreed to the <a target="blank" href="https://scarletbox.io/privacy"> Terms and Conditions</a></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <button className="btn w-100 btn-dark" >
                                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Register
                                                </button>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                                                <span className='px-4'>OR</span> 
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                                            </div>
                                        
                                            <div className="col-12">
                                                <button className="btn btn-dark w-100" type="button" onClick={onClickLogin}>Login</button>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                            </form>

                        </Modal.Body>
                    ) 

                    : (
                        <Modal.Body style={{minHeight: '300px'}}>
                        <div className="mt-5 d-flex flex-column align-items-center">
                            <div className="spinner-border text-danger" style={{width: '6rem', height: '6rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="pt-3">
                                <p className="font-weight-bold">Loading...</p>
                            </div>
                        </div>
                    </Modal.Body>
                    )
                }
            </Modal>
        </>
    )
}

export default Form