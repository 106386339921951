import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { isMobile } from "react-device-detect";

const AuctionItemSkeleton = ({arts}) => {
    return  Array(arts).fill(0).map((item, i) => ((
        <div key={i} className={isMobile ? ' row pl-0 pr-0 my-2 ' : 'row pl-0 my-2 '}>
            <div className="col-md-3">
                <Skeleton height={200}/>
            </div>
            <div className="col-md-9">
                <a href='#'><Skeleton/></a>
                <a href='#'><Skeleton/></a>
                <a href='#'><Skeleton/></a>
            </div>
        </div>
        
       ))
    )
}
 
export default AuctionItemSkeleton;