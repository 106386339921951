import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const AuctionCollectionSkeleton = ({collections}) => {
    return  Array(collections).fill(0).map((item, i) => ((
       
            <div className="col-md-4">
                <Skeleton height={200}/>
            </div>
         
       ))
     
    )

}

export default AuctionCollectionSkeleton