import React, {Component, useState, useEffect} from "react";
import apiClient from "../../../helpers/api";
import { useForm } from "react-hook-form";

import { Modal } from "react-bootstrap";
import Select from 'react-select'
import Web3 from 'web3';


const MyAccount = (props) => {
    const { user } = props
    const [isLoading, setIsLoading] = useState(false)
    const [show, setShow] = useState(false)
    const { handleSubmit, formState } = useForm();
    const [country, setCountry] = useState()
    const { isSubmitting} = formState;
    const [error, setError] = useState()

    const AllowedChainId = process.env.REACT_APP_CHAINID;
    const [defaultAccount,setDefaultAccount]         = useState(null);
    const [defaultChain,setDefaultChain]             = useState(null);
    const [walletError,setWalletError]               = useState(null);

     //input fields
     const [username, setUsername] = useState(user.username)
     const [name, setName] = useState(user.name)
     const [last_name, setLastname] = useState(user.last_name)
     const [mobile, setMobile] = useState(user.mobile)
     const [email, setEmail] = useState(user.email)
     const [twitter, setTwitter] = useState(user.twitter)
     const [discord, setDiscord] = useState(user.discord)
     const [wallet, setWallet] = useState(user.wallet)
     const [country_selected, setCountrySel] = useState(user.country_code)
     const [message_, setMessage_] = useState("Successfully Updated Profile")
     const [currentwaller, setcurrentwaller] = useState(user.wallet)
    const onSubmit = (data) => {

        return new Promise(resolve => {
            apiClient.post(`${process.env.REACT_APP_API}/account/update-info`, {
                username: username,
                name: name,
                last_name: last_name,
                mobile: mobile,
                email: email,
                twitter: twitter,
                discord: discord,
                country_code: country_selected,
                wallet:wallet
            }).then(res => {
                
                if(res.data.code == 201) { 
                    setMessage_(res.data.wallet+' '+res.data.error)
                } else {
                    setMessage_("Successfully Updated Profile")
                }
                setShow(true)

                       
                resolve();
            }).catch(error => {
              
                if(error.response.status >= 401){
                    setError(error.response.data)
                    console.log(error);
                    document.querySelector('#my-account-form').parentElement.scrollIntoView({behavior: "smooth"})
                    resolve();
                }  
            })
        })

    }

    useEffect(() => {
        apiClient.get(`${process.env.REACT_APP_API}/geo-location/country`).then(res => {
            setCountry(res.data.data)
        })

        // if(!window.ethereum){
        //     setWalletError('To link you metamask address please install metamask');
        // } 

    }, [])

    const options = country && country.map(val => {
        return  { value: val.id, label: `${val.name} (${val.phonecode})` };
    })

    const selectedd = options && options.filter(res => {
        return res.value === user.country_code
    })

    async function getWallet () {
        if(!window.ethereum){
            setWalletError('To get you metamask address please install metamask')
        } else {
            window.ethereum.request({method:"eth_requestAccounts"}).
            then(result => {
                setWallet(result[0])
            })
        }
        
    }

    try{
        if(currentwaller == ''){
            window.ethereum.on('accountsChanged', (accounts) => {
                setWallet(accounts[0])
            });
        }
    } catch(e){ }
    

    return (
        <div className="px-2 px-md-5 pb-3">
        <h5 className="font-weight-bold text-dark font-family-normal">My Account</h5>
       
        <form id="my-account-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
               
                <div className="col-12 mb-3">
                    {walletError ? <small className="text-danger">{walletError}</small> : ''}
                    <div className="input-group ">
                        <label>My Metamask Wallet</label>
                        <input type="text" className="form-control" readOnly name="wallet" placeholder="My Wallet" value={wallet} aria-label="My Wallet" aria-describedby="basic-addon2"></input>
                        {user.wallet == '' || user.wallet == null ? <button  className="btn btn-dark p-3 py-11" onClick={getWallet} type="button">Link My wallet</button>
                        : ''}
                    </div>
                    {user.wallet == '' || user.wallet == null ? <small>Once this Metamask wallet is linked to this account, it cannot be changed. You need to create a new account to use a new Metamask wallet.</small> : ''}
                </div>
               
                <div className="col-12">
                    <div className="form-group">
                        <label>USERNAME</label>
                        <input type="text" className="form-control rounded-0" name="username" onChange={e => setUsername(e.target.value)} defaultValue={user.username} placeholder="USERNAME / ALIAS"  />
                        {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group mt-2">
                        <label>NAME</label>
                        <input type="text" className="form-control rounded-0" name="name" onChange={e => setName(e.target.value)} defaultValue={user.name} placeholder="FIRST NAME"  />
                        {error ? ( <p className="text-red m-0"> {error.errors.name} </p>) : ""}

                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group mt-2">
                        <label>LAST NAME</label>
                        <input type="text" className="form-control rounded-0" name="last_name" onChange={e => setLastname(e.target.value)} defaultValue={user.last_name} placeholder="LAST NAME"  />
                        {error ? ( <p className="text-red m-0"> {error.errors.last_name} </p>) : ""}
                    </div>
                </div>
                <div className="col-12">
                <div className="row align-items-center">
                    <div className="col-md-4 col-12 pl-0">
                        <div className="form-group mt-2">
                            {/* <select className={`form-control  rounded-0`} onChange={e => setCountrySel(e.target.value)} defaultValue={user.country_code}>
                                <option>Country Code</option>
                                {
                                    country && country.map((val, id) => {
                                        return (
                                            <option key={id} value={val.id}>{`${val.name} (${val.phonecode})`}</option>
                                        )
                                    })
                                }
                            </select> */}
                            <label>COUNTRY CODE</label>
                            {
                               selectedd && <Select options={options} className="react-select country-code-form" defaultValue={selectedd[0]} classNamePrefix="react-select" onChange={ e => setCountrySel(e.value)} />
                            }
                            
                            {error ? ( <p className="text-red m-0"> {error.errors.country_code} </p>) : ""}
                        </div>
                    </div>
                    <div className="col-md-8 col-12 p-0">
                        <div className="form-group mt-2">
                            <label>MOBILE NUMBER</label>
                            <input type="number" className="form-control rounded-0" name="mobile" onChange={e => setMobile(e.target.value)} defaultValue={user.mobile} placeholder="MOBILE NUMBER" />                                            </div>
                            {error ? ( <p className="text-red m-0"> {error.errors.mobile} </p>) : ""}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group mt-2">
                        <label>EMAIL</label>
                        <input type="email" className="form-control rounded-0" name="email" onChange={e => setEmail(e.target.value)} defaultValue={user.email} placeholder="EMAIL"  />
                        {error ? ( <p className="text-red m-0"> {error.errors.email} </p>) : ""}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group mt-2">
                        <label>TWITTER HANDLE (OPTIONAL)</label>
                        <input type="text" className="form-control rounded-0" name="twitter" onChange={e => setTwitter(e.target.value)} defaultValue={user.twitter} placeholder="TWITTER"  />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group mt-2">
                        <label>DISCORD HANDLE (OPTIONAL)</label>
                        <input type="text" className="form-control rounded-0" name="discord" onChange={e => setDiscord(e.target.value)} defaultValue={user.discord} placeholder="DISCORD"  />
                    </div>
                </div>
                <div className="col-12 text-right">
                    <button className="btn btn-dark mt-md-2 mt-4" type="submit">
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        UPDATE
                    </button>
                </div>
            </div>
        </form>
         
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="">
                        <h5 className="text-center text-normal">{message_}</h5>
                    <br/>
                    <button className="btn btn-smaller btn-primary btn-block" onClick={() => { window.location.reload()}}>CLOSE</button>
                </div>
            </Modal.Body>
        </Modal>
    </div>
    
    );
    
} 
 
export default MyAccount;