import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";

import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Checkout from "../../components/Checkout/Checkout";
import swal from 'sweetalert';
import Countdown from '../CountdownTimer/Countdown';
import apiClient from '../../helpers/api';
import ModalPromo from "../Modal/ModalPromo";

const PublicSalesForm = (props) => {
    
    const {register, handleSubmit, formState:{errors},formState,reset, setValue} = useForm();

    const [redirect,setRedirect] = useState(false)
    const [info, setInfo] = useState()
    const [artists, setArtists] = useState()
    const [checkout,setCheckout] = useState(false)
    const [artist, setArtist] = useState('')
    const [price_text, setPrice_text] = useState('')
    const [price, setPrice] = useState('')
    const [rearity, setRearity] = useState('')
    const [with_frame, setWith_frame] = useState(false)
    const [customField, setCustomField] = useState('')
    const [public_note, setPublic_note] = useState('')
    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [error_msg, setError_msg] = useState('')
    const [ethPrice, setEthPrice] = useState('')
    const [date_end, setDate_end] = useState('')
    const [shedule_id, setShedule_id] = useState('')
    const [allowed_art_bundle, setAllowedArtBundle] = useState('')
    const [art_bundle_qty, setArtBundleQty] = useState('')
    const [bundles, setBundles] = useState()
    const [bundle_count, setBundleCount] = useState(null)
    const [qty_error, setQtyError]  = useState('')
    const [supply, setSupply]     = useState('')
    const [artistName, setArtistName]     = useState('')
    const [spinning_box, setSpinningBox] = useState('/img/box.gif')

    const {isSubmitting} = formState;
    const [artistCount, setArtistCount]  = useState('')

    const [withCustomField, setWithCustomField]  = useState(false)
    const [customFieldLabel, setCustomFieldLabel]  = useState('')

    const [publicGuideLine, setPublicGuideLine]  = useState('')
    const [free_type, setFreeType]  = useState(0)
    const [withFreeMerch, setWithFreeMerch]  = useState(false)
    const [freeMessage, setFreeMessage]  = useState('')
 
    const [schedfreeMessage, setSchedFreeMessage]  = useState('')
    const [freeMessage2, setFreeMessage2]  = useState('')
    const type = 2;
    const [errorCustomField, setErrorCustomField]  = useState('')
    
    const [optional, setOptional]  = useState([]);
    const [defaultOption, setdefaultOption]  = useState([])
    const [optionImage, setoptionImage]  = useState('')
    const [optionLabel, setoptionLabel]  = useState('')
    const [custom_field_type, setcustom_field_type]  = useState('')

    const [isaddon, setIsaddon]     = useState('')
    const [addonTitle, setAddonTitle]     = useState('')
    const [addPrice, setAddPrice]     = useState('')
    const [addShippingFee, setShippingFee]     = useState('')

    const [chisaddon, setChisaddon]     = useState(false)
    const [chaddontitle, setChaddontitle]     = useState('')
    const [chaddprice, setChaddprice]     = useState('')
    const [chaddonShippingFee, setChaddonShippingFee]     = useState('')
    const [allowChainId, setAllowChainId]     = useState('')
    const [chainName, setChainName]     = useState('')
    const [defaultSf, setDefaultSf]     = useState('')
    const [max_supply, setMaxSupply] = useState('')
    const [sample_art, setSampleArt] = useState('')
    const [chainCode, setChainCode] = useState('')
    
    const [add_on_image,set_add_on_image] = useState('')

    const [merch_is_force, setMerchIsForce] = useState(false);
    const [discount_note, setDiscountNote]  = useState("");

    const [isForceAddon, setisForceAddon] = useState('')

    const [showAddOnShipping, setShowAddOnShipping] = useState(true);

    const onCheckout = (e) => {
    
        return new Promise(resolve => {
            setError_msg('')
            var is_process = true;
            var error_msg  = 'Max length is only 8 characters.';
            if(with_frame){
                
                if(customField == '' && withCustomField){
                    is_process = false;
                    error_msg= 'Please '+customFieldLabel+' to proceed';
                } else {
                    if(custom_field_type == 2){
                        let customLength = customField.length;
                        if(customLength > 8){
                            is_process = false;
                        }
                    }
                }
            }

            if(is_process){
            apiClient.post(`${process.env.REACT_APP_API}/checkout/process/verify`, {
                project_id: project_id,
                artist: artist,
                rarity:1,
                withframe:with_frame,
                art_bundle_qty: art_bundle_qty
            }).then(res => { 
               
                if(res.data.status == 200){
                    
                    setCheckout(true)
                    resolve()
                } else if(res.data.status == 201){
                    swal({
                        title: "Error",
                        text: res.data.message,
                        icon: "warning",
                      }).then((value) => {
                        window.location.reload()
                      });
                      resolve()
                } else {
                    setError_msg(res.data.message)
                    resolve()
                }

            }).catch(error => {
               console.log(error);
                // if(error.response.status == 401){
                //     setRedirect(true)
                // }
            });
            } else {
                setErrorCustomField(error_msg);
                resolve();
            }
          //  
        });
    };

    const onFreeChange = (e) => {
        if(e.target.value == 0){
             setWith_frame(true);
            setWithFreeMerch(true);
        } else {
            setWith_frame(false);
            setWithFreeMerch(false);
        }

        var index = e.nativeEvent.target.selectedIndex;
         
        setFreeMessage(e.nativeEvent.target[index].text.replace("I want", ""));
       
    }

    const changeArtist = (e) => {
        const idx = e.target.selectedIndex;
        const option = e.target.querySelectorAll('option')[idx];
        setArtist(e.target.value)
        setArtistName(option.getAttribute('data-artistname'))
        
        if (option.getAttribute('data-sample_addon')) {
            setSampleArt(option.getAttribute('data-sample_addon'))
        }else{
            setSampleArt(option.getAttribute('data-sample_art'))
        }
    }

    const selectAddons = (e) => {
        if(e.target.checked){
            setChisaddon(true);
            setChaddontitle(e.target.getAttribute('data-title'));
            setChaddprice(e.target.getAttribute('data-price'));
            setChaddonShippingFee(e.target.getAttribute('data-sf'));
        } else {
            setChisaddon(false);
            setChaddontitle('');
            setChaddprice('');
            setChaddonShippingFee('');
        }
    }

    //verify arts available
    const verifyArtQty = (e) => {

        return new Promise(resolve => {
            setError_msg('')
            apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/verify`, {
                project_id: project_id,
                artist_id: artist,
                qty: e,
                withframe:with_frame,
                allowed_art_bundle: allowed_art_bundle,
                type: type
            }).then(res => {

               if(res.data.code == 200){

                if(res.data.result.free_type == 0 || res.data.result.free_type == 2 || res.data.result.free_type == 8){
                    setWith_frame(false);
                    setWithFreeMerch(false);
                } else if(res.data.result.free_type == 4){
                  
                    setWith_frame(true);
                    setWithFreeMerch(true);
                  
                    setOptional(['I want '+res.data.result.free_message,'I want '+res.data.result.free_message2]);
                } else{
                    setWith_frame(true);
                    setWithFreeMerch(true);
                }

                res.data.result.is_force == 1 ? setMerchIsForce(true) : setMerchIsForce(false)

                if(res.data.result.with_custom_field == 2){
                    setWithCustomField(true);
                    setCustomFieldLabel(res.data.result.cutom_label)
                    
                } else if(res.data.result.with_custom_field == 3){
                    setWithCustomField(true);
                    setCustomFieldLabel(res.data.result.cutom_label)
                    setdefaultOption(res.data.result.opt_value)
                    setoptionImage(res.data.result.option_image)
                    setoptionLabel(res.data.result.option_label)
                } else {
                    setWithCustomField(false);
                }

                setDiscountNote(res.data.result.discount_note)
                setDefaultSf(res.data.result.default_sf);
                setFreeType(res.data.result.free_type);
                setFreeMessage(res.data.result.free_message);
                setFreeMessage2(res.data.result.free_message2);
                setcustom_field_type(res.data.result.with_custom_field)
                setArtBundleQty(res.data.result.qty)

                res.data.result.show_addon == 1 ?  setShowAddOnShipping(false) : setShowAddOnShipping(true)

                if(res.data.result.enable_add_on == 2){

                    var new_price = res.data.result.art_price_fiat;
                    setIsaddon(res.data.result.enable_add_on);
                    setAddonTitle("I want my " + res.data.result.add_on);
                    setAddPrice(res.data.result.add_on_price);
                  
                    if(res.data.result.default_sf > 0){
                        
                        setShippingFee(res.data.result.default_sf);
                    } else {
                        setShippingFee(res.data.result.shipping_fee);
                    }
                    
                    set_add_on_image(res.data.result.add_on_image);
                  
                    
                    setChisaddon(false);
                    setChaddontitle('');
                    setChaddprice('');
                    setChaddonShippingFee('');
                    setisForceAddon(res.data.result.force_addon);
                    if(res.data.result.force_addon == 2){
                        setChisaddon(true);
                        setChaddontitle(res.data.result.add_on);
                        setChaddprice(res.data.result.add_on_price);
                        if(res.data.result.default_sf > 0){
                        
                            setChaddonShippingFee(res.data.result.default_sf);
                        } else {
                            setChaddonShippingFee(res.data.result.shipping_fee);
                        }
                    } else {
                        setChisaddon(false);
                        setChaddontitle('');
                        setChaddprice('');
                        setChaddonShippingFee('');
                    }
                    
                    
                } else {
                    setIsaddon(1);
                    setisForceAddon(1);
                    
                    var new_price = res.data.result.art_price_fiat;
                }

                setPrice(new_price)

                setPrice(res.data.result.art_price_fiat)
                setPrice_text(res.data.result.art_price_fiat.toLocaleString("en-US"))
                setEthPrice(res.data.result.art_price_eth)

               }else{
                    swal({
                        title: "Error",
                        text: res.data.msg,
                        icon: "warning",
                      }).then((value) => {
                        window.location.reload()
                      });
                      resolve()
               }

            }).catch(error => {
            
                if(error.response.status == 401){
                    setRedirect(true)
                }
            });
          //  
        });

    }
    


    useEffect(() => {

        if(!allowed_art_bundle && !allowed_art_bundle == 2){
            if(localStorage.getItem("artX_")){
                apiClient.post(`${process.env.REACT_APP_API}/checkout/fiat/update_toke_id`, {
                    art_id: localStorage.getItem("artX_"),
                }).then(res => {
                    localStorage.removeItem("mytime");
                });
            }
        } else {
            if(localStorage.getItem("artX_")){
                apiClient.post(`${process.env.REACT_APP_API}/checkout/fiat/update_bundle`, {
                    bundle_no: localStorage.getItem("artX_"),
                }).then(res => {
                    localStorage.removeItem("artX_");
                });
            }
        }

            try { 
                apiClient.get(`${process.env.REACT_APP_API}/sale/public-sales/${props.purl}`,{withCredentials:true})
                .then(async res => {
                    console.log(res);
                    if(res.data.data.status.supply > 0){
                        setChainName(res.data.data.chain_name)
                        setChainCode(res.data.data.chain_code)
                        setAllowChainId(res.data.data.chaid_id)
                        setProject_name(res.data.data.info.project_name)
                        setPublic_note(res.data.data.info.public_sale_notes)
                        setProject_id(res.data.data.info.id)
                        setPrice_text(res.data.data.info.public_price_text)
                        setPrice(res.data.data.info.public_price)
                        setInfo(res.data.data.info)
                        setArtists(res.data.data.artists)
                        setRearity(res.data.data.rearity)
                        setDate_end(new Date(res.data.data.status.date_end).getTime())
                        setShedule_id(res.data.data.status.shedule_id)
                        setEthPrice(res.data.data.info.public_eth)
                        setAllowedArtBundle(res.data.data.info.allowed_art_bundle)
                        setSupply(res.data.data.status.supply)
                        setMaxSupply(res.data.data.status.sched.max_supply)

                        setDefaultSf(res.data.data.info.sf);

                        if(res.data.data.info.spinning_box != ''){
                            setSpinningBox(res.data.data.info.spinning_box)
                        } 

                        setBundles(res.data.data.bundles)
                        
                        //set discount note
                        if (res.data.data.bundles[0].discount_note) {
                            setDiscountNote(res.data.data.bundles[0].discount_note);
                        }

                        setPublicGuideLine(res.data.data.info.public_guideline);
                        setArtistCount(res.data.data.artist_count)

                        if(res.data.data.artist_count == 1){
                            setArtist(res.data.data.artists[0]['artist_id'])
                            
                             if(res.data.data.artists[0]['front_name'] == ''  || res.data.data.artists[0]['front_name']  == null){
                                setArtistName(res.data.data.artists[0]['artist']['artist_name']);
                             } else {
                                setArtistName(res.data.data.artists[0]['front_name']);
                             }
                            
                            reset({artist: res.data.data.artists[0]['artist_id']})
                        }
                        if (res.data.data.status.sched.free_message) {
                            setSchedFreeMessage(res.data.data.status.sched.free_message)
                        }
                        window.scrollTo(0, 0)
                    
                    if (res.data.data.info.allowed_art_bundle >= 2) {
                        if (await res.data.data.bundles.length == 1 && !artist) {
                            setValue("art_bundle_qty", res.data.data.bundles[0]['quantity'])
                            
                            setError_msg('')
                            apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/verify`, {
                                project_id: res.data.data.info.id,
                                artist_id: artist? artist : null,
                                qty: res.data.data.bundles[0]['quantity'],
                                withframe:with_frame,
                                allowed_art_bundle: res.data.data.info.allowed_art_bundle,
                                type: type
                            }).then(res => {
                                if(res.data.code == 200){

                                    if(res.data.result.free_type == 0 || res.data.result.free_type == 2 || res.data.result.free_type == 8){
                                        setWith_frame(false);
                                        setWithFreeMerch(false);
                                    } else if(res.data.result.free_type == 4){
                                    
                                        setWith_frame(true);
                                        setWithFreeMerch(true);
                                    
                                        setOptional(['I want '+res.data.result.free_message,'I want '+res.data.result.free_message2]);
                                    } else{
                                        setWith_frame(true);
                                        setWithFreeMerch(true);
                                    }
                    
                                
                    
                                    if(res.data.result.with_custom_field == 2){
                                        setWithCustomField(true);
                                        setCustomFieldLabel(res.data.result.cutom_label)
                                        
                                    } else if(res.data.result.with_custom_field == 3){
                                        setWithCustomField(true);
                                        setCustomFieldLabel(res.data.result.cutom_label)
                                        setdefaultOption(res.data.result.opt_value)
                                        setoptionImage(res.data.result.option_image)
                                        setoptionLabel(res.data.result.option_label)
                                    } else {
                                        setWithCustomField(false);
                                    }
                    
                                    
                                    setFreeType(res.data.result.free_type);
                                    setFreeMessage(res.data.result.free_message);
                                    setFreeMessage2(res.data.result.free_message2);
                                    setcustom_field_type(res.data.result.with_custom_field)
                    
                                        setArtBundleQty(res.data.result.qty)
                    
                                        setPrice(res.data.result.art_price_fiat)
                                        setPrice_text(res.data.result.art_price_fiat.toLocaleString("en-US"))
                                        setEthPrice(res.data.result.art_price_eth)
                                }else{
                                        swal({
                                            title: "Error",
                                            text: res.data.msg,
                                            icon: "warning",
                                        }).then((value) => {
                                            window.location.reload()
                                        });
                                        
                                }

                            }).catch(error => {
                            
                                if(error.response.status == 401){
                                    setRedirect(true)
                                }
                            });
                        }
                        setBundleCount(res.data.data.bundles.length)
                    }
                    
                    } 
                }) 
            } catch(e){
                console.log(e);
            }
    },[])
    
    if(checkout){
       
        return (<div><Checkout isForceAddon={isForceAddon}  chainCode={chainCode}  defaultsf={defaultSf}  chainName={chainName} allowChainId={allowChainId} chaddonShippingFee={chaddonShippingFee} chaddprice={chaddprice} chaddontitle={chaddontitle} addon={chisaddon} ChangeClient={props.onChangeClient} free_type={free_type} custom_field={customField} 
             free={freeMessage} artist_name={artistName} spinning={spinning_box} eth_price={ethPrice} shedule_id={shedule_id}  project_name={project_name} project_id={project_id} with_frame={with_frame} rearity={rearity} type={type} price={price} price_text={price_text}  artist={artist} allowed_art_bundle={allowed_art_bundle} art_bundle_qty={art_bundle_qty}/></div>)
    }

    if(redirect){
        return <Redirect to=""/> 
    }

    return (
        
       
         <section className="item-details-area pt-5">
            <div className="container">
                <div className="row justify-content-md-center pt-5 pb-5">
                    <div className='col-md-8'>
                    
                       <div className='row justify-content-md-center'>
                                <div className="col-lg-5 col-12">
                                    <img src={spinning_box} alt="art" className="img-fluid"/>
                                  
                                </div>
                                <div className="col-lg-7 col-12">
                                     
                                    <div className="content">
                                        <p className='mb-1 text-danger'>{discount_note}</p>
                                        <h4 className="mt-md-0 mt-2 mb-2 font-family-normal text-dark">PHP {price_text ? price_text : ""}</h4>
                                         <p className='my-1 text-dark'>OR</p>
                                         <h4 className="mt-md-0 mt-2 mb-2 font-family-normal text-dark">ETH {ethPrice ? ethPrice : ""} <img className='' style={{width: "150px"}} src='/img/gcrypto_dark.png'></img></h4>
                                        {
                                            error_msg ?  <div className='alert alert-danger mt-3'>{error_msg}</div> : ''
                                        }
                                        <form onSubmit={handleSubmit(onCheckout)}  className="item-form no-hover px-0 mt-2 ">
                                            
                                            {/* select artist */}
                                            <div className="form-group mt-2">
                                               
                                            <label className='text-dark'>Select Artist</label>
                                                <select className={`form-control ${ errors.artist ? "is-invalid" : ""}`} {...register("artist", { required: true, maxLength: 10 })} onChange={ e => changeArtist(e) } >
                                                { artistCount > 1 ? <option></option> : '' }
                                                   {
                                                      artists ? artists.map((val,id) => {
                                                          return (
                                                            <option data-artistname={val.front_name == '' || val.front_name == null  ? val.artist.artist_name : val.front_name} data-sample_art={val.sample_art} data-sample_addon={val.sample_addon_image} disabled={val.artist.arts_count == 0 ? true : null} value={val.artist_id} selected={artistCount <= 1 ? true : false}>{val.front_name == '' || val.front_name == null  ? val.artist.artist_name : val.front_name}{val.artist.arts_count == 0 ? ' - Sold out' : null} {val.show_soldout_counter == 2 ? ` - ${val.artist.arts_sold} / ${(max_supply / artistCount).toString()} Arts Sold` : ""}</option>
                                                          ) 
                                                       }) : ""
                                                   }
                                                </select>
                                                {errors.artist && <div className="invalid-feedback">Please select artist</div>}
                                            </div>
                                            { 
                                                sample_art ?
                                                <>
                                                    <a href="#" className="font-weight-normal" data-toggle="modal" data-target={`#modal-pomotion-${"sample_art"}`}>{artistName} Sample Art <span className='font-weight-bold' style={{color: "red"}}>View</span></a>
                                                    <ModalPromo img={ `${window.photo_url}storage/sample_art/${sample_art}` } id={"sample_art"} />
                                                </>
                                                : ''
                                            }

                                            {/* select quantity */}
                                            {
                                                allowed_art_bundle && allowed_art_bundle == 2 ? (
                                                    <>{
                                                        
                                                        bundle_count && bundle_count == 1 ? (
                                                            <div className="form-group mt-2 d-none pb-3 border-bottom">
                                                                <label className='text-dark pb-2'>Select Quantity</label>
                                                                {
                                                                    bundles ? bundles.map((val, id) => {
                                                                        return (
                                                                            <div className="form-check">
                                                                                <input className="form-check-input"  type="radio" {...register("art_bundle_qty", { required: true })} checked="true" onClick={ e =>verifyArtQty(val.quantity) } id={`flexRadioDefault${val.quantity}`}></input>
                                                                                <label className="form-check-label text-dark" for={`flexRadioDefault${val.quantity}`}>
                                                                                <span className='font-weight-bold'>{val.quantity} {val.private_lebel ? val.private_lebel : ''}</span>
                                                                                    {
                                                                                        val.free_type != 0  ?
                                                                                        <><br></br>
                                                                                            <small>
                                                                                                <span className="text-dark">+ {val.free_message} { val.free_type == 3 ? 'and' : val.free_type == 4 && val.free_type == 7  ?  'or' : ''} {val.free_message2 ?  val.free_message2 : ""}</span>
                                                                                                { 
                                                                                                    val.free_photo && val.free_type != 2 && val.free_type != 8 ?
                                                                                                    <>
                                                                                                    <a href="#" className="font-weight-bold" data-toggle="modal" data-target={`#modal-pomotion-${val.quantity}`} style={{color: "red"}}> View</a>
                                                                                                    <ModalPromo img={ val.free_photo } id={val.quantity} />
                                                                                                    </>
                                                                                                    : ''
                                                                                                }
                                                                                            </small>
                                                                                        </>
                                                                                        : ''
                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    }) : ""
                                                                }
                                                            </div>) : (
                                                                <div className="form-group mt-2 pb-3 border-bottom">
                                                                <label className='text-dark pb-2'>Select Quantity</label>
                                                                {
                                                                    bundles ? bundles.map((val, id) => {
                                                                        return (
                                                                            <div className="form-check">
                                                                                <input className="form-check-input"  type="radio" {...register("art_bundle_qty", { required: true })} onClick={ e =>verifyArtQty(val.quantity) } id={`flexRadioDefault${val.quantity}`}></input>
                                                                                <label className="form-check-label text-dark" for={`flexRadioDefault${val.quantity}`}>
                                                                                <span className='font-weight-bold'>{val.quantity} {val.private_lebel ? val.private_lebel : ''}</span>
                                                                                    {
                                                                                        val.free_type != 0  ?
                                                                                        <><br></br>
                                                                                            <small>
                                                                                                <span className="text-dark">+ {val.free_message} { val.free_type == 3 ? 'and' : val.free_type == 4 && val.free_type == 7  ?  'or' : ''} {val.free_message2 ?  val.free_message2 : ""}</span>
                                                                                                { 
                                                                                                    val.free_photo && val.free_type != 2 ?
                                                                                                    <>
                                                                                                    <a href="#" className="font-weight-bold" data-toggle="modal" data-target={`#modal-pomotion-${val.quantity}`} style={{color: "red"}}> View</a>
                                                                                                    <ModalPromo img={ val.free_photo } id={val.quantity} />
                                                                                                    </>
                                                                                                    : ''
                                                                                                }
                                                                                            </small>
                                                                                        </>
                                                                                        : ''
                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    }) : ""
                                                                }
                                                            </div>
                                                            )
                                                    
                                                    }</>
                                                    
                                                ) : ("")
                                            }

                                            {/* free type 4 || NFT or Merch */}
                                            {withFreeMerch && free_type == 4 ? <>
                                                <label className="text-dark" for="exampleCheck1">Please select your free</label>
                                                <select className=' form-control mb-2' onChange={onFreeChange}>
                                                    {
                                                    optional.map((val, id) => {
                                                        return <option value={id}>{val}</option>
                                                    })
                                                    }
                                                </select>
                                            </>
                                            : '' }

                                            {/* with free */}
                                            {withFreeMerch && free_type != 4 ? 
                                                <div className={`form-group form-check ${merch_is_force ? "d-none" : ""}`}>
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={with_frame} {...register("with_frame")}  onChange={ e => setWith_frame(e.target.checked) }/>
                                                    <label className="form-check-label text-dark pl-2 pt-1" for="exampleCheck1">I want my {freeMessage}</label>
                                                </div>
                                                : ''
                                            }

                                            {schedfreeMessage ? 
                                                <div className="form-group form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={with_frame} {...register("with_frame")}  onChange={ e => setWith_frame(e.target.checked) }/>
                                                    <label className="form-check-label text-dark pl-2 pt-1" for="exampleCheck1">{schedfreeMessage}</label>
                                                </div>
                                                : ''
                                             }

                                            {/* with free and custom text field */}
                                            { withCustomField && withFreeMerch && with_frame && custom_field_type == 2  ? 
                                                <div className="form-group">
                                                    <label className="text-dark" for="exampleCheck1">{customFieldLabel}</label>
                                                    <input type="text" className="form-control"  {...register("custom_field")} onChange={ e => setCustomField(e.target.value) } />
                                                </div>
                                                : ''
                                            }

                                            {/* with free and custom select field */}
                                            { withCustomField && withFreeMerch && with_frame && custom_field_type == 3 ? 
                                                <div className="form-group">
                                                    <label className="text-dark" for="exampleCheck1">{customFieldLabel}</label>
                                                        <select className=' form-control mb-2' {...register("custom_field")}  onChange={ e => setCustomField(e.target.value) }>
                                                            <option value=""></option>
                                                        {
                                                        defaultOption.map((val, id) => {
                                                            return <option value={val}>{val}</option>
                                                        })
                                                        }
                                                    </select>
                                                    <a href="#" className="font-weight-normal" data-toggle="modal" data-target='#modal-pomotion-bkt_custom_model'> {optionLabel}</a>
                                                    <ModalPromo img={ optionImage } size='modal-xl' id='bkt_custom_model' />
                                                </div>
                                                : ''
                                            }

                                            {isaddon == 2 ? 
                                                <div className="form-group form-check">
                                                    <input type="checkbox"   data-sf={addShippingFee} data-price={addPrice}  data-title={addonTitle} className="form-check-input" id="myaddons" checked={chisaddon} {...register("addons")}   onClick={ e => selectAddons(e) } />
                                                    <label className="form-check-label text-dark pl-2 pt-1" for="myaddons">
                                                        {addonTitle}
                                                        {
                                                            showAddOnShipping ? (<>
                                                                (+PHP{addPrice})  
                                                                {
                                                                    addShippingFee > 0 ? <> and (+PHP{addShippingFee} Shipping Fee)</> : ''
                                                                } 
                                                                </>) : ""
                                                            
                                                        }
                                                    {
                                                         add_on_image != '' ? 
                                                         <>&nbsp;
                                                          <a href="#" className="font-weight-bold " data-toggle="modal" data-target='#modal-pomotion-bkt_custom_model' style={{color: "red"}}> View Sample Here</a>
                                                          <ModalPromo img={ add_on_image } size='modal-xl' id='bkt_custom_model' />
                                                        </> : ''
                                                    }
                                                    </label>
                                                </div>
                                                : ''
                                            }
                                            
                                            {errorCustomField && <div className="text-danger">{errorCustomField}</div>}
                                            {errors.art_bundle_qty && <div className="text-danger">Please select quantity</div>}

                                            {public_note && <div className="px-4 py-3 border rounded text-dark" dangerouslySetInnerHTML={{ __html: public_note }} />}
                                            
                                            <button className="btn btn-smaller btn-dark mt-2" >
                                              {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                Checkout</button>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                { publicGuideLine ?                         
                <div className='row mt-3'>
                    <div className='col-12 border border-dark rounded-lg'  >
                    <h4 className='text-dark normal-font'>
                    For Public Sale Guide:
                    </h4>
                    <div className='text-dark pb-3' dangerouslySetInnerHTML={{__html: publicGuideLine}}>
                    </div>

                  

                    </div>
                   
                </div>
                :''
               } 
            </div>
            
        </section>
            
    );
};
 
export default PublicSalesForm;