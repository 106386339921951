import React, {useState, useEffect} from 'react';
//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import ModalLogin from "../../components/Modal/ModalLogin";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import VipBanner from '../../components/Banner/VipBanner';
import Tabs from '../../components/Vip/Tabs';
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";
import ModalRegister from '../../components/Modal/ModalRegister';
import {Helmet} from "react-helmet";
import { isMobile } from "react-device-detect";
import apiClient from '../../helpers/api';
import Skeleton from 'react-loading-skeleton';
import Checkout from "../../components/Shop/Checkout";

const Vip = (props) => {
    const[isCheckout, setIsCheckout]=useState('');
    const[vipData, setVipData]          = useState([]);
    const [changeClient, setChangeClient] = useState(false);
    const [banners, setBanners]  = useState('')
    const [isError, setIsError] = useState(false)
    const [vipid, setVipid_]  = useState(0)
    const [sourceCode, setSourceCode]  = useState('')
    const [isLoaded, setIsLoaded]  = useState(false)
    const [marginTopStyle, setMarginTopStyle]  = useState(0)
    const [vipStatus, setVipStatus]  = useState(0)
    const [vipHideSold,setHideSold]  = useState(0)
    const [showPeso,setShowPeso]  = useState(0)
    const [showArtistPerArt,setShowArtistPerArt]  = useState(0)
    const [hideProductTab,setHideProductTab]  = useState(0)
    const [displayPerArtist,setDisplayPerArtist]  = useState(0)
    const [artistBioBtnLabel,setArtistBioBtnLabel]  = useState('')

    const onChangeClient = (value) => {
        //
        setChangeClient(value)
    }
    const fetchData = async () => {
        try{
            apiClient.get(`${process.env.REACT_APP_API}/vip/${props.match.params.url}/${props.match.params.code}`)
            .then( res => {
                
                setShowPeso(res.data.result.show_peso)
                setShowArtistPerArt(res.data.result.show_artist_per_art)
                setHideProductTab(res.data.result.hide_product_tab)
                setDisplayPerArtist(res.data.result.display_per_artist)
                setArtistBioBtnLabel(res.data.result.artist_bio_btn_label)
                setSourceCode(props.match.params.code);
                setIsError(false);
                setMarginTopStyle(isMobile ? 0 : 95)
                if(res.data.result.status == 3){
                    setBanners(isMobile ? res.data.result.onhold_mobile_banner : res.data.result.onhold_image)
                } else {
                    setBanners(isMobile ? res.data.result.baner_image : res.data.result.banner)
                }
                setHideSold(res.data.result.hide_sold)
                setVipid_(parseInt(res.data.result.id));
                setIsLoaded(true);
                setVipStatus(res.data.result.status);
                apiClient.get(`${process.env.REACT_APP_API}/vip/get/reserve_art/${props.match.params.url}`)
                    .then(res => {
                }).catch( err_ => {
                })
            }).catch( err => {
                setIsError(true);
            })
            //  const res = await apiClient.get(`${process.env.REACT_APP_API}/vip/${props.match.params.url}/${props.match.params.code}`);
            //  setSourceCode(props.match.params.code);
            //  setIsError(false);
            //  setBanners(isMobile ? res.data.result.baner_image : res.data.result.banner)
            //  setVipid_(parseInt(res.data.result.id));
        } catch(error){
            setIsError(true);
        }
    }
    
    useEffect(() => {
        fetchData();
    }, [])

    if (isError) {
        return <PageNotFound url={props.location.pathname}/>
    }

    return ( 
        <>
        <Helmet>
            <title>Scarletbox VIP </title>
            <meta name="robots" content="noindex,nofollow" data-react-helmet="true" />
        </Helmet> 
          
        <div className="main">
            <PageLoading  />
            
            <Header/> 
            {vipStatus}
            {isLoaded ? <VipBanner marginTopStyle={marginTopStyle} banner={banners} /> : <section className="carousel-bg align-items-center pb-0"><Skeleton height={200}/></section>}
            {
                vipStatus != 3 ?
                !isCheckout 
                ? <Tabs artistBioBtnLabel={artistBioBtnLabel} displayPerArtist={displayPerArtist} hideProductTab={hideProductTab} showArtistPerArt={showArtistPerArt} showPeso={showPeso} viphidesold={vipHideSold} vipdata={setVipData} stateChanger={setIsCheckout} vipid={props.match.params.url} partner={props.match.params.code} />
                : <Checkout onChangeClient={onChangeClient}  vipdata={vipData} stateChanger={setIsCheckout} />
                : ''
            }
       
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <ModalLogin/>
            <ModalRegister/>
            <Scrollup />
        </div>
        </>
    );

}


 
export default Vip;