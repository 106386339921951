import React, {useState, useEffect} from 'react';
import apiClient from "../../helpers/api";
const Faq = (props) => {
    const [faq, setFaq] = useState([])
    useEffect(() => {
        

        apiClient.get(`${process.env.REACT_APP_API}/vip/get/faq/${props.vipid}`)
            .then(async res => {
                setFaq(res.data.results);
        }).catch( err => {
            
        })

    }, []);

    return (
        <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
            <h3 className='text-dark'>General FAQs</h3>
            <div className="accordion" id="netstorm-accordion">
                <div className="row justify-content-center">
                    <div className="col-12 p-0">
                        {
                            faq.map((faq_val, faq_id)=> {
                                return (
                                     <div key={faq_id} className="single-accordion-item p-0 px-0 pb-3">
                                        <div className="bg-inherit border-0 p-0">
                                            <button className="btn d-block border border-dark text-left w-100 py-4 text-dark" type="button" data-toggle="collapse" data-target={`#collapseFaq${faq_id}`}>
                                                <span className="font-weight-normal">{faq_val.question}</span>
                                            </button>
                                        </div>
                                        <div id={`collapseFaq${faq_id}`} className="collapse" data-parent="#netstorm-accordion">
                                            <div className="card-body py-3" dangerouslySetInnerHTML={{__html: faq_val.answer}}>
                                            </div>
                                        </div>
                                    </div>
                                )
                             })
                        }  
                    </div>
                </div>
            </div>
        </div>
    );

};
export default Faq;
