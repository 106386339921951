import React, {useState, useReducer, useEffect} from 'react';
import { useCookies } from "react-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import apiClient from "../../helpers/api";
import ArtSkeleton from '../../Skeleton/Vip/ArtSkeleton';
import { isMobile } from "react-device-detect";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ImageSkeleton from '../../Skeleton/Vip/ImageSkeleton';
import ModalPromo from "../Modal/ModalPromo";
import ModalArtist from '../Modal/ModalArtist';


const Collections = (props) => {

    const [data, setData] = useState([])
    const [origPrice,setOrigPrice] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [checkout,setCheckout] = useState(false)
    const [artId, setArtId] = useState(0)
    const [merchImage, setMerchImag] = useState('')
    const [currentPlaying, setcurrentPlaying] = useState(0)
    const [reseverAllowedCount, setReseverAllowedCount] = useState(0)
    const [transactionType, setTransactionType] = useState(0)
    const [ignored,forceUpdate] = useReducer(x => x +1,0)
    const playIcon = <FontAwesomeIcon  icon="fa-solid fa-play" size='lg' />;
    const pauseIcon = <FontAwesomeIcon  icon="fa-solid fa-pause" size='lg'/>;
    const { handleSubmit, formState } = useForm();
    const [reserveArts, setReserveArts] = useState(['']);
    const [errorMsg, setErrorMsg] = useState('');
    const [tempUserCode, setTempUserCode] = useCookies(['temp_code']);
    const [error, setError] = useState()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [userId, setUserId] = useState('')
    const [theVipId, setTheVipId] = useState(0)
    const [totalArtist, setTotalArtist] = useState(0)
    const [counter, setCounter] = useState(1)
    const [vipData, setVipData] = useState([])
    const [selectedArtist, setSelectedArtist] = useState('')
    const [selectedLabel, setSelectedLabel] = useState('')
    const [selectedArtCode, setArtCode] = useState(0)
    const [totalArtReseverd, setTotalArtReseverd] = useState(0)
    const [loadedImage, setloadedImage] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [artistTabActive, setArtistTabActive] = useState('')
    const [noArt, setNoArt] = useState(false)
    const [artistId,setArtistId] = useState(0)
   
    const { isSubmitting} = formState;
    const [isloaded, setIsLoaded] = useState(false)
    const [isloadedart, setIsLoadedart] = useState(false)

    const playBtn = {
        position: "absolute",
        background: "rgb(0 0 0 / 30%)",
        left:"45%",
        top: '25%',
        border:"0px",
        color:"#fff"
    };
    const noBorderRadius = {
        borderRadius:"0px !important",
        
    }
    const soldOut = {
        background:"#f9f9f9"
    }
   
    

    const header = {
        background: "#222",
    }

    const body = {
        background: "#cbc7c7",
    }

    const small = {
        fontSize:"12px"
    }
    const noBorder = {
        borderRadius:"0px",
        color:"#ffffff"
    }
    const vidPlay = (e) => {
        let currn = e.target.getAttribute('data-id');
        if(currentPlaying == e.target.getAttribute('data-id')){
            e.target.pause();
            setcurrentPlaying(0);
        } else {
            setcurrentPlaying(currn);
            e.target.play();
        }
       
    }


    let xy = 0;


    const clearSearch = () => {

        
        document.querySelector(`#search`).value = "";
        setArtistTabActive('');
        setSearchKey('');
       // forceUpdate()
    }
    
    const onSearchBytab = (e) => {
        setArtistTabActive(e.target.getAttribute('data-tabid'));
        setSearchKey(e.target.value);
    }

    const openModal = (e) => {
        setTotalArtReseverd(parseInt(e.target.getAttribute('data-reservation_')) + 1)
        setArtId( e.target.getAttribute('data-art'));
        setSelectedArtist( e.target.getAttribute('data-artist'));
        setSelectedLabel( e.target.getAttribute('data-label'));
        setArtCode( e.target.getAttribute('data-artcode'));
        setMerchImag(e.target.getAttribute('data-img'));
        setShowModal(true);
    }

    const buyNow = (e) => {
        props.stateChanger('yes')
        props.vipdata({ 
            'merch_details' : e.target.getAttribute('data-merch_details'),
            'merch_label': e.target.getAttribute('data-merch_label'),
            'art_id': e.target.getAttribute('data-art'),
            'artist': e.target.getAttribute('data-artist'),
            'art_code': e.target.getAttribute('data-artcode'),
            'label':  e.target.getAttribute('data-label'),
            'free': vipData.free,
            'price':  e.target.getAttribute('data-price') ? e.target.getAttribute('data-price') : vipData.price,
            'project_id': vipData.project_id ? vipData.project_id : e.target.getAttribute('data-project_id'),
            //'eth_price': vipData.eth_price,
            //'eth_price':  e.target.getAttribute('data-eth_price'),
            'vip_id': theVipId,
            'artist_id':e.target.getAttribute('data-artist_id'),
            'reserve_id':e.target.getAttribute('data-reservation_'),
            'bundle':e.target.getAttribute('data-bundle'),
            'eth_price':e.target.getAttribute('data-eth_price'),
            'source':e.target.getAttribute('data-source'),
            'sf':vipData.sf,
            'merch' : e.target.getAttribute('data-img'),
            'art_img_type' : e.target.getAttribute('data-art_img_type'),
            'art_img' : e.target.getAttribute('data-art_img'),
            'with_meta':e.target.getAttribute('data-with_meta'),
            'sales_type':4,
            'upsell':e.target.getAttribute('data-upsell'),
            'vip_art_id':e.target.getAttribute('data-vip_art_id'),
            'upsel_label':e.target.getAttribute('data-upsel_label'), 
        })
    }

    const isImageLoaded = (e) => {
        let varn = e.target.getAttribute('data-id');
        setloadedImage(oldFiles => [...oldFiles,varn]);
    }

    useEffect(() => {

        apiClient.get(`${process.env.REACT_APP_API}/vip/get/all_arts/${props.vipidc}`)
            .then(async res => {
                
                setTotalArtist(res.data.total_artist);
                setTheVipId(res.data.vip_id);
                setData(res.data.results);
                setReseverAllowedCount(parseInt(res.data.resever_allowed_count));
                setTransactionType(res.data.type);
                setIsLoaded(true)
                setIsLoadedart(true)
                setVipData({
                    price:res.data.price,
                    project_id: res.data.project_id,
                    free: res.data.free,
                    eth_price: res.data.eth_price,
                    sf:res.data.sf
                })
                

                setOrigPrice(res.data.price)
        }).catch( err => {
            console.log(err)
        })

    }, [ignored])
    return (
        <>
        {isloadedart ?
        <div className="tab-pane fade show active" id="collection" role="tabpanel" aria-labelledby="collection-tab">
            <div className="row justify-content-end mt-3">
                        <div className="col-md-4 align-self-end ">
                        <input type="text" id={`search`} className="form-control mb-2 mr-sm-2" onChange={ e => onSearchBytab(e) } placeholder="Search Artist or Title"></input>
                        </div>
            </div>
            <div class="row">
                { 
                    searchKey != '' ?
                        <div className="col-12 col-md-12 p-2 mb-2" style={soldOut}>
                            <p className='text-dark'>Result found for <b>"{searchKey}"</b> key. <a style={{cursor:'pointer'}} className='text-danger' onClick={() => clearSearch()}>Clear Search</a></p>
                        </div>
                    : ''
                }
                {
                    data.length == 0 && searchKey == '' ? 
                    <div className="col-12 col-md-12 text-center pt-5 pb-5" style={soldOut}>
                        <h3 className='text-dark'>All Arts are Sold Out!</h3>
                    </div>
                    : ''
                }
                {
                data.map((art_details, idx)=> { 
                    return(
                        <div class="col-md-4 mb-2" style={{display:art_details.label.toLowerCase().indexOf(searchKey) >= 0 || art_details.details.art_name.toLowerCase().indexOf(searchKey)  ? 'Block' : 'none' }}>
                            <div class="border p-2">
                            {
                               art_details.replace_uri != null ?
                                <>
                                    <ImageSkeleton  style_={loadedImage.indexOf(art_details.replace_uri) >= 0 ? "none" : "block"}/>
                                    <img onLoad={ (e) => isImageLoaded(e)} style={{display:loadedImage.indexOf(art_details.replace_uri) < 0 ? "none" : "block"}} data-id={art_details.replace_uri} src={art_details.replace_uri} className='img-fluid'></img>
                                </>
                                : 
                                art_details.extension == 'mp4' ?
                                <div className="image-wrapper" style={{background:"#000"}}>
                                    {
                                        !isMobile ?  <a style={playBtn}>{currentPlaying == art_details.art_id ? pauseIcon : playIcon}</a> : ''
                                    }
                                    <video  preload="auto" muted= {isMobile ? true : false} autoPlay= {isMobile ? true : false} controls= {isMobile ? true : false} playsInline loop onClick={e => vidPlay(e)} data-id={art_details.art_id} id={`video${art_details.art_id}`}  className="embed-responsive-item " src={art_details.uri} style={{width: "100%",height:"380px"}} ></video>
                                </div>
                                : <>
                                    <ImageSkeleton  style_={loadedImage.indexOf(art_details.uri) >= 0 ? "none" : "block"}/>
                                    <img onLoad={ (e) => isImageLoaded(e)} style={{display:loadedImage.indexOf(art_details.uri) < 0 ? "none" : "block"}} data-id={art_details.uri} src={art_details.uri} className='img-fluid'></img>
                                  </>
                            }
                                <h5 class="text-dark mb-0 mt-2" dangerouslySetInnerHTML={{__html: art_details.label}}></h5>
                                <p class="text-dark mb-2 mt-1 " >{art_details.details.art_name}</p>
                                <p class="text-dark mb-2 mt-1 ">{art_details.artist_name}</p>
                                <p class=" mb-2 mt-1">
                                    <span class="font-weight-bold">Price:</span> PHP
                                    {
                                        art_details.price != null && art_details.price != '' ? 
                                            art_details.price.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})
                                        :  origPrice.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})

                                    }
                                     
                                </p>
                                <hr className='my-2'></hr>
                                {
                                art_details.merch_image != '' || art_details.merch_image != null ?
                                
                                <div class="col-md-12  py-1 px-0 mb-1">
                                    <a class="text-danger text-uppercase font-weight-bold mb-1  pl-0" data-toggle="modal" data-target={`#modal-pomotion-${art_details.art_id}`} href="#">
                                    {art_details.merch_label ? art_details.merch_label : 'CLICK TO VIEW SET'}
                                    </a>
                                    <ModalPromo img={ art_details.merch } id={art_details.art_id} />
                                </div>
                                 
                                : ''
                                }
                                
                                {
                                props.showArtistPerArt == 2 ?
                                <div class="col-md-12  py-1 px-0 mb-1">
                                    <a class="text-dark text-uppercase font-weight-bold mb-1 pl-0" data-toggle="modal" data-target={`#modal-artist-${art_details.artist_id}`} href="#">
                                    {props.artistBioBtnLabel ? props.artistBioBtnLabel : 'CLICK TO ARTIST BIO'}
                                    </a>
                                    <ModalArtist content={art_details} />
                                </div>
                                : '' }

                                {
                                    transactionType == 1 ? 
                                        reseverAllowedCount <= art_details.reservation_ ?
                                        <div class="col-md-12  py-1 px-0"><button className='btn text-uppercase btn-dark text-white btn-block rounded' style={{borderRadius: 0,fontWeight:'normal'}}>Reserved</button></div>
                                        :  reserveArts.indexOf(art_details.art_id) >= 0 ?
                                        <div class="col-md-12  py-1 px-0"> <button className='btn btn-sm text-uppercase btn-dark text-white btn-block rounded' style={{borderRadius: 0,fontWeight:'normal'}}>Reserved</button></div>
                                        : <div class="col-md-12  py-1 px-0"><button onClick={e => openModal(e)} data-artist={art_details.artist_name} data-label={art_details.label} data-artcode={art_details.art_code} data-reservation_={art_details.reservation_} data-art={art_details.art_id} data-img={art_details.merch} className='btn btn-sm text-uppercase btn-danger btn-block' style={{borderRadius: 0,fontWeight:'normal'}}> I want to reserve this</button></div>

                                    : art_details.fake_status == null  ? 
                                        art_details.status == 3 ?
                                        <div class="col-md-12  py-1 px-0"><button className='btn btn-sm text-uppercase btn-dark text-white btn-block rounded' style={{borderRadius: 0,fontWeight:'normal'}}>SOLD</button></div>
                                        : <div class="col-md-12  py-1 px-0">
                                            <button  data-upsel_label={art_details.upsell_label} data-vip_art_id={art_details.id} data-upsell={art_details.enable_upsell} data-with_meta={art_details.with_meta}  onClick={e => buyNow(e)} data-merch_details={art_details.merch_details ? art_details.merch_details : ''} data-merch_label={art_details.merch_label ? art_details.merch_label : 'CLICK TO VIEW SET'} data-project_id={art_details.project_id} data-art_img_type={art_details.extension} data-art_img={art_details.uri} data-sf={art_details.sf} data-source={props.partner} 
                                            data-eth_price={art_details.eth_price != null && art_details.eth_price != '' ? art_details.eth_price : art_details.eth_price} 
                                            data-price={art_details.price != null && art_details.price != '' ? art_details.price : origPrice}   data-bundle={art_details.is_bundle} data-reservation_={art_details.reservation_} data-artist_id={art_details.artist_id} data-artist={art_details.artist_name} data-label={art_details.label} data-artcode={art_details.art_code}  data-art={art_details.art_id} data-img={art_details.merch}   style={{borderRadius: 0}} class="btn btn-danger btn-block rounded px-5 mb-1" >
                                            Collect Now
                                            </button>
                                          </div>
                                    : <div class="col-md-12  py-1 px-0"><button className='btn btn-sm text-uppercase btn-dark text-white btn-block rounded' style={{borderRadius: 0,fontWeight:'normal'}}>{art_details.fake_status }</button></div>
                                }
                                
                                
                               
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </div>
        
        : <div className="row"><ArtSkeleton arts={6}/></div> }
        
        </>
    )
}
export default Collections; 