import React, {useState, useEffect} from 'react';
import PrivateSaleCollection from '../../components/Collections/PrivateSaleCollection';
import AuctionSaleCollection from '../../components/Collections/AuctionSaleCollection';
import PublicSaleCollection from '../../components/Collections/PublicSaleCollection';


const SaleCollection = (props) => {
    console.log(props);
    if ((props.public && props.public.status === 200) && (props.private && props.private.status === 201)) {
        return (

            <section className="artist mb-0 mt-0 " style={{backgroundImage: `url(${"../../img/new_banner/Common_Background.jpg"})`}}>
                    {
                        props.private && props.private.status === 301 ? (
                            <PrivateSaleCollection params={props.params} />
                        ) : ""
                    }
                    <PublicSaleCollection params={props.params}  />
                {/* <AuctionSaleCollection params={props.params}  /> */}
            </section> 
        );
    }

    if ((props.private && props.private.status === 200) && (props.public && props.public.status === 201)) {
        return (

            <section className="artist mb-0 mt-0 "  style={{backgroundImage: `url(${"../../img/new_banner/Common_Background.jpg"})`}}>
                    <PrivateSaleCollection params={props.params}  />
                    {
                        props.public && props.public.status === 301 ? (
                            <PublicSaleCollection params={props.params} />
                        ) : ""
                    }
                {/* <AuctionSaleCollection params={props.params}  /> */}
            </section> 
        );
    }

    return (

      
        <section className="artist mb-0 mt-0"  style={{backgroundImage: `url(${"../../img/new_banner/Common_Background.jpg"})`}}>
            <PrivateSaleCollection params={props.params}  />
            <PublicSaleCollection params={props.params}  />
            {/* <AuctionSaleCollection params={props.params}  /> */}
        </section> 
    );

}

export default SaleCollection;