import React, {useState, useEffect} from 'react';

import apiClient from '../../helpers/api';
import { useParams } from 'react-router';


//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import ModalLogin from "../../components/Modal/ModalLogin";
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import SaleCollection from '../../components/Collections/SaleCollection';
import Artist from '../../components/Shortcodes/Artist';
import ProjectCarousel from '../../components/Banner/ProjectCarousel';
import ProjectSneakPeek from '../../components/Shortcodes/ProjectSneakPeek';
// import Banner_Carousel from '../../components/Banner/Carousel';
import RoadMap from '../../components/RoadMap/RoadMap';
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";
import PageRoadMap from '../../components/RoadMap/PageRoadMap';
import Banner from '../../components/Banner/Banner';
import ModalRegister from '../../components/Modal/ModalRegister';



const RoadMapPage = () => { 

    const [data, setData] = useState({})
    const [isError, setIsError] = useState(false)


    useEffect(() => {
        apiClient.get(`${process.env.REACT_APP_API}/page`)
        .then(res => {
            // console.log(res.data.data.projects[0]);
            setData(res.data.data.projects)
            setIsError(false)

            
        }).catch( err => {

            console.log(err);
            setIsError(true)
        })


    }, [])

    if (isError) {
        return <PageNotFound />
    }

    return (
     
        <div className="main">
            <PageLoading />
            <Header/>
            <Banner title="Road Map" />
           {
                data.length == 0 || null ?  null : <PageRoadMap params={data} /> 
           }
            
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <ModalLogin/>
            <ModalRegister/>
            <Scrollup />
        </div>
    );

}


 
export default RoadMapPage;