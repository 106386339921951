import React, {useEffect, useState}from "react";

import apiClient from "../../helpers/api";
import { useForm } from "react-hook-form";
import Alert from 'react-bootstrap/Alert';

const ForgotPassword = () => {

    const { handleSubmit, formState } = useForm();
    const [email, setEmail] = useState('');
    const { isSubmitting } = formState;
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});

    const onSubmit = () => {
        

        return new Promise(resolve => {

            apiClient.post(`/auth/forgot_password`, { 
                email: email,
            }).then(response => {
                setData(response.data)
                setShow(true)
                resolve();
            }).catch(function (error) {
                
                setData(error.response.data)
                setShow(true)
                resolve();
            })

        });
    }

    return(
        <section className="item-details-area mt-1">
            <div className="container">
                <div className="row xbtk">
                    <div className='col-md-7 mx-auto'>
                        <div className='red-border bg-dark my-3'>
                            <div className='card-body py-5 px-4'>
                                <h2 className="text-center my-0 text-white">ScarletBox.io</h2>
                                <hr className="w-50 mx-auto my-2"></hr>
                                <p className="text-center my-0 text-white">Forget Password</p>
                                <Alert show={show} variant={data && data.code == 200 ? "success" : "danger"}>
                                    <p>{data.msg}</p>
                                </Alert>
                                <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                                    <div className="form-group">
                                        <input type="email" onChange={ e => {setEmail(e.target.value)}} className="form-control" placeholder="Email" id="emailForgetPassword" aria-describedby="emailHelp"/>
                                        <button disabled={isSubmitting}  className="btn btn-danger mt-3 btn-lg btn-block rounded-0" type="submit">
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword;