import React, { Component } from 'react';

import ModalBid from '../Modal/ModalBid';

const initData = {
    itemImg: "/img/auction_2.jpg",
    date: "2022-03-30",
    tab_1: "Bids",
    tab_2: "History",
    tab_3: "Details",
    ownerImg: "/img/avatar_1.jpg",
    itemOwner: "Themeland",
    created: "15 Jul 2021",
    title: "JOYA x BALDOVINO",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    price_1: "1.5 ETH",
    price_2: "$500.89",
    count: "1 of 5",
    size: "14000 x 14000 px",
    volume: "64.1",
    highest_bid: "2.9 BNB",
    bid_count: "1 of 5",
    btnText: "Place a Bid"
}

const tabData_1 = [
    {
        id: "1",
        img: "/img/avatar_1.jpg",
        price: "14 ETH",
        time: "4 hours ago",
        author: "@arham"
    },
    {
        id: "2",
        img: "/img/avatar_2.jpg",
        price: "10 ETH",
        time: "8 hours ago",
        author: "@junaid"
    },
    {
        id: "3",
        img: "/img/avatar_3.jpg",
        price: "12 ETH",
        time: "3 hours ago",
        author: "@yasmin"
    }
]

const tabData_2 = [
    {
        id: "1",
        img: "/img/avatar_6.jpg",
        price: "32 ETH",
        time: "10 hours ago",
        author: "@hasan"
    },
    {
        id: "2",
        img: "/img/avatar_7.jpg",
        price: "24 ETH",
        time: "6 hours ago",
        author: "@artnox"
    },
    {
        id: "3",
        img: "/img/avatar_8.jpg",
        price: "29 ETH",
        time: "12 hours ago",
        author: "@meez"
    }
]

const sellerData = [
    {
        id: "1",
        img: "/img/avatar_1.jpg",
        seller: "@ArtNoxStudio",
        post: "Creator"
    },
    {
        id: "2",
        img: "/img/avatar_2.jpg",
        seller: "Virtual Worlds",
        post: "Collection"
    }
]

const bids = [
    {
        "id": 1,
        "amount": "89,000",
        "date": "2022-03-30",
        "bidder": "NFTjunkie"
    },
    {
        "id": 2,
        "amount": "90,000",
        "date": "2022-03-30",
        "bidder": "StepCoGaming"
    },
    {
        "id": 3,
        "amount": "95,000",
        "date": "2022-03-30",
        "bidder": "blockshane"
    },
    {
        "id": 4,
        "amount": "150,000",
        "date": "2022-03-30",
        "bidder": "amaterasu"
    }
]

class ItemDetails extends Component {
    state = {
        initData: {},
        tabData_1: [],
        tabData_2: [],
        sellerData: [],
        bids: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            tabData_1: tabData_1,
            tabData_2: tabData_2,
            sellerData: sellerData,
            bids: bids
        })
    }

    render() {
        return (
            <section className="item-details-area bg-grey">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-5">
                            <div className="item-info">
                                <div className="item-thumb text-center">
                                    <img src="/img/artwork/Mock Artwork 1.jpg" alt="" />
                                </div>
                                <div className="card bg-grey shadow-none countdown-times countdown-pri my-4">
                                    <div className="countdown d-flex justify-content-center" data-date={this.state.initData.date} />
                                </div>
                                <div className='px-5'><p>{this.state.initData.content}</p></div>
                                
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Content */}
                            <div className="content mt-5 mt-lg-0">
                                <h3 className="m-0 color-primary normal-font">{this.state.initData.title}</h3>
                                <h4 className='text-secondary normal-font my-2'>NFT 1</h4>
                                <div className="countdown-times countdown-pri">
                                    <div className="countdown d-flex justify-content-center" data-date={this.state.initData.date} />
                                </div>
                                <div className='mt-3'>
                                    <h5 className='mb-0 text-secondary normal-font'>HIGHEST BID</h5>
                                    <h3 className='m-0 text-secondary normal-font'>500 ETH</h3>
                                    <p className='mt-0 text-secondary normal-font'>OR PHP 150,000</p>
                                </div>
                                <button className="btn-block btn btn-primary mt-4" data-toggle="modal" data-target="#modal-bid">{this.state.initData.btnText}</button>
                                <p className='text-center'>BIDDING STARTS AT PHP 89,000</p>
                                <div className='mt-3'>
                                <h4 className='mb-0 text-secondary normal-font'>BIDS</h4>

                                    <div className='table-responsive'>
                                        <table className="table table-main">
                                            <thead>
                                                <tr>
                                                    <th>BID</th>
                                                    <th>BID DATE</th>
                                                    <th>BIDDER</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.bids.map(bid => {
                                                    return  <tr key={`key_${bid.id}`}>
                                                        <td>{bid.amount}</td>
                                                        <td>{bid.date}</td>
                                                        <td>{bid.bidder}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <ModalBid/>
            </section>
        );
    }
}

export default ItemDetails;