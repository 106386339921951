import React, {Component} from "react";
import axios from "axios";

//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';

//new
import ModalLogin from "../../components/Modal/ModalLogin";
// import PrivateSaleBox from "../../components/Sale/PrivateSaleBox";
// import Banner from "../../components/Banner/Banner";
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import Checkout from "../../components/Checkout/Checkout";

class CheckoutBox extends Component {

    state = {
        data: [],
        content: ''
        
    };
    componentDidMount(){
        axios.get('http://localhost:3000/json-server/data.json')
        .then(res => {
            this.setState({
                data: res.data,
                content: res.data.private_sale,
               
            })
        })
    }

    render() { 
        return (
            <div className="main">
                <Header/>
                <PageLoading />
                <Checkout />
                
            </div>
        );
    }
}
 
export default CheckoutBox;