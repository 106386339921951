import React from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Banner from "../../components/Banner/Banner";
import ModalMenu from "../../components/Modal/ModalMenu";
import BannerSale from "../../components/Banner/BannerSale";
import BulkReveal from "../../components/BulkReveal/BulkReveal";

const BulkRevealPage = () => {


    return (
        <>
            <Header/>
            <BannerSale title="Bulk Reveal"/>
            <BulkReveal/>
            <ModalMenu />
            <Footer/>
        </>
    )


}

export default BulkRevealPage;