import React, {Component} from "react";

const data = [
    {
        id: "1",
        img: "/img/artist/Abdulmari Imao.jpg",
        date: "2021-12-09",
        title: "Virtual Worlds",
        seller_thumb: "/img/avatar_1.jpg",
        artist: "ABDULMARI IMAO",
        content: 'Abdulmari Imao was a Filipino painter and sculptor. Imao was named National Artist of the Philippines for Sculpture in 2006. A Tausug, Imao is the first Moro to receive the recognition'
    },
    {
        id: "2",
        img: "/img/artist/Jose Joya.jpg",
        date: "2021-10-05",
        title: "Collectibles",
        seller_thumb: "/img/avatar_2.jpg",
        artist: "JOSE JOYA",
        content: 'Abdulmari Imao was a Filipino painter and sculptor. Imao was named National Artist of the Philippines for Sculpture in 2006. A Tausug, Imao is the first Moro to receive the recognition'
    },
    {
        id: "3",
        img: "/img/artist/Toym Imao.jpg",
        date: "2021-09-15",
        title: "Arts",
        seller_thumb: "/img/avatar_3.jpg",
        artist: "TOYM IMAO",
        content: 'Abdulmari Imao was a Filipino painter and sculptor. Imao was named National Artist of the Philippines for Sculpture in 2006. A Tausug, Imao is the first Moro to receive the recognition'
    },
    {
        id: "4",
        img: "/img/artist/Andre Baldovino.jpg",
        date: "2021-12-29",
        title: "Robotic Arts",
        seller_thumb: "/img/avatar_4.jpg",
        artist: "ANDRE BALDOVINO",
        content: 'Abdulmari Imao was a Filipino painter and sculptor. Imao was named National Artist of the Philippines for Sculpture in 2006. A Tausug, Imao is the first Moro to receive the recognition'
    },
]

class FeaturedArtist extends Component {

    state = {
        data: []
    }
    componentDidMount(){
        this.setState({
            data: data
        })
    }

    render() { 
        return (
            <>
                <div className="featured-artist-section" style={{backgroundColor: "#878787"}}>
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="section_title text-white text-center">Featured Artists</h1>

                                <div className="auctions-slides">
                                    <div className="swiper-container slider-mid-2 items">
                                        <div className="swiper-wrapper">
                                            {/* Single Slide */}
                                            {this.state.data.map((item, idx) => {
                                                return (
                                                    <div key={`${idx}`} className="swiper-slide item">
                                                        <div className="card bg-white rounded-0">
                                                            <p className="text-center font-weight-bold">{item.artist}</p>
                                                            <div className="image-over mx-auto">
                                                                <a href="/item-details">
                                                                    <img width={350} src={item.img} alt="" />
                                                                </a>
                                                            </div>
                                                            <p>{item.content}</p>
                                                            <button className="btn">LEARN MORE</button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="swiper-pagination" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default FeaturedArtist;