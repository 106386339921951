import React from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import BannerSale from "../../components/Banner/BannerSale";

const ForgotPasswordPage = () => {

    return (
        <div className="main">
            <PageLoading />
            <Header/>
            {/* <BannerSale title="Forget Password"/> */}
            <ForgotPassword />
            <Footer />
        </div>
    )

}

export default ForgotPasswordPage;