import React, {Component} from "react";
import axios from "axios";

//import components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import ModalLogin from "../../components/Modal/ModalLogin";
import Banner from "../../components/Banner/Banner";




class WhatIsNft extends Component {
    state = {
        data: [],
        video_link: '',
        banner: ''
    };
    componentDidMount(){
        axios.get('http://localhost:3000/json-server/data.json')
        .then(res => {
            this.setState({
                data: res.data,
                video_link: res.data.what_is_nft.video_link,
                banner: res.data.what_is_nft.banner
                
            })
        })
    }

    render() { 
        return (
            <div className="main">
                <Header/>
                <Banner image={this.state.banner}/>
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <h5 className="text-danger normal-font">What is an NFT?</h5>
                        <p>NFT Stands for Non-Fungible Tokens. These are unique assets whose ownership is recorded and stored on a digital ledger or blockchain. Each unique asset is represented in the form of a smart contract which points to an artwork, music, real estate and gaming characters.</p>
                        <p>Watch this video to find out more about NFT.</p>
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" src={this.state.video_link} allowfullscreen></iframe>
                        </div>
                    </div>
                </div>

                <Footer />
                <ModalSearch />
                <ModalMenu />
                <ModalLogin/>
                <Scrollup />
            </div>
        );
    }
}
 
export default WhatIsNft;