import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { isMobile } from "react-device-detect";
const ArtSkeleton = ({arts}) => {
    return  Array(arts).fill(0).map(item => ((
        <div className={isMobile ? ' col-md-4 pl-0 pr-0 my-2 ' : ' col-md-4 pl-0 my-2 '}>
            <Skeleton height={200}/>
            <a><Skeleton/></a>
            <a><Skeleton/></a>
        </div>
        
       ))
    )
}

export default ArtSkeleton 