import React, {Component} from "react";

class ProjectSneakPeek extends Component {

    render() { 
    //    if(Object.keys(this.props.params).length && this.props.sneak.length === 0 || null) {
    //         return  null;
    //    }

  
       
    // console.log(this.props)
        return (
            <>
            <section className="live-auctions-area sneak-peek pt-5"  style={{backgroundImage: `url(${"../../img/new_banner/Common_Background.jpg"})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <h2 className="mb-5 text-center normal-font text-dark">
                                {this.props.params.title}
                            </h2>
                        </div>  
                    </div>
                    <div className="auctions-slides">
                        <div className="row d-flex justify-content-center">
                            <>
                                <>
                                    {
                                        this.props.sneak.map((sneak,idx) => {
                                            {/* console.log(sneak.url.toString().endsWith("mp4")) */}
                                            return (
                                                <div key={`${idx}`} className="col-lg-4 col-sm-6 item mb-2">

                                                { 
                                                   sneak.url.toString().endsWith("mp4") ? 

                                                    <div>
                                                        <div className="image-over">
                                                            <div className="bkt embed-responsive embed-responsive-16by9 ">
                                                                <video className="embed-responsive-item" autoPlay muted loop src={window.photo_url+sneak.url} />
                                                            </div>  
                                                          
                                                        </div>
                                                        
                                                        <h5 className="mb-0 text-dark normal-font text-center" dangerouslySetInnerHTML={{__html: sneak.title}}></h5>
                                                    </div>

                                                    :
                                                   
                                                    <div>
                                                        <div className="image-over">

                                                            <img
                                                                className="img-fluid"
                                                                src={window.photo_url+sneak.url}
                                                                alt=""
                                                                />  
                                                         
                                                        </div>
                                                        <h5 className="mb-0 text-dark normal-font text-center" dangerouslySetInnerHTML={{__html: sneak.title}}></h5>      
                                                    </div>
                                                }
                                                    



                                                </div>
                                                
                                            );
                                        })
                                    }

                                </>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <div className="text-dark sneak-peek-content" dangerouslySetInnerHTML={{__html:  this.props.params.content}}></div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                    
                  
                </div>
            </section>
                    
            
            </>
            
            
        );
    }
}
 
export default ProjectSneakPeek;