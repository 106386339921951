import React, {useState, useEffect} from 'react';
import apiClient from '../../helpers/api';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//new
import ModalLogin from "../../components/Modal/ModalLogin";
// import Banner from "../../components/Banner/Banner";
import BannerSale from "../../components/Banner/BannerSale";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ModalSearch from "../../components/Modal/ModalSearch";
import ModalMenu from "../../components/Modal/ModalMenu";
import Scrollup from "../../components/Scrollup/Scrollup";

import swal from 'sweetalert';

const Crypto = (props) => {
    const {register, handleSubmit, formState:{errors},formState,reset, getValues,unregister, setValue} = useForm({ mode: 'onBlur' })
    const [isError,setIsError] = useState(false);
    const [withFree, setWithFree] = useState(false)
    const [comp_l,setComp_l] = useState(false);
    const [msg,setMsg] = useState();
    const [isRedirect,setIsRedirect] = useState(false);
    const [selfRedirect,setSelfRedirect] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    const [fire_,setFire_] = useState(false);
    const loadCounter = localStorage.getItem("load_counter");
    const {isSubmitting} = formState;

    const [country, setCountry] = useState()
    const [users, setUsers] = useState()
    const [region, setRegion] = useState()
    const [city, setCity] = useState()
    const [shipping_fee, setShipping_fee] = useState(0)
    const [eth_shipping_fee, setEth_shipping_fee] = useState(0)
    const [total, setTotal] = useState(props.price)
    const [price, setPrice] = useState()
    const [tr_no, setTrno] = useState()

    const onSubmitShipping = (data) => {
        apiClient.post(`${process.env.REACT_APP_API}/checkout/paymongo/shipping_address`, data).then(res => {
            swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                closeOnClickOutside: false
            }).then((value) => {
                window.location.href = "/account/collections"
            });

        }).catch(error =>{
            swal({
                title: "Error",
                text: error.response.data.message,
                icon: "warning",
                closeOnClickOutside: false
            }).then((value) => {
                setIsRedirect(true)

            });
        })
    }

    const onChangeCountry = (e) => { 
        setRegion('')
        setCity('')
        const idx = e.target.selectedIndex;
        const option = e.target.querySelectorAll('option')[idx];
        const eth = option.getAttribute('ethshipping');
        const ph_sf = option.getAttribute('phshipping');
        setEth_shipping_fee(eth);
        apiClient.get(`${process.env.REACT_APP_API}/geo-location/state/${e.target.value}`)
        .then(res => {
            let price_sf = 0
            price_sf = ph_sf
            setShipping_fee(price_sf)
            setTotal(parseInt(price) + parseInt(price_sf))

            reset({
                total: parseInt(price) + parseInt(price_sf),
                sf: price_sf,
            });
            
            setRegion(res.data.data)
        })

     };

     const onChangeRagion = (e) => { 
        setCity('')
        apiClient.get(`${process.env.REACT_APP_API}/geo-location/city/${e.target.value}`)
        .then(res => {
            setCity(res.data.data)
            
        })

     }; 

     useEffect(() => {
        apiClient.get(`${process.env.REACT_APP_API}/crypto-meta/success/${props.match.params.code}`)
            .then(res => {

                console.log(res.data.free);
               
                setMsg(res.data.message)
                if(res.data.free > 0){
                    apiClient.get(`${process.env.REACT_APP_API}/geo-location/country`)
                    .then(res => {
                        setCountry(res.data.data)
                    }) 
                   
                    apiClient.get(`${process.env.REACT_APP_API}/account/user`)
                    .then( async res => {
                        setUsers(res.data)
                        
                        if (res.data.client_address) {
                            const prov = await apiClient.get(`${process.env.REACT_APP_API}/geo-location/state/${res.data.client_address.country_id}`)
                            const city = await apiClient.get(`${process.env.REACT_APP_API}/geo-location/city/${res.data.client_address.province_id}`)
                                
                            await setRegion(prov.data.data)
                            await setCity(city.data.data)
                            
                            if (res.data.client_address.shipping_fee > 0) {
                                setShipping_fee(res.data.client_address.shipping_fee)
                                setTotal(parseInt(props.price) + parseInt(res.data.client_address.shipping_fee))
                            }

                            await reset({
                                'country_id': res.data.client_address.country_id,
                                'state_id': res.data.client_address.province_id,
                                'city_id': res.data.client_address.city_id,
                                'shipping_address': res.data.client_address.address,
                                'post_code': res.data.client_address.postal,
                                'sf' : res.data.client_address.shipping_fee,
                                'total': parseInt(props.price) + parseInt(res.data.client_address.shipping_fee),
                            })

                            
                        }

                        reset();
                    })
                    setTrno(res.data.tr_no)
                    setWithFree(true)
                } 
            }).catch(error => {
                // console.log(error)
                // window.location.href = "/"
            });

            
        
    },[])

    if(selfRedirect){
       
        setTimeout( () => {
            localStorage.setItem('load_counter', 1)
            window.location.reload()
        }, 60000)
    }
    
    if(isRedirect){
        window.location.href = "/"
    }

    

    return (
        <div className="main"> 
        <Header/>
        <BannerSale  title=""/>
        { !isError ? 
            <section className="item-details-area pt-5">
                <div className="container">
                {
                    !withFree ? 
                    (
                        <div className="row justify-content-md-center bg-dark red-border pt-5 pb-5">
                            <div className='col-md-8'>
                            {!comp_l ?
                            <>
                            <h3 className="text-white text-center">Thank you! Purchase is complete.</h3>
                            <p className='text-white'>
                            Congratulations! You are now the proud owner of a historic moment in Philippine Arts and Culture.<br></br>
                            {msg}<br/><br/>
                            As an owner of this NFT, you are contributing to the value of the artist’s work as it connects humanity across time.                           
                            </p>
                            </>
                            : <p className='text-white'>Transaction already completed please go to your collections</p>
                            }
                            <center> 
                            <a href="/account/collections" className="btn btn-primary mt-2">View My Collections</a>
                            </center>
                            </div>
                        </div>
                    )  : 
                    (
                        <div className="row justify-content-md-center bg-dark red-border pt-5 pb-5">
                            <div className='col-12 px-5'>
                                <h3 className="text-white text-center">Thank you! Purchase is complete.</h3>
                                <p className='text-white'>
                                Congratulations! You are now the proud owner of a historic moment in Philippine Arts and Culture.<br></br>
                                {msg}<br/> 
                                </p>
                            </div>
                            <div className="col-12 px-5 text-white">
                                <br/>
                                <p className='mb-0' style={{fontSize: "24px"}}>Wait! &#9995;</p>
                                <p className='mt-2'>To receive your FREE Premium Gift, we need an address to send to. Please fill out below. 
                                    <br></br>
                                    (For addresses outside Philippines, we will contact you for a possible shipping fee.)
                                    <br></br>
                                    <br></br>
                                    <span style={{color: "yellow"}}>Note: If you do not give an address, we will not be able to send your gift.</span>
                                    <br></br>
                                    Questions? Reach out to <a target="_blank" href="mailto:concierge@scarletbox.io"> concierge@scarletbox.io</a>
                                </p>
                                <hr style={{backgroundColor: "white", margin: "auto !important", height: "1px", border: "none" }}/> 
                                <div className='alert chkout'>
                                    <form onSubmit={handleSubmit(onSubmitShipping)}  className="item-form no-hover text-white">
                                        <input type="hidden" {...register("tr_no")} value={tr_no} />
                                        <div className="row">
                                                        <div className="col-md-12 pb-2">
                                                            <strong >Shipping Details</strong>
                                                        </div>
                                                        
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>First Name</label>
                                                                <input type="text" className={`form-control ${ errors.shipping_first_name ? "is-invalid" : ""}`} {...register("shipping_first_name", { required: true})} defaultValue={users ? users.name : ''} />
                                                                {errors.shipping_first_name && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Last Name</label>
                                                                <input type="text" className={`form-control ${ errors.shipping_last_name ? "is-invalid" : ""}`} {...register("shipping_last_name", { required: true})} defaultValue={users ? users.last_name : ''}/>
                                                                {errors.shipping_last_name && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Email</label>
                                                                <input type="email" className={`form-control ${ errors.email ? "is-invalid" : ""}`} {...register("email", { required: true})} defaultValue={users ? users.email : ''}/>
                                                                {errors.email && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Country</label>
                                                                <select className={`country form-control ${ errors.country_id ? "is-invalid" : ""}`}  {...register("country_id", { required: true})} onChange={onChangeCountry}>
                                                                    <option value=""></option>
                                                                    {
                                                                    country ? country.map((val, id) => {
                                                                        return (
                                                                            <option ethshipping={val.eth_shipping} phshipping={val.ph_shipping} key={id} value={val.id}>{val.name}</option>
                                                                        )
                                                                    }) : ""
                                                                    }
                                                                </select>
                                                                {errors.country_id && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Provice/Region</label>
                                                                <select className={`form-control selectCountry ${ errors.state_id ? "is-invalid" : ""}`} id="province"  {...register("state_id", { required: true})} onChange={onChangeRagion}>
                                                                    <option value=""></option>
                                                                    {
                                                                    region ? region.map((val, id) => {
                                                                        return (
                                                                            <option key={id} value={val.id}>{val.name}</option>
                                                                        )
                                                                    }) : ""
                                                                    }
                                                                </select>
                                                                {errors.state_id && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>City</label>
                                                                <select className={`form-control  ${ errors.city_id ? "is-invalid" : ""}`}   id="city" {...register("city_id")}>
                                                                    <option value=""></option>
                                                                    {
                                                                    city ? city.map((val, id) => {
                                                                        return (
                                                                            <option key={id} value={val.id}>{val.name}</option>
                                                                        )
                                                                    }) : ""
                                                                    }
                                                                </select>
                                                                
                                                                {errors.city_id && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                        
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Address</label>
                                                                <small style={{fontSize: "11px"}}>If the city is not avaible please include the city on your address</small>
                                                                <input type="text" className={`form-control  ${ errors.shipping_address ? "is-invalid" : ""}`} {...register("shipping_address", { required: true})}  />
                                                                {errors.shipping_address && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Postal Code</label>
                                                                <input type="text" className={`form-control  ${ errors.post_code ? "is-invalid" : ""}`} {...register("post_code", { required: true})} />
                                                                {errors.post_code && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Mobile Number</label>
                                                                <input type="text" className={`form-control  ${ errors.mobile ? "is-invalid" : ""}`} {...register("mobile", { required: true})} defaultValue={users ? users.mobile : ''}/>
                                                                {errors.mobile && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group form-check">
                                                                <input type="checkbox" id="defaultAddress" defaultChecked={true} className={`form-check-input  ${ errors.default_address ? "is-invalid" : ""}`} {...register("default_address")}/>
                                                                <label className='form-check-label mt-1' for="defaultAddress">Set as Default Address</label>
                                                                {errors.default_address && <div className="invalid-feedback">This field is required</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <button className="btn btn-block btn-danger text-white mb-2 mx-2" >
                                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Submit
                                                        </button>
                                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                }
                </div>
            </section>
            : '' 
        }
        <Footer />
            <ModalSearch/>
            <ModalMenu />
            <ModalLogin/>
            <Scrollup />
        </div>
        
    )

}

export default Crypto;