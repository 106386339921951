import React, {useState, useEffect} from 'react';
import { useForm, } from "react-hook-form";
import apiClient from "../../helpers/api";
import swal from 'sweetalert';
import Fiat from "./Fiat";
import CryptoPayment from "./CryptoPayment";
import Gcash from './Gcash';
import BillEase from './BillEase';
import CheckoutAuth from './CheckoutAuth';
import CheckoutLogin from './CheckoutLogin';
import { Alert } from 'react-bootstrap';
import { async } from 'q';
import Auth from '../../helpers/auth/Auth';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;


const Checkout = (props) => {
    const [country, setCountry] = useState()
    const [region, setRegion] = useState()
    const [city, setCity] = useState()
    const [redirect,setRedirect] = useState(false)
    const [withEtherium,setWithEtherium] = useState(false);
    const [spinning_box, setSpinningBox] = useState('/img/box.gif')
    const [bundle_no, setBundleNo] = useState()
    const [loadClient, setLoadClient] = useState(false);
    const [terms, setTerms] = useState('')

    const payment_methods = ['Credit/Debit Card','Meta Mask', '', '', 'GCash']
    const  [paymentType, setPaymentType] = useState([]);
    const {register, handleSubmit, formState:{errors},formState,reset, getValues,unregister, setValue} = useForm({ mode: 'onBlur' })
    const {isSubmitting} = formState;
 
    const [users, setUsers] = useState()
    
    const [shipping_fee, setShipping_fee] = useState(0)
     
    const [payment_method, setPayment_method] = useState(5)
    const [total_amount, setTotalAmount] = useState(props.price)
    
    const [isopen, setIsopen] = useState(false)
    const [trno,setTrno] = useState()
    const [pricefiat,setPricefiat] = useState()
    const [cyrtop, setcyrtop] = useState(false)
   
    const [isGcash, setIsGcash] = useState(false);
    const [isBillease, setIsBillease] = useState(false);

    const [eth_shipping_fee, setEth_shipping_fee] = useState(0)
    const [eth_fee, setEth_fee] = useState(props.eth_price)
   
    //bundle_no
    

    const [isLogin, setIsLogin] = useState();
    const [showLogin, setShowLogin] = useState();
    

    const [loginData, setLoginData] = useState();


    //login

    const [with_account, setWithAccount] = useState(false);
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState('');

    const [passwordShown, setPasswordShown] = useState(false);

    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");
    const [metamask,setMetamask] = useState(true);

    const [username, setUsername] = useState('')
    const [name, setName] = useState('')
    const [last_name, setLastname] = useState('')
    const [mobile, setMobile] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
  
    const [errormsg, setErrorMsg] = useState('')

    /**
     * login process
     */
     const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };

      const onClickWallet = async () => {

        if(metamask){
             window.ethereum.request({method:"eth_requestAccounts"}).
            then(result => {
                window.ethereum.request({ method: 'eth_chainId' }).then(
                    chain => {
                        apiClient.post(`/auth/register-wallet`, { 
                            wallet: result[0],
                            email: email,
                            password: password,
                            // isRegistered: isRegistered,
                            // name: firstname,
                            // last_name: lastname,
                            // mobile: mobileno
                        }).then(response => {

                            console.log(response);
                            if (response.status == 200) {
                                localStorage.setItem('wallet',result[0])
                                localStorage.setItem('chain',chain)
                                // localStorage.setItem('isAuth', true)
                                if (response.data.client.email == null && response.data.client.name == null && response.data.client.last_name== null) {
                                    setPayment_method(2)
                                    setPaymentType(['','Meta Mask','', '','', ''])
                                    alert("Only ETH payment is allowed")
                                }
                                Auth.storeAuth()
                                onChangeClient(response.data.client.id)
                                setIsLogin(true)
                                setUsers(response.data.client)
                                setPayment_method(2)
                                //setShowModal(false)
                            //window.location.reload()
                            }
                        }).catch(function (error) {
                            localStorage.removeItem("wallet")
                            localStorage.removeItem("chain")
                        })

                    }
                );
            })

        } else {
            window.open("https://metamask.io/download/")
        }

    }

     //register form toggle password
     const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }

    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password")
        {
            setConfirmPasswordType("text")
         return;
        }
            setConfirmPasswordType("password")
      }
    
    const verifyLogin = async () => {
        try {
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);
            console.log(res);
            if (res.status == 200) {
                setUsers(res.data.id)
                setValue('client_id',res.data.id)
                return true;
            } 
        } catch (error) {
            return false;
        }
    }

    const onChangeAccount = (e) => {
        setWithAccount(e.target.checked)
    }

    const onCheckout = async (data) => {
        setLoginData(data);
        const loggin = await verifyLogin();
        if (!loggin) {
            if (with_account) {
                return new Promise(resolve => {

                    apiClient.post(`/auth/login`, { 
                        email: email,
                        password: password,
                    }).then(response => {
                        if (response.status == 200) { 
                            // localStorage.setItem('isAuth', true)
                            Auth.storeAuth()
                            onChangeClient(response.data.client_id)
                            setUsers(response.data)
                            setIsLogin(true)
                            //setShowModal(false)
                            data["client_id"] = response.data.client_id
                            
                            apiClient.post(`${process.env.REACT_APP_API}/checkout/process/generate_transaction`,data).then(res => {
                                if(res.data.status == 200){
                                    setTrno(res.data.result.tr_no)
                                    setPricefiat(res.data.result.total_crypto)
                
                                    if(payment_method == 1){
                                        setIsopen(true)
                                    }else if(payment_method == 5){
                                        setIsGcash(true)
                                    }else if(payment_method == 6){
                                        setIsBillease(true)
                                    } else {
                                        setcyrtop(true)
                                    }
                
                                    resolve()
                                } else {
                                    swal({
                                        title: "Error",
                                        text: res.data.message,
                                        icon: "warning",
                                    }).then((value) => {
                                        window.location.reload()
                                    });
                                    resolve()
                                }
                            }).catch(error => {
                                resolve()
                            });
                            
                            // window.location.reload(false)
                            resolve();
                        }
                    }).catch(function (error) {
                        if(error.response.status==401){
                            setShow(true)
                            console.log(error.response.data);
                            resolve();
                        }
                    })
            
                });
            }else{
                return new Promise(resolve => {
                    apiClient.post(`${process.env.REACT_APP_API}/auth/login`, {
                        username: username,
                        name: name,
                        last_name: last_name,
                        mobile: mobile,
                        email: email,
                        password: password,
                        confirm_password: confirm_password,
                        isRegistered: false,
                    }).then(response => {
                        console.log(response);
                        if (response.status == 200) { 
                            // localStorage.setItem('isAuth', true)
                            Auth.storeAuth()
                            onChangeClient(response.data.client_id)
                            data["client_id"] = response.data.client_id
                            setIsLogin(true)
                            setUsers(response.data)
                            apiClient.post(`${process.env.REACT_APP_API}/checkout/process/generate_transaction`,data).then(res => {
                                if(res.data.status == 200){
                                    setTrno(res.data.result.tr_no)
                                    setPricefiat(res.data.result.total_crypto)
                
                                    if(payment_method == 1){
                                        setIsopen(true)
                                    }else if(payment_method == 5){
                                        setIsGcash(true)
                                    }else if(payment_method == 6){
                                        setIsBillease(true)
                                    } else {
                                        setcyrtop(true)
                                    }
                
                                    resolve()
                                } else {
                                    swal({
                                        title: "Error",
                                        text: res.data.message,
                                        icon: "warning",
                                    }).then((value) => {
                                        window.location.reload()
                                    });
                                    resolve()
                                }
                            }).catch(error => {
                                resolve()
                            });
                            resolve();
                        } else {
                            swal({
                                title: "Error",
                                text: response.data.message,
                                icon: "warning",
                            }).then((value) => {
                                window.location.reload()
                            });
                            resolve()
                        }
                    }).catch(function (error) {
                        if(error.response.status==401){
                            setShow(true)
                            console.log(error.response.data.msg);
                            setErrorMsg(error.response.data.msg)
                            resolve();
                        }
                    })
                })
            }
            return false;
        }else{
            setIsLogin(true);
            setShowLogin(false);
            // console.log(data);
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);

            data["client_id"] = res.data.id;
            return await new Promise(resolve => {
                apiClient.post(`${process.env.REACT_APP_API}/checkout/process/generate_transaction`,data).then(res => {
                    // console.log(res);
                    if(res.data.status == 200){
                        setTrno(res.data.result.tr_no)
                        setPricefiat(res.data.result.total_crypto)

                        if(payment_method == 1){
                            setIsopen(true)
                        }else if(payment_method == 5){
                            setIsGcash(true)
                        }else if(payment_method == 6){
                            setIsBillease(true)
                        } else {
                            setcyrtop(true)
                        
                        }

                        resolve()
                    } else {
                        swal({
                            title: "Error",
                            text: res.data.message,
                            icon: "warning",
                        }).then((value) => {
                            window.location.reload()
                        });
                        resolve()
                    }
                }).catch(error => {
                    resolve()
                });
            
        })
        }
    }
    
    useEffect(() => {
        
        if(props.spinning){
            setSpinningBox(props.spinning);
        }

        if(window.ethereum){
            setWithEtherium(true)
        }

        // if(!props.art_bundle_qty){
        //     const payment_methods = ['Credit/Debit Card','Meta Mask'];
        // } 

        apiClient.get(`${process.env.REACT_APP_API}/geo-location/country`)
            .then(res => {
                setCountry(res.data.data)
            })

        setPayment_method(5)
        
        if(props.addon){
            var new_total = parseFloat(props.price) + parseFloat(props.chaddprice);
            if(props.chaddonShippingFee > 0){
                if(props.defaultsf == 0){
                    setShipping_fee(props.chaddonShippingFee)
                } else {
                    setShipping_fee(props.defaultsf)
                }
                
                new_total = new_total + parseFloat(props.chaddonShippingFee)
            }
            setTotalAmount(new_total);
            setPricefiat(new_total);
            if(props.isForceAddon == 2){
                setPaymentType(['Credit/Debit Card','Meta Mask', '', '', 'GCash / GCredit'])
            } else {
                setPaymentType(['Credit/Debit Card','', '', '', 'GCash / GCredit'])
            }
           
            reset();
        } else {
            if(props.defaultsf > 0 && props.with_frame){
               var new_total = parseFloat(props.price) + parseFloat(props.defaultsf);
                setShipping_fee(props.defaultsf)
                setTotalAmount(new_total);
                setPricefiat(new_total);
            }
            setPaymentType(['Credit/Debit Card','Meta Mask', '', '', 'GCash / GCredit'])
            reset();
        }
        apiClient.get(`${process.env.REACT_APP_API}/account/user`)
            .then( async res => {
                setUsers(res.data)
                setIsLogin(true);

                if(res.data.email == null){
                    setPayment_method(2)
                    setPaymentType(['','Meta Mask','', '','', ''])
                
                } else {
                    setPayment_method(5)
                    // setPaymentType(['Credit/Debit Card','Meta Mask', '', '', 'GCash', 'BillEase'])
                    if(props.addon){
                        setPaymentType(['Credit/Debit Card','', '', '', 'GCash / GCredit'])
                    } else {
                        setPaymentType(['Credit/Debit Card','Meta Mask', '', '', 'GCash / GCredit'])
                    }

                }

                // if (res.data.client_address) {
                //     const prov = await axios.get(`${process.env.REACT_APP_API}/geo-location/state/${res.data.client_address.country_id}`)
                //     const city = await axios.get(`${process.env.REACT_APP_API}/geo-location/city/${res.data.client_address.province_id}`)
                        
                //     await setRegion(prov.data.data)
                //     await setCity(city.data.data)
                    
                //     if (res.data.client_address.shipping_fee > 0) {
                //         setShipping_fee(res.data.client_address.shipping_fee)
                //         setTotalAmount(parseInt(props.price) + parseInt(res.data.client_address.shipping_fee))
                //     }

                //     await reset({
                //         'country_id': res.data.client_address.country_id,
                //         'state_id': res.data.client_address.province_id,
                //         'city_id': res.data.client_address.city_id,
                //         'shipping_address': res.data.client_address.address,
                //         'post_code': res.data.client_address.postal,
                //         'sf' : res.data.client_address.shipping_fee,
                //         'total_amount': parseInt(props.price) + parseInt(res.data.client_address.shipping_fee),
                //     })

                    
                // }

                reset();
                
            }).catch(e => {
                apiClient.post('/account/logout')
                .then( res => {
                    localStorage.removeItem("wallet")
                    localStorage.removeItem('isAuth')
                    Auth.deleteCookie('jwt')
                    setIsLogin(false)
                }).catch( e => {
                    console.log(e.response.data);
                })
                
                localStorage.clear()
            })
        


        // check if have reserved art
        if (localStorage.getItem("artx_bundle_tr_no_") && localStorage.getItem("artx_bundle_tr_no_")) {
            apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/cancel`, {
                tr_no: localStorage.getItem("artx_bundle_tr_no_"),
                bundle_no: localStorage.getItem("artx_bundle_"),
            }).then(res => {
                console.log(res);
                localStorage.removeItem("artx_bundle_tr_no_");
                localStorage.removeItem("artx_bundle_");
            })
        }
    },[])
   
    const onChangeClient = (e) =>{
        setValue('client_id',e)
        props.ChangeClient(true)
    }

     const onPaymentMethod = (e) => { 
        setPayment_method(e.target.value)
        
        reset({
            payment_method: e.target.value
        });
     }

     const closeModal = () => {
        
        setShowLogin(false);
        setIsopen(false);
        setcyrtop(false);
        setIsGcash(false);
        setIsBillease(false);
    }

    //const back_url = props.type == 2 ? '/sale/public-sale' : '/sale/private-sale'
    const back_url = () => {
        window.location.reload();
    }
    return (
       
        <section className="item-details-area pt-5">
            <div className="container-fluid">
            <form onSubmit={handleSubmit(onCheckout)}  className="item-form no-hover text-dark">
                    <div className="row justify-content-md-center pt-3 pt-lg-5 pb-5">
                        <div className="col-md-12 p-0 m-0">
                            <div className="row justify-content-md-center">
                                <div className="col-lg-5 my-lg-0 my-2 mx-auto mx-lg-2 col-12 pt-3 rounded-lg border border-dark">
                                            <input type="hidden" {...register("custom_field")} value={props.custom_field} />
                                            <input type="hidden" {...register("code")} value={props.privatecode} />
                                            <input type="hidden" {...register("with_frame")} value={props.with_frame} />
                                            <input type="hidden" {...register("free")} value={props.free} />
                                            <input type="hidden" {...register("rearity")} value={props.rearity} />
                                            <input type="hidden" {...register("project_id")} value={props.project_id} />
                                            <input type="hidden" {...register("sales_type")} value={props.type} />
                                            <input type="hidden" {...register("artist")} value={props.artist} />
                                            <input type="hidden" {...register("sf")} defaultValue={shipping_fee} />
                                            <input type="hidden" {...register("total")} value={total_amount} />
                                            <input type="hidden" {...register("payment_method")} value={payment_method} />
                                            <input type="hidden" {...register("price")} defaultValue={props.price} />
                                            <input type="hidden" {...register("shedule_id")} defaultValue={props.shedule_id} />
                                            <input type="hidden" {...register("free")} defaultValue={props.free} />

                                            <input type="hidden" {...register("chaddontitle")} defaultValue={props.chaddontitle} />
                                            <input type="hidden" {...register("chaddprice")} defaultValue={props.chaddprice} />
                                            <input type="hidden" {...register("chaddonShippingFee")} defaultValue={props.chaddonShippingFee} />
                                            <input type="hidden" {...register("free_type")} defaultValue={props.free_type} />
                                            {/* {
                                               props.with_frame ?
                                               <input type="hidden" {...register("free_type")} defaultValue={props.with_frame ? props.free_type : 0} />
                                               :
                                               <input type="hidden" {...register("free_type")} defaultValue={props.free_type == 4 ? props.free_type : 0} />
                                            } */}

                                            
                                            
                                            {/* random art */}
                                            <input type="hidden" {...register("art_bundle_qty")} value={props.art_bundle_qty} />
                                            <input type="hidden" {...register("allowed_art_bundle")} value={props.allowed_art_bundle} />

                                            
                                            <input type="hidden" {...register("eth_total")} defaultValue={eth_fee}/>
                                            <input type="hidden" {...register("client_id")} defaultValue={users ? users.id : ''}/>
                                    <strong >Details</strong>
                                    <div className="form-group mt-3">
                                        <p>{props.project_name}<br></br> </p>
                                        <p>
                                        {
                                            props.type == 1 ? <span>Private Sale</span> : <span>Public Sale</span>
                                        }
                                        </p>
                                        <hr className='mt-0 pt-0 ' style={{backgroundColor:"#fff"}}></hr>

                                        
                                        <p>Artist : <span className="float-right">{props.artist_name }</span></p>

                                        {
                                            props.allowed_art_bundle == 2 ? (
                                                <p>Art Quantity : <span className="float-right">{props.art_bundle_qty }</span></p>
                                            ) : ('')
                                        }

                                        <p>Price : <span className="float-right">Php {props.price_text.toLocaleString("en-US")}</span></p>
                                        {
                                           props.with_frame ? props.free != '' ? <p>{props.free}</p> : '' : props.free_type == 4 && props.free != '' ? <p>Free {props.free}</p> :''
                                        }

                                        {
                                            props.addon ? 
                                            <>
                                            <hr className='mt-0 pt-0 ' style={{backgroundColor:"#fff"}}></hr>
                                            <p>Add On : <span className="float-right">{props.chaddontitle}</span></p>
                                            <p>Add On Price: <span className="float-right">Php {props.chaddprice.toLocaleString("en-US")}</span></p>
                                            </>
                                            : ''
                                        }

                                        {       
                                           shipping_fee != 0 ? <>
                                           <p>Shipping Fee : <span className="float-right">Php {shipping_fee.toLocaleString("en-US")}</span></p>
                                           <p className='text-danger'>Note: This shipping fee only includes shipping within the Philippines. For overseas shipping, we will contact the collector for the quote after their purchase</p>
                                           </> : ''
                                        }
                                        
                                        <hr  style={{backgroundColor:"#fff"}}></hr>
                                        <p>Total : <span className="float-right">Php {total_amount.toLocaleString("en-US")}</span></p>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-4 my-lg-0 my-2 col-12 mx-auto mx-lg-2 pt-3 rounded-lg border border-dark">
                                    <strong>Payment Method</strong>
                                    <div className='payment_container d-flex flex-column-reverse'>
                                    {
                                    !props.art_bundle_qty ? 
                                        payment_methods.map((level, index) => {
                                            return (
                                                level ? (
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" onChange={ onPaymentMethod } name="payment_methodx" id={`rd_${index}`} value={index + 1} checked={payment_method == index + 1} />
                                                        <label className="form-check-label" for={`rd_${index}`}>
                                                        <img style={{width: "30px"}} className="mr-2" src={`/img/payment_method_dark_${index}.png`}/>{level} 
                                                        </label>
                                                    </div>
                                                ) : ""
                                            )
                                            }) 
                                            : paymentType.map((level, index) => {
                                                return level !='' ? 
                                                (
                                                    <div className={"form-check form-check-"+index}>
                                                        <input className="form-check-input" type="radio" onChange={ onPaymentMethod } name="payment_methodx" id={`rd_${index}`} value={index + 1} checked={payment_method == index +1} />
                                                        <label className="form-check-label" for={`rd_${index}`}>
                                                        <img style={{width: "28px"}} className="mr-2" src={`/img/payment_method_dark_${index}.png`}/>{level} 
                                                        </label>
                                                    </div>)
                                                    : ""
                                            })
                                    }
                                    </div>
                                    <hr  style={{backgroundColor:"#fff"}}></hr> 
                                    <div className='alert chkout'>
                                        Note: Bank Transfer or Deposit accepted. Pls email 
                                        <a target="_blank" href="mailto:concierge@scarletbox.io"> concierge@scarletbox.io</a> for bank details. 
                                    </div>
                                    {
                                        !isLogin || !users ? (
                                            <>
                                                <div className="form-group form-check">
                                                    <input type="checkbox" className="form-check-input" id='withAccount' defaultChecked={with_account} onClick={e => onChangeAccount(e)} />
                                                    <label className="form-check-label ml-1 mt-1 text-danger" for="withAccount" >I already have an account</label>
                                                </div>
                                                
                                                {
                                                    with_account ? (
                                                        <div className='alert chkout'>
                                                            <Alert show={show} variant="danger">
                                                                <p>
                                                                    Invalid Email or Password
                                                                </p>
                                                            </Alert>
                                                            <p className='font-weight-bold'>Log in or Create Account to Checkout</p>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group mt-0">
                                                                        <label className="text-dark font-weight-bold">Email</label>

                                                                    <input type="email" className="form-control" name="email"  defaultValue={email}  onChange={e => setEmail(e.target.value)} placeholder="Enter your Email" required="required" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mt-3">
                                                                        <label className="text-dark font-weight-bold">Password</label>

                                                                        <input type={passwordShown ? "text" : "password"}  className="form-control" name="password" onChange={e => setPassword(e.target.value)} placeholder="Enter your Password" required="required" />
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className="form-group form-check">
                                                                                <input type="checkbox" className="form-check-input checkboxx" id='showPassword' onClick={togglePasswordVisiblity} />
                                                                                <label className="form-check-label ml-1 mt-1" for="showPassword">Show Password</label>
                                                                            </div>
                                                                    
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className="text-right">
                                                                                <a href='/auth/forget_password' target='blank'>Forgot Password</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                            
                                                                </div>
                                                        
                                                                <div className='col-12'>
                                                                    <button className="btn w-100 btn-dark" >
                                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Login and checkout
                                                                    </button>
                                                                </div>
                                                                {
                                                                   !props.addon ? 
                                                                    <>
                                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                                    <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                                                                    <span className='px-4'>OR</span> 
                                                                    <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                                                                </div>
                                                                
                                                                <div className="col-12">
                                                                    <button className="btn btn-dark w-100" type="button" onClick={onClickWallet} >Connect to Wallet</button>
                                                                </div>
                                                                    </>
                                                                    : ''
                                                                }
                                                                
                                                                <div className="col-12 pt-3">
                                                                    {/* <p className="text-center text-dark" style={{ fontSize: '15px' }}>*Please register if you do not have an account <a className='text-danger' onClick={onClickRegisterLink}>here</a>.</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='alert chkout'>
                                                            <p className='font-weight-bold'>Register to proceed Checkout</p>
                                                            <Alert show={show} variant="danger">
                                                                <p>
                                                                    {errormsg}
                                                                </p>
                                                            </Alert>
                                                            <div className="row justify-content-center">
                                                            
                                                                <div className="col-12">
                                                                    <div className="form-group mt-2">
                                                                        <label className="text-dark">First Name</label>
                                                                        <input type="text" className={`form-control`} name="name" onChange={ e =>setName(e.target.value) }  />
                                                                        
                                                                    </div>
                                                                </div> 
                                                                <div className="col-12">
                                                                    <div className="form-group mt-2">
                                                                        <label className="text-dark">Last Name </label>
                                                                        <input type="text" className={`form-control`} name="last_name" onChange={ e =>setLastname(e.target.value) }  />
                                                                    </div>
                                                                </div> 
                                                            
                                                                <div className="col-md-6 col-12 pl-0 d-none">
                                                                    <div className="form-group mt-2">
                                                                        <label className="text-dark">Username &#40; Optional &#41;</label>
                                                                        {/* <input type="text" className={`form-control ${ error && error.errors.username ? "is-invalid" : ""}`} name="username" onChange={ e =>setUsername(e.target.value) }  />
                                                                        {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""} */}
                                                                        <input type="text" className="form-control" name="username" onChange={ e =>setUsername(e.target.value) }  />
                                                                    </div>
                                                                </div> 
                                                                <div className="col-md-12">
                                                                    <div className="form-group mt-2">
                                                                        <label className="text-dark">Email</label>
                                                                        <input type="email" className={`form-control`} name="email"onChange={ e =>setEmail(e.target.value) } required="required"/>
                                                                    </div>
                                                                </div> 
                                                            
                                                                <div className="col-12 row">
                                                                    <div className="form-group col-10 px-0">
                                                                        <label className="text-dark">Password</label>
                                                                        <input type={passwordType} className={`form-control`} name="password" onChange={ e =>setPassword(e.target.value) }  required="required"/>
                                                                    </div>
                                                                    <div className="form-group col-2 mt-4">
                                                                    <button type="button" className="btn btn-dark btn-smaller" onClick={togglePassword}>
                                                                        { passwordType==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                                    </button>
                                                                    </div>
                                                                </div> 
                                                            
                                                                <div className="col-12 row">
                                                                    <div className="form-group col-10 px-0">
                                                                        <label className="text-dark">Confirm Password</label>
                                                                        <input type={confirmpasswordType} className={`form-control`} name="confirm_password" onChange={ e =>setConfirmPassword(e.target.value) }  required="required"/>
                                                                    </div>
                                                                    <div className="form-group col-2 mt-4">
                                                                        <button type="button" className="btn btn-dark btn-smaller"  onClick={toggleConfirmPassword}>
                                                                            { confirmpasswordType ==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                                        </button>
                                                                    </div>
                                                                </div> 
                                                                <div className="col-12">
                                                                    <div className="form-group mt-1 ml-3">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input " type="checkbox" name="inlineRadioOptions" id="inlineRadio1" onChange={ e =>setTerms(e.target.value) }/>
                                                                            <label className="form-check-label ml-1 mt-1" htmlFor="inlineRadio1">I have read and agreed to the <a target="blank" href="https://scarletbox.io/privacy"> Terms and Conditions</a></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12'>
                                                                    <button className="btn w-100 btn-dark" >
                                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Register and checkout
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                         : ""
                                    }

                                    {
                                        !isLogin ? (
                                            <div className='col-12' style={{padding: "1rem 2.5rem"}}>
                                                <a href="#" onClick={back_url} className="btn btn-block btn-dark">
                                                    Cancel
                                                </a>
                                            </div>
                                        ): (
                                            <div className="row justify-content-center">
                                                <div className='col-6 p-1'>
                                                    <button className="btn btn-block btn-dark" >
                                                    {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}Checkout
                                                    </button>
                                                </div>
                                                <div className='col-6 p-1'>
                                                <a href="#" onClick={back_url} className="btn btn-block btn-dark">
                                                    Cancel
                                                </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                    

                                    
                                    {/* <div className='py-2'>
                                    <p className="text-justify px-2" style={{ fontSize: '13px' }}><i>Disclaimer: All sales will occur on the Scarletbox Platform. GCash makes no representations or warranties, express or implied, regarding the Scarletbox Platform and/or any material contained therein. All transactions shall be at your own, exclusive risk.</i></p>
                                    </div> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {
                !isLogin && showLogin ? <CheckoutAuth showmodal={showLogin} 
                                            onHide={closeModal} 
                                            mail={getValues("email")} 
                                            firstname={getValues("shipping_first_name")} 
                                            lastname={getValues("shipping_last_name")} 
                                            mobileno={getValues("mobile")} 
                                            changeClient={onChangeClient}
                                            setPaymentType={setPaymentType}
                                            setPayment_method={setPayment_method}
                                            showFiat={setIsopen}
                                            showCrypto={setcyrtop}
                                            showGcash={setIsGcash}
                                            showBillease={setIsBillease}
                                            paymentMethod={payment_method}
                                            loginData={loginData}
                                            setTrno={setTrno}
                                            setPricefiat={pricefiat}
                                         /> 
                : ""
            }

            {isBillease  
                ? <BillEase user={users} artist={props.artist} type={props.type} show={isBillease} onHide={closeModal} tr_no={trno} price_fiat={pricefiat} project_id={props.project_id} art_bundle_qty={props.art_bundle_qty}/> 
                : ''
            } 
            {isGcash  
                ? <Gcash user={users} artist={props.artist} type={props.type} show={isGcash} onHide={closeModal} tr_no={trno} price_fiat={pricefiat} project_id={props.project_id} art_bundle_qty={props.art_bundle_qty}/> 
                : ''
            }
            {isopen  
                ? <Fiat user={users} artist={props.artist} type={props.type} show={isopen} onHide={closeModal} tr_no={trno} price_fiat={pricefiat} project_id={props.project_id} art_bundle_qty={props.art_bundle_qty}/> 
                : ''
            }
            <CryptoPayment chainCode={props.chainCode} chainName={props.chainName} allowChainId={props.allowChainId} user={users} project_id={props.project_id} artist={props.artist} type={props.type}  show={cyrtop} onHide={closeModal} sf={eth_shipping_fee} eth={eth_fee} art_bundle_qty={props.art_bundle_qty} tr_no={trno} price_fiat={pricefiat}/>
            
        </section>
         
       
       
    );
}
 
export default Checkout;