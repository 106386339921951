import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { Modal, Button, Form} from "react-bootstrap";
import Web3 from 'web3';
import apiClient from "../../helpers/api";

const CryptoPayment = (props) => {
    const [isopen, setIsopen] = useState(props.isopen)
    const [isSuccess, setIsSuccess] = useState(null)
    const {register, handleSubmit,formState} = useForm({ mode: 'onBlur' })
    const [errorMessage,setErrorMessage] = useState(null);
    const [errorEthMessage,setErrorEthMessage] = useState(null);
    const [defaultAccount,setDefaultAccount] = useState(localStorage.getItem('wallet'));
    const [defaultChain,setDefaultChain] = useState(localStorage.getItem('chain'));
    const [isPaying,setIsPaying] = useState(false);
   
    const AllowedChainId = process.env.REACT_APP_CHAINID;

    var contract = null;

    async function connectWallet_() {
        window.ethereum.request({method:"eth_requestAccounts"}).
        then(result => {
            setDefaultAccount(result[0])
            window.ethereum.request({ method: 'eth_chainId' }).then(
                chain => {
                    if(chain === props.eth_data.allowChainId){
                        setDefaultChain(chain)
                    } else { 
                        setErrorMessage('Please use '+props.eth_data.chainName+' only');
                    }
                }
            );
        })
    }

    useEffect(() => {

        

        if(!window.ethereum){
            setErrorMessage('Please Install Metamask Digital Wallet');
        } 

        if(defaultChain !== null){
            if(defaultChain !== props.eth_data.allowChainId){
                setErrorMessage('Please use '+props.eth_data.chainName+' only');
            }
        }
    });

    try{
       
        window.ethereum.on('chainChanged', (chainId) => {
           
            if(!localStorage.getItem('chain')){
                if(chainId === props.eth_data.allowChainId){
                    setDefaultChain(chainId)
                    setErrorMessage(null);
                } else {
                    setErrorMessage('Please use '+props.eth_data.chainName+' only');
                } 
            } else {
                if(localStorage.getItem('chain') === props.eth_data.allowChainId){
                    setDefaultChain(chainId)
                    setErrorMessage(null);
                } else {
                    setErrorMessage('Please use '+props.eth_data.chainName+' only');
                } 
            }
       });
           
       
        if(!localStorage.getItem('chain')){
            window.ethereum.on('accountsChanged', (accounts) => {
                setDefaultAccount(accounts[0])
            });
        } 

    } catch(e){ }

    async function mint_(){
        setIsPaying(true);
        setErrorEthMessage('Please dont refresh or leave the site while the transaction is on progress')
        
        var cytppo = await apiClient.post(`${process.env.REACT_APP_API}/checkout/fiat/artist_data`, {
            tr_no: props.tr_no,
        }) 
       
        var web3        = new Web3(window.ethereum);
        contract        = new web3.eth.Contract(JSON.parse(cytppo.data.json),cytppo.data.address);
        var _mintAmount = 1;
        var mintRate    = Number(await contract.methods.cost().call());
        
        var totalAmount = mintRate;
        try{
            
            var verifyArt  = await apiClient.post(`${process.env.REACT_APP_API}/vip/process-now/verify_art`, {
                art_id: props.eth_data.art_id,
                vip_id: props.eth_data.vip_id,
                user_id:props.user
            }) 

            if(verifyArt.data.code === 300){
                setIsPaying(false);
                setErrorEthMessage(verifyArt.data.msg)
            } else {
                try{
                    
                    if(props.isbundle == 1){
                        var trx = await contract.methods.mint(defaultAccount,_mintAmount,props.eth_data.art_code).send({from:defaultAccount,value:String(totalAmount)})
                    } else {
                        var trx = await contract.methods.mint(defaultAccount,[props.eth_data.art_code]).send({from:defaultAccount,value:String(totalAmount)})
                    }

                    try{ // create order
                        var cCreate = await apiClient.post(`${process.env.REACT_APP_API}/checkout/fiat/shop_order`, {
                            tr_no: props.tr_no,
                            tr_hash:trx.transactionHash,
                            wallet_address:defaultAccount,
                            contract_address:cytppo.data.address
                        }) 

                        window.location = '/thank/'+cCreate.data.tr;

                        setIsSuccess(cCreate.data.message)
                    } catch(e){ 
                        try{
                            await apiClient.post(`${process.env.REACT_APP_API}/vip/process-now/update_art`, {
                                art_id: props.eth_data.art_id,
                                vip_id: props.eth_data.vip_id,
                                user_id:props.user
                            })
                            setIsPaying(false);
                            if(e.code === 4001){
                                setErrorEthMessage(e.message)
                            } else {
                                setErrorEthMessage('Transaction failed')
                            }
                        } catch(e){
                            setIsPaying(false);
                            setErrorEthMessage('Transaction failed')
                        }
                    }  
                   
                } catch(e){
                   
                    try{
                        await apiClient.post(`${process.env.REACT_APP_API}/vip/process-now/update_art`, {
                            art_id: props.eth_data.art_id,
                            vip_id: props.eth_data.vip_id,
                            user_id:props.user
                        })
                        setIsPaying(false);
                        if(e.code === 4001){
                            setErrorEthMessage(e.message)
                        } else {
                            setErrorEthMessage('Transaction failed')
                        }
                    } catch(e){
                        setIsPaying(false);
                        setErrorEthMessage('Transaction failed')
                    }
                }

            }

        } catch(e){
          
            setIsPaying(false);
            if(e.code === 4001){
                setErrorEthMessage(e.message)
            } else {
                setErrorEthMessage('Transaction failed')
            }
        }

    }

    

    return (
        <>
        <Modal 
                show={props.show} 
                onHide={props.onHide} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='checkout-modal ' backdrop="static"  keyboard={false}>
                <Form   >
                    
                <Modal.Body>
                    {!isSuccess ? <>
                    <center>
                    <h3 className='text-dark mt-1 mb-2'>Pay using Etherium </h3>
                 
                    </center><hr className=' mt-2 mb-3'></hr>
                    <div className='ml-5 mr-5'>
                    <p >Price : <span className="float-right">{props.eth_data.eth} {props.eth_data.chainCode}</span></p>
                    <p>Total : <span className="float-right">{parseFloat(props.eth_data.eth)} {props.eth_data.chainCode}</span> </p>

                    </div>
                    <center>
                    {defaultAccount ?
                        <><hr></hr><p style={{fontSize:'15px'}}>Wallet address<br></br> {defaultAccount} </p> </>: '' }
                      <Form.Control type="hidden" {...register("art_id", { required: true})} value={props.eth_data.art_id} />   
                    <Form.Control type="hidden" {...register("art_code", { required: true})} value={props.eth_data.art_code} /> 
                    <Form.Control type="hidden" {...register("tr_no", { required: true})} value={props.tr_no} />     
                    <Form.Control type="hidden" {...register("price_fiat", { required: true})} value={props.price_fiat} />
                    <Form.Control type="hidden" {...register("wallet_address", { required: true})} value={defaultAccount}/>
                    <Form.Control type="hidden" {...register("sf", { required: true})} value={props.eth_data.sf}/>
                    <Form.Control type="hidden" {...register("eth", { required: true})} value={props.eth_data.eth}/>
                    <Form.Control type="hidden" {...register("vip_id", { required: true})} value={props.eth_data.vip_id}/>
                    <Form.Control type="hidden" {...register("tr_no", { required: true})} value={props.tr_no}/>
                    
                    <Form.Control type="hidden" {...register("total", { required: true})} value={parseFloat(props.eth_data.eth) + parseFloat(props.eth_data.sf)}/>
                    {errorMessage ? <p className='text-danger'> {errorMessage }</p> : '' }
                    
                    <hr></hr>
                    {errorEthMessage ?  <p className='text-danger mb-0 ' style={{fontSize: "22px"}} > {errorEthMessage }</p> : '' }
                    <a href='#' className='text-dark'>Need more eth? Get it at <img style={{width: "150px"}} src='/img/gcrypto_dark.png'></img></a>
                    <br></br>
                    <br></br>
                    <Button variant="dark" onClick={props.onHide}>Close</Button> 
                    {errorMessage ? '' : <>
                        
                        { defaultAccount ?
                        
                         <Button variant="dark" onClick={mint_}  className='ml-3'>{isPaying && <span className="spinner-border spinner-border-sm mr-1"></span>} Pay</Button>
                        : 
                        <Button variant="dark" onClick={connectWallet_} className='ml-3'>Connect Wallet</Button>
                        }
                     </>
                    }
                    </center>
                    </> : 
                    <><center><h3 className='text-dark mt-1 mb-2'>Thank You</h3>
                    <p>{isSuccess}</p>
                    <p className='text-dark'>As an owner of this NFT, you are contributing to the value of the artist’s work as it connects humanity across time.</p>
                    <a href="/account/collections"  className='ml-3 btn btn-dark'>View My Transaction</a>
                    </center></>}
                </Modal.Body>
                </Form>
                </Modal>
        </>
    )
}

export default CryptoPayment;