const BannerAuction = (props) => {
    return (
        <section className="carousel-bg align-items-center" 
            data-mobile={props.banner} 
            style={{backgroundImage: `url('${props.banner}')`,height:'50vh',backgroundAttachment:"unset",marginTop:95}} >
        </section>

    );
}
 
export default BannerAuction;