import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import apiClient from "../../helpers/api";
import swal from 'sweetalert';


const Fiat = (props) => {

    const [year_,setYear_] = useState(new Date().getFullYear())
    const [errormsg,setErrormsg] = useState()
    const [users, setUsers] = useState(props.user)
    const [tr_no,setTrno] = useState(props.tr_no)
    const [price,setPrice] = useState(props.price_fiat)
    const {register, handleSubmit, formState:{errors},formState,reset} = useForm({ mode: 'onBlur' })
    const [redirect, setRedirect] = useState(false)
    
    const onFiatPay = async (data) => {

        
        setErrormsg('')
        let details = {
            client_id: props.user,
            project_id: props.project_id,
            tr_no: props.tr_no,
            art_bundle_qty: props.art_bundle_qty,
            type: props.type,
            artist_id:props.artist
        }

        try {
            var verifyArt  = await apiClient.post(`${process.env.REACT_APP_API}/vip/process-now/verify_art`, {
                art_id: props.eth_data.art_id,
                vip_id: props.eth_data.vip_id,
                user_id:props.user
            }) 

            if(verifyArt.data.code === 300){
                setErrormsg(verifyArt.data.msg)
            } else {
                return new Promise(resolve => {
                    apiClient.post(`${process.env.REACT_APP_API}/checkout/paymongo/create`, data).then(res => {
                        if(res.data.code == 200){
                            window.location = res.data.url;
                            } else if(res.data.code == 250){
                                window.location = res.data.url;
                            } else {
                                setErrormsg(res.data.msg)
                            }
                            resolve()
                        }).catch(error => { 
                                if(error.response.status == 401){
                                    console.log(error.response)
                                    setErrormsg(error.response.data.message)
                                }
                            resolve()
                        });
                    
                })
            }
        } catch (error) {
            if(error.code === 4001){
                setErrormsg(error.message)
            } else {
                setErrormsg('Transaction failedx')
            }
        }
    
    }

    const {isSubmitting} = formState;

    // useEffect(() => {
    //     // console.log(props);
    //     apiClient.get(`${process.env.REACT_APP_API}/account/user`)
    //         .then(res => {
    //             setUsers(res.data)
    //             reset({control_number:tr_no})
    //         })
    // },[reset])

    var rows = [], i = 0, len = 12;
    var years = [], x = year_ - 1, lenx = year_+30;
    while (++i <= len) rows.push(i);
    while (++x <= lenx) years.push(x);


    //check if project is bundle and show promt if reload or leave site
    if (redirect && props.art_bundle_qty) {
        window.addEventListener("beforeunload", (ev) => 
        {  
            ev.preventDefault();
            //revert reserved if transaction fail 
            if (props.art_bundle_qty) {
                apiClient.post(`${process.env.REACT_APP_API}/sale/random_bundle/process/cancel`, {tr_no: props.tr_no}).then(res => {
                    console.log(res);
                }).catch(e => {
                    console.log(e);
                })
            }
            return ev.returnValue = 'Are you sure you want to close?';
        });
    }
    
    

    return (
        <>

        <Modal 
                    show={props.show} 
                    onHide={props.onHide} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='checkout-modal'
                    backdrop="static">
                <Modal.Header >
                    <Modal.Title className='text-dark'>Credit/Debit Card Information</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onFiatPay)} >
                <Modal.Body>

                        {
                             errormsg ?  <div className='alert alert-danger'>{errormsg}</div> : ''
                        }
                        <input type="hidden" className='form-control' {...register("control_number", { required: true})} defaultValue={props.tr_no}/>
                        <input type="hidden" {...register("total_")} value={props.price_fiat} />
                        <input type="hidden" {...register("client_id")} defaultValue={props.user}/>
                        <input type="hidden" {...register("sales_type")} defaultValue={props.sales_type}/>
                        
                        {/* bundle */}
                        <input type="hidden" {...register("bundle_no")} defaultValue={props.bundle_no} />

                        <Form.Group className="mb-3" controlId="card_num">
                            <Form.Label className="font-weight-bold">Card Number</Form.Label>
                            <Form.Control type="text"  placeholder="Enter Your Card Number" className={`form-control ${ errors.card_number ? "is-invalid" : ""}`} {...register("card_number", { required: true})} />
                            {errors.card_number && <div className="invalid-feedback">This field is required</div>}

                        </Form.Group>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="month"  className="pl-0">
                                <Form.Label className="font-weight-bold">Month</Form.Label>
                                <select  className={`form-control ${ errors.month ? "is-invalid" : ""}`} {...register("month", { required: true})}>
                                    <option value=""></option>
                                    {rows.map(function (i) {
                                        return <option key={i} value={i}>{i}</option>;
                                    })}
                                </select>
                                {errors.month && <div className="invalid-feedback">This field is required</div>}

                            </Form.Group>
                                   
                            <Form.Group as={Col} controlId="year">
                                <Form.Label className="font-weight-bold">Year</Form.Label>
                                <select  className={`form-control ${ errors.year ? "is-invalid" : ""}`} {...register("year", { required: true})}>
                                    <option value=""></option>
                                    {years.map(function (i) {
                                        return <option key={i} value={i}>{i}</option>;
                                    })}
                                </select>
                                {errors.year && <div className="invalid-feedback">This field is required</div>}
                            </Form.Group>

                            <Form.Group as={Col} controlId="cv_code" className="pr-0">
                                <Form.Label className="font-weight-bold">CV Code</Form.Label>
                                <Form.Control type="text" className={`form-control ${ errors.cvc ? "is-invalid" : ""}`} {...register("cvc", { required: true})} />
                                {errors.cvc && <div className="invalid-feedback">This field is required</div>}
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3" controlId="card_owner">
                            <Form.Label className="font-weight-bold">Card Owner</Form.Label>
                            <Form.Control type="text"  placeholder="Enter Card Owner Name" className={`form-control ${ errors.card_owner ? "is-invalid" : ""}`} {...register("card_owner", { required: true})} />
                            {errors.card_owner && <div className="invalid-feedback">This field is required</div>}
                        </Form.Group>
                        
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={props.onHide}>
                        Close
                    </Button>
                    <Button variant="dark" type="submit">
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Proceed
                    </Button>
                </Modal.Footer>
                </Form>
                </Modal>
        </>
    )

}

export default Fiat;