import { useState } from "react";
import CountdownTimer from "../CountdownTimer/Countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const AuctionItem = (props) => {

    const btn_outline_red = {
        border: '1px solid #c31b01',
        backgroundColor: '#fffff',
        color: '#c31b01'
    }
    const [isEnded,setisEnded] = useState([]);
    const getCollectionStatus = (v) => {
        if(!isEnded.includes(v)){
            setisEnded(isEnded => [...isEnded,v]);
        }
    }
    const playIcon = <FontAwesomeIcon  icon="fa-solid fa-play" size='lg' />;
    const playBtn = {
        position: "absolute",
        background: "rgb(0 0 0 / 30%)",
        left:"45%",
        top: '40%',
        border:"0px",
        color:"#fff"
    };
    return (
        <div className="row border my-5 p-5">
            <div className="col-md-5">
                <div className="item-info">
			        <div className="item-thumb text-center">
					    <div className="image-over">
                            <img onClick={() => props.viewOtherImages('jpg',props.art_details._mint_file,'main',props.other_images,props.art_details._mint_file)} className="img-fluid" src={props.art_details && props.art_details._mint_file}/>
                        </div>
                    </div>
                </div>
                   <div className="row ">
                        {
                                props.other_images ?
                                props.other_images.map((images, i)=> {
                                    return(
                                    <div key={i} className="col-4 col-md-3 mt-2">
                                        {
                                            images.extension != 'mp4' ?
                                            <img onClick={() => props.viewOtherImages(images.extension,images.image_,i,props.other_images,props.art_details._mint_file)} className="img-fluid" src={images.image_}/>
                                            :   <div className="image-wrapper" style={{background:"#000"}}>
                                                 <a style={playBtn}>{playIcon}</a>
                                            <video onClick={() => props.viewOtherImages(images.extension,images.image_,i,props.other_images,props.art_details._mint_file)} preload="auto" muted= {true} autoPlay={false} controls= {false}  className="embed-responsive-item " 
                                            src={images.image_} style={{width: "100%",height:"70px"}} ></video>
                                        </div>
                                        }
                                       
                                    </div>
                                    )
                                } )

                                : ''
                           
                            
                        }
                        
			    </div>
            </div>
            <div className="col-md-7">
                <p className="my-2 font-weight-bolder" style={{fontSize: '20px'}}>{props.label}</p>
                <p className="my-2 font-weight-bold">Artist: {props.artist && props.artist.artist_name}</p>
                <p className="my-2 " dangerouslySetInnerHTML={{ __html: props.description}}></p>
                
                {
                    props.base_price  ?  <p className="mt-2 mb-2"><span className="font-weight-bold">Starting Price:</span> PHP {props.base_price && props.base_price.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}</p> : ""
                }
                {
                    props.highest_offer ? <p className="mt-2 mb-2"><span className="font-weight-bold">Highest Bid:</span> PHP {props.highest_offer && props.highest_offer.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}</p> : ""
                }

                {
                    props.isStarted ? 
                        !isEnded.includes(props.key) && props.auctionstatus == 1 ?
                    <CountdownTimer show={props.showTimer != 1 ?  'd-none' : ''} id={props.key} changeIsEnded={getCollectionStatus} targetDate={props.bid_clode_date} />
                    : "" : ""
                }

                { 
                    
                    props.free ? <p className="text-dark font-weight-bold text-uppercase  mt-2 mb-2" style={{cursor: "pointer"}} onClick={props.viewMerch}><u>{props.merch_title ? props.merch_title : "View Merch"}</u></p> : ""

                }
                {props.isStarted}
                {

                props.auctionallowed == 0 ||  !props.isStarted ?  <p className="my-2"><span className=" text-danger text-uppercase font-weight-bold">Coming Soon</span></p> : 
                    !isEnded.includes(props.key) && props.auctionstatus == 1 ?
                    <button type="button" className="btn btn-danger rounded-0 px-5 mt-2 mb-3" data-auction-id={props.auction_id} data-auction-art-id={props.auction_art_id} onClick={props.placeBids}> Place BID </button> : <><span className=" text-danger text-uppercase font-weight-bold">Auction Ended</span><br></br></>
                }

            </div>
        </div>
    );
}
 
export default AuctionItem;